import { Directive, Input, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[hmtRestrictInputDecimalPlaces]'
})
export class RestrictDecimalPlacesDirective {
  @Input() decimalPlaces: number | null = null;
  @Input() integerPlaces: number | null = null;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const inputValue: string = event.target.value;
    let regex: RegExp;
    const cursorPosition = event.target.selectionStart;

    if (this.integerPlaces !== null && this.decimalPlaces !== null) {
      const [integerPart, decimalPart] = inputValue.split('.');
      if (integerPart.length > this.integerPlaces || (decimalPart && decimalPart.length > this.decimalPlaces)) {
        event.target.value = `${integerPart.slice(0, this.integerPlaces)}${decimalPart ? '.' + decimalPart.slice(0, this.decimalPlaces) : ''}`;
        event.target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
        return;
      }
      regex = new RegExp(`^\\d{1,${this.integerPlaces}}(\\.\\d{0,${this.decimalPlaces}})?$`);
    } else if (this.integerPlaces !== null) {
      regex = new RegExp(`^\\d{1,${this.integerPlaces}}$`);
    } else if (this.decimalPlaces !== null) {
      regex = new RegExp(`^\\d+(\\.\\d{0,${this.decimalPlaces}})?$`);
    } else {
      return; // No restrictions
    }

    if (!regex.test(inputValue)) {
      event.target.value = inputValue.slice(0, -1); // Remove extra chars
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // Prevent typing of the dot character if decimalPlaces is not specified
    if (this.decimalPlaces === null && event.key === '.') {
      event.preventDefault();
    }
  }
}
