import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'hmt-vehicle-count-modifier',
  templateUrl: './vehicle-count-modifier.component.html',
  styleUrls: ['./vehicle-count-modifier.component.scss']
})
export class VehicleCountModifierComponent implements OnInit {

  @Output() modifiedCount: EventEmitter<number> = new EventEmitter();
  formGroup: FormGroup;

  constructor( public dialogRef: MatDialogRef<VehicleCountModifierComponent>,
               @Inject(MAT_DIALOG_DATA) public data: {count: number }) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.formGroup = new FormGroup({
      countControl: new FormControl(this.data.count ?? 0)
    });
  }

  add(): void {
    if (this.data?.count != null) {
      this.data.count += 1;
      this.formGroup.get('countControl').setValue(this.data.count);
    }
  }

  reduce(): void {
    if (this.data?.count) {
      this.data.count -= 1;
      this.formGroup.get('countControl').setValue(this.data.count);
    }
  }

  close() {
    this.modifiedCount.emit(+this.formGroup.get('countControl').value);
    this.dialogRef.close();
  }

}
