import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UPLOAD_FILE_MAXIMUM_SIZE} from '@configs/constants';
import {FileTypeExtension} from '@control-tower/models/enums';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UploadFiles} from '@control-tower/models/upload-files';

@Component({
  selector: 'hmt-attachment-picker',
  templateUrl: './attachment-picker.component.html',
  styleUrls: ['./attachment-picker.component.scss']
})
export class AttachmentPickerComponent implements OnInit {
  filteredFiles: File[] = [];
  fileNamesLargerThatMaxFileSize: string[] = [];
  selectedFiles: File[] = [];
  imageURL: string[] = [];
  formGroup: FormGroup;
  @Output() filesUpload = new EventEmitter<File[]>();

  constructor(private formBuilder: FormBuilder) {
    this.formGroup = this.formBuilder.group({
      selectedFiles: ['']
    });
  }

  ngOnInit(): void {
  }

  removeFile(fileName: string) {
    let index: number = null;
    for (let i = 0; i < this.filteredFiles.length; i++) {
      const file = this.filteredFiles[i];
      if (file.name === fileName) {
        index = i;
        break;
      }
    }
    if (index !== -1) {
      this.filteredFiles.splice(index, 1);
      this.selectedFiles.splice(index, 1);
      this.imageURL.splice(index, 1);
    }
  }

  onFilesSelect(fileList: FileList) {
    if (fileList && fileList.length) {
      const files = Array.from((fileList));
      files.forEach((file: File) => {
        if (file.size > UPLOAD_FILE_MAXIMUM_SIZE) {
          this.fileNamesLargerThatMaxFileSize.push(file.name);
        } else {
          if (!this.checkDuplicateRecords(file)) {
            this.filteredFiles.push(file);
            this.selectedFiles.push(file);
            this.setPreviewUrl(file);
          }
        }
      });
      this.formGroup.patchValue({
        ['selectedFiles'] : this.selectedFiles,
      });
      this.filesUpload.emit(this.selectedFiles);
    }
  }

  checkDuplicateRecords(file: File): boolean {
    for (const filteredFile of this.selectedFiles) {
      if (filteredFile.name === file.name) {
        return true;
      }
    }
    return false;
  }

  setPreviewUrl(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    switch (file.type) {
      case FileTypeExtension.IMAGE_PNG:
      case FileTypeExtension.IMAGE_JPEG:
        reader.onload = () => {
          this.imageURL.push(reader.result as string);
        };
        break;
      case FileTypeExtension.APPLICATION_MSWORD:
      case FileTypeExtension.APPLICATION_WORD:
      case FileTypeExtension.APPLICATION_DOX:
        this.imageURL.push('./assets/icons/doc-icon.svg');
        break;
      case FileTypeExtension.APPLICATION_PDF:
        this.imageURL.push('./assets/icons/pdf-icon.svg');
        break;
      default:
        this.imageURL.push('./assets/icons/no-image.svg');
        break;
    }
  }
}
