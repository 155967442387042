import {Component, Input, OnInit} from '@angular/core';
import {Jpm} from '@control-tower/models/jpm';
import {ResourceRunTimeData} from '@shared/models/resource-run-time-data.model';
import {EntityMap} from '@shared/models/types';
import {ResourceContributionModel} from '@control-tower/models/resource-contribution.model';
import * as moment from 'moment';
import {ResourceType} from "@schedule/models/enums";

@Component({
  selector: 'hmt-resources-details',
  templateUrl: './resources-details.component.html',
  styleUrls: ['./resources-details.component.scss']
})
export class ResourcesDetailsComponent implements OnInit {

  drivers: EntityMap<string, ResourceRunTimeData> = {};
  assistants: EntityMap<string, ResourceRunTimeData> = {};
  vehicles: EntityMap<string, ResourceRunTimeData> = {};
  trailers: EntityMap<string, ResourceRunTimeData> = {};
  object = Object;
  private trailerResources: ResourceContributionModel[];
  private assistantResources: ResourceContributionModel[];
  private driverResources: ResourceContributionModel[];
  private vehicleResources: ResourceContributionModel[];


  @Input('jpm') set setResources(jpm: Jpm) {
    if (jpm) {
      this.drivers = this.generateResourceContribution(jpm, 'DRIVER');
      this.assistants = this.generateResourceContribution(jpm, 'ASSISTANT');
      this.vehicles = this.generateResourceContribution(jpm, 'VEHICLE');
      this.trailers = this.generateResourceContribution(jpm, 'TRAILER');
    }
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  private calculateOdometerDistance(resource: ResourceContributionModel): number {
    const endOdometer = resource.odometerReadings.reduce((a, b) => (a + b.endOdometer), 0);
    const startOdometer = resource.odometerReadings.reduce((a, b) => (a + b.startOdometer), 0);
    return endOdometer - startOdometer;
  }

  generateResourceContribution(jpm: Jpm, resourceType: string): EntityMap<string, ResourceRunTimeData> {
    const resourceMap: EntityMap<string, ResourceRunTimeData> = {};
    this.vehicleResources = jpm.resourceContributions.filter(resource => resource.resourceType === 'VEHICLE');
    this.driverResources = jpm.resourceContributions.filter(resource => resource.resourceType === 'DRIVER');
    this.assistantResources = jpm.resourceContributions.filter(resource => resource.resourceType === 'ASSISTANT');
    this.trailerResources = jpm.resourceContributions.filter(resource => resource.resourceType === 'TRAILER');
    jpm.resourceContributions
      .filter(resource => resource.resourceType === resourceType)
      .forEach(resource => {
        const duration = this.calculateDuration(resource.startTime, resource.endTime);
        resourceMap[resource.resourceId] = {
          name: resource.resourceName,
          gpsDistance: resource.actualTripDistance,
          odometerDistance: this.calculateOdometerDistance(resource),
          duration,
          distanceContributionPercentage: this.calculateDistanceContributionPercentage(jpm, resource.actualTripDistance),
          durationContributionPercentage: this.calculateDurationPercentageByResourceType(resource, duration),
          odometerContributionPercentage: this.calculateOdometerContributionPercentage(jpm, resource)
        } as ResourceRunTimeData;

      });
    return resourceMap;
  }

  calculateDurationPercentageByResourceType(resource: ResourceContributionModel, duration: number){
    if (resource.resourceType === 'VEHICLE') {
      return this.calculateDurationContributionPercentage(this.vehicleResources, duration);
    }

    if (resource.resourceType === 'DRIVER') {
      return this.calculateDurationContributionPercentage(this.driverResources, duration);
    }

    if (resource.resourceType === 'ASSISTANT') {
      return this.calculateDurationContributionPercentage(this.assistantResources, duration);
    }

    if (resource.resourceType === 'TRAILER') {
      return this.calculateDurationContributionPercentage(this.trailerResources, duration);
    }
  }

  calculateDuration(startTime: Date, endTime: Date): number {
    return moment.duration(moment(endTime).diff(startTime)).asMinutes();
  }

  calculateDurationContributionPercentage(resources: ResourceContributionModel[], contributedDuration: number) {

    const totalDuration =  resources?.reduce((pre, cur) => {
      const duration  = moment.duration(moment(cur.endTime).diff(cur.startTime)).asMinutes();
      return pre + duration;
    }, 0);

    return +((contributedDuration / totalDuration) * 100).toFixed(2);
  }

  calculateDistanceContributionPercentage(jpm: Jpm, contributedDistance: number) {
    const totalDistance = this.getTotalGpsDistance(jpm.resourceContributions);
    if (!totalDistance) {
      return 0;
    }
    const distance =  (contributedDistance / totalDistance) * 100;

    if (!distance) {
      return 0;
    }

    return +distance.toFixed(2);
  }

  calculateOdometerContributionPercentage(jpm: Jpm, resource: ResourceContributionModel) {
    const endOdometer = resource.odometerReadings.reduce((a, b) => (a + b.endOdometer), 0);
    const startOdometer = resource.odometerReadings.reduce((a, b) => (a + b.startOdometer), 0);
    if (jpm.jobManualDistance === 0){
      return  0;
    }
    return +(((endOdometer - startOdometer) / jpm.jobManualDistance) * 100).toFixed(2);
  }

  getTotalGpsDistance(resourceContributions: ResourceContributionModel[]): number {
    return resourceContributions.filter(resourceContribution => resourceContribution.resourceType === ResourceType.DRIVER)
      .map(resourceContribution => resourceContribution.actualTripDistance).reduce((sum, current) => sum + current, 0);
  }

  validateResourceMap(map: EntityMap<string, ResourceRunTimeData>): boolean {
    return !!Object.keys(map).length;
  }
}
