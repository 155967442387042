import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ContainerTypeAndSize} from '@control-tower/models/jpm/jpm-container-type-and-size.model';
import { JobFile } from '@core/models/job-file';
import { UtilService } from '@schedule/services';
import {EntityMap} from '@shared/models/types';
import { UtilityService } from '@shared/services/utility.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'hmt-add-container-bulk',
  templateUrl: './add-container-bulk.component.html',
  styleUrls: ['./add-container-bulk.component.scss']
})
export class AddContainerBulkComponent implements OnInit, OnDestroy {
  public unsubscribe: Subject<void> = new Subject();
  formGroup: FormGroup;
  containers: EntityMap<string, {typeAndSize: ContainerTypeAndSize, containerNumber: string, order: number}[]> = {};
  containerTypesAndSizes: EntityMap<number, ContainerTypeAndSize[]> = {};
  typesAndSizes: ContainerTypeAndSize[] = [];

  @Input('containerTypesAndSizes') set setContainerTypesAndSizes(typesAndSizes: ContainerTypeAndSize[]) {
    // set all types and size to first container group
    this.typesAndSizes = typesAndSizes;
    this.containerTypesAndSizes[0] = typesAndSizes;
  }

  @Input() jobFile: JobFile;
  @Input() order: number;

  @Input('containerNumbers') set setContainerNumbers(containerData: { containerNumber: string, typeAndSize, order: number }[]) {
    if (containerData?.length > 0) {
      this.containers = containerData
        .filter(value => value.order === this.order)
        .reduce((prev: EntityMap<string, { typeAndSize: ContainerTypeAndSize, containerNumber: string, order: number }[]>,
                 curr: { typeAndSize: ContainerTypeAndSize, containerNumber: string, order: number }) => {
        if (prev[curr?.typeAndSize.length]) {
          prev[curr?.typeAndSize.length] = prev[curr?.typeAndSize.length].concat(curr);
        } else {
          prev[curr?.typeAndSize.length] = [curr];
        }
        return prev;
      }, {});
      this.createForm(this.containers);
    }
  }

  @Input('removeAllContainers') set setRemoveAllContainers(trigger: boolean) {
    if (trigger) {
      this.containers = {};
      this.containerTypesAndSizes = {};
      this.containerTypesAndSizes[0] = this.typesAndSizes;
      this.createForm();
    }
  }

  @Output() containerBulk = new EventEmitter<{containerNumber: string, typeAndSize: ContainerTypeAndSize, order: number}[]>();

  constructor(
    private formBuilder: FormBuilder,
    public utilService: UtilityService
  ) {
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(containerData?: EntityMap<string, {typeAndSize: ContainerTypeAndSize, containerNumber: string}[]>): void {
    this.formGroup = this.formBuilder.group({
      containerGroups: this.formBuilder.array(this.generateContainerGroup(containerData))
    });
  }

  generateContainerGroup(containerDataMap?: EntityMap<string, {typeAndSize:
      ContainerTypeAndSize, containerNumber: string}[]>): FormGroup[] {
    const containerData: {typeAndSize: ContainerTypeAndSize, containerNumber: string}[][] =
      (containerDataMap) ? Object.values(containerDataMap) : [];
    if (containerData?.length === 0 || !containerData)  {
      return [this.formBuilder.group({
        containerType: [''],
        containers: ['']
      })];
    }

    return Object.entries(containerData.flat(1).reduce((prev: EntityMap<string, string[]>,
                                                        curr: { containerNumber: string, typeAndSize: ContainerTypeAndSize}) => {
      if (prev[curr?.typeAndSize.label]) {
        prev[curr?.typeAndSize.label] = prev[curr?.typeAndSize.label].concat(curr.containerNumber);
      } else {
        prev[curr?.typeAndSize.label] = [curr.containerNumber];
      }
      return prev;
    }, {}))
      .map((group: [string, string[]], index) => {
        // set type and size arrays for each dropdown
        this.containerTypesAndSizes[index + 1] = this.containerTypesAndSizes[index].filter(type => type.label !== group[0]);
        return this.formBuilder.group({
          containerType: this.typesAndSizes.find(type => type.label === group[0]),
          containers: group[1].toString()
        });
      });

  }

  private get getContainerGroups(): FormArray {
    return this.formGroup.controls.containerGroups as FormArray;
  }

  addContainerGroup(): void {
    this.getContainerGroups.push(this.formBuilder.group({
      containerType: [''],
      containers: ['']
    }));
  }

  removeContainerGroup(index: number): void {
    if (this.getContainerGroups.length > 1) {
      this.getContainerGroups.removeAt(index);
    } else {
      this.getContainerGroups.at(index).reset();
    }
  }

  generateContainerNumbers(index?: number): void {
    const containerValues = this.getContainerGroups.at(index).value;
    this.generateContainerNumbersFromContainerGroups(containerValues);
  }

  generateContainerNumbersFromContainerGroups(containerValues: {containerType: ContainerTypeAndSize, containers: string}) {
    // if containers of specified size exist, then add or create new group
    if (
      containerValues &&
      containerValues?.containers &&
     containerValues.containers?.length > 0 && this.containers[containerValues?.containerType?.length]?.length > 0) {
      // remove duplicates that may occur when merging input array and existing array
      const filterList = this.containers[containerValues.containerType.length]
        .concat(containerValues?.containers?.split(/[\s,]+/)
          .map(container => ({typeAndSize: containerValues.containerType, containerNumber: container.trim(), order: this.order}))
          .filter(container => container?.containerNumber))
          .filter((elem, arrayIndex, self) => self.findIndex(
          (containerObj) => (containerObj.containerNumber === elem.containerNumber)) === arrayIndex);
      this.containers[containerValues?.containerType?.length] = filterList;
    } else {
      this.containers[containerValues.containerType.length] = containerValues.containers.split(/[\s,]+/)
        .map(container => ({typeAndSize: containerValues.containerType, containerNumber: container.trim(), order: this.order}))
        .filter(container => container?.containerNumber);
    }
  }

  setNextTypeAndSizeArray(index: number) {
    const containerValues = this.getContainerGroups.at(index).value;
    // create next container group types and sizes by excluding previously selected types and sizes
    this.containerTypesAndSizes[index + 1] =
      this.containerTypesAndSizes[index].filter(res => res?.label !== containerValues?.containerType?.label);

  }
  generateContainers(): void {
    const containerEntries = Object.entries(this.containers)
      .map(res => {
        return res[1].map(container =>
          ({containerNumber: container?.containerNumber, typeAndSize: container?.typeAndSize, order: container?.order}));
      }).flat(1);
    this.containerBulk.emit(containerEntries);
  }

  removeContainerNumber(type: string, containerNumber: string): void {
    this.containers[type] = this.containers[type]?.filter(container => container.containerNumber !== containerNumber);
    this.removeContainerNumberFromPastedString(containerNumber, type);
  }

  removeContainerNumberFromPastedString(containerNumber: string, type: string) {
    const controlIndex = this.getContainerGroups.getRawValue().findIndex(control => control?.containerType === type);
    const containerNumbersString: string = this.getContainerGroups.at(controlIndex).value.containers;
  }

  enableDisableGenerateContainers(): boolean {
    if (this.containers) {
      return Object.values(this.containers).length <= 0;
    }
    return false;
  }

  addAllContainers() {
    this.getContainerGroups.controls
      .forEach((control: AbstractControl) => {
        this.generateContainerNumbersFromContainerGroups(control?.value);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
