export const environment = {
  production: false,
  developer: 'Haulmatic',
  version: 2.2,
  haulmatic: {
    baseUrl: 'https://dev.app.haulmatic.com',
    apiGateway: 'https://dev.api.haulmatic.com'
  },
  google: {
    apiKey: 'AIzaSyBhHe3jP_tu9VkVCGZxVAVZW6bfWzcCiUY'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyADs6_CUHL09NnfZS7enmEnZWYpFCdYUKA',
    authDomain: 'infra-316905.firebaseapp.com',
    databaseURL: 'https://infra-316905-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'infra-316905',
    storageBucket: 'infra-316905.appspot.com',
    messagingSenderId: '263876098383',
    appId: '1:263876098383:web:41e60fee5f35849f6c6d3a',
    measurementId: 'G-CYV438Q6K9'
  }
};
