import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {API} from '@configs/api.config';
import {AddContainer} from '../models/add-container.model';
import {ContainerTypeAndSize} from '@control-tower/models/jpm/jpm-container-type-and-size.model';
import {DateMap, JobFile, JobFileLeg} from '@core/models/job-file';
import {WizardLocation} from '../models/locations.model';
import {JobFileLocation} from '@core/models/job-file/job-file-location.model';
import {CustomerType} from '../models/enums/customer-type.enum';
import {ActivityType} from '../models/enums/activity-type.enum';
import {InitLeg} from '../models/init-leg.model';
import {OrgPartner} from '../models/org-partner.model';
import {SavedRoute} from '../models/saved-route.model';
import {ServiceSequenceTemplate} from '../models/service-sequence-template.model';
import {JobFileServiceModel} from '@core/models/job-file/job-file-service.model';
import {CalculatedRoute} from '@shared/models/calculated-route.model';
import {LocationType} from '@shared/models';
import {GeoCoordinate} from '@shared/models/geo-coordinate';
import {BillingInfo} from "@core/models/job-file/billing-info.model";
import { MoveShipmentOrderData } from '../models/move-shipment-order.data';
import { BillingIndicator } from '../models/enums/billing-indicator-type.enum';
import { EntityMap } from '@shared/models/types';
import { VehicleDetails } from '@shared/models/vehicle-details.model';

@Injectable({
  providedIn: 'root'
})
export class ContainerManagementService {

  private addContainerInfoComponentSource = new Subject<{container: AddContainer}>();
  private loadSummaryComponentSource = new Subject<{containers: AddContainer[]}>();
  loadSummaryComponent$ = this.loadSummaryComponentSource.asObservable();
  addContainerInfoComponent$ = this.addContainerInfoComponentSource.asObservable();

  constructor(private httpClient: HttpClient) {
  }

  getHSCodes(): Observable<any[]> {
    const url = API.assets.getHSCodes;
    return this.httpClient.get<any[]>(url);
  }

  getContainerTypeAndSize(): Observable<ContainerTypeAndSize[]> {
    const url = API.assets.getContainerTypesAndSizes;
    return this.httpClient.get<ContainerTypeAndSize[]>(url);
  }

  createInitContainer(addContainers: AddContainer[], jobRefId: string, orgId: string, order: number): Observable<number> {
    const url = API.jobFile.createInitContainer.format([orgId, jobRefId]);
    return this.httpClient.post<number>(url, addContainers, {params: new HttpParams().set('order', (order) ? order.toString() : '')});
  }

  saveInitContainer(addContainers: AddContainer[], jobRefId: string, orgId: string, order: number): Observable<number> {
    const url = API.jobFile.saveInitContainer.format([orgId, jobRefId]);
    return this.httpClient.post<number>(url, addContainers, {params: new HttpParams().set('order', (order) ? order.toString() : '')});
  }

  generateLegsFromWizardLocations(locations: WizardLocation[]): Observable<InitLeg[]> {
    const url = API.jobFile.generateInitLegs;
    return this.httpClient.post<InitLeg[]>(url, locations);
  }

  getFumigators(orgId: string, orgFunctions): Observable<OrgPartner[]> {
    const url = API.fleetManager.getOrgPartnerByOrganizationFunction.format([orgId, orgFunctions]);
    return this.httpClient.get<OrgPartner[]>(url);
  }

  mapContainerTypeDescriptionToContainerTypeAndSizeModel(containerTypeAndSizes: ContainerTypeAndSize[], containerTypeDescription: string)
    : ContainerTypeAndSize {
    const mappedContainerTypeAndSize = containerTypeAndSizes
      .find(containerTypeAndSize => containerTypeAndSize.label === containerTypeDescription);
    return mappedContainerTypeAndSize ? {...mappedContainerTypeAndSize} : null;
  }

  calculateDistanceBetweenStartAndEnd(legs: JobFileLeg[]): number {
    const distance = legs.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.distance;
    }, 0);
    return distance;
  }

  getWizardLocationsFromJobFileContainerLegs(jobFileLegs: JobFileLeg[]): WizardLocation[] {
    const wizardLocations: WizardLocation[] = [];
    if (jobFileLegs.length === 0) {
      return wizardLocations;
    }
    const startLocation = this.mapJobFileLegLocationToWizardLocation(jobFileLegs[0].startLocation);
    return [
      startLocation,
      ...jobFileLegs.map(leg => this.mapJobFileLegLocationToWizardLocation(leg.endLocation, leg.wayPoints, leg.useTolls, leg.activityType))
    ];
  }

  mapJobFileLegLocationToWizardLocation(jobFileLocation: JobFileLocation,
                                        wayPoints: GeoCoordinate[] = [], useTolls = false, activityType?: ActivityType)
    : WizardLocation {
    return {
      wayPoints,
      type: jobFileLocation.type,
      locationId: jobFileLocation.id,
      locationName: jobFileLocation.name,
      geoLocation: jobFileLocation.geoLocation,
      activityType,
      useTolls
    } as WizardLocation;
  }

  createFullRouteUsingLegsForContainer(container: AddContainer) {
    this.addContainerInfoComponentSource.next({container});
  }

  updateLoadsSummary(containersMap: EntityMap<string, AddContainer>) {
    const containers: Array<AddContainer>  = []
    for (const key in containersMap) {
      if (containersMap.hasOwnProperty(key)) {
        containers.push(containersMap[key]);
      }
    }
    this.loadSummaryComponentSource.next({containers});
  }

  getCustomerType(workflowId: string): CustomerType {
    if (workflowId.startsWith('OF-IM')) {
      return CustomerType.CONSIGNEE;
    } else if (workflowId.startsWith('OF-EX')) {
      return CustomerType.SHIPPER;
    } else {
      return CustomerType.SHIPPING_LINE;
    }
  }

  getRelevantContainerDate(dateList: DateMap[], workflowId: string): DateMap {
    const jobType = workflowId.split('-')[1];
    if ('EX' === jobType) {
      return dateList.find(dateMap => dateMap.title === 'Cargo Cut off Date');
    } else if ('IM' === jobType) {
      return dateList.find(dateMap => dateMap.title === 'Cargo Clearance Date');
    } else if ('LH' === jobType) {
      return dateList.find(dateMap => dateMap.title === 'Cargo Cut off Date');
    }
    return null;
  }
  getContainer(containerId: string, jobRefId: string, orgId: string): Observable<AddContainer> {
    const url = API.jobFile.getContainer.format([orgId, jobRefId, containerId]);
    return this.httpClient.get<AddContainer>(url);
  }

  getContainerByOrder(order: number, jobRefId: string, orgId: string): Observable<AddContainer[]> {
    const url = API.jobFile.getContainerByOrder.format([orgId, jobRefId, order.toString()]);
    return this.httpClient.get<AddContainer[]>(url);
  }

  sendForApproval(jobRefId: string, orgId: string, order: number): Observable<boolean> {
    const url = API.jobFile.sendForApproval.format([orgId, jobRefId, order.toString()]);
    return this.httpClient.get<boolean>(url);
  }

  getAlreadySavedRoutes(jobRefId: string, orgId: string, searchKey: string): Observable<SavedRoute[]> {
    const url = API.jobFile.getContainerTemplates.format([orgId, jobRefId , searchKey]);
    return this.httpClient.get<SavedRoute[]>(url);
  }

  getAlreadySavedRoutesByOperationId(jobRefId: string, orgId: string, operationId: string, searchKey: string): Observable<ServiceSequenceTemplate[]> {
    const url = API.jobFile.getContainerTemplatesByOperation.format([orgId, jobRefId , operationId, searchKey]);
    return this.httpClient.get<ServiceSequenceTemplate[]>(url);
  }

  removeTemplate(templateId: string): Observable<any> {
    const url = API.jobFile.removeTemplate.format([templateId]);
    return this.httpClient.delete<string>(url);
  }

  saveServiceSequenceTemplate(serviceSequenceTemplate: ServiceSequenceTemplate): Observable<ServiceSequenceTemplate> {
    const url = API.jobFile.saveServiceSequenceTemplate;
    return this.httpClient.post<ServiceSequenceTemplate>(url, serviceSequenceTemplate);
  }

  updateServiceSequenceTemplate(serviceSequenceTemplate: ServiceSequenceTemplate): Observable<ServiceSequenceTemplate> {
    const url = API.jobFile.updateServiceSequenceTemplate;
    return this.httpClient.patch<ServiceSequenceTemplate>(url, serviceSequenceTemplate);
  }

  updateService(orgId: string, jobRefId: string, containerId: string, service: JobFileServiceModel): Observable<JobFileServiceModel> {
    const url = API.jobFile.updateService.format([orgId, jobRefId, containerId]);
    return this.httpClient.post<JobFileServiceModel>(url, service);
  }

  addService(orgId: string, jobRefId: string, containerId: string, index: number, service: JobFileServiceModel)
    : Observable<JobFileServiceModel> {
    const url = API.jobFile.addService.format([orgId, jobRefId, containerId, index.toString()]);
    return this.httpClient.post<JobFileServiceModel>(url, service);
  }

  updateChannelAndAddDryService(orgId: string, jobRefId: string, containerId: string, index: number,
                                activityId: string, customsChannel: string, operationId?: string, workflowId?: string)
    : Observable<JobFileServiceModel> {
    const url = API.jobFile.updateChannelAndAddDryService.format([orgId, jobRefId, containerId, index.toString(),
      activityId, customsChannel, operationId, workflowId]);
    return this.httpClient.post<JobFileServiceModel>(url, null);
  }

  generateDryServiceFromActivityId(activityId: string,
                                   billingActivated: boolean, billingIndicatorType: BillingIndicator, jobRefId: string, containerId: string, serviceId: string): Observable<JobFileServiceModel> {
    const url = API.jobFile.generateDryServiceByActivityId.format([activityId, String(billingActivated ? billingActivated : false), billingIndicatorType, jobRefId, containerId]);

    let params = new HttpParams();
    params = params.set('serviceId', (serviceId) ? serviceId : '');
    return this.httpClient.post<JobFileServiceModel>(url, null, {params});

  }

  deleteService(orgId: string, jobRefId: string,
                containerId: string, serviceId: string): Observable<AddContainer> {
    const url = API.jobFile.removeService.format([orgId, jobRefId, containerId, serviceId]);
    return this.httpClient.get<AddContainer>(url);
  }

  applyServiceSequenceTemplate(orgId: string, jobRefId: string, containerId: string, templateName: string): Observable<AddContainer> {
    const url = API.jobFile.applyServiceSequenceTemplate.format([orgId, jobRefId, containerId, templateName]);
    return this.httpClient.post<AddContainer>(url, null);
  }

  removeServiceSequenceTemplate(templateId: string): Observable<AddContainer> {
    const url = API.jobFile.removeTemplate.format([templateId]);
    return this.httpClient.delete<AddContainer>(url);
  }

  duplicateContainer(orgId: string, jobRefId: string, containerId: string,
                     newOrder?: boolean, noOfContainers?: number): Observable<number> {
    const url = API.jobFile.duplicateContainer.format([orgId, jobRefId, containerId]);
    let params = new HttpParams();
    params = params.set('newOrder', (newOrder) ? String(newOrder) : String(false));
    params = params.set('noOfContainers', (noOfContainers) ? String(noOfContainers) : '');
    return this.httpClient.post<number>(url, null, {params});
  }

  addNewContainer(orgId: string, jobRefId: string, vehicleDetails: VehicleDetails): Observable<AddContainer[]> {
    const url = API.jobFile.addNewContainer.format([orgId, jobRefId]);
    return this.httpClient.post<AddContainer[]>(url, vehicleDetails);
  }

  removeContainer(orgId: string, jobRefId: string, containerId: string): Observable<string> {
    const url = API.jobFile.removeContainer.format([orgId, jobRefId, containerId]);
    return this.httpClient.post<string>(url, null);
  }

  calculateRouteDetails(fromLocId: string, toLocId: string)
    : Observable<CalculatedRoute> {
    const url = API.jobFile.calculateRouteDetails.format([fromLocId, toLocId]);
    return this.httpClient.get<CalculatedRoute>(url);
  }

  updateContainer(orgId: string, jobRefId: string, addContainer: AddContainer): Observable<string> {
    const url = API.jobFile.updateContainer.format([orgId, jobRefId, addContainer.containerId]);
    return this.httpClient.post<string>(url, addContainer);
  }

  updateOrdersData(orgId: string, jobRefId: string, moveShipmentOrderData: MoveShipmentOrderData): Observable<JobFile> {
    const url = API.jobFile.updateOrdersData.format([orgId, jobRefId]);
    return this.httpClient.post<JobFile>(url, moveShipmentOrderData);
  }

  getUpdatedContainerByExpressWays(orgId: string, jobRefId: string, containerId: string, useExpressWays: boolean): Observable<AddContainer> {
    const url = API.jobFile.updateContainerByUseExpressWayOption.format([orgId, jobRefId, containerId, useExpressWays.toString()]);
    return this.httpClient.post<AddContainer>(url, null);
  }

  getUpdatedMapDistanceByExpressWays(orgId: string, jobRefId: string, containerId: string, useExpressWays: boolean, legs: InitLeg[]): Observable<AddContainer> {
    const url = API.jobFile.updateMapDistanceByUseExpressWayOption.format([orgId, jobRefId, containerId, useExpressWays.toString()]);
    return this.httpClient.post<AddContainer>(url, legs);
  }

  optimizeOrderMovedContainers(orgId: string, jobRefId: string, fromContainerId: string, toContainerId: string){
    const url = API.jobFile.optimizeOrderMovedContainers.format([orgId, jobRefId, fromContainerId, toContainerId]);
    return this.httpClient.get(url);
  }

  partiallySaveContainerData(orgId: string, jobRefId: string, addContainer: AddContainer): Observable<string> {
    const url = API.jobFile.saveContainerData.format([orgId, jobRefId]);
    return this.httpClient.post<string>(url, addContainer);
  }

  updateContainerWhenAddWayPoint(orgId: string, jobRefId: string, addContainer: AddContainer): Observable<string> {
    const url = API.jobFile.updateContainerWhenAddWayPoint.format([orgId, jobRefId]);
    return this.httpClient.post<string>(url, addContainer);
  }

  saveContainerBulk(orgId: string, jobRefId: string,
                    containerData: {containerNumber: string, typeAndSize: ContainerTypeAndSize, order: number}[],
                    order: number, newOrder?: boolean) {
    const url = API.jobFile.saveContainerBulk.format([orgId, jobRefId, order.toString()]);
    let params = new HttpParams();
    params = params.set('newOrder', (newOrder) ? String(newOrder) : String(false));
    return this.httpClient.post<number>(url, containerData, {params});
  }

  removeAllContainers(orgId: string, jobRefId: string, order: number): Observable<string> {
    const url = API.jobFile.removeAllContainers.format([orgId, jobRefId, order.toString()]);
    return this.httpClient.delete<string>(url);
  }

  updateCustomsChannel(orgId: string, jobRefId: string, containerId: string, customsChannel: string): Observable<boolean> {
    const url = API.jobFile.updateCustomsChannel.format([orgId, jobRefId, containerId, customsChannel]);
    return this.httpClient.post<boolean>(url, null);
  }

  updateChannelAndRemoveService(orgId: string, jobRefId: string, containerId: string,
                                serviceId: string, customsChannel: string): Observable<boolean> {
    const url = API.jobFile.updateChannelAndRemoveService.format([orgId, jobRefId, containerId, serviceId, customsChannel]);
    return this.httpClient.post<boolean>(url, null);
  }

  setBilling(orgId: string, jobRefId: string, containerId: string, serviceId: string, billingInfo: BillingInfo): Observable<JobFileServiceModel> {
    const url = API.jobFile.setBillingStartAndEnd.format([orgId, jobRefId, containerId, serviceId]);
    return this.httpClient.post<JobFileServiceModel>(url, billingInfo);
  }

}
