<mat-form-field class="chip-list">
  <mat-label class="uppercase">{{label}}</mat-label>
  <mat-chip-list #chipList aria-label="Item selection">
    <input
      [placeholder]="placeholder"
      #selectedItemInput
      [formControl]="formControl"
      [matAutocomplete]="autoComplete"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
  </mat-chip-list>
  <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="selectItem($event.option.value)">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      {{item?.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-chip
  *ngFor="let item of selectedItems"
  [selectable]="selectable"
  [removable]="removable"
  (removed)="remove(item)" class="chip">
  {{item?.name}}
  <mat-icon matChipRemove *ngIf="removable" class="cancel">cancel</mat-icon>
</mat-chip>
