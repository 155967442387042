import { Injectable } from '@angular/core';
import {CostDescriptionModel} from '@core/models/cost-description.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API} from '@configs/api.config';

@Injectable({
  providedIn: 'root'
})
export class CostService {

  constructor(private httpClient: HttpClient) { }

  searchCost(keyword: string): Observable<CostDescriptionModel[]> {
    const url = API.rateCard.getCost.format([keyword]);
    return this.httpClient.get<CostDescriptionModel[]>(url);
  }
}
