import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {VehicleType} from '@schedule/models/enums';
import {LiveVehicleInfo} from '@control-tower/models/live-vehicle-info.model';
import {SpeedModel} from '@control-tower/models/Speed.model';

@Component({
  selector: 'hmt-live-map-vehicle-details',
  templateUrl: './live-map-vehicle-details.component.html',
  styleUrls: ['./live-map-vehicle-details.component.scss']
})
export class LiveMapVehicleDetailsComponent implements OnInit, OnDestroy {

  @Input() liveVehicleInfo: LiveVehicleInfo;
  @Input() speeds: SpeedModel;
  interval = null;
  number = Number;
  isCurrentLegAverageSpeed = false;

  constructor() { }

  ngOnInit(): void {
  }

  truncate(sentence: string): string {
    if (sentence && sentence.length > 13) {
      return sentence.substr(0, 13) + '..';
    }
    return sentence;
  }

  changeAverageSpeedType() {
    this.isCurrentLegAverageSpeed = !this.isCurrentLegAverageSpeed;
  }

  ngOnDestroy() {
  }
}
