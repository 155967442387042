<div class="legend">
  <div class="planned-route">
    <div class="legend-style dash"></div>
    <div class="legend-name">Planned Route</div>
  </div>
  <div class="actual-route">
    <div><b>Actual</b> :</div>
    <div class="on-time">
      <div class="legend-style green"></div>
      <div class="legend-name">On Time</div>
    </div>
    <div class="delayed">
      <div class="legend-style red"></div>
      <div class="legend-name">Delayed</div>
    </div>
  </div>
</div>