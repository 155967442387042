export enum PackageType {
  BAGS = 'BAGS',
  BALES = 'BALES',
  BARRELS = 'BARRELS',
  BOXES = 'BOXES',
  BULK = 'BULK',
  BUNDLES = 'BUNDLES',
  CARTOONS = 'CARTOONS',
  CASES = 'CASES',
  CRATES = 'CRATES',
  DRUMPS = 'DRUMPS',
  METERS = 'METERS',
  NUMBERS = 'NUMBERS',
  PACKAGES = 'PACKAGES',
  PACKS = 'PACKS',
  PALLETS = 'PALLETS',
  PIECES = 'PIECES',
  PLATES = 'PLATES',
  REELS = 'REELS',
  ROLLS = 'ROLLS',
  SETS = 'SETS',
  UNITS = 'UNITS',
  W_BOXES = 'W_BOXES',
  OTHERS = 'OTHERS'
}
