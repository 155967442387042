import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ZUUL } from "@configs/api.config";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({providedIn: 'any'})
export class DynamicFormService {
    constructor(private httpClient: HttpClient, private router: Router) {}

    // TODO: handle post method as well
    fetchValuesForDropdown(dataSourceUrl: string, isSuffix = false, processUrlParams = false, paramsMap = {}, queryParams = {}): Observable<unknown> {
        let processedUrlSuffix = dataSourceUrl;
        if (processUrlParams) {
            processedUrlSuffix = this.processUrlPrams(paramsMap, dataSourceUrl);
        }
        // TODO: implement full pagination. the page number and page size is hardcoded. this is a quick fix.
        queryParams['pageNum'] = '0';
        queryParams['pageSize'] = '100';
        const processedQueryParams = this.processQueryParams(queryParams);
        const url = isSuffix ? `${ZUUL}${processedUrlSuffix}` : processedUrlSuffix;
        return this.httpClient.get<any[]>(url, {params: processedQueryParams});
    }

    uploadFile(dataSourceUrl: string, file: File, isSuffix = false, processUrlParams = false, paramsMap = {}, queryParams = {}) {
        let processedUrlSuffix = dataSourceUrl;
        if (processUrlParams) {
            processedUrlSuffix = this.processUrlPrams(paramsMap, dataSourceUrl);
        }
        const processedQueryParams = this.processQueryParams(queryParams);
        const url = isSuffix ? `${ZUUL}${processedUrlSuffix}` : processedUrlSuffix;
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.httpClient.post(url, formData, { params: processedQueryParams, responseType: 'arraybuffer', observe: 'response' }).pipe(
            map(response => {
                const contentType: string = response.headers.get('content-type');
                const isJsonResponse: boolean = contentType && contentType.includes('application/json');
                if (isJsonResponse) {
                    return JSON.parse(new TextDecoder().decode(response.body));
                }
                return response.body;
            }),
            catchError(error => { throw new Error('Order file processing failed. Please verify column headings or file format.') }
        )
        )
    }

    navigateTo(url: string, queryParams: any): void {
        this.router.navigate([url], {queryParams})
    }

    private processUrlPrams(paramsMap: {[key: string]: string}, url: string): string {
        let apiString = url;
        return Object.keys(paramsMap)
            .reduce((prev: string, param: string, index: number) => {
                prev = prev.replace(param, paramsMap[param]);
                return prev;
              }, apiString);
    }

    private processQueryParams(queryParamsMap: {[key: string]: any}): HttpParams {
        // TODO: fix the limitations in this code
        const keys = Object.keys(queryParamsMap);
        let httpParams = new HttpParams();
        const getStringValue = (k) => typeof queryParamsMap[k] === 'object' ? Array.isArray(queryParamsMap[k]) ? queryParamsMap[k].toString() : JSON.stringify(queryParamsMap[k]) : queryParamsMap[k]
        keys.forEach(k => {
            httpParams = httpParams.set(k, getStringValue(k));
        });

        return httpParams;
    }

}
