import {Component, Input, OnInit} from '@angular/core';
import {EntityMap} from '@shared/models/types';
import {RecipientDetails} from '../../models/recipient-details.model';

@Component({
  selector: 'hmt-recipient-details',
  templateUrl: './recipient-details.component.html',
  styleUrls: ['./recipient-details.component.scss']
})
export class RecipientDetailsComponent implements OnInit {
  @Input() data: {
    headers: {
      title: string,
      columnNames: string[],
      tableHeaders: string[],
      subDocuments: EntityMap<string, string[]>
    },
    recipientDetails: RecipientDetails[]
  };
  columnNames: string[] = [];
  header: string;
  tableHeaders = [];
  subDocuments: EntityMap<string, string[]>
  dataSource: RecipientDetails[] = [];

  constructor() { }

  ngOnInit(): void {
    this.dataSource = this.data.recipientDetails;
    this.columnNames = this.data.headers.columnNames;
    this.tableHeaders = this.data.headers.tableHeaders;
    this.subDocuments = this.data.headers.subDocuments;
  }
}
