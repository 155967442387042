<div class="app-wrapper">
  <div class="app-header">
    <hmt-header (toggle)="onToggle()"></hmt-header>
  </div>
  <div class="app-content animate-show-hide" id="menu-toggle">
    <mat-sidenav-container class="nav-container" autosize>
      <mat-sidenav #sidenav class="main-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
        <ul class="item-list">
          <li class="item" *ngFor="let moduleConfig of navBarModuleConfigs$ | async; let i = index;" (click)="collapseModule(moduleConfig)" [class.disabled]="moduleConfig?.disabled">
            <div class="item-wrapper" matTooltip="{{moduleConfig.title}}">
              <img [src]="moduleConfig.iconPath" class="icon-color icon-size" alt="dashboard">
              <span class="icon-color full-width icon-label" *ngIf="expanded">{{moduleConfig.title}}</span>
              <!-- <ng-container *ngIf="expanded && moduleConfig.subPagesConfigs.length > 0">
                <span class="material-icons icon-arrow" *ngIf="!moduleConfig.collapsed">keyboard_arrow_right</span>
                <span class="material-icons icon-arrow" *ngIf="moduleConfig.collapsed">keyboard_arrow_down</span>
              </ng-container> -->
            </div>
            <!-- <ul class="item-list sub-list" *ngIf="expanded && moduleConfig.collapsed">
              <li class="item sub-item" *ngFor="let subPageConfig of moduleConfig.subPagesConfigs" (click)="navigateTo(subPageConfig)">
                <div class="item-wrapper">
                  <img [src]="subPageConfig.imageUrl" class="icon-color icon-size" alt="{{subPageConfig?.name}}">
                  <span class="icon-color full-width icon-label">{{subPageConfig?.name}}</span>
                </div>
              </li>
            </ul> -->
          </li>
        </ul>
<!--        <div class="developer">-->
<!--          <img src="./assets/icons/question.svg" class="icon-color question-icon" alt="dashboard">-->
<!--          <span class="vertical icon-color version">{{ version }}</span>-->
<!--          <span class="name icon-color">{{ developer }}</span>-->
<!--        </div>-->
      </mat-sidenav>

      <div class="sidenav-content">
        <router-outlet></router-outlet>
      </div>

    </mat-sidenav-container>
  </div>
</div>

