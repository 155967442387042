import { Injectable } from '@angular/core';
import {KeyValueComponent} from '../components/key-value/key-value.component';
import {JobImformationComponent} from '@shared/components/job-imformation/job-imformation.component';
import {CustomerDetailsComponent} from '../components/customer-details/customer-details.component';
import {TransportPlanComponent} from '../components/transport-plan/transport-plan.component';
import {InformationTableComponent} from '../components/information-table/information-table.component';
import {RecipientDetailsComponent} from '../components/recipient-details/recipient-details.component';
import { VehicleCapacityComponent } from '../../dynamic-form/components/vehicle-capacity/vehicle-capacity.component';

@Injectable({
  providedIn: 'root'
})
export class ComponentRegistryService {
  componentsMap: {[key: string]: any} = {
    keyValue: KeyValueComponent,
    jobDetails: JobImformationComponent,
    orgInfo: CustomerDetailsComponent,
    transportPlan: TransportPlanComponent,
    informationTable: InformationTableComponent,
    recipientDetails: RecipientDetailsComponent,
    vehicleCapacities: VehicleCapacityComponent,
  };

  constructor() { }

  getComponent(componentName: string): any {
    return this.componentsMap[componentName];
  }
}
