import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TerminateReason} from '@shared/models/enums/terminate-reason.enum';
import {MatDialogRef} from '@angular/material/dialog';
import {TerminateJobQutModel} from '@shared/models/terminate-job-qut-model';

@Component({
  selector: 'hmt-terminate-job-shared',
  templateUrl: './terminate-job-shared.component.html',
  styleUrls: ['./terminate-job-shared.component.scss']
})
export class TerminateJobSharedComponent implements OnInit {
  formGroup: FormGroup;

  @Output() terminateJobQut: EventEmitter<TerminateJobQutModel> = new EventEmitter<TerminateJobQutModel>();

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TerminateJobSharedComponent>
  ){ }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.formGroup = this.formBuilder.group({
      reason: [],
      terminateDate: [],
      invoiceCreated: [],
      comments: [],
    });
  }

  setTerminateType(type: string) {
    this.formGroup.controls.reason.patchValue(type);
  }

  save() {
    this.terminateJobQut.emit(this.formGroup.value);
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

}
