import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogHandlerService} from '@core/services/dialog-handler.service';
import {RateTier} from '../models/rate-tier.model';
import {Observable} from 'rxjs';
import { AdditionalCost } from '@shared/models/additional-cost.model';


@Component({
  selector: 'hmt-create-tier',
  templateUrl: './create-tier.component.html',
  styleUrls: ['./create-tier.component.scss']
})
export class CreateTierComponent implements OnInit {
  rateTierForm: FormGroup;
  @Output() savetier = new EventEmitter<RateTier>();
  @Output() updateTier = new EventEmitter<RateTier>();

  constructor(public dialogRef: MatDialogRef<CreateTierComponent>,
              private formBuilder: FormBuilder,
              public dialogHandlerService: DialogHandlerService,
              @Inject(MAT_DIALOG_DATA) public data: {
                orgId: string,
                tiers: Observable<RateTier[]>;
                selectedTier?: RateTier,
                edit: boolean
              }
  ) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm(){
    this.rateTierForm = this.formBuilder.group({
      name: [this.data.edit ? this.data.selectedTier.name : ''],
      description: [this.data.edit ? this.data.selectedTier.description : ''],
      fuelCost: [this.data.edit ? this.data.selectedTier.fuelCost : ''],
      tyreCost: [this.data.edit ? this.data.selectedTier.tyreCost : ''],
      partCost: [this.data.edit ? this.data.selectedTier.partCost : ''],
      orgId: [this.data.orgId],
      // locName: [this.data.edit ? this.data.selectedTier.locName : ''],
      // minKmPerMonth: [this.data.edit ? this.data.selectedTier.minExpMonthlyIncome : ''],
      // minExpMonthlyIncome: [this.data.edit ? this.data.selectedTier.minExpMonthlyIncome : ''],
      additionalCost: this.formBuilder.array(
        this.data.edit ? this.data.selectedTier.additionalCost.map(c => this.newCostItem(c))  : []),
    });
  }

  close() {
    this.dialogRef.close();
  }

  newCostItem(cost?: AdditionalCost): FormGroup {
    return this.formBuilder.group({
      costName: [cost ? cost.costName : ''],
      cost: [cost ? cost.cost : '']
    });
  }

  get costItems(): FormArray {
    return this.rateTierForm.get('additionalCost') as FormArray;
  }

  addNewTireCost() {
    this.costItems.push(this.newCostItem());
  }

  removeTireCost(i: number) {
    this.costItems.removeAt(i);
  }

  getTotalCostPerKm(): number {
    const {fuelCost = 0, tyreCost = 0, partCost = 0, additionalCost = []} = this.rateTierForm.value;
    return fuelCost + tyreCost + partCost + additionalCost.reduce((sum: number, curr: {cost: number}) => sum + +curr.cost, 0);
  }

  saveNewTier() {
    const tier = this.rateTierForm.value;
    if (this.data.edit) {
      this.updateTier.emit({
        ...this.data.selectedTier,
        ...tier
      });
    } else {
      this.savetier.emit({
        ...tier,
        locName: 0, // TODO: remove below properties from backend and here
        minKmPerMonth: 0,
        minExpMonthlyIncome: 0
      });
    }

    this.close();
  }
}

