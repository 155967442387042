import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberToText'
})
export class NumberToTextPipe implements PipeTransform {

  transform(value: number, currency: string): string {
    const text = this.numberToText(value);
    const cents = +this.getCents(value) === 0 ? '' : ' and ' + this.numberToText(+this.getCents(value)) + ' Cents';
    return  currency + ' ' + text +   cents  + ' Only.';
  }

  getCents(num) {
    return (num % 1 * 100).toFixed(2);
  }

  numberToText(num: number) {
    const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const thousands = ['', 'Thousand', 'Million', 'Billion'];

    if (num === 0) {
      return 'Zero';
    }

    if (num < 0) {
      return 'Minus ' + this.numberToText(Math.abs(num));
    }

    let text = '';
    let chunk;
    let chunkText;
    let chunkLength;
    let chunkRemainder;

    for (let i = 3; i >= 0; i--) {
      const divisor = Math.pow(10, i * 3);
      if (num >= divisor) {
        chunk = Math.floor(num / divisor);
        num -= chunk * divisor;
        chunkLength = chunk.toString().length;
        chunkRemainder = chunk;
        while (chunkLength > 0) {
          if (chunkLength === 3) {
            chunkText = ones[Math.floor(chunkRemainder / 100)] + ' Hundred';
            chunkRemainder %= 100;
            chunkLength--;
            if (chunkRemainder > 0) {
              chunkText += '';
            }
          } else if (chunkLength === 2) {
            if (chunkRemainder >= 10 && chunkRemainder < 20) {
              chunkText = teens[chunkRemainder - 10];
              chunkRemainder = 0;
              chunkLength = 0;
            } else {
              chunkText = tens[Math.floor(chunkRemainder / 10)];
              chunkRemainder %= 10;
              chunkLength--;
              if (chunkRemainder > 0) {
                chunkText += '';
              }
            }
          } else {
            chunkText = ones[chunkRemainder];
            chunkRemainder = 0;
            chunkLength--;
          }
          text += chunkText + ' ';
        }
        text += thousands[i] + ' ';
      }
    }
    return text.trim();
  }
}
