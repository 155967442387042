import {Injectable} from '@angular/core';
import {MultiSelectEntry} from '@shared/models/multi-select-entry';
import {OrganizationLocation} from '../../modules/organization/models/organization-location';
import {GatewayLocation} from '@shared/models/gateway-location.model';
import {Point} from '@shared/models/point';
import {GeoLocation} from '@shared/models/geo-location.model';
import {Polygon} from '@shared/models/polygon';
import {Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {Location, LocationType} from '@shared/models';
import {AttachmentModel} from '../../modules/fuel/models/attachment.model';
import {HttpClient} from '@angular/common/http';
import { LoadType } from '@shared/models/enums/load-type.enum';
import { OrgLocation } from 'app/modules/wizard/models/org-location.model';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private httpClient: HttpClient) {}

  getEnumKeyByEnumValue(relevantEnum, value): string {
    const keys = Object.keys(relevantEnum).filter(
      (enumEntry) => relevantEnum[enumEntry] === value
    );
    return keys.length > 0 ? keys[0] : null;
  }

  getMultiSelectEntryFromEnum(enumKey, relevantEnum): MultiSelectEntry {
    return Object.entries(relevantEnum)
      .map((status) => ({ id: status[0], name: status[1] }))
      .find((item) => item.id === enumKey) as MultiSelectEntry;
  }

  mapOrganizationLocationToGatewayLocationAsObservable(
    organizationLocation: Observable<OrganizationLocation>
  ): Observable<GatewayLocation> {
    return organizationLocation.pipe(
      mergeMap((item) => {
        return of(this.mapOrganizationLocationToGatewayLocation(item));
      })
    );
  }

  mapOrganizationLocationToGatewayLocation(
    organizationLocation: OrganizationLocation
  ): GatewayLocation {
    return {
      id: organizationLocation.id,
      name: organizationLocation?.locationName,
      locationType: organizationLocation?.locationType as LocationType,
      geoLocation: this.convertOrgLocGeoLocationToGateLocGeoLocation(
        organizationLocation.geolocation
      ),
      geoFence: this.convertOrgLocGeoFenceToGateLocGeoFence(
        organizationLocation.geofence
      ),
      principalContact: organizationLocation?.principalContact,
      address: organizationLocation?.address,
      phoneNumber: '',
      contact: {
        email: organizationLocation?.email,
        name: '',
        designation: '',
        mobile: '',
        phone: '',
      },
      faxNumber: '',
      bayInformation: [],
    };
  }

  convertOrgLocGeoLocationToGateLocGeoLocation(
    orgLocGeoLocation: Point
  ): GeoLocation {
    if (!orgLocGeoLocation || !orgLocGeoLocation.coordinates  || !Array.isArray(orgLocGeoLocation.coordinates) || orgLocGeoLocation.coordinates.length < 2) {
      return null;
    }

    return {
      lat: orgLocGeoLocation.coordinates[1],
      lng: orgLocGeoLocation.coordinates[0],
    };
  }


  convertOrgLocGeoFenceToGateLocGeoFence(
    orgLocGeoFence: Polygon
  ): GeoLocation[] {
    if (!orgLocGeoFence || !orgLocGeoFence.coordinates) {
      return [];
    }
    return orgLocGeoFence.coordinates[0].map((item) => {
      return {
        lat: item[1],
        lng: item[0],
      };
    });
  }

  mapOrganizationLocationToLocation(
    organizationLocation: OrganizationLocation
  ): Location {
    return {
      id: organizationLocation?.id,
      name: organizationLocation?.locationName,
      type: organizationLocation?.locationType,
      address: organizationLocation?.address?.addressLine,
      contactNumber: '',
      contactPerson: organizationLocation?.principalContact?.firstName,
    };
  }

  getImageData(attachment: AttachmentModel): Observable<Blob> {
    return this.httpClient
      .get(attachment.url, { responseType: 'blob' })
      .pipe(map((res) => new Blob([res])));
  }

  getImageDataByFileUrl(url: string): Observable<Blob> {
    return this.httpClient
      .get(url, { responseType: 'blob' })
      .pipe(map((res) => new Blob([res])));
  }

  getRelevantTextBasedOnKeyOfLoadType(loadType: LoadType) {
    switch (loadType) {
      case LoadType.CONTAINER:
        return 'Container';
      case LoadType.TRUCK:
        return 'Truck load';
      case LoadType.FCL:
        return 'Container';
      case LoadType.FTL:
        return 'Load';
      case LoadType.LCL:
        return 'Container';
      case LoadType.BOX:
        return 'Box';
      default:
        return 'Container';
    }
  }

  getRelevantTitleBasedOnKeyOfLoadType(loadType: LoadType) {
    switch (loadType) {
      case LoadType.CONTAINER:
        return 'Container';
      case LoadType.TRUCK:
        return 'Truck';
      case LoadType.FCL:
        return 'Container';
      case LoadType.FTL:
        return 'Load';
      case LoadType.LCL:
        return 'Load';
      case LoadType.BOX:
        return 'Box';
      default:
        return 'Load';
    }
  }
}
