import {LocationType} from '@shared/models';
import {CONTAINER_MANAGEMENT_ACTION_PREFIX} from '../container-management/container-management.actions';
import {ActivityRelationType} from '../../models/enums/activity-relation-type.enum';

export const WIZARD_ACTION_PREFIX = '[Wizard State]';

export class LoadLocationsAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Load Locations`;
  constructor() { }
}

export class LoadGatewayLocationsAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Load Locations from the Gateway`;
    constructor(public locationQueries: Array<{locationId: string, locationType: LocationType}>) {
  }
}

export class LoadLocationsOfTypeAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Load Locations from the Gateway for a given location type`;
  constructor(public locationType: LocationType) {
  }
}

export class LoadOrgPartnerLocationsAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Load Org Partner Locations`;
  constructor() { }
}

export class LoadLocationByActivityPurpose{
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Load locations according to purpose`;
  constructor(public purpose: string, public locationRelationshipType: ActivityRelationType, public locationSearchValue?: string) {
  }
}

export class GetLocationById {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Load location by id`;
  constructor(public id: string) {
  }
}
