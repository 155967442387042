<div class="image-attachment-wizard-container">
  <div class="title">{{title}}</div>
  <img class="image" [src]="attachment?.imageUrl">
  <button mat-button class="attach-button" (click)="openFileExplorer()">
    <label>
      {{getButtonName()}}
    </label>
    <mat-icon aria-hidden="false">attach_file</mat-icon>
    <input id="file-input" class="file-input" #imageInput type="file" (change)="processFile(imageInput)">
  </button>
  <div class="messages" *ngFor="let message of attachment?.message">
    <span class="message">{{message}}</span>
  </div>
</div>
