import {AlertDialogType} from '@shared/models';
import { NotificationData } from '@shared/models/notification-data.model';

export const NOTIFICATION_ACTION_PREFIX = '[Notification]';

export class SetNotificationAction {
  static readonly type = `${NOTIFICATION_ACTION_PREFIX} Set Notification Action`;
  constructor(public message: string, public notificationType: AlertDialogType, public config = null) {
  }
}


// this is the newly introduced notifications
export class ShowNotificationAction {
  static readonly type = `${NOTIFICATION_ACTION_PREFIX} Show Notification Action`;
  // TODO: introduce a new data model
  constructor(public notificationData: NotificationData, public config = null) {
  }
}

export class GetInitialNotifications {
  static readonly type = `${NOTIFICATION_ACTION_PREFIX} Get Initial data From Firebase`;
  constructor(public notificationLimit: number) {
  }
}

export class FetchMoreNotifications {
  static readonly type = `${NOTIFICATION_ACTION_PREFIX} Fetch More Data From Firebase`;
  constructor(public notificationLimit: number) {
  }
}

