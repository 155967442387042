import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PaymentTerm} from '@shared/models/payment-term.model';
import {PaymentTermType} from '@shared/models/enums/payment-term-type.enum';

@Component({
  selector: 'hmt-payment-terms',
  templateUrl: './payment-terms.component.html',
  styleUrls: ['./payment-terms.component.scss']
})
export class PaymentTermsComponent implements OnInit {
  status: number;
  advance: number;
  paymentType: string;
  creditDays: number;
  paymentInPercentage = 100;
  form: FormGroup;
  total;
  selectedPaymentTerm: string;
  isRadioInAdvance = false;
  isRadioImmediatelyCompletion = false;
  isRadioDaysUponCompletion = false;
  isRadioAdvanceInValue = false;
  isRadioAdvanceInPercentage = false;
  isRadioAdvanceInValueAndDays = false;
  isRadioAdvanceInPercentageAndDays = false;

  @Output() paymentTerm: EventEmitter<{paymentTerm: PaymentTerm, isValidPaymentTerm: boolean}> = new EventEmitter();

  @Input('totalCost') set setTotal(total: number) {
    this.total = total;
  }

  @Input('selectedPaymentTerm') set setPaymentTerm(paymentTerm: PaymentTerm) {
    if (paymentTerm) {
      this.setInitialData(paymentTerm);
    }
  }

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.createForm();
    this.checkPaymentTerm();
  }

  setInitialData(paymentTerm: PaymentTerm) {
    this.advance = paymentTerm.advance;
    this.creditDays = paymentTerm.creditDays;
    this.paymentType = paymentTerm.paymentType;
  }

  createForm() {
    this.form = this.formBuilder.group({
      selectedRadio: new FormControl(''),
      formDaysUponCompletion: this.formBuilder.group({
        daysUponCompletionCreditDays: new FormControl({value: '', disabled: true},
          [Validators.required, Validators.min(1)]),
      }),
      formAdvanceInValue: this.formBuilder.group({
        advanceInValueAdvance: new FormControl({value: '', disabled: true},
          [Validators.required, Validators.max(this.total), Validators.min(1)]),
        advanceInValueBalance: new FormControl(''),
      }),
      formAdvanceInPercentage: this.formBuilder.group({
        advanceInPercentageAdvance: new FormControl({value: '', disabled: true},
          [Validators.required, Validators.max(100), Validators.min(1)]),
        advanceInPercentageBalance: new FormControl(''),
      }),
      formAdvanceInPercentageAndDays: this.formBuilder.group({
        advanceInPercentageAndDaysAdvance: new FormControl({value: '', disabled: true},
          [Validators.required, Validators.max(100), Validators.min(1)]),
        advanceInPercentageAndDaysBalance: new FormControl(''),
        advanceInPercentageAndDaysCreditDays: new FormControl({value: '', disabled: true},
          [Validators.required, Validators.min(1)]),
      }),
      formAdvanceInValueAndDays:
        this.formBuilder.group({
          advanceInValueAndDaysAdvance: new FormControl({value: '', disabled: true},
            [Validators.required, Validators.max(this.total), Validators.min(1)]),
          advanceInValueAndDaysBalance: new FormControl(''),
          advanceInValueAndDaysCreditDays: new FormControl({value: '', disabled: true},
            [Validators.required, Validators.min(1)]),
        }),
    });
  }

  checkPaymentTerm() {
    if (this.advance === this.total && this.creditDays === 0 && this.paymentType === PaymentTermType.OTHER) {
      this.isRadioInAdvance = true;
    } else if (this.advance === 0 && this.creditDays === 0 && this.paymentType === PaymentTermType.OTHER) {
      this.isRadioImmediatelyCompletion = true;
    } else if (this.advance === 0 && this.creditDays > 0 && this.paymentType === PaymentTermType.OTHER) {
      this.isRadioDaysUponCompletion = true;
      this.form.get('formDaysUponCompletion').patchValue({
        daysUponCompletionCreditDays: this.creditDays,
      });
    } else if ((0 < this.advance && this.advance < this.total) && this.creditDays === 0 && this.paymentType === PaymentTermType.VALUE) {
      this.isRadioAdvanceInValue = true;
      this.form.get('formAdvanceInValue').patchValue({
        advanceInValueAdvance: this.advance,
        advanceInValueBalance: this.total - this.advance
      });
    } else if ((0 < this.advance && this.advance < 100) && this.creditDays === 0 && this.paymentType === PaymentTermType.PERCENTAGE) {
      this.isRadioAdvanceInPercentage = true;
      this.form.get('formAdvanceInPercentage').patchValue({
        advanceInPercentageAdvance: this.advance,
        advanceInPercentageBalance: 100 - this.advance
      });
    } else if ((0 < this.advance && this.advance < 100) && this.creditDays > 0 && this.paymentType === PaymentTermType.PERCENTAGE) {
      this.isRadioAdvanceInPercentageAndDays = true;
      this.form.get('formAdvanceInPercentageAndDays').patchValue({
        advanceInPercentageAndDaysAdvance: this.advance,
        advanceInPercentageAndDaysBalance: 100 - this.advance,
        advanceInPercentageAndDaysCreditDays: this.creditDays
      });
    } else if ((0 < this.advance && this.advance < this.total) && this.creditDays > 0 && this.paymentType === PaymentTermType.VALUE) {
      this.isRadioAdvanceInValueAndDays = true;
      this.form.get('formAdvanceInValueAndDays').patchValue({
        advanceInValueAndDaysAdvance: this.advance,
        advanceInValueAndDaysBalance: this.total - this.advance,
        advanceInValueAndDaysCreditDays: this.creditDays
      });
    }
  }

  changeRadio(paymentTerm: string) {
    this.selectedPaymentTerm = paymentTerm;
    this.resetInputs();
    switch (paymentTerm) {
      case 'InAdvance': {
        this.status = 1;
        this.advance = this.total;
        this.creditDays = 0;
        this.paymentType = PaymentTermType.OTHER;
        this.savePaymentTerm(this.advance, this.creditDays, this.paymentType);
        break;
      }
      case 'ImmediatelyCompletion': {
        this.status = 2;
        this.advance = 0;
        this.creditDays = 0;
        this.paymentType = PaymentTermType.OTHER;
        this.savePaymentTerm(this.advance, this.creditDays, this.paymentType);
        break;
      }
      case 'DaysUponCompletion': {
        this.status = 3;
        this.form.get('formDaysUponCompletion.daysUponCompletionCreditDays').enable();
        this.advance = 0;
        this.creditDays = this.form.get('formDaysUponCompletion').value.daysUponCompletionCreditDays;
        this.paymentType = PaymentTermType.OTHER;
        this.savePaymentTerm(this.advance, this.creditDays, this.paymentType);
        break;
      }
      case 'AdvanceInValue': {
        this.status = 4;
        this.form.get('formAdvanceInValue.advanceInValueAdvance').enable();
        this.advance = this.form.get('formAdvanceInValue').value.advanceInValueAdvance;
        this.creditDays = 0;
        this.paymentType = PaymentTermType.VALUE;
        this.savePaymentTerm(this.advance, this.creditDays, this.paymentType);
        break;
      }
      case 'AdvanceInPercentage': {
        this.status = 5;
        this.form.get('formAdvanceInPercentage.advanceInPercentageAdvance').enable();
        this.advance = this.form.get('formAdvanceInPercentage').value.advanceInPercentageAdvance;
        this.creditDays = 0;
        this.paymentType = PaymentTermType.PERCENTAGE;
        this.savePaymentTerm(this.advance, this.creditDays, this.paymentType);
        break;
      }
      case 'AdvanceInPercentageAndDays': {
        this.status = 6;
        this.form.get('formAdvanceInPercentageAndDays.advanceInPercentageAndDaysAdvance').enable();
        this.form.get('formAdvanceInPercentageAndDays.advanceInPercentageAndDaysCreditDays').enable();
        this.advance = this.form.get('formAdvanceInPercentageAndDays').value.advanceInPercentageAndDaysAdvance;
        this.creditDays = this.form.get('formAdvanceInPercentageAndDays').value.advanceInPercentageAndDaysCreditDays;
        this.paymentType = PaymentTermType.PERCENTAGE;
        this.savePaymentTerm(this.advance, this.creditDays, this.paymentType);
        break;
      }
      case 'AdvanceInValueAndDays': {
        this.status = 7;
        this.form.get('formAdvanceInValueAndDays.advanceInValueAndDaysAdvance').enable();
        this.form.get('formAdvanceInValueAndDays.advanceInValueAndDaysCreditDays').enable();
        this.advance = this.form.get('formAdvanceInValueAndDays').value.advanceInValueAndDaysAdvance;
        this.creditDays = this.form.get('formAdvanceInValueAndDays').value.advanceInValueAndDaysCreditDays;
        this.paymentType = PaymentTermType.VALUE;
        this.savePaymentTerm(this.advance, this.creditDays, this.paymentType);
        break;
      }
      default: {
        break;
      }
    }
  }

  resetInputs() {
    this.form.reset();
    this.form.get('formDaysUponCompletion.daysUponCompletionCreditDays').disable();
    this.form.get('formAdvanceInValue.advanceInValueAdvance').disable();
    this.form.get('formAdvanceInPercentage.advanceInPercentageAdvance').disable();
    this.form.get('formAdvanceInPercentageAndDays.advanceInPercentageAndDaysAdvance').disable();
    this.form.get('formAdvanceInPercentageAndDays.advanceInPercentageAndDaysCreditDays').disable();
    this.form.get('formAdvanceInValueAndDays.advanceInValueAndDaysAdvance').disable();
    this.form.get('formAdvanceInValueAndDays.advanceInValueAndDaysCreditDays').disable();
  }

  changeDaysUponCompletion() {
    this.advance = 0;
    this.creditDays = this.form.get('formDaysUponCompletion.daysUponCompletionCreditDays').value;
    this.paymentType = PaymentTermType.OTHER;
    this.savePaymentTerm(this.advance, this.creditDays, this.paymentType);
  }

  changeAdvanceInValueAdvance() {
    this.form.get('formAdvanceInValue').patchValue({
      advanceInValueBalance: this.total - this.form.get('formAdvanceInValue.advanceInValueAdvance').value,
    });
    this.advance = this.form.get('formAdvanceInValue.advanceInValueAdvance').value;
    this.creditDays = 0;
    this.paymentType = PaymentTermType.VALUE;
    this.savePaymentTerm(this.advance, this.creditDays, this.paymentType);
  }

  changeAdvanceInPercentageAdvance() {
    this.form.get('formAdvanceInPercentage').patchValue({
      advanceInPercentageBalance: this.paymentInPercentage - this.form
        .get('formAdvanceInPercentage.advanceInPercentageAdvance').value,
    });
    this.advance = this.form.get('formAdvanceInPercentage.advanceInPercentageAdvance').value;
    this.creditDays = 0;
    this.paymentType = PaymentTermType.PERCENTAGE;
    this.savePaymentTerm(this.advance, this.creditDays, this.paymentType);
  }

  changeAdvanceInPercentageAndDays() {
    this.form.get('formAdvanceInPercentageAndDays').patchValue({
      advanceInPercentageAndDaysBalance: this.paymentInPercentage - this.form
        .get('formAdvanceInPercentageAndDays.advanceInPercentageAndDaysAdvance').value,
    });
    this.advance = this.form.get('formAdvanceInPercentageAndDays.advanceInPercentageAndDaysAdvance').value;
    this.creditDays = this.form.get('formAdvanceInPercentageAndDays.advanceInPercentageAndDaysCreditDays').value;
    this.paymentType = PaymentTermType.PERCENTAGE;
    this.savePaymentTerm(this.advance, this.creditDays, this.paymentType);
  }

  changeAdvanceInValueAndDays() {
    this.isRadioAdvanceInValueAndDays = false;
    this.form.get('formAdvanceInValueAndDays').patchValue({
      advanceInValueAndDaysBalance: this.total - this.form.get('formAdvanceInValueAndDays.advanceInValueAndDaysAdvance').value,
    });
    this.advance = this.form.get('formAdvanceInValueAndDays.advanceInValueAndDaysAdvance').value;
    this.creditDays = this.form.get('formAdvanceInValueAndDays.advanceInValueAndDaysCreditDays').value;
    this.paymentType = PaymentTermType.VALUE;
    this.savePaymentTerm(this.advance, this.creditDays, this.paymentType);
  }

  validatePaymentTerm(paymentTerm: string){
    switch (paymentTerm) {
      case 'InAdvance':
      case 'ImmediatelyCompletion': {
        return true;
      }
      case 'DaysUponCompletion': {
        return this.form.get('formDaysUponCompletion').valid;
      }
      case 'AdvanceInValue': {
        return this.form.get('formAdvanceInValue').valid;
      }
      case 'AdvanceInPercentage': {
        return this.form.get('formAdvanceInPercentage').valid;
      }
      case 'AdvanceInPercentageAndDays': {
        return this.form.get('formAdvanceInPercentageAndDays').valid;
      }
      case 'AdvanceInValueAndDays': {
        return this.form.get('formAdvanceInValueAndDays').valid;
      }
      default: {
        break;
      }
    }
  }

  savePaymentTerm(advance: number, creditDays: number, paymentType: string) {
    const paymentTerm = {
      advance,
      creditDays,
      paymentType
    } as PaymentTerm;
    this.paymentTerm.emit({paymentTerm, isValidPaymentTerm: this.validatePaymentTerm(this.selectedPaymentTerm)});
  }
}
