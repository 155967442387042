import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MapComponent} from '@shared/components/map/map.component';
import {FormControl, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {LatLng} from '@shared/models/enums/lat-lng.model';
import {Point} from '@shared/models/point';
import {takeUntil, tap} from 'rxjs/operators';
import {LocationType} from '../../../modules/organization/models/enums/location-type';

@Component({
  selector: 'hmt-map-location-search',
  templateUrl: './map-location-search.component.html',
  styleUrls: ['./map-location-search.component.scss']
})
export class MapLocationSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe = new Subject<void>();
  formControl = new FormControl();
  location;

  @ViewChild('mapComponent', {static: false}) mapComponent: MapComponent;
  @ViewChild('addressText') addressText;
  @Output() searchedLocation = new EventEmitter<Point>();
  @Output() placeDetails = new EventEmitter();
  @Output() reEnterLocation = new EventEmitter<Point>();
  @Input() clearMap: Observable<boolean>;
  @Input() locationType: Observable<LocationType>;

  @Input('marker') set setMarker(obj: {point: Point, locationType: LocationType, address?: string}) {
    this.location = obj;
    if (!obj?.locationType) {
      this.formControl.disable();
    }
    if (obj?.point && obj?.point) {
      this.locationType = of(obj.locationType);
      this.formControl.patchValue((obj?.address) ? obj?.address : '');
      this.formControl.enable();
      this.mapComponent?.removeMarkers();
      this.mapComponent?.setBounds([new LatLng(obj?.point.coordinates[1], obj?.point.coordinates[0])]);
      this.mapComponent?.addMarker({
        position: new google.maps.LatLng(obj?.point.coordinates[1], obj?.point.coordinates[0])
      }, false);
      this.searchedLocation.emit(obj?.point);
    }
  }

  constructor() {
  }

  ngOnInit(): void {
    if (!this.location?.locationType) {
      this.listenToLocationType();
      this.formControl.disable();
      this.listenToClearMapEvent();
    }
  }

  listenToLocationType(): void {
    this.locationType
      .pipe(
        takeUntil(this.unsubscribe),
        tap(locationType => {
          if (locationType) {
            this.formControl.enable();
          }
        })
      )
      .subscribe();
  }

  listenToClearMapEvent(): void {
    this.clearMap
      .pipe(
        takeUntil(this.unsubscribe),
        tap(res => {
          if (res) {
            this.mapComponent.removeMarkers();
            this.formControl.patchValue('');
          }
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.mapComponent.initMap({
      ...this.mapComponent.defaultOptions,
      zoom: 14,
      maxZoom: 20,
      minZoom: 5
    });
    this.initializeLocationSearch();
    this.mapComponent.addMapClickListener(true);
  }

  emitSearchedLocation(result: Point) {
    if (!this.location?.locationType) {
      this.searchedLocation.emit(result);
    }
    if (this.mapComponent?.markers?.length > 0) {
      this.mapComponent?.removeMarkers();
      this.mapComponent?.setBounds([new LatLng(result.coordinates[1], result.coordinates[0])]);
      this.mapComponent?.addMarker({
        position: new google.maps.LatLng(result.coordinates[1], result.coordinates[0])
      }, false);
      this.reEnterLocation.emit(result);
    } else {
      this.mapComponent?.removeMarkers();
      this.mapComponent?.setBounds([new LatLng(result.coordinates[1], result.coordinates[0])]);
      this.mapComponent?.addMarker({
        position: new google.maps.LatLng(result.coordinates[1], result.coordinates[0])
      }, false);
      this.searchedLocation.emit(result);
    }

  }

  emitSearchedLocationDetails(result) {
    this.placeDetails.emit(result);
  }

  initializeLocationSearch(): void {
    this.mapComponent.getPlaces(this.addressText.nativeElement);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
