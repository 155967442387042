import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RateCard} from '@shared/models/rate-card.model';
import {Observable} from 'rxjs';
import {API} from '@configs/api.config';
import {Organization} from '../../organization/models/organization';
import {RateTier} from '@shared/models/rate-tier.model';
import {Pageable} from '@shared/models/pageable.model';
import {Vehicle} from '@shared/models/vehicle';
import {ContractType} from '@shared/models/enums/rate-sheet-contract-type.enum';
import {VehicleCategory} from '@shared/models/vehicle-category.model';
import {ResourceType} from '@shared/models/enums/resource.type';
import {Consignee} from '../../reporting/models/consignee.model';
import {OrganizationLocation} from '../../organization/models/organization-location';
import { MatrixDataTemplate } from 'app/modules/organization/models/matrix-data.model';
import { ContractFuelRate } from 'app/modules/organization/models/contract-fuel-rate.model';

@Injectable({
  providedIn: 'root'
})
export class ContractManagementService {

  constructor(private httpClient: HttpClient) {
  }

  saveRateCard(newRateCard: RateCard): Observable<RateCard> {
    const url = API.rateCard.saveRateCard;
    return this.httpClient.post<RateCard>(url, newRateCard);
  }

  getPartnerOrgs(verticals: string[], searchKey: string): Observable<Organization[]> {
    const url = API.organization.getPartnerOrgsByVerticals;
    let params = new HttpParams();
    if (verticals.length > 0) {
      params = params.set('verticals', verticals.toString());
    }
    params = params.set('searchKey', searchKey);
    return this.httpClient.get<Organization[]>(url, {params});
  }

  findOrgPartnersAndOnBoardedOrganizations(orgId: string, orgType: string, searchKey: string, verticals: string[])
    : Observable<Organization[]> {
    const url = API.organization.getOrganizationsByOrgIdAndOrgType.format([orgId, orgType]);
    let params = new HttpParams();
    params = params.append('searchKey', String(searchKey));
    params = params.append('verticals', verticals.toString());
    return this.httpClient.get<Organization[]>(url, {params});
  }

  getAllTiers(orgId: string): Observable<RateTier[]> {
    const url = API.rateCard.getAllTiers.format([orgId]);
    return this.httpClient.get<RateTier[]>(url);
  }

  getRateCardsByFilters(orgId: string, customerId: string, vertical: string, pageNo: number, pageSize: number,
                        searchKey: string, contractType: ContractType, operationId?: string):
    Observable<Pageable<RateCard>> {
    let params = new HttpParams();
    if (customerId != null && customerId !== '') {
      params = params.set('customerId', customerId);
    }

    if (vertical != null && vertical !== '') {
      params = params.set('vertical', vertical);
    }
    params = params.set('searchKey', searchKey);
    params = params.set('type', contractType);
    if(operationId != null && operationId !== '' && operationId !== 'all'){
      params = params.set('operationId', operationId);
    }
    const url = API.rateCard.getRateCardsByFilters.format([orgId, pageNo.toString(), pageSize.toString()]);
    return this.httpClient.get<Pageable<RateCard>>(url, {params});
  }

  fetchCurrencies(): Observable<string[]> {
    const url = API.organization.getCurrencies;
    return this.httpClient.get<string[]>(url);
  }

  getVehicleByManagedByOrg(orgId: string, searchKey: string): Observable<Vehicle[]> {
    let params = new HttpParams();
    params = params.set('searchKey', searchKey);
    const url = API.organization.getVehiclesByManagingOrg.format([orgId]);
    return this.httpClient.get<Vehicle[]>(url, {params});
  }

  getVehicleByManagedByOrgAndCategory(orgId: string, searchKey: string, types: ResourceType[], vehicleCategory: string):
    Observable<Vehicle[]> {

    const url = API.organization.getVehiclesByManagingOrgFilter.format([orgId]);

    let params = new HttpParams();
    params = params.set('searchKey', searchKey);
    if (types != null) {
      params = params.append('vehicleTypes', types.toString());
    }
    params = params.append('vehicleCategory', vehicleCategory);

    return this.httpClient.get<Vehicle[]>(url, {params});
  }

  getVehicleCategories(): Observable<VehicleCategory[]> {
    const url = API.organization.getVehicleCategories;
    return this.httpClient.get<VehicleCategory[]>(url);
  }

  saveTier(newTier: RateTier): Observable<RateTier> {
    const url = API.rateCard.saveTier;
    return this.httpClient.post<RateTier>(url, newTier);
  }

  getOrgLocations(orgId: string, searchValue: string): Observable<OrganizationLocation[]> {

    const url  = `${API.locations.getLocationsForWizard}`;
    let params = new HttpParams();
    if (searchValue !== ''){
      params = params.set('searchKey', searchValue);
    }
    params = params.set('orgTypes', ['ORG_PARTNER', 'PUBLIC'].toString());
    params = params.set('pageSize', 50 + '');
    params = params.set('pageNumber', 0 + '');
    return this.httpClient.get<OrganizationLocation[]>(url, {params});
  }

  getOrgLocationsByIds(locationIds: string[]): Observable<OrganizationLocation[]> {
    const filter = {
      where: {
        id: {
          inq: locationIds
        }
      },
    };
    const url  = `${API.locations.getLocationsForWizard}`;
    let params = new HttpParams().set('filter', JSON.stringify(filter));
    params = params.set('orgTypes', ['ORG_PARTNER', 'PUBLIC'].toString());
    return this.httpClient.get<OrganizationLocation[]>(url, {params});
  }

  saveOperationDistanceMatrix(matrixData: MatrixDataTemplate): Observable<MatrixDataTemplate> {
    const url = API.rateCard.saveOperationDistanceMatrix;
    return this.httpClient.post<MatrixDataTemplate>(url, matrixData);
  }

  getOperationDistanceMatrix(operationId: string, orgId: string): Observable<MatrixDataTemplate> {
    const url = API.rateCard.getOperationDistanceMatrix.format([operationId, orgId]);
    return this.httpClient.get<MatrixDataTemplate>(url);
  }

  getDistanceMatrixTemplateOperationListWise( orgId?: string, searchKey?: string ,operationIdList?: string[]): Observable<MatrixDataTemplate[]> {
    const url = API.rateCard.getDistanceMatrixOperationList.format([orgId]);
    const body = {
      searchKey: searchKey,
      operationIdList: operationIdList
    };
    return this.httpClient.post<MatrixDataTemplate[]>(url, body);
  }

  getFuelRates(operationId: string, orgId: string): Observable<ContractFuelRate[]> {
    const url = API.organization.getFuelRates.format([orgId, operationId]);
    return this.httpClient.get<ContractFuelRate[]>(url);
  }

  saveFuelRates(operationId: string, orgId: string, fuelRates: ContractFuelRate): Observable<ContractFuelRate[]> {
    const url = API.organization.updateFuelRates.format([orgId, operationId]);
    return this.httpClient.post<ContractFuelRate[]>(url, fuelRates);
  }

  deleteFuelRate(operationId: string, orgId: string, fuelRateId: string): Observable<void> {
    const url = API.organization.deleteFuelRates.format([orgId, operationId, fuelRateId]);
    return this.httpClient.delete<void>(url);
  }

  updateFuelRateContracts(operationId: string, orgId: string, fuelRateContracts: ContractFuelRate): Observable<ContractFuelRate[]> {
    const url = API.organization.updateFuelRateContracts.format([orgId, operationId]);
    return this.httpClient.post<ContractFuelRate[]>(url, fuelRateContracts);
  }
}
