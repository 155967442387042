import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedState } from '@core/store/shared.state';
import { environment } from '@env/environment';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
@Component({
  selector: 'hmt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(SharedState.getSpinnerStatus) isSpinnerEnabled: Observable<{spinner: boolean, spinnerMessage: string}>;
  private unsubscribe: Subject<void> = new Subject();

  spinnerPros: {spinner: boolean, spinnerMessage: string} = {spinner: false, spinnerMessage: ''};

  ngOnInit(): void {
    // this.loadScript(
    //   `https://maps.googleapis.com/maps/api/js?key=${environment.google.apiKey}&libraries=places,geometry,drawing`,
    // ).then((value) => {console.log(value); }, (error) => {console.log(error); } );
    this.isSpinnerEnabled
    .pipe(
      tap(result => this.spinnerPros = result),
      takeUntil(this.unsubscribe)
    )
    .subscribe();
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      try {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = name;
        document.getElementsByTagName('head')[0].appendChild(script);
        console.log('Script Loaded');
        resolve('Ok');
      } catch (e) {
        reject(`Error ${e}`);
      }
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
