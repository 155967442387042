import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JobFile } from '@core/models/job-file';
import { User } from '@core/models/user.model';
import { JobType } from '@shared/models/enums/job-type.enum';
import { LoadType } from '@shared/models/enums/load-type.enum';
import { JobFileCreationDTO } from '@shared/models/job-file-creation.model';
import { EntityMap } from '@shared/models/types';
import { JobGroup } from 'app/modules/job-group/models/job-group.model';
import { Operation } from 'app/modules/organization/models/operation';
import { OrgUser } from 'app/modules/organization/models/org-user';
import { forkJoin, Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'hmt-create-job-file',
  templateUrl: './create-job-file.component.html',
  styleUrls: ['./create-job-file.component.scss']
})
export class CreateJobFileComponent implements OnInit {

  jobFileForm: FormGroup;
  jobType = JobType;
  loadType = LoadType;
  selectedJobType: JobType = JobType.IMPORT;
  selectedCollaborators: User[] = [];
  alreadySelectedJobGroupId = null;
  selectedOperation: Operation = null;

  @Output() addJobGroup: EventEmitter<void> = new EventEmitter();
  @Output() addJobFile: EventEmitter<JobFileCreationDTO> = new EventEmitter();
  @Output() searchJobGroup: EventEmitter<string> = new EventEmitter();
  @Output() searchCollaborator: EventEmitter<string> = new EventEmitter();


  constructor(
    private dialogRef: MatDialogRef<CreateJobFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      jobGroups: Observable<JobGroup[]>,
      selectedJobGroupId: Observable<string>,
      selectedJobGroup: Observable<JobGroup>,
      internalCollaborators: Observable<User[]>,
      operations: Observable<Operation[]>,
      selectedOperation: Observable<Operation>,
    }
  ) { }

  ngOnInit(): void {
    this.data.selectedOperation.pipe(
      take(1),
      tap(operation => {
        this.selectedOperation = operation;
        this.initForm();
        this.updateSelectedJobGroup();
      })
    ).subscribe();
  }

  updateSelectedJobGroup(): void {
    this.data.selectedJobGroup
      .pipe(
        filter(jg => jg != null && jg.id != this.alreadySelectedJobGroupId),
        take(1),
        tap(jobGroup => {
          this.jobFileForm.patchValue({
            jobGroup
          });
          this.alreadySelectedJobGroupId = jobGroup.id;
        })
      ).subscribe();
  }

  initForm(selectedJobGroup?: JobGroup) {
    this.jobFileForm = new FormGroup({
      jobTitle: new FormControl('', [Validators.required]),
      jobGroup: new FormControl(selectedJobGroup ? selectedJobGroup : null, [Validators.required]),
      // jobRefNumber: new FormControl('', [Validators.required]),
      customerReferenceNo: new FormControl(''),
      internalCollaborators: new FormControl(''),
      loadType: new FormControl('', Validators.required),
      operation: new FormControl({value: this.selectedOperation?.id === '' ? null : this.selectedOperation, disabled: this.selectedOperation?.id !== ''}, Validators.required),
    });

    this.jobFileForm.get('jobGroup')
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        filter((jg: JobGroup | string) => {
          if (typeof jg == 'object' && jg.id == 'default') {
            return false;
          }
          return true;
        }),
        map((jg: JobGroup | string) => typeof jg == 'object' ? jg.name : jg),
        tap((text: string) => {
          this.searchJobGroup.emit(text);
        })
      )
      .subscribe();

    this.jobFileForm.get('internalCollaborators')
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        tap((searchText: string) => {
          this.searchCollaborator.emit(searchText);
        })
      )
      .subscribe();
  }

  public getJobGroupValue(): string {
    const jg: JobGroup | string = this.jobFileForm.get('jobGroup').value;
    return typeof jg == 'object' ? jg.name : jg;
  }

  displayWithGroupId(jg: JobGroup): string {
    if (!jg) {
      return '';
    }
    return jg.name;
  }

  displayWithCollaborator(user: User): string {
    if (!user) {
      return '';
    }
    return `${user.firstName} ${user.lastName}`;
  }

  onSelectCollaborator(collaborator: User): void {
    this.jobFileForm.controls['internalCollaborators'].patchValue(null);
    const index = this.selectedCollaborators.findIndex(c => c.id == collaborator.id)
    if (index >= 0) { // already exist
      return;
    }
    this.selectedCollaborators.push(collaborator);
  }

  removeCollaborator(index: number): void {
    this.selectedCollaborators.splice(index, 1);
  }

  onAddJobGroup(): void {
    this.addJobGroup.emit();
    this.updateSelectedJobGroup();
  }

  selectJobType(jobType: JobType): void {
    this.selectedJobType = jobType;

    switch (jobType) {
      case JobType.REMOVAL:
      case JobType.RELOCATION:
      case JobType.REPOSITION:
        this.removeLoadTypesValidation();
        break;
      default:
        this.enableLoadTypeValidation();
    }
  }

  enableLoadTypeValidation() {
    this.jobFileForm.addControl('loadType', new FormControl('', Validators.required));
  }

  removeLoadTypesValidation() {
    this.jobFileForm.removeControl('loadType');
  }

  createJobFile(): void {
    if (!this.jobFileForm.valid) {
      Object.keys(this.jobFileForm.controls).forEach(field => {
        const control = this.jobFileForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    const {
      jobTitle,
      jobGroup,
      customerReferenceNo,
      loadType,
      operation
    } = this.jobFileForm.getRawValue();
    const jobFileCreationRequest: JobFileCreationDTO = {
      jobTitle,
      jobGroupId: jobGroup && jobGroup.id != 'default' ? jobGroup.id : null,
      customerReferenceNo,
      loadType,
      orgId: null,
      jobType: this.selectedJobType,
      internalCollaborators: this.selectedCollaborators.map(ic => ic.id),
      operationId: operation.id,
    };
    this.addJobFile.emit(jobFileCreationRequest);
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
