export enum LoadType {
  CONTAINER = 'CONTAINER',
  FCL = 'FCL',
  LCL = 'LCL',
  TRUCK = 'TRUCK',
  BOX = 'BOX',
  FTL= 'FTL',
  LTL= 'LTL',
  BULK= 'BULK',
  PACKAGE= 'PACKAGE',
  COURIER= 'COURIER',
  PALLET= 'PALLET',
  SOLID_BULK= 'SOLID_BULK',
  LIQUID_BULK= 'LIQUID_BULK'
}
