<div class="progress-line col full-width">
  <div class="stepper-up row full-width">
    <div class="title"></div>
    <div class="job-type"></div>
    <img class="logo" src="">
  </div>

  <div class="stepper-bottom row full-width">
    <span class="wall"></span>
    <ng-container *ngFor="let step of steps; let index">
      <div class="step col">
        <div class="step-circle">
          <span class="circle" [ngClass]="currentStepNo >= step.stepNo ? 'circle' : 'circle-blue'">
            <mat-icon *ngIf="currentStepNo >= step.stepNo">done</mat-icon>
            <p *ngIf="step.step !== currentStep">{{step.stepNo}}</p>
          </span>
        </div>
        <div [ngClass]="step.step === currentStep ? 'border-blue' : 'border-white'">{{step.stepName}}</div>
      </div>
    </ng-container>
    <span class="wall"></span>
  </div>
</div>
