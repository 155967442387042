<div class="recipient-details col">
  <div class="rd-header row">
    <p class="title">Recipient Details</p>
  </div>
  <mat-divider></mat-divider>
  <div class="rd-content">
    <div class="wrapper">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnNames; let i = index">
          <th mat-header-cell *matHeaderCellDef class="text-medium-bold"> {{tableHeaders[i]}}</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="subDocuments[column].length <= 0">{{element[column]}}</div>
            <ng-container *ngIf="subDocuments[column].length > 0">
              <div *ngIf="element[column] as subDoc">
                <div *ngFor="let col of subDocuments[column]">{{col | titlecase}}: {{subDoc[col]}}</div>
              </div>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
      </table>
    </div>
  </div>
</div>
