import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import { LocationCountryDetails } from 'app/modules/wizard/models/org-country-details.model';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'hmt-registered-address',
  templateUrl: './registered-address.component.html',
  styleUrls: ['./registered-address.component.scss']
})
export class RegisteredAddressComponent implements OnInit {
  filteredCountriesList: LocationCountryDetails[] = [];
  filteredStatesList: string[] = [];

  @Input() public formGroup: FormGroup;
  @Input() countries: LocationCountryDetails[];
  @Input() formValidity: BehaviorSubject<boolean>;
  constructor(
  ) { }

  ngOnInit(): void {
  }

  onSelectCountry(): void {
    this.formGroup.controls['registeredAddress'].patchValue({ state : ''})
    this.filteredCountriesList = []
    this.filteredStatesList = [];
    this.formValidity.next(false); //still state have to be set
  }

  onSelectState(): void {
    this.formValidity.next(true);
    this.filteredStatesList = []
  }

  searchCountry(e) {
    this.formValidity.next(false);
    const val = e.toLowerCase();
    this.filteredCountriesList  = this.countries.filter(country => {
      if (country.countryName.toLowerCase().indexOf(val) !== -1 || !val) {
        return country;
      }
    });
  }

  onClickCountry() {
    if(!this.formGroup.get(['registeredAddress', 'country']).value) {
      this.filteredCountriesList = this.countries;
    }
  }

  searchState(e) {
    this.formValidity.next(false);
    if (!this.formGroup.get(['registeredAddress', 'country']).value) {
      return;
    }
    const val = e.toLowerCase();
    const statesOfSelectedCountry = this.countries.find(country => country.countryName === this.formGroup.get(['registeredAddress', 'country']).value)?.states;
    if (statesOfSelectedCountry) {
      this.filteredStatesList = statesOfSelectedCountry.filter(state => {
        if (state.toLowerCase().indexOf(val) !== -1 || !val) {
          return state;
        }
      });
    }
  }

  onClickState() {
    if (this.formGroup.get(['registeredAddress', 'country']).value) {
      if(!this.formGroup.get(['registeredAddress', 'state']).value){
        this.filteredStatesList = this.countries.find(country => country.countryName === this.formGroup.get(['registeredAddress', 'country']).value)?.states;
      }
    } else {
      this.filteredStatesList = [];
    }
  }

}
