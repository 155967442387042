import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AlertDialogComponent} from '@shared/components/alert-dialog/alert-dialog.component';
import {AlertDialogType} from '@shared/models';
import {MatDialogConfig} from '@angular/material/dialog/dialog-config';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {ComponentType} from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class DialogHandlerService {

  readonly defaultAlertDialogConfig = {
    height: '21%',
    width: '30%',
  };
  readonly defaultDialogConfig = {
    height: '100%',
    position: {right: '0px', top: '0px'}
  };

  constructor(public matDialog: MatDialog) { }

  /**
   * @description - Open Dialog for confirmation, warning, success and errors
   * @param title - Title of the dialog
   * @param message - Message or Question that we want show
   * @param type - Type of the Dialog AlertDialogType
   * @param config - Other configuration that we want to parse, if not Default options will be used
   */
  openConfirmationDialog(title: string, message: string, type: AlertDialogType, alertOnly: boolean = false,
                         config: MatDialogConfig = this.defaultAlertDialogConfig)
    : MatDialogRef<AlertDialogComponent> {
    return this.matDialog
      .open(AlertDialogComponent, {...this.defaultAlertDialogConfig, ...config, data: {title, message, type, alertOnly}});
  }

  /**
   * @description Open Material Dialog, parse data and listen to close events
   * @param component - component that we need to parse
   * @param data - data that we need to parse to the model
   * @param config - dialog configs, if empty default configs will be used
   */
  openDialog(component: ComponentType<any>, data = {}, config: MatDialogConfig = this.defaultDialogConfig): MatDialogRef<any> {
    return this.matDialog.open(component, {...this.defaultDialogConfig, ...config, data});
  }
}
