import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LoadInfo, ShipmentOrderSpec } from "@core/models/job-file/shipment-order-spec.model";
import { Subject } from "rxjs";
import { startWith, takeUntil, tap } from "rxjs/operators";
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'hmt-shipment-order',
  templateUrl: './shipment-order.component.html',
  styleUrls: ['./shipment-order.component.scss']
})
export class ShipmentOrderComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject();

  @Input() order: ShipmentOrderSpec;
  @Input() dataMap = {
    numberOfLoads: 0,
    useMaxLocationCount: false
  };
  @Input() selectable = true;
  @Input() deletable = true;
  @Input() assignable = false;
  @Input() numberOfLoads = 0;
  @Input() formGroup: FormGroup;
  @Output() selectOrder?: EventEmitter<{ selected: boolean, order: ShipmentOrderSpec }> = new EventEmitter();
  @Output() removeShipmentOrder?: EventEmitter<string> = new EventEmitter();

  consignees: any[] = [];
  priorities: string[] = ['High', 'Medium', 'Low'];
  loads: LoadInfo[] = [];
  remainingTime = '';

  ngOnInit(): void {
    this.initFormGroup();
    this.processOrderDetails();
    this.calculateRemainingTime();
    this.numberOfLoads = this.dataMap?.numberOfLoads || 0;

    this.changeNumberOfLoads();
    this.listenToNumberOfLoadChanges();
    this.listenToLoadAndRouteOptimization();
  }

  private changeNumberOfLoads(): void {
    this.loads = Array(+this.numberOfLoads).fill(0).map((x,i)=> ({
      loadIndex: i,
      loadNumber: i + 1
    }));
  }

  private listenToLoadAndRouteOptimization(): void {
    if (!this.formGroup.get('optimizeLoadAndRoute')) {
      console.log('optimizeLoadAndRoute is not available in form yet');
      return;
    }

    // const startValue = (
    //   (this.formGroup.get('shipmentOrderAssignmentData') as FormGroup)
    //   .get(this.order.orderId) as FormGroup
    // ).get('loadInfo').value || true;

    this.formGroup.get('optimizeLoadAndRoute').valueChanges
      .pipe(
        // startWith(startValue),
        takeUntil(this.unsubscribe),
        tap(v => {
          this.assignable = v;

          const controller = (
            (this.formGroup.get('shipmentOrderAssignmentData') as FormGroup)
            .get(this.order.orderId) as FormGroup
          ).get('loadInfo');

          if (v) {
            controller.clearValidators();
            controller.clearAsyncValidators();
            controller.setErrors(null);
            controller.updateValueAndValidity();
            controller.disable();
          } else {
            controller.enable();
            controller.setValidators(Validators.required);
            controller.markAllAsTouched();
            controller.updateValueAndValidity();
          }
        })
      )
      .subscribe();
  }

  private listenToNumberOfLoadChanges(): void {
    if (!this.formGroup.get('numberOfLoads')) {
      // console.log('number of loads control not added to form group to listen');
      return;
    }

    this.formGroup.get('numberOfLoads').valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        tap(numberOfLoads => {
          this.numberOfLoads = numberOfLoads;
          this.changeNumberOfLoads();
        })
      )
      .subscribe();
  }

  processOrderDetails(): void {
    if (!this.order) {
      return;
    }
  }

  displayWithOderNumber(load: {loadNumber: string, loadIndex: number}): string {
    if (load == null) {
      return null;
    }

    return load.loadNumber;
  }

  displayWithConsigneeName(consignee: any): string {
    if (consignee == null) {
      return null;
    }

    return consignee.name;
  }

  displayWithPriority(priority: any): string {
    if (priority == null) {
      return null;
    }

    return priority.priority;
  }

  private calculateRemainingTime(): void {
    try {
      const deliveryEndDateTime = new Date(this.order.deliveryEndDateTime).getTime();
      const currentDateTime = new Date().getTime();
      const seconds = Math.floor((deliveryEndDateTime - currentDateTime) / 1000);
      // TODO: re-visit the logic
      const minutes = Math.abs(seconds % 60);
      const days = Math.floor(seconds / (3600 * 24));
      const hours = Math.abs(Math.floor(((seconds / (3600 * 24)) % 1) * 24));

      const timeString = `${days}d ${hours}h ${minutes}m`;
      this.remainingTime = timeString;
    } catch (e) {
      console.log('Error calculating remaining time', e);
    }
  }

  private initFormGroup(): void {
    (this.formGroup.get('shipmentOrderAssignmentData') as FormGroup).addControl(
      this.order.orderId,
      new FormGroup({
        loadInfo: new FormControl(this.order.shipmentOrderLoadInfo),
        partialShipmentAllowed: new FormControl(this.order.partialShipmentAllowed),
        consignee: new FormControl({value: this.order.consigneeName || '', disabled: true}),
        priority: new FormControl(this.order.priority || 'Low'),
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onSelectOrder($event: MatCheckboxChange, order: ShipmentOrderSpec) {
    this.selectOrder.emit({selected: $event.checked, order});
  }

  deleteShipmentOrder(order: ShipmentOrderSpec) {
    this.removeShipmentOrder.emit(order.id ?? order.orderId);
  }
}
