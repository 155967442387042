import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {
  GetLocationById,
  LoadGatewayLocationsAction,
  LoadLocationByActivityPurpose,
  LoadLocationsOfTypeAction
} from './locations.actions';
import {LocationsStateModel} from '../../models/state/locations-state.model';
import {LocationsService} from '../../services/locations.service';
import {GatewayService} from '@control-tower/services/gateway.service';
import {AlertDialogType, LocationType} from '@shared/models';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {GatewayLocation} from '@shared/models/gateway-location.model';
import {SetNotificationAction} from '@core/store/notification/notification.actions';
import {UtilityService} from '@shared/services/utility.service';
import {OrganizationLocationsService} from '../../../organization/services/organization-locations.service';
import {OrganizationLocation} from 'app/modules/organization/models/organization-location';
import {ActivityRelationType} from '../../models/enums/activity-relation-type.enum';
import { OrganizationService } from '../../..//organization/services/organization.service';

export const getInitialLocationsState = (): LocationsStateModel => ({
  wizardLocations: {},
  selectedActivityLocations: {}
});
export const LocationsStateKey = 'locations';

@State<LocationsStateModel>({
  name: 'locations',
  defaults: getInitialLocationsState()
})

@Injectable()
export class LocationsState {

  constructor(private store$: Store,
              private locationService: LocationsService,
              private gatewayService: GatewayService,
              private organizationService: OrganizationService,
              private utilityService: UtilityService,
              private organizationLocationsService: OrganizationLocationsService) {
  }

  @Selector()
  static getAllWizardLocations(state: LocationsStateModel) {
    return state.wizardLocations;
  }

  @Selector()
  static getAllWizardLocationsAsAnArray(state: LocationsStateModel): GatewayLocation[] {
    return Object.values(state.wizardLocations).flat();
  }

  @Selector()
  static getLocationsInLocationType(state: LocationsStateModel): (locationType: LocationType) => GatewayLocation[] {
    return (locationType: LocationType) => {
      return Object.values(state.wizardLocations).filter(location => location.locationType === locationType);
    };
  }

  @Selector()
  static getSelectedActivityLocations(state: LocationsStateModel): GatewayLocation[] {
      return Object.values(state.selectedActivityLocations);
  }

  @Action(LoadGatewayLocationsAction)
  loadGatewayLocations({getState, patchState, dispatch}: StateContext<LocationsStateModel>, {locationQueries}: LoadGatewayLocationsAction) {
    const locationsToBeFetched = locationQueries.filter(locationQuery => !getState().wizardLocations[locationQuery.locationId]);
    const locations = locationsToBeFetched
      .map((value => value.locationId));
    const filter = {
      where: {
        _id: {
          inq: locations
        }
      }
    };

    return this.organizationLocationsService.getLocationsByFilter(JSON.stringify(filter))
      .pipe(
        tap((organizationLocations: OrganizationLocation[]) => {
          const locationMap = organizationLocations.reduce((locMap, gtwLoc) => {
            locMap[gtwLoc.id] = this.utilityService.mapOrganizationLocationToGatewayLocation(gtwLoc);
            return locMap;
          }, {});
          patchState({wizardLocations: {...getState().wizardLocations, ...locationMap}});
        }),
        catchError(err => dispatch(new SetNotificationAction('Error loading locations', AlertDialogType.ERROR)))
      );
  }

  @Action(LoadLocationsOfTypeAction)
  loadGatewayLocationsForLocationType({getState, patchState, dispatch}: StateContext<LocationsStateModel>,
                                      {locationType}: LoadLocationsOfTypeAction) {
    const filter = {
      where: {
        locationType: {
          inq: [locationType]
        }
      }
    };
    return this.organizationLocationsService.getLocationsByFilter(JSON.stringify(filter))
      .pipe(
        tap((organizationLocations: OrganizationLocation[]) => {
          const locationMap = organizationLocations.reduce((locMap, gtwLoc) => {
            locMap[gtwLoc.id] = this.utilityService.mapOrganizationLocationToGatewayLocation(gtwLoc);
            return locMap;
          }, {});
          patchState({wizardLocations: {...getState().wizardLocations, ...locationMap}});
        }),
         catchError(err => dispatch(new SetNotificationAction('Error loading locations', AlertDialogType.ERROR)))
      ).subscribe();
  }

  @Action(LoadLocationByActivityPurpose)
  loadLocationByPurpose({dispatch, patchState, getState}: StateContext<LocationsStateModel>,
                        {purpose, locationRelationshipType, locationSearchValue = ''}: LoadLocationByActivityPurpose){
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store$.selectSnapshot(state => state);
    // let filter;

    let operationId;
    let orgTypes = [];
    let orgIds = [];
    let purposes = [];

    purposes.push(purpose);
    if (locationRelationshipType == ActivityRelationType.OTHER) {
      orgTypes.push('ORG_PARTNER');
      operationId = jobFile.operationId;
    } else if (locationRelationshipType == ActivityRelationType.PUBLIC) {
      orgTypes.push('PUBLIC');
      if (jobFile.workflowId === 'LF-DI-FTL') {
        orgTypes.push('ORG_PARTNER');
      }
    } else { // partner org
      operationId = jobFile.operationId;
      orgTypes = ['ORG_PARTNER']
      orgIds.push(jobFile.propertyMap.orgPartnerId);
    }
    
    return this.organizationLocationsService.getLocationsByOrgTypesAndOrgIdsAndSearchKey(orgTypes, orgIds, purposes, operationId, locationSearchValue)
      .pipe(
        tap((organizationLocations: OrganizationLocation[]) => {
          const locationMap = organizationLocations.reduce((locMap, gtwLoc) => {
            locMap[gtwLoc.id] = this.utilityService.mapOrganizationLocationToGatewayLocation(gtwLoc);
            return locMap;
          }, {});
          patchState({
            selectedActivityLocations: locationMap,
            wizardLocations: {...getState().wizardLocations, ...locationMap}
          });
        }),
        catchError(err => {
          console.log(err);
          return dispatch(new SetNotificationAction('Error loading locations', AlertDialogType.ERROR))
        })
      );
    
    
    
    // TODO: refactor bellow code, this is done to complete the demo on 10th july, 2023
    // if (locationRelationshipType == ActivityRelationType.OTHER) {
    //   return this.organizationLocationsService.getLocationsByOrgTypesAndOrgIdsAndSearchKey(['ORG_PARTNER'], [], [purpose], operationId)
    //     .pipe(
    //       tap((organizationLocations: OrganizationLocation[]) => {
    //         const locationMap = organizationLocations.reduce((locMap, gtwLoc) => {
    //           locMap[gtwLoc.id] = this.utilityService.mapOrganizationLocationToGatewayLocation(gtwLoc);
    //           return locMap;
    //         }, {});
    //         patchState({
    //           selectedActivityLocations: locationMap,
    //           wizardLocations: {...getState().wizardLocations, ...locationMap}
    //         });
    //       }),
    //       catchError(err => {
    //         console.log(err);
    //         return dispatch(new SetNotificationAction('Error loading locations', AlertDialogType.ERROR))
    //       })
    //     )
    // } else if (locationRelationshipType !== ActivityRelationType.PARTNER) {
    //    filter = {
    //     where: {
    //       'purposes.purposeId': {
    //         inq: [purpose]
    //       }
    //     }
    //   };
    //   orgTypes = ['PUBLIC'];

    //   // TODO: remove this hardcoded wf id and load locs dynamically.
    //   if (jobFile.workflowId === 'LF-DI-FTL') {
    //     orgTypes.push('ORG_PARTNER');
    //   }
    // } else {
    //   filter = {
    //     where: {
    //       orgId: {
    //         inq: [jobFile.propertyMap.orgPartnerId]
    //       },
    //       'purposes.purposeId': {
    //         inq: [purpose]
    //       }
    //     }
    //   };
    //   orgTypes = jobFile.propertyMap.orgPartnerId != null && jobFile.propertyMap.orgPartnerId != "" ? []: ['ORG_PARTNER']
    // }





    // return this.organizationLocationsService.getLocationsByFilterForWizard(JSON.stringify(filter), orgTypes)
    //   .pipe(
    //     tap((organizationLocations: OrganizationLocation[]) => {
    //       const locationMap = organizationLocations.reduce((locMap, gtwLoc) => {
    //         locMap[gtwLoc.id] = this.utilityService.mapOrganizationLocationToGatewayLocation(gtwLoc);
    //         return locMap;
    //       }, {});
    //       patchState({
    //         selectedActivityLocations: locationMap,
    //         wizardLocations: {...getState().wizardLocations, ...locationMap}
    //       });
    //     }),
    //     catchError(err => {
    //       console.log(err);
    //       return dispatch(new SetNotificationAction('Error loading locations', AlertDialogType.ERROR))
    //     })
    //   ).subscribe();

  }

  @Action(GetLocationById)
  getLocationById({dispatch, patchState, getState}: StateContext<LocationsStateModel>, {id}: GetLocationById){
    const filter = {
      where: {
        _id: {
          inq: [id]
        }
      }
    };

    return this.organizationLocationsService.getLocationsByFilter(JSON.stringify(filter))
      .pipe(
        tap((organizationLocations: OrganizationLocation[]) => {
          const locationMap = organizationLocations.reduce((locMap, gtwLoc) => {
            locMap[gtwLoc.id] = this.utilityService.mapOrganizationLocationToGatewayLocation(gtwLoc);
            return locMap;
          }, {});
          patchState({wizardLocations: {...getState().wizardLocations, ...locationMap}});
        }),
        catchError(err => dispatch(new SetNotificationAction('Error loading location for given id', AlertDialogType.ERROR)))
      ).subscribe();
  }

}
