<div [formGroup]="formGroup">
  <div class="contacts-details-container" formGroupName="contactDetails">
    <div class="column-1">
      <mat-form-field>
        <mat-label class="label mandatory">Address</mat-label>
        <input
          matInput
          formControlName="address"
          placeholder="Address">
      </mat-form-field>
      <mat-form-field>
        <mat-label class="label">Email</mat-label>
        <input
          matInput
          formControlName="email"
          placeholder="Email Address">
      </mat-form-field>
      <div class="city-zip-code">
        <mat-form-field class="city">
          <mat-label class="label mandatory">City</mat-label>
          <input
            matInput
            formControlName="city"
            placeholder="City">
        </mat-form-field>
        <mat-form-field class="zip-code">
          <input
            matInput
            formControlName="zipCode"
            placeholder="Zip Code">
        </mat-form-field>
      </div>
      <mat-form-field>
        <input
          matInput
          formControlName="state"
          placeholder="State">
      </mat-form-field>
      <mat-form-field>
        <mat-label class="label mandatory">Country</mat-label>
        <input
          matInput
          formControlName="country"
          placeholder="Country">
      </mat-form-field>
    </div>
    <div class="column-2">
      <mat-form-field>
        <input
          matInput
          formControlName="website"
          placeholder="Website">
      </mat-form-field>
      <div formArrayName="contactNumbers">
        <div
          *ngFor="let contact of formGroup['controls']?.contactDetails['controls']?.contactNumbers['controls']; let i = index">
          <div [formGroupName]="i">
            <div class="contact-extension">
              <mat-form-field class="phone-number">
                <input
                  matInput
                  formControlName="contactNumber"
                  placeholder="Official Phone Number">
              </mat-form-field>
              <div class="ext">
                <mat-form-field class="ext-field">
                  <input
                    matInput
                    formControlName="extension"
                    placeholder="EXT">
                </mat-form-field>
                <button mat-icon-button class="delete-contact" (click)="deleteContact(i)">
                  <img src="assets/icons/trash_can.svg">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="add-contact" mat-raised-button (click)="addNewContact()" color="accent">
        <mat-icon>add</mat-icon>
        ADD
      </button>
    </div>
  </div>
</div>
