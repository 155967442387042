<div class="assign-supervisor-container">
  <div class="header">
    <div class="close-button">
      <mat-icon class="close-button">close</mat-icon>
    </div>
    <div class="title">{{title}}</div>
  </div>
  <div class="content-container">
    <div class="note-remarks-title">
      Notes / Remarks
    </div>
    <mat-form-field appearance="outline">
      <label>
        <textarea class="remarks" matInput rows="2" placeholder="Remarks" [(ngModel)]="message"></textarea>
      </label>
    </mat-form-field>
    <div class="work-type">
      {{workType}}
    </div>
    <div class="supervisor-container" *ngFor="let supervisor of data?.supervisors | async; index as i">
      <div class="supervisor" (click)="selectSupervisor(supervisor)"
           [ngClass]="{'supervisor-clicked': clickedSupervisor?.id === supervisor.id}">
        <img class="supervisor-photo"
             src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
             alt="potrait"/>
        <span class="supervisor-text"
              [ngClass]="{'supervisor-clicked': clickedSupervisor?.id == supervisor.id}">
          {{supervisor.firstName}} {{supervisor.lastName}}
       </span>
      </div>
    </div>
    <div class="button-group">
      <button mat-raised-button class="button" color="warn" (click)="cancel()">Cancel</button>
      <button mat-raised-button class="button assign-button" color="accent"
              [ngClass]="{'assigned-disabled': !!(!clickedSupervisor?.id)}"
              (click)="assignSupervisor()"
              [disabled]="!!(!clickedSupervisor?.id)">
        Assign
      </button>
    </div>
  </div>
