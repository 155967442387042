import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {DemurrageSlab} from '@shared/models/rate-card.model';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {RateTier} from '@shared/models/rate-tier.model';
import {CreateTierComponent} from '@shared/create-tier/create-tier.component';
import {DialogHandlerService} from '@core/services/dialog-handler.service';
import {VehicleCategory} from '@shared/models/vehicle-category.model';

@Component({
  selector: 'hmt-flag-down-rate',
  templateUrl: './flag-down-rate.component.html',
  styleUrls: ['./flag-down-rate.component.scss']
})
export class FlagDownRateComponent implements OnInit {
  @Input() flagDownRateForm: FormGroup;
  @Input() vehicleCategory: VehicleCategory;
  @Input() set setIndex(index: number){
    this.index = index;
  }
  @Input() rateTiers: RateTier[];
  @Output() removeFlagDownRate = new EventEmitter<number>();
  @Output() saveNewRateTier = new EventEmitter<RateTier>();
  index: number;
  private unsubscribe: Subject<void> = new Subject();
  dct: string; // short name for demurrageCalculationType
  demurragePerHrActivated: boolean;
  isCheckBoxUseTier = false;
  isCheckBoxFixedPrice = false;

  icon = false;

  constructor(private rateCardFormBuilder: FormBuilder, private dialogHandlerService: DialogHandlerService) {
  }
  ngOnInit(): void {
    this.flagDownRateForm.controls.vehicleCategoryName.patchValue(this.vehicleCategory.categoryName);
    this.flagDownRateForm.controls.vehicleCategory.patchValue(this.vehicleCategory.id);
    this.demurragePerHrActivated = this.flagDownRateForm.controls.demurragePerHrActivated.value;
    this.flagDownRateForm.controls.demurragePerHrActivated.valueChanges.pipe(
      takeUntil(this.unsubscribe),
      tap((value: boolean) => {
        this.demurragePerHrActivated = value;
      }),
    ).subscribe();

    this.dct = this.flagDownRateForm.controls.demurrageCalculationType.value;
    this.flagDownRateForm.controls.demurrageCalculationType.valueChanges.pipe(
      takeUntil(this.unsubscribe),
      tap((value: string) => {
        this.dct = value;
      }),
    ).subscribe();
  }

  click(){
    this.icon = !this.icon;
  }

  setVehicleTier(tier: RateTier) {
    this.flagDownRateForm.patchValue({
      vehicleTier: tier.tierId,
    });
  }

  openCreateTier() {
    const data = {
      orgId: this.flagDownRateForm.value.orgId,
      tiers: this.rateTiers
    };
    const dialogRef = this.dialogHandlerService.openDialog(CreateTierComponent, data, {
      width: '629px',
      height: '100%',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.componentInstance.savetier
      .pipe(
        takeUntil(this.unsubscribe),
        tap((newTier: RateTier) => {
          this.saveRateTier(newTier);
        })
      ).subscribe();
  }

  saveRateTier(newTier: RateTier) {
    this.saveNewRateTier.emit(newTier);
  }

  displayTierName(tierId?: string) {
    return (this.rateTiers.find((tier: RateTier) => tier.tierId === tierId)?.name) ?
      this.rateTiers.find((tier: RateTier) => tier.tierId === tierId)?.name : tierId;
  }

  toggleRadioButton(value: number) {
    if (value === 1) {
      this.isCheckBoxUseTier = true;
      this.isCheckBoxFixedPrice = false;
    } else {
      this.isCheckBoxUseTier = false;
      this.isCheckBoxFixedPrice = true;
    }
  }

  getDemurrageSlabs(): FormArray {
    const slabs = this.flagDownRateForm.get('demurrageSlabs') as FormArray;
    this.toggleUntilClearancePerHour(slabs?.value);
    return slabs;
  }

  addDemurrageSlab(): void {
    const slabs = (this.flagDownRateForm.get('demurrageSlabs') as FormArray);
    slabs.push(this.rateCardFormBuilder.group({
      hours: [0],
      rate: [0]
    }));
    this.toggleUntilClearancePerHour(slabs?.value);
  }

  removeDemurrageSlab(slabIndex: number): void {
    const slabs = (this.flagDownRateForm.get('demurrageSlabs') as FormArray);
    slabs.removeAt(slabIndex);
    this.toggleUntilClearancePerHour(slabs?.value);
  }

  toggleUntilClearancePerHour(slabs: DemurrageSlab[]) {
    if (!slabs || slabs.length === 0) {
      this.flagDownRateForm.get('demurragePerHrTillClearance').disable();
    } else {
      this.flagDownRateForm.get('demurragePerHrTillClearance').enable();
    }
  }

  checkLocationTypeSelected() {
    return !(this.flagDownRateForm.controls.demurragePublicLocation.value || this.flagDownRateForm.controls.demurrageOtherLocation.value);
  }

  removeRate() {
    this.removeFlagDownRate.emit(this.index);
  }

  checkDemurragePerHrActivated() {
    return this.demurragePerHrActivated;
  }
}
