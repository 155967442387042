import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API} from '@configs/api.config';
import {Organization} from '../models/organization';
import {OrganizationType} from '../models/enums/organization-type';
import {VerticalInfo} from '../models/vertical-info';
import { Country } from '@shared/models/country.model';
import {Operation} from "../models/operation";
import {HolidaysCalendar} from "@shared/models/holidays-calendar.model";
import { GeoFenceSpeedLimit } from '../models/geo-fence-speed-limit.model';
import { Pageable } from '@shared/models/pageable.model';
import {VehicleCategory} from "@shared/models/vehicle-category.model";

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  saveOrganization(organization: Organization): Observable<Organization> {
    const url = API.organization.saveOrganization;
    return this.httpClient.put<Organization>(url, organization);
  }

  addResourceBulk(file: File): Observable<any> {
    const data = new FormData();
    data.append('file',file, file.name);
    const url = API.organization.bulkUpload;
    return this.httpClient.post<any>(url, data, {
      responseType: 'blob' as 'json',
    });
  }

  getOrganization(orgId: string): Observable<Organization> {
    const url = API.organization.getOrganization.format([orgId]);
    return this.httpClient.get<Organization>(url);
  }

  getPartnerOrganization(): Observable<Organization> {
    const url = API.organization.getPartnerOrganization;
    return this.httpClient.get<Organization>(url);
  }

  /*getPartnerOrgs(): Observable<Organization[]> {
    const url = API.organization.getPartnerOrganizations;
    return this.httpClient.get<Organization[]>(url);
  }*/
  getPartnerOrgs(): Observable<Organization[]> {
    const url = API.organization.getPartnerOrganizationsByOperations;
    return this.httpClient.get<Organization[]>(url);
  }

  getPaginatedPartnerOrgs(searchText: string, pageNum: Number, pageSize: Number): Observable<Pageable<Organization>>{
    let params = new HttpParams();
    const url = API.organization.getPaginatedPartnerOrganizationsByOperations;
    if (searchText != null || searchText != "") {
      params = params.append('searchKey', searchText);
    }

    if(pageNum != null){
      params = params.append('pageNum',String(pageNum));
    }

    if(pageSize != null){
      params =params.append('pageSize',String(pageSize))
    }

    return this.httpClient.get<Pageable<Organization>>(url, { params });
  }

  getCommonOrgs(): Observable<Organization[]> {
    const url = API.organization.getCommonOrganizations;
    return this.httpClient.get<Organization[]>(url);
  }

  getPaginatedCommonOrgs(searchText: string, pageNum: Number, pageSize: Number): Observable<Pageable<Organization>>{
    let params = new HttpParams();
    const url = API.organization.getPaginatedCommonOrganizations;
    if (searchText != null || searchText != "") {
      params = params.append('searchKey', searchText);
    }

    if(pageNum != null){
      params = params.append('pageNum',String(pageNum));
    }

    if(pageSize != null){
      params =params.append('pageSize',String(pageSize))
    }

    return this.httpClient.get<Pageable<Organization>>(url, { params });
  }

  getOnBoardedOrgs(): Observable<Organization[]> {
    const url = API.organization.getOnBoardedOrganizations;
    return this.httpClient.get<Organization[]>(url);
  }

  getPaginatedOnBoardedOrgs(searchText: string, pageNum: Number, pageSize: Number): Observable<Pageable<Organization>>{
    let params = new HttpParams();
    const url = API.organization.getPaginatedOnBoardedOrganizations;
    if (searchText != null || searchText != "") {
      params = params.append('searchKey', searchText);
    }

    if(pageNum != null){
      params = params.append('pageNum',String(pageNum));
    }

    if(pageSize != null){
      params =params.append('pageSize',String(pageSize))
    }

    return this.httpClient.get<Pageable<Organization>>(url, { params });
  }

  getChildrenOrganizations(parentOrgId: string): Observable<Organization[]> {
    const url = API.organization.getChildrenOrganizations.format([parentOrgId]);
    return this.httpClient.get<Organization[]>(url);
  }

  getPaginatedChildrenOrganizations(parentOrgId: string, searchText: string, pageNum: Number, pageSize: Number): Observable<Pageable<Organization>>{
    let params = new HttpParams();
    const url = API.organization.getPaginatedChildrenOrganizations.format([parentOrgId]);
    if (searchText != null || searchText != "") {
      params = params.append('searchKey', searchText);
    }

    if(pageNum != null){
      params = params.append('pageNum',String(pageNum));
    }

    if(pageSize != null){
      params =params.append('pageSize',String(pageSize))
    }

    return this.httpClient.get<Pageable<Organization>>(url, { params });
  }

  getOrganizationsByVertical(vertical: string, searchText:string): Observable<Organization[]> {
    const url = API.organization.getOrganizationsByVertical.format([vertical]);
    let params = new HttpParams();

    if (searchText != null || searchText != "") {
      params = params.append('searchKey', searchText);
    }

    return this.httpClient.get<Organization[]>(url, {params});
  }

  deleteOrganization(orgId: string): Observable<string> {
    const url = API.organization.deleteOrganizationLocation.format([orgId]);
    return this.httpClient.delete<string>(url);
  }

  getOrgsByTypeAndId(orgId: string, type: OrganizationType): Observable<Organization[]> {
    const url = API.organization.getOrgsByTypeAndId.format([orgId, type.toString()]);
    return this.httpClient.get<Organization[]>(url);
  }

  getVerticalInfos(): Observable<VerticalInfo[]> {
    const url = API.organization.getVerticalInfos;
    return this.httpClient.get<VerticalInfo[]>(url);
  }

  fetchCurrencies(): Observable<string[]> {
    const url = API.organization.getCurrencies;
    return this.httpClient.get<string[]>(url);
  }

  fetchCountryById(countryId: string): Observable<Country> {
    const url = API.organization.getCountryById.format([countryId]);
    return this.httpClient.get<Country>(url);
  }

  getHolidaysByCountryAndYear(countryId: string, year: number): Observable<HolidaysCalendar> {
    const url = API.organization.getHolidaysByCountryAndYear.format([countryId, year.toString()]);
    return this.httpClient.get<HolidaysCalendar>(url);
  }

  checkOrgShortNameAvailability(shortName: string) {
    const url = API.organization.checkOrgShortNameAvailability.format([shortName]);
    return this.httpClient.get<boolean>(url);
  }

  getOrganizationsByOrgIdAndOrgType(orgId: string, orgType: string, searchKey: string): Observable<Organization[]> {
    const url = API.organization.getOrganizationsByOrgIdAndOrgType.format([orgId, orgType]);
    let params = new HttpParams();
    params = params.append('searchKey', String(searchKey));
    return this.httpClient.get<Organization[]>(url, {params});
  }

  getOperations(): Observable<Operation[]> {
    const url = API.organization.getOperations;
    return this.httpClient.get<Operation[]>(url);
  }

  getOperationSpeedLimits(operationId: string, searchString: string): Observable<GeoFenceSpeedLimit[]> {
    const url = API.organization.getOperationSpeedLimits.format([operationId]);
    return this.httpClient.get<GeoFenceSpeedLimit[]>(url, {params: {searchKey: searchString}});
  }

  saveOperationSpeedLimit(speedLimit: GeoFenceSpeedLimit): Observable<GeoFenceSpeedLimit> {
    const url = API.organization.saveOperationSpeedLimits.format([speedLimit.operationId]);
    return this.httpClient.post<GeoFenceSpeedLimit>(url, speedLimit);
  }

  deleteOperationSpeedLimit(speedLimit: GeoFenceSpeedLimit): Observable<GeoFenceSpeedLimit> {
    const url = API.organization.deleteOperationSpeedLimits.format([speedLimit.operationId, speedLimit.id]);
    return this.httpClient.delete<GeoFenceSpeedLimit>(url);
  }

  saveOperation(operation: Operation): Observable<Operation> {
    const url = API.organization.updateAndAddOperation;
    return this.httpClient.put<Operation>(url, operation);
  }

  getOrgPartnerOrgIds(): Observable<string[]> {
    const url = API.organization.getOrgIdsByOrgType;
    let params = new HttpParams();
    params = params.append('orgTypes', String("ORG_PARTNER"));
    return this.httpClient.get<string[]>(url, {params});
  }

  getVehicleCategories(): Observable<VehicleCategory[]> {
    const url = API.organization.getVehicleCategories;
    return this.httpClient.get<VehicleCategory[]>(url);
  }
}
