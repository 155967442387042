import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {JobFile, JobFileContainer} from '@core/models/job-file';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ServiceViewModel} from '@aggregated-jpm/models/service-view.model';
import {Driver} from '@aggregated-jpm/models/driver.model';


@Component({
  selector: 'hmt-map-jpm',
  templateUrl: './map-jpm.component.html',
  styleUrls: ['./map-jpm.component.scss']
})
export class MapJpmComponent implements OnInit {

  @Input() jobFile$: Observable<JobFile>;
  @Input() services$: Observable<ServiceViewModel[]>;
  @Input() containerJob: JobFileContainer;
  @Input() currentDriver$: Observable<Driver>;

  constructor() { }

  ngOnInit(): void {
  }

}
