import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { AppWrapperComponent } from 'app/app-wrapper.component';
import {AuthGuardService} from '@core/services/auth-guard.service';
import { APP_ROUTING } from '@configs/constants';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuardService],
    data: {routeId: 'wizard'}
  },
  {
    path: '',
    // path: APP_ROUTING.main,
    component: AppWrapperComponent,
    loadChildren: () => import('./app-wrapper.module').then(m => m.AppWrapperModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

