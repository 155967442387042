<div class="contacts-details-container">
  <form [formGroup]="formGroup">
    <div class="form">
      <div class="contacts-type" formArrayName="phoneNumbers">
        <p class="contacts-type-header">TELEPHONE</p>
        <div *ngFor="let contactNumber of formGroup['controls'].phoneNumbers['controls']; let i = index">
          <div [formGroupName]="i">
            <div class="form-data-row">
              <div class="value">
                <mat-form-field class="telephone">
                  <label>
                    <input type="text"
                           matInput
                           formControlName="number"
                           class="telephone-value">
                  </label>
                </mat-form-field>
              </div>
              <div class="close-icon">
                <button (click)="removeContactNumberInFormGroup(i)"
                        mat-icon-button color="warn"><span class="material-icons">delete</span></button>
              </div>
            </div>
          </div>
        </div>
        <button (click)="addContactNumberFormGroup()"
          mat-raised-button color="accent"><mat-icon>add</mat-icon> ADD</button>
      </div>
      <div class="contacts-type" formArrayName="faxNumbers">
        <p class="contacts-type-header">FAX</p>
        <div *ngFor="let contactNumber of formGroup['controls'].faxNumbers['controls']; let i = index">
          <div [formGroupName]="i">
            <div class="form-data-row">
              <div class="value">
                <mat-form-field class="telephone">
                  <label>
                    <input type="text"
                           matInput
                           formControlName="number"
                           class="telephone-value">
                  </label>
                </mat-form-field>
              </div>
              <div class="close-icon">
                <button (click)="removeFaxNumberInFormGroup(i)"
                        mat-icon-button color="warn"><span class="material-icons">delete</span></button>
              </div>
            </div>
          </div>
        </div>
        <button (click)="addFaxNumberFormGroup()"
                mat-raised-button color="accent">
          <mat-icon>add</mat-icon> ADD
        </button>
      </div>
    </div>
  </form>
</div>
