import {Component, Input, OnInit} from '@angular/core';
import {ResourceRunTimeData} from '@shared/models/resource-run-time-data.model';
import {ResourceType} from '@schedule/models/enums';

@Component({
  selector: 'hmt-resource-view',
  templateUrl: './resource-view.component.html',
  styleUrls: ['./resource-view.component.scss']
})
export class ResourceViewComponent implements OnInit {

  @Input() selectedResource: ResourceRunTimeData;
  @Input() resourceType: string;
  resources = ResourceType;

  constructor() {
  }

  ngOnInit(): void {
  }

  getProfileDp(name: string): string {
    if (!name) {
      return '';
    }
    const stringArrays = name.split(' ').filter(str => str);
    if (stringArrays.length === 1) {
      return stringArrays[0]?.charAt(0)?.toUpperCase();
    }
    if (stringArrays.every(str => typeof str)) {
      return stringArrays[0][0]?.toUpperCase() + stringArrays[1][0]?.toUpperCase();
    }
    return '';
  }
}
