import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'hmt-principle-contacts',
  templateUrl: './principle-contacts.component.html',
  styleUrls: ['./principle-contacts.component.scss']
})
export class PrincipleContactsComponent implements OnInit {
  @Input() public formGroup: FormGroup;
  @Output() addPrincipleContactNumberGroup = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

}
