export enum LegCardStyle {
    WHITE,
    LITE_BLUE,
    FADED_WHITE,
    GOLDEN,
    DEEP_GOLDEN,
    FOOTER_WHITE,
    FOOTER_GREEN,
    FOOTER_BLUE,
    FOOTER_RED,
}
