<div class="vehicle-capacity-container">
  <div class="header">
    <!-- <span>Vehicle Capacity</span> -->
    <div class="col button-group">
      <form class="row toggle-group mt-10"  [formGroup]="group">
        <mat-slide-toggle formControlName="useSelectedVehiclesForAll">Reuse Available Vehicles</mat-slide-toggle>
        <mat-slide-toggle [matTooltip]="'Use fixed vehicle cost for different capacities'" formControlName="useVehicleCosts">Use Vehicle Costs</mat-slide-toggle>
        <mat-slide-toggle [disabled]="true">My Vehicle Capacity</mat-slide-toggle>
        <mat-slide-toggle [disabled]="true">Marketplace</mat-slide-toggle>
      </form>
      <!-- <span class="fill-space"></span> -->

      <div class="row mt-10 add-capacity">
        <button mat-raised-button class="add" [matMenuTriggerFor]="menu">
          <mat-icon>add</mat-icon>
          Add
        </button>
        <!--TODO: load default lengths if there are no internal length categories-->
        <mat-menu #menu="matMenu">
          <button class="menu-btn" mat-menu-item 
            *ngFor="let containerCategory of internalVehicleCategories | keyvalue"
            (click)="setExternalType(containerCategory.key)"
            >
              {{containerCategory.key}} FT
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="internal trucks-container">
      <span>Internal</span>
      <ng-template *ngTemplateOutlet="containerCategory; context: {$implicit: internalVehicleCategories, vehicleType: 'INTERNAL'}"></ng-template>
    </div>
    <div class="external trucks-container">
      <span>External</span>
      <ng-template *ngTemplateOutlet="containerCategory; context: {$implicit: externalVehicleCategories, vehicleType: 'EXTERNAL'}"></ng-template>
    </div>
    <ng-template #containerCategory let-categories let-vehicleType="vehicleType">
      <div class="container-category-container">
        <div *ngFor="let containerCategory of categories | keyvalue">
          <div class="container-category">
            <div class="category-content">
              <span>{{containerCategory.key}}</span>
              <span class="container-count">{{containerCategory.value?.count}}</span>
            </div>
            <div class="edit" (click)="editVehicleCategory(vehicleType, containerCategory.value.count, containerCategory.key)">
              <mat-icon>edit</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

