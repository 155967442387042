import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {
  FetchMoreNotifications,
  GetInitialNotifications,
  SetNotificationAction,
  ShowNotificationAction
} from './notification.actions';
import {NotificationState as NotificationStateModel} from '../../models';
import {Injectable, NgZone} from '@angular/core';
import {SnackBarHandlerService} from '@core/services/snack-bar-handler.service';
import {NotificationModel} from '@control-tower/models/notification.model';
import {FirebaseService} from '@core/services/firebase.service';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {AlertDialogType} from '@shared/models';

export const getNotificationKey = 'notification';
export const getInitialState = (): NotificationStateModel => ({
  fireBaseNotifications: [],
  totalNotificationCount: 0
});

@State<NotificationStateModel>({
  name: getNotificationKey,
  defaults: getInitialState()
})
@Injectable()
export class NotificationState {

  constructor(
    private snackBarService: SnackBarHandlerService,
    private firebaseService: FirebaseService,
    private store: Store,
    private zone: NgZone
  ) {
  }

  @Selector()
  static getNotifications(state: NotificationStateModel): NotificationModel[] {
    return state.fireBaseNotifications;
  }

  @Selector()
  static getTotalNotificationCount(state: NotificationStateModel): number {
    return state.totalNotificationCount;
  }

  @Action(SetNotificationAction)
  setNotification(ctx: StateContext<NotificationStateModel>, {message, notificationType: type, config}: SetNotificationAction) {
    this.snackBarService.dismissAll();
    this.zone.run(() => {
      this.snackBarService
        .openFromComponent(type, message, config);
    });
  }

  @Action(ShowNotificationAction)
  showNotification(ctx: StateContext<NotificationStateModel>, {notificationData, config}: ShowNotificationAction) {
    this.snackBarService.dismissAll();
    this.zone.run(() => {
      this.snackBarService
      .showNotification(notificationData, notificationData.type, config);
    });
  }

  @Action(GetInitialNotifications)
  getInitialNotifications({patchState, getState}: StateContext<NotificationStateModel>,
                          {notificationLimit}: GetInitialNotifications) {
    const {user} = this.store.selectSnapshot(authState => authState.auth);
    return this.firebaseService.getInitialNotifications(notificationLimit, user)
      .pipe(
        takeUntil(new Subject()),
        map(data => {
          return data.map(e => {
            return e.payload.doc.data() as NotificationModel;
          });
        }),
        map((notificationList: NotificationModel[]) => {
          return notificationList.map(notification => {
            notification.timeDiff = moment(new Date(notification.createdAt.seconds * 1000)).fromNow();
            return notification;
          });
        }),
        tap((notificationsList: NotificationModel[]) => {
          let notifications = [...getState().fireBaseNotifications];
          if (notifications.length >= 1) {
            notificationsList.forEach( notification => {
              notifications.splice(-1);
              notifications.unshift(notification);
              // const message = notification.title + ' => ' + notification.message;
              // this.store.dispatch(new SetNotificationAction(message, AlertDialogType.SUCCESS));
              this.store.dispatch(new ShowNotificationAction({
                title: notification.title,
                message: notification.message,
                type: AlertDialogType.SUCCESS
              }));
            });
          } else {
            notifications = notificationsList;
          }
          patchState({
            fireBaseNotifications: notifications,
            totalNotificationCount: getState().totalNotificationCount + notificationLimit
          });
        })
      );
  }

  @Action(FetchMoreNotifications)
  fetchMoreNotifications({patchState, getState}: StateContext<NotificationStateModel>,
                         {notificationLimit}: FetchMoreNotifications) {
    const {user} = this.store.selectSnapshot(authState => authState.auth);
    return this.firebaseService.fetchMoreNotifications(notificationLimit, user)
      .pipe(
        takeUntil(new Subject()),
        map(data => {
          return data.map(e => {
            return e.payload.doc.data() as NotificationModel;
          });
        }),
        map((notificationList: NotificationModel[]) => {
          return notificationList.map(notification => {
            notification.timeDiff = moment(new Date(notification.createdAt.seconds * 1000)).fromNow();
            return notification;
          });
        }),
        map((notifications: NotificationModel[]) => {
          if (notifications.length) {
            return patchState({
              fireBaseNotifications: getState().fireBaseNotifications.concat(notifications),
              totalNotificationCount: getState().totalNotificationCount + notificationLimit
            });
          }
        })
      );
  }
}
