export enum ApprovalStateType {
  SEND_FOR_INTERNAL_APPROVAL = 'SEND_FOR_INTERNAL_APPROVAL',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  INTERNALLY_APPROVED = 'INTERNALLY_APPROVED',
  INTERNALLY_REJECTED = 'INTERNALLY_REJECTED',
  CUSTOMER_PENDING_APPROVAL = 'CUSTOMER_PENDING_APPROVAL',
  CUSTOMER_APPROVED = 'CUSTOMER_APPROVED',
  CUSTOMER_REJECTED = 'CUSTOMER_REJECTED',
  DISPUTED = 'DISPUTED',
  CONFIRM = 'CONFIRM',
  PAID = 'PAID'
}
