import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {API} from '@configs/api.config';
import {EntityMap} from '@shared/models/types';
import {groupBy, mergeMap, reduce, toArray} from 'rxjs/operators';
import {Vehicle} from '../models/vehicle.model';
import {Location, LocationType} from '@shared/models';
import {Person} from '../models/Person.model';
import {ResourceType} from '@schedule/models/enums';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  getAllVehiclesForAGivenOrganization(orgId: string): Observable<Vehicle[]> {
    const url = API.fleetManager.getAllVehiclesForAGivenOrganization.format([orgId]);
    return this.httpClient.get<Vehicle[]>(url);
  }

  /*getAllDriversForAGivenOrganization(orgId: string): Observable<Person[]> {
    const url = API.fleetManager.getAllDriversForAGivenOrganization.format([orgId]);
    return this.httpClient.get<Person[]>(url);
  }*/
  getAllDriversForAGivenOrganization(orgId: string): Observable<Person[]> {
    const url = API.fleetManager.getAllDriversForAGivenOrganization.format(['driver']);
    return this.httpClient.get<Person[]>(url);
  }

  getLocationsByOnlyLocationType(locationType: LocationType): Observable<Location[]> {
    const url = API.gateway.getLocationsByType.format([locationType]);
    return this.httpClient.get<Location[]>(url);
  }

  groupByVehicleType(vehicles: Vehicle[]): Observable<EntityMap<string, Vehicle[]>> {
    return from(vehicles)
      .pipe(
        groupBy(vehicle => vehicle.type),
        mergeMap(group => group.pipe(toArray())),
        reduce((incidentMap, group) => ({...incidentMap, [group[0].type]: group}), {}),
      );
  }
}
