import {Action, Selector, State, StateContext} from '@ngxs/store';
import {AutoLoginAction, AutoLoginFailed, AutoLoginSuccess, Login, LoginFailed, LoginSuccess, Logout, PasswordResetAction, PasswordResetRequestAction, RefreshToken, ResetLoginMessage, SetLogo, SetToken, SetUser, SetCurrentUserOperations} from './auth.actions';
import {Injectable} from '@angular/core';
import {AuthService} from '@core/services/auth.service';
import {User} from '@core/models/user.model';
import {CookieService} from 'ngx-cookie-service';
import {of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {ResourceType} from '@schedule/models/enums';
import jwtDecode from 'jwt-decode';
import {Router} from '@angular/router';
import {AttachmentModel} from '../../../modules/fuel/models/attachment.model';
import { SetSpinner } from '../shared.actions';
import { SetNotificationAction } from '../notification/notification.actions';
import { AlertDialogType } from '@shared/models';
import { Operation } from 'app/modules/organization/models/operation';
import { stat } from 'fs';
import { RefreshTokenDto } from '@core/models/refresh-token.dto';

export const authStateKey = 'auth';
export class AuthStateModel {
  public token: string;
  public user: User;
  public message: string;
  public error: boolean;
  public logo: AttachmentModel;
  public currentUserOperations: Operation[];
  public selectedOperation: Operation;
}

@State<AuthStateModel>({
  name: authStateKey,
  defaults: {
    token: null,
    user: null,
    message: null,
    error: false,
    logo: null,
    currentUserOperations: [],
    selectedOperation: null,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router
  ) {}

  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  @Selector()
  static getUser(state: AuthStateModel): User {
    return state.user;
  }

  @Selector()
  static getLogo(state: AuthStateModel): AttachmentModel {
    return state.logo;
  }

  @Selector()
  static getOrgId(state: AuthStateModel): string {
    return state.user?.orgId;
  }

  @Selector()
  static getErrorMessage(state: AuthStateModel): string {
    return state.message || null;
  }

  @Selector()
  static hasError(state: AuthStateModel): boolean {
    return state.error;
  }

  @Selector()
  static getCurrentUserOperations(state: AuthStateModel): Operation[] {
    return [...state.currentUserOperations];
  }

  @Selector()
  static getSelectedOperation(state: AuthStateModel): Operation {
    if (state.user.operations.length > 1) {
      return { id: '', name: 'All', vehicleSpeed: null};
    }
    return state.currentUserOperations.find(
      (op) => op.id === state.user?.operations[0]
    );
  }

  @Action(SetUser)
  setUser({ patchState }: StateContext<AuthStateModel>, { user }: SetUser) {
    return of(patchState({ user }));
  }

  @Action(SetToken)
  setToken({ patchState }: StateContext<AuthStateModel>, { token }: SetToken) {
    patchState({ token });
  }

  @Action(SetLogo)
  setLogo(
    { patchState, getState }: StateContext<AuthStateModel>,
    { orgId }: SetLogo
  ) {
    const state = getState();
    return this.authService.getLogo(orgId).pipe(
      tap((logo) => {
        patchState({
          logo,
        });
      })
    );
  }

  @Action(SetCurrentUserOperations)
  setCurrentUserOperations({
    patchState,
    getState,
  }: StateContext<AuthStateModel>) {
    const state = getState();
    return this.authService.getCurrentUserOperations().pipe(
      tap((operations) => {
        patchState({
          currentUserOperations: [...operations],
        });
      })
    );
  }

  @Action(AutoLoginAction)
  autoLogin({ dispatch, patchState }: StateContext<AuthStateModel>) {
    patchState({ token: null, user: null, message: null, logo: null, error: false});
    const token = this.cookieService.get('token');
    if (!token) {
      dispatch([new AutoLoginFailed(), new SetSpinner(false)]);
      return;
    }
    try {
      const decodedToken = jwtDecode(token);
      const exp = decodedToken[`exp`] as number;

      if (exp && Date.now() > exp * 1000) {
        console.log('token expired, Auto login failed');
        dispatch([new AutoLoginFailed(), new SetSpinner(false)]);
      }
      // auto login success
      const user: User = decodedToken as User;
      user.userId = decodedToken[`orgUserId`];
      // TODO: remove this, and get it from the token, currently this is not in the token
      user.designation = ResourceType.FLEETMANAGER;
      patchState({ token, user, message: 'Login Successful', logo: null });
      dispatch([new AutoLoginSuccess(), new SetSpinner(false)]);
    } catch (e) {
      dispatch([new AutoLoginFailed(), new SetSpinner(false)]);
    }
  }

  @Action(Login)
  login(
    { dispatch, patchState }: StateContext<AuthStateModel>,
    loginAction: Login
  ) {
    dispatch(new SetSpinner(false));
    return this.authService.login(loginAction).pipe(
      tap((loginResponse) => {
        this.handleLoginToken(loginResponse, patchState);
        dispatch([
          new LoginSuccess(loginAction.redirectUrl),
          new SetSpinner(false),
        ]);
      }),
      catchError((err) => {
        return dispatch([new LoginFailed(err.message)]);
      })
    );
  }

  @Action(RefreshToken)
  refresh(
    { dispatch, patchState }: StateContext<AuthStateModel>,
    action: RefreshToken
  ) {

    dispatch(new SetSpinner(true));
    const refreshToken = localStorage.getItem('refreshToken');
    const tokenRequest: RefreshTokenDto = {refreshToken, selectedOperation: action.operation?.id}

    return this.authService.refreshToken(tokenRequest).pipe(
      tap((loginResponse) => {
        this.handleLoginToken(loginResponse, patchState);
        window.location.reload();
      }),
      catchError((err) => {
        // TODO: Give a good login message
        dispatch([new LoginFailed(err.message)]);
        return dispatch(new SetSpinner(false));
      })
    );
  }

  handleLoginToken(
    loginResponse,
    patchState: (val: Partial<AuthStateModel>) => AuthStateModel
  ) {
    const decodedToken = jwtDecode(loginResponse.accessToken);
    const user: User = decodedToken as User;
    user.userId = decodedToken[`orgUserId`];
    // TODO: remove this, and get it from the token, currently this is not in the token
    // user.designation = ResourceType.FLEETMANAGER;

    patchState({
      token: loginResponse.accessToken,
      user,
      message: 'Login Successful',
      logo: null,
    });
    this.cookieService.deleteAll('token');
    this.cookieService.set(
      'token',
      loginResponse.accessToken,
      decodedToken[`exp`] * 1000,
      '/'
    );
    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('refreshToken', loginResponse.refreshToken);
  }

  @Action(LoginFailed)
  processLoginFailure(
    { dispatch, patchState }: StateContext<AuthStateModel>,
    { errorMsg }: LoginFailed
  ) {
    dispatch([new SetSpinner(false)]);
    return patchState({ token: null, user: null, error: true, message: 'Username or Password Incorrect, Please try again', logo: null});
  }

  @Action(ResetLoginMessage)
  resetLoginMessage({ patchState }: StateContext<AuthStateModel>) {
    return patchState({ message: null, error: false});
  }

  @Action(Logout)
  logout(
    { patchState }: StateContext<AuthStateModel>,
    { redirect, currentPage }: Logout
  ) {
    this.cookieService.deleteAll();
    localStorage.clear();
    patchState({
      message: null, token: null, user: null, logo: null, error: false
    });
    this.cookieService.deleteAll('/');
    localStorage.clear();
    console.log('Local storage cleared');
    // const logoutUrl = `${environment.haulmatic.baseUrl}/login`;
    // if (redirect) {
    //   const currentRoute = encodeURIComponent(document.location.href);
    //   this.cookieService.deleteAll('token');
    //   window.location.href = `${logoutUrl}?redirect=${currentRoute}`;
    // } else {
    //   window.location.href = logoutUrl;
    // }
  }

  @Action(PasswordResetRequestAction)
  sendPasswordResetRequest({dispatch}: StateContext<AuthStateModel>, {userId}: PasswordResetRequestAction) {
    return this.authService.initResetPassword(userId)
      .pipe(
        map(r => {
          console.log(r);
          return dispatch(new SetNotificationAction('Successfully Initialized Reset Password', AlertDialogType.SUCCESS));
        }),
        catchError(err => dispatch(new SetNotificationAction('Error Initializing Reset Password', AlertDialogType.ERROR)))
      );
  }

  @Action(PasswordResetAction)
  resetPassword({dispatch}: StateContext<AuthStateModel>, {newPassword, reTypeNewPassword, resetKey}: PasswordResetAction) {
    return this.authService.resetPassword({newPassword, confirmPassword: reTypeNewPassword, resetKey})
      .pipe(
        map(r => {
          console.log(r);
          return dispatch(new SetNotificationAction('Successfully Reset Password', AlertDialogType.SUCCESS));
        }),
        catchError(err => dispatch(new SetNotificationAction('Error Resetting Password', AlertDialogType.ERROR)))
      );
  }
}
