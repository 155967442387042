import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {Logout, RefreshToken, SetCurrentUserOperations, SetLogo} from '@core/store/auth/auth.actions';
import {take, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {NotificationModel} from '@control-tower/models/notification.model';
import {FetchMoreNotifications, GetInitialNotifications} from '@core/store/notification/notification.actions';
import {NotificationState} from '@core/store/notification/notification.state';
import {AuthState} from '@core/store/auth/auth.state';
import {User} from '@core/models/user.model';
import {Router} from '@angular/router';
import {AttachmentModel} from '../../../modules/fuel/models/attachment.model';
import { APP_ROUTING } from '@configs/constants';
import { ResetSharedState } from '@core/store/shared.actions';
import { Operation } from 'app/modules/organization/models/operation';

@Component({
  selector: 'hmt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private maximumNotificationLimit = 10;
  chats$ = 21;
  name$ = 'Name';
  showNotifications = false;

  @Select(NotificationState.getNotifications) firebaseNotifications$: Observable<NotificationModel[]>;
  @Select(NotificationState.getTotalNotificationCount) notificationCount$: Observable<number>;
  @Select(AuthState.getUser) user$: Observable<User>;
  @Select(AuthState.getLogo) logo: Observable<AttachmentModel>;
  @Select(AuthState.getCurrentUserOperations) operations$: Observable<Operation[]>;
  @Select(AuthState.getSelectedOperation) selectedOperation$: Observable<Operation>;

  @Output() toggle = new EventEmitter<void>();

  // Uncomment this line when you want to scroll
  /*@ViewChild('notificationList', {read: ElementRef, static: false}) notificationListView: ElementRef;*/

  constructor(
    private cookieService: CookieService,
    private actions$: Actions,
    private store$: Store,
    private router: Router) {
  }

  ngOnInit(): void {
    this.listenToNotifications();
    this.user$.pipe(
      take(1),
      tap(user => {
        this.store$.dispatch(new SetLogo(user.orgId));
      })
    ).subscribe();

    this.user$.pipe(
      take(1),
      tap(() => {
        this.store$.dispatch(new SetCurrentUserOperations());
      })
    ).subscribe();

    this.actions$
      .pipe(
        ofActionCompleted(Logout),
        take(1),
        tap(t => {
          this.router.navigate([ APP_ROUTING.auth.login], {queryParams: {redirect: t.action.currentPage}});
        })
      ).subscribe();
  }

  compareOperations(op1: Operation, op2: Operation) {
    if (!op1 || !op2) {
      return false;
    }
    return op1.id === op2.id;
  }

  handleChangeOperation(event) {
    this.store$.dispatch(new RefreshToken(event.value));
  }

  toggleDrawer() {
    this.toggle.emit();
  }

  logout() {
    this.store$.dispatch([new Logout(false), new ResetSharedState()]);
  }

  listenToNotifications(): void {
    this.store$.dispatch(new GetInitialNotifications(this.maximumNotificationLimit));
  }

  toggleNotificationView() {
    this.showNotifications = !this.showNotifications;
  }

  onClickNotification(notification: NotificationModel) {
    switch (notification.updatedNotificationType) {
      case 'WORK_ORDER_UPDATE':
      case 'ACTIVITY_COMPLETE':
      case 'JOB_COMPLETE':
      case 'INCIDENT':
        this.router.navigate([`/control-tower`], {
          queryParams: {
            jobRefId: notification.jobRefId,
            containerId: notification.containerId
          }
        });
        break;
      case 'JOB_ASSIGNED':
        this.router.navigate([`/schedule`], {queryParams: {jobRefId: notification.jobRefId}});
        break;
    }
  }

  // Uncomment below codes get data when scroll down
  /*scrollHandler() {
    const scrollDiv = this.notificationListView.nativeElement;
    if (
      scrollDiv.scrollHeight <=
      Math.ceil(scrollDiv.scrollTop) + scrollDiv.offsetHeight
    ) {
      this.resetNotificationCount(this.notificationCount$);
      this.store$.dispatch(new FetchMoreNotifications(this.maximumNotificationLimit));
    }
  }*/

  seeMore() {
    this.resetNotificationCount(this.notificationCount$);
    this.store$.dispatch(new FetchMoreNotifications(this.maximumNotificationLimit));
  }

  resetNotificationCount(notificationCount$: Observable<number>) {
    notificationCount$.pipe(
      tap(numberOfNotification => this.maximumNotificationLimit = numberOfNotification)
    ).subscribe();
  }
}
