<div class="freight-details-container">
  <div class="header-container">
    <div class="header">
      <h1>{{data.title}}</h1>
    </div>
  </div>
  <div class="detail-box">
    <div class="detail-column">
      <div *ngFor="let item of data.freightDetails; let i = index"
           [ngClass]="[highlight ? 'freight-detail-row' : 'general-detail-row', (highlight ?  'highlight' : '')]">
        <div class="left-column">{{item.title}}</div>
        <div class="mid-flex-grow" *ngIf="highlight"></div>
        <div class="right-column">{{item.value}}</div>
      </div>
    </div>
  </div>
</div>
