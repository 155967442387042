import {ContainerSummary} from '../models';
import {DateMap} from '@core/models/job-file';
import {TerminateType} from '../models/enums';
import {Jpm} from '@control-tower/models/jpm/jpm.model';
import {EntityMap} from '@shared/models/types';
import {TerminateJobModel} from '@control-tower/models/terminate-job-model';
import {LocationType} from '@shared/models';
import {WorkOrder} from '../models/work-order/work-order';
import {Children, WorkOrderDefinition} from '../models/work-order/work-order-definition.model';
import {JpmLeg} from '@control-tower/models/jpm/jpm-leg.model';
import {JPMLocation} from '@control-tower/models/jpm-location.model';
import {GatewayLocation} from '@shared/models/gateway-location.model';
import {UploadFiles} from '@control-tower/models/upload-files';
import {PreSignedUrl} from '@control-tower/models/jpm/pre-signed-url';
import * as moment from 'moment';
import {WorkOrderStatus} from '@control-tower/models/enums/work-order-status.enum';
import {IncidentModel} from '@control-tower/models/incident.model';
import {ActivityDocumentsModel} from '@control-tower/models/work-order/activity-documents.model';
import {CostInfo} from '@control-tower/models/costInfo.model';
import {JpmCostsViewModel} from '@control-tower/models/jpm/jpm-costs-view.model';
import {GpsInfo} from '@control-tower/models/jpm/GpsInfo.model';
import {LiveVehicleInfo} from '@control-tower/models/live-vehicle-info.model';
import {VehicleType} from '@schedule/models/enums';
import {DocumentRefType} from '@control-tower/models/enums/document-ref-type.enum';
import {IncidentType} from '@shared/models/enums/incident-type.enum';
import {WorkOrderType} from '@shared/models/enums/work-order-type.enum';
import {TerminateJobQutModel} from '@shared/models/terminate-job-qut-model';
import {BayInfo} from '@shared/models/bay-info.model';
import {DistanceType} from '@control-tower/models/enums/distance-type.enum';
import {SpeedModel} from '@control-tower/models/Speed.model';
import {Resource} from '@schedule/models';
import {WorkFlowComponent} from '../../wizard/models/workflow/work-flow-component';
import {CustomsChannel} from '../../wizard/models/enums/customs-channel.enum';
import {AttachmentModel} from '../../fuel/models/attachment.model';
import {FileMetadata} from '@control-tower/models/files/file-metadata.model';
import {PrincipalContact} from '@shared/models/principalContact.model';
import {getAddress} from '../../wizard/test-data/job-summary-test';
import { LoadType } from '@shared/models/enums/load-type.enum';

export function getCostViewModels(numberOfViewModels = 1): JpmCostsViewModel[] {
  return [...Array(numberOfViewModels).keys()]
    .map((i: number): JpmCostsViewModel => {
      const costs = getJpmCosts();
      return {
        activityMessage: `header-message ${i}`,
        activityHeader: `header ${i}`,
        minCostDate: costs[costs.length - 1],
        costs
      } as any;
    });
}

export function getJpmCosts(numberOfCosts = 1): CostInfo[] {
  return [...Array(numberOfCosts).keys()]
    .map((i: number): CostInfo => {
      return {
        id: `${i}`,
        activityId: `${i + 1}`,
        costDescription: `Description ${i}`,
        amount: 100 * (i + 1),
        payable: true,
        payableAmount: 1000,
        createdDate: new Date(2020, 10, 25, 1 + i, 11, 0, 0).getTime(),
        billable: i % 2 === 0,
        workOrderId: `${i}`
      };
    });
}

export function getContainerSummary(numberOfResources: number, plan = false): ContainerSummary[] {
    return [...Array(numberOfResources).keys()]
        .map((i: number): ContainerSummary => ({
            containerId: 'Container 1',
            containerOrder: 1,
            containerNo: null,
            containerType: '40FT DRY',
            cutOffDate: new Date(2020, 5, 10, 5, 0),
            percentage: 50,
            plannedShipDate: new Date(2020, 5, 10, 3, 0),
            route: 'Galle - Colombo',
            plan,
            warning: false,
            terminate: false,
            isInProgress: false,
            jpmId: 'test',
            jobRefId: 'test-1',
            loadType: LoadType.CONTAINER,
            poolId: 'poolId',
            couple: false,
            coupleContainer: 'coupleNo'
        }));
}

export function getTerminateJobModel(): TerminateJobModel {
  return  {
    orgId: 'org-1',
    jobRefId: 'job-ref-1',
    comment: 'test comment',
    invoice: true,
    terminationDate: new Date(2020, 12, 12, 1, 12),
    terminationType: TerminateType.INTERNAL_ISSUE
  };
}

export function getTerminateJobQutModel(): TerminateJobQutModel {
  return  {
    comments: 'test comment',
    invoiceCreated: true,
    terminateDate: new Date(2020, 12, 12, 1, 12),
    reason: 'Customer Canceled'
  };
}

export function getReportIncidentModel(count: number): IncidentModel[] {
  return [...Array(count).keys()].map(
    (index: number) =>
      ({
        id: `incident-${index}`,
        jpmId: `jpmId-${index}`,
        legId: `legId-${index}`,
        workOrderId: `workOrder-id-${index}`,
        activityId: `activity-id-${index}`,
        incidentType: IncidentType.ACCIDENT,
        date: new Date('Sun Sep 13 2020 23:54:18 GMT+0530'),
        comment: 'incident',
        documents: [],
        actions: [],
        reason: '',
        reportedBy: ''
      } as IncidentModel)
  );
}

export function getJPM(count: number): Jpm[] {
  return [...Array(count).keys()].map(
    (val: number, index: number) =>
      ({
        orgId: 'haulmatic',
        jobRefId: `job-ref-id-${index}`,
        containerID: `container-id-${index} ${index}`,
        containerNumber: `containerNumber-${index}`,
        legs: index % 2 === 0 ? getLegs(2) : null,
        isCompleted: true,
        costs: getCostInfosAsArray(2),
        jpmId: `jpm-id-${index}`,
        gpsEncodedString: `encodedPath-${index}`,
        documents: getPreSignedUrls(2)
      } as Jpm)
  );
}

export function getCostInfosAsArray(count: number): CostInfo[] {
  return [...Array(count).keys()].map(
    (index: number) => ({
      id: `id-${index}`,
      activityId: `activity-${index}`,
      amount: 3000,
      billable: true,
      payable: true,
      payableAmount: 1000,
      costDescription: `cost-description-${index}`,
      createdDate: null,
      workOrderId: `work-order-${index}`
    })
  );
}

export function getJpmsAsMap(countVal: number): EntityMap<string, Jpm> {

  const containers: EntityMap<string, Jpm> = {};
  const jpms = getJPM(countVal);
  // let index = 1;
  jpms.forEach( jpm => {
    containers[jpm.containerID] = jpm;
    // index = index + 1;
  });
  return containers;
}

export function getContainerDates(numberOfDates: number): DateMap[] {
  return [...Array(numberOfDates).keys()]
    .map((i: number): DateMap => ({
      order: i,
      title: `title ${i}`,
      date: new Date(2020, 12, i + 1, i + 1, 20),
    }));
}

export function getJpms(count: number, numberOfLegs = 1, numberOfTasks = 1): Jpm[] {
  return [...Array(count).keys()].map(
    (val: number, index: number) =>
      ({
        jpmId: `jpmId-${index}`,
        orgId: 'haulmatic',
        jobRefId: `job-ref-id-${index}`,
        containerID: `container-id-${index}`,
        containerNumber: `containerNumber-${index}`,
        legs: getLegs(numberOfLegs, numberOfTasks)
      } as Jpm)
  );
}

export function getLegs(numberOfLegs = 1, numberOfTasks = 1): JpmLeg[] {
  return [...Array(numberOfLegs).keys()].map(index => {
    return {
      legId: `${index}`,
      order: index,
      iconType: 0,
      status: 'IDLE',
      vehicleTeamId: `${index}`,
      trailer: `trailer-id${index}`,
      trailerRegNumber: `TR-${index}`,
      primeMover: `prime-mover-id-${index}`,
      vehicleType: 'PRIME_MOVER',
      vehicleRegNumber: `VR-${index}`,
      tripId: `trip-id-${index}`,
      driver: `driver-id-${index}`,
      driverName: `Driver-${index}`,
      assistant: `assistant-id-${index}`,
      assistantName: `Assistant-${index}`,
      isUseSameVehicle: false,
      isUseSameTrailer: false,
      isUseHighways: false,
      isCompleted: false,
      isStarted: false,
      actualTimeLeaved: '',
      positioningTime: new Date(),
      scheduledEndTime: new Date(),
      dateandTime: new Date(),
      startLocation: getLocation('Start Location', LocationType.PORT),
      endLocation: getLocation('End Location', LocationType.WAREHOUSE),
      wayPoints: [],
      name: '',
      loadingTime: 0,
      external: false,
      externalPartnerId: '',
      isTrailerExternal: false,
      primeMoverExternalDetails: null,
      trailerExternalDetails: null,
      actualStart: '',
      actualEnd: '',
      isAdditionalLeg: false,
      legDistance: 0,
      legDuration: 0,
      completePercentage: 0,
      advanceOptions: null,
      detention: null,
      isExternal: false,
      events: [],
      jobStarted: false,
      isStartGeoFenceVerified: false,
      startOdometer: 1000,
      endOdometer: 1200,
      isEndGeoFenceVerified: false,
      avoidTolls: true,
      tollEntries: [],
      manualDistance: '',
      gpsDistance: '',
      distanceType: DistanceType.MANUAL,
      distanceOverridden: false,
      overriddenDistance: '',
      startOdometerCompleted: false,
      endOdometerCompleted: false,
    } as any;
  });
}

export function getLocation(name: string, type: LocationType): JPMLocation {
  return  {
    locName: name,
    type,
    gpsCoodinates: null,
    geoFence: []
  };
}

export function getWorkOrders(numberOfWorkOrders = 1): WorkOrder[] {
  return [...Array(numberOfWorkOrders).keys()].map((index: number) => ({
    id: `work-order-${index}`,
    orgId: 'haulmatic',
    jobRefId: `job-ref-id-${index}`,
    jpmId: 'jpm-id',
    legId: `leg-id-${1 + index}`,
    workOrderId: `${index}`,
    parentTaskId: `activity-id-${index % 2}`,
    moduleRefId: `ref-${index % 2}`,
    order: index,
    legOrder: 1 + index,
    completionTitle: `completion title - ${index}`,
    completionMessage: `completion message - ${index}`,
    completionTime: moment('20/09/2020 09:15:00', 'DD MM YYYY hh:mm:ss').toDate(),
    specialInstructions: 'test',
    message: `message-${index}`,
    state: WorkOrderStatus.IDLE
  } as WorkOrder));
}

export function getWorkOderDefinitions(numberOfDefinitions = 1, type = WorkOrderType.USER): WorkOrderDefinition[] {
  return [...Array(numberOfDefinitions).keys()].map((index: number) => ({
    id: `${index}`,
    description: `description ${index}`,
    formJsonSchema: `string`,
    responseURL: `api/${index}`,
    name: `work order - ${index}`,
    exceptions: {[IncidentType.DELAYED]: []},
    type,
    locationTypes: [LocationType.PORT],
    children: index === 0 ? getWorkOderDefinitionChildren(numberOfDefinitions - 1) : []
  } as WorkOrderDefinition));
}

export function getWorkOderDefinitionChildren(numberOfDefinitions = 1, type = WorkOrderType.USER): Children[] {
  return [...Array(numberOfDefinitions).keys()].map((index: number) => ({
    workOrderId: `${index}`,
    mandatory: index % 2 === 0 ? true : false
  } as Children));
}

export function getWorkOderDefinitionsInTransit(type = WorkOrderType.USER): WorkOrderDefinition[] {
  return [...Array(1).keys()].map((index: number) => ({
    id: `${index}`,
    description: `description ${index}`,
    formJsonSchema: `string`,
    responseURL: `api/${index}`,
    name: `In transit`,
    exceptions: {[IncidentType.DELAYED]: []},
    type
  } as WorkOrderDefinition));
}

export function getWorkOrderActivities(numberOfActivities = 1): WorkOrder[] {
  return getWorkOrders(numberOfActivities)
    .map((workOrder: WorkOrder) => {
      const index = workOrder.order;
      workOrder.id = `activity-${index}`;
      workOrder.workOrderId = `work-order-id-${index}`;
      workOrder.workOrderType = `ACTIVITY`;
      workOrder.messageHeader = `title-${index}`;
      workOrder.message = `message-${index}`,
      workOrder.locationId = `location-id-${index}`;
      workOrder.locationType = index === 0
        ? LocationType.CONTAINER_YARD : index === numberOfActivities - 1 ? LocationType.PORT : LocationType.WAREHOUSE;
      workOrder.order = index % 2;
      workOrder.legOrder = Math.floor(index / 2);
      workOrder.jobRefId = `job-ref-id-${index}`;
      workOrder.workOrderName = `work-order-name-${index}`;
      workOrder.state =  WorkOrderStatus.IDLE;
      workOrder.legId = `leg-id-${index}`;
      workOrder.startTimeEta = new Date(2020, 11, 20 + index, 11, 11, 11);
      workOrder.endTimeEta = new Date(2020, 11, 20 + index, 11, 11, 11);
      workOrder.startTime = new Date(2020, 11, 20 + index, 11, 11, 11);
      workOrder.endTime = new Date(2020, 11, 20 + index, 11, 11, 11);

      return workOrder;
    });
}

export function getActivityDocuments(noOfActivities: number): ActivityDocumentsModel[] {
  const workOrders: WorkOrder[] = getWorkOrderActivities(noOfActivities);
  return workOrders.map(workOrder => ({activity: workOrder, documents: getPreSignedUrls(3)}));
}

export function getGatewayLocations(numberOfLocations = 3, locationType?: LocationType): GatewayLocation[] {
  return [...Array(numberOfLocations).keys()]
    .map((index: number) => ({
      id: `location-id-${index}`,
      name: `Location ${index}`,
      faxNumber: `0112555369`,
      phoneNumber: `0112555369`,
      address: {
        country: 'Sri Lanka',
        addressLine: 'Haulmatic Yard',
        state: 'North Central',
        city: 'Nugegoda',
        zipCode: '5074',
      },
      principalContact: {
        firstName: 'cha',
        lastName: 'pra',
        gender: 'male',
        mobile: '0710000000',
        email: 'a@gmail.com',
        designation: 'manager',
      } as PrincipalContact,
      contact: {
        name: 'name',
        mobile: 'string',
        phone: '0112555369',
        email: 'string',
        designation: 'string'
      },
      geoFence: [],
      geoLocation: null,
      locationType: locationType ? locationType : index === 0
        ? LocationType.CONTAINER_YARD : index === numberOfLocations - 1 ? LocationType.PORT : LocationType.WAREHOUSE,
      bayInformation: getBayInformation()
    }));
}

export function getBayInformation(numberOfBays = 3): BayInfo[] {
  return [...Array(numberOfBays).keys()]
    .map((index: number) => ({
      bayName: `bay-${index}`,
      bayNum: `bay-num-${index}`
    }));
}

export function getPrincipleContacts(numberOfContacts): PrincipalContact[] {
  return [...Array(numberOfContacts).keys()]
    .map((index: number) => ({
      firstName: `cha-${index}`,
      lastName: `pra-${index}`,
      gender: `male-${index}`,
      mobile: `0710000000-${index}`,
      email: `a@gmail.com-${index}`,
      designation: `manager-${index}`,
      faxNumber: `fax-${index}`,
      nic: `nic-${index}`,
      division: `division-${index}`,
      officeContact: {
        contactNumber: `office-contact-${index}`,
        extension: `extension-${index}`
      },
      officeAddress: getAddress()
    }));
}

export function getJpmLocations(numberOfLocations = 3): JPMLocation[] {
  return [...Array(numberOfLocations).keys()]
    .map((index: number) => ({
      locationId: `location-id-${index}`,
      locName: `Location ${index}`,
      gpsCoodinates: {},
      geoFence: [],
      type: index === 0
        ? LocationType.CONTAINER_YARD : index === numberOfLocations - 1 ? LocationType.PORT : LocationType.WAREHOUSE,
      iconType: 0,
      order: 0,
      faxNumber: `0112555369`,
      phoneNumber: `0112555369`,
      address: {
        country: 'Sri Lanka',
        addressLine: 'Haulmatic Yard',
        state: 'North Central',
        city: 'Nugegoda',
        zipCode: '5074',
      },
      principalContact: {
        firstName: 'cha',
        lastName: 'pra',
        gender: 'male',
        mobile: '0710000000',
        email: 'a@gmail.com',
        designation: 'manager',
      },
      isExternalLocation: false,
      isEmptyContainerPickupYard: false,
      isAdditionalLocation: false,
      isStopOver: false,
      status: '',
      orgLocationId: '',
      orgPartnerId: '',
      note: '',
    } as any));
}

export function getUploadFilesObjects(): UploadFiles {
  return {
      refId: '0',
      refType: DocumentRefType.WORK_ORDER,
      activityId: 'activity-1',
      workOrderFiles: [new File([''], 'test-1'), new File([''], 'test-2')]
      };
}

export  function getPreSignedUrls(numberOfFiles: number): PreSignedUrl[] {
  return [...Array(numberOfFiles).keys()]
    .map((index: number) => ({
      id: `${index}`,
      refId: `work-order-${index}`,
      refType: DocumentRefType.WORK_ORDER,
      name: `file-${index}`,
      originalFileName: '',
      extension: 'image/jpg',
      key: `key-${index}`,
      thumbnailKey: `thumbnail-key-${index}`,
      thumbnailUrl: null,
      url: null,
      heading: null,
      thumbnail: null
    }));
}

export function  getPreSignedUrlsForWorkOrders(numberOfFiles): EntityMap<string, PreSignedUrl[]> {
  const urls: EntityMap<string, PreSignedUrl[]> = {};
  for (let i = 0; i <= numberOfFiles; i ++) {
    urls[i] = getPreSignedUrls(i + 1);
  }
  return urls;
}

export function getJpmCompletionPercentageMap(numberOfRecords): EntityMap<string, number> {
  const completionMap: EntityMap<string, number> = {};
  for (let i = 0; i < numberOfRecords; i++) {
    completionMap['container-id-' + i + ' ' + i] = 15 + i;
  }
  return completionMap;
}

export function getGpsInfoArray(numberOfRecords): GpsInfo[] {
  return [...Array(numberOfRecords).keys()]
    .map((index: number) => ({
      deviceId: 'device-1',
      elevation: 3000 + index,
      event: `event-${index}`,
      fuelLevel: 200 + index,
      jpmId: `jpm-${index}`,
      legId: `leg-${index}`,
      orgId: `org-${index}`,
      logDate: new Date(2020, 11, 20 + index, 11, 11, 11),
      longitude: 80.09876 + index,
      latitude: 8.08753 + index,
    }) as GpsInfo);
}

export function getMarkerLocations(numberOfRecords): JPMLocation[] {
  return [...Array(numberOfRecords).keys()]
    .map((index: number) => ({
      locName: `loc-${index}`,
      gpsCoodinates: {lat: 8.062317, lng: 80.418111},
      type: LocationType.CONTAINER_YARD
    }) as JPMLocation);
}

export function getLiveVehicleInfo(numberOfRecords): LiveVehicleInfo[] {
  return [...Array(numberOfRecords).keys()]
    .map((index: number) => ({
      external: null,
      vehicleType: VehicleType.PRIME_MOVER,
      vehicleNo: `VR-${index}`,
      trailer: `TR-${index}`,
      assistant: `Assistant-${index}`,
      driver: `Driver-${index}`,
      gpsConnected: null,
      gpsAccuracy: null,
      lastUpdatedAt: null,
      maxSpeed: null,
      currentSpeed: null,
      averageSpeed: null,
      hardStops: null
    }) as LiveVehicleInfo);
}

export function getSpeeds(numberOfSpeedModels): SpeedModel[] {
  return [...Array(numberOfSpeedModels).keys()]
    .map((index: number) => ({
      minimumSpeed: 20 + index,
      currentSpeed: 35 + index,
      averageSpeed: 18 + index
    }) as SpeedModel);
}

export function mapWorkOderDefinitions(resource: WorkOrderDefinition[]): EntityMap<string, WorkOrderDefinition> {
  return resource.reduce((prev: EntityMap<string, WorkOrderDefinition>, curr: WorkOrderDefinition) => {
    prev[curr.id] = curr;
    return prev;
  }, {});
}

export function getWorkflowComponents(numberOfComponents: number): WorkFlowComponent[] {
  return [...Array(numberOfComponents).keys()]
    .map((index: number) => ({
      componentId: `component-${index}`,
      label: `label-${index}`,
      name: `name-${index}`,
    }) as WorkFlowComponent);
}

export function getWorkFlowSteps(components: WorkFlowComponent[]): EntityMap<number, WorkFlowComponent[]> {
  return components.reduce((prev: EntityMap<number, WorkFlowComponent[]>, curr: WorkFlowComponent, index) => {
    prev[index] = [curr];
    return prev;
  }, {});
}

export function getCustomsChannels(): CustomsChannel[] {
  const customsChannels = [];
  customsChannels.push('RED');
  customsChannels.push('GREEN');
  return customsChannels;
}

export function getAttachments(noOfAttachments): AttachmentModel[] {
  return [...Array(noOfAttachments).keys()]
    .map((i: number): AttachmentModel => ({
        id: `id-${i}`,
        name: `name-${i}`,
        contentType: `extension-${i}`,
        url: `url-${i}`,
      } as AttachmentModel
    ));
}

export function getFileMetadata(noOfFileMetadata): FileMetadata[] {
  return [...Array(noOfFileMetadata).keys()]
    .map((i: number): FileMetadata => ({
        id: `id-${i}`,
        orgId: `name-${i}`,
        name: `name-${i}`,
        contentType: 'contentType',
        objectKey: `objectKey`,
      } as FileMetadata
    ));
}
