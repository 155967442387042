import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHoursMinutes'
})
export class MinutesToHoursMinutesPipe implements PipeTransform {

  transform(minutes: number): string {
    return `${Math.floor(minutes / 60)} Hrs   ${Math.floor(minutes % 60)} Min`;
  }

}
