<div class="validation-message col full-width-height">
  <div class="vm-header row">
    <p class="title">Warning</p>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="vm-content col">
    <p class="message">{{data.message}}</p>
  </div>
  <mat-divider></mat-divider>
  <div class="vm-footer row">
    <button mat-stroked-button color="warn" (click)="close()">Ok</button>
  </div>
</div>
