import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Location, LocationType} from '@shared/models';
import {Observable, throwError} from 'rxjs';
import {API} from '@configs/api.config';
import {GatewayLocation} from '@shared/models/gateway-location.model';

@Injectable({
  providedIn: 'root'
})
export class GatewayService {

  constructor(private readonly httpClient: HttpClient) { }

  getLocationByLocationIdAnType(locations: {locationId: string, locationType: LocationType}[]): Observable<GatewayLocation[]> {
    const url = API.gateway.getLocations;
    return this.httpClient.post<GatewayLocation[]>(url, locations);
  }

  getLocationsByOnlyLocationType(locationType: LocationType): Observable<GatewayLocation[]> {
    const url = API.gateway.getLocationsByType.format([locationType]);
    return this.httpClient.get<GatewayLocation[]>(url);
  }
}
