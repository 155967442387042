import { Component, Inject, NgZone, ViewEncapsulation } from "@angular/core";
import { MatSnackBar, MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { AlertDialogType, SnackBarData } from "@shared/models";
import { NotificationData } from "@shared/models/notification-data.model";


@Component({
    selector: 'hmt-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationComponent {

  matSnackBarTye = AlertDialogType;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotificationData, private matSnackBar: MatSnackBar) { }

  public close(): void {
    this.matSnackBar.dismiss();
  }
}