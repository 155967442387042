<div class="vehicle-count-container">
  <div class="vehicle-count-header">
    <div class="close-button">
      <button class="close-button-click" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <h2>Modify Vehicle Count</h2>
  </div>
  <div class="content">
    <button mat-icon-button (click)="add()">
      <mat-icon>add</mat-icon>
    </button>
    <form [formGroup]="formGroup">
      <mat-form-field>
        <input type="number"
               placeholder="Enter Count"
               matInput
               formControlName="countControl">
      </mat-form-field>
    </form>
    <button mat-icon-button (click)="reduce()">
      <mat-icon>remove</mat-icon>
    </button>
  </div>

  <div class="row ok-btn">
    <button mat-raised-button color="primary"  (click)="close()">
      OK
    </button>
  </div>
</div>