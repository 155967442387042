import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {Cost} from '../../models/cost.model';
import * as uuid from 'uuid';

@Component({
  selector: 'hmt-add-cost',
  templateUrl: './add-cost.component.html',
  styleUrls: ['./add-cost.component.scss']
})
export class AddCostComponent implements OnInit {
  @Output() addCost: EventEmitter<Cost> = new EventEmitter<Cost>();
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddCostComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      cost: Cost
    }
  ) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      description: new FormControl(''),
      cost: new FormControl('')
    });
    if (this.data.cost != null){
      this.form.controls.description.setValue(this.data.cost.description);
      this.form.controls.cost.setValue(this.data.cost.value);
    }
  }

  close() {
    this.dialogRef.close();
  }

  onAddCost() {
    const cost = {
      id: this.data.cost != null ? this.data.cost.id : uuid.v4(),
      value: this.form.get('cost').value,
      description: this.form.get('description').value
    } as Cost;
    if(cost.value && cost.description.trim() !== ''){
      this.addCost.emit(cost);
      this.close();
    }
  }
}
