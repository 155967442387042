<div class="addition-and-subtraction col full-width-height">
  <div class="as-header row">
    <p class="title">{{ data.name ? data.name : 'Add Item' }}</p>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="as-content col">
    <form [formGroup]="discountForm" *ngIf="data.type === 'discount'" class="full-width">
      <mat-form-field class="full-width">
        <mat-label>Description</mat-label>
        <input matInput formControlName="description">
      </mat-form-field><br>
      <mat-radio-group (change)="onchange($event)">
        <mat-radio-button value="1" [checked]="paymentType === paymentTypes.AMOUNT">Amount</mat-radio-button>
        <mat-radio-button value="2" [checked]="paymentType === paymentTypes.PERCENTAGE">Percentage</mat-radio-button>
      </mat-radio-group>
      <mat-form-field class="full-width value">
        <mat-label>Value</mat-label>
        <input matInput formControlName="value" type="number" hmtRestrictInputDecimalPlaces decimalPlaces="2">
        <mat-error *ngIf="discountForm.get('value').hasError('required')">Value is required</mat-error>
        <mat-error *ngIf="discountForm.get('value').hasError('invalidNumber')">Value must be a valid number</mat-error>
        <mat-error *ngIf="discountForm.get('value').hasError('invalidDecimalPlaces')">Value must have up to two decimal places</mat-error>
      </mat-form-field>
    </form>

    <!--for tax change-->
    <form [formGroup]="taxForm" *ngIf="data.type === 'tax'" class="full-width">
      <mat-form-field class="full-width">
        <mat-label>Tax type</mat-label>
        <mat-select formControlName="taxName">
          <mat-option *ngFor="let tax of taxNames" [value]="tax" (click)="onchangeTax(tax)">
            {{tax}}
          </mat-option>
        </mat-select>
      </mat-form-field><br>
      <mat-form-field class="full-width">
        <mat-label>Value</mat-label>
        <input matInput formControlName="percentage" type="number" [attr.disabled]="true">
        <mat-icon matSuffix class="my-icon">percentage</mat-icon>
      </mat-form-field>
    </form>
  </div>
  <mat-divider></mat-divider>
  <div class="as-footer row">
    <button mat-stroked-button color="warn" (click)="close()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onSubmit()">Save</button>
  </div>
</div>
