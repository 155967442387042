import {VEHICLES_ACTION_PREFIX} from "../../modules/organization/store/vehicles/vehicles.actions";

export const SHARED_STATE_ACTION_PREFIX = '[Cost State]';

export class SearchCost {
  static readonly type = `${SHARED_STATE_ACTION_PREFIX} Search cost`;
  constructor(public keyword: string) { }
}

export class SetSpinner {
  static readonly type = `${SHARED_STATE_ACTION_PREFIX} Set Spinner`;
  constructor(public isEnable: boolean, public message?: string) { }
}

export class LoadCountryById {
  static readonly type = `${SHARED_STATE_ACTION_PREFIX} load Country`;
  constructor(public countryId?: string) { }
}

export class LoadOrgById {
  static readonly type = `${SHARED_STATE_ACTION_PREFIX} load Organization`;
  constructor(public orgId: string) { }
}

export class ResetSharedState {
  static readonly type = `${SHARED_STATE_ACTION_PREFIX} Reset State`;
}

export class GetVehicleCategories {
  static readonly type = `${VEHICLES_ACTION_PREFIX} fetch vehicle categories`;

  constructor(public isStandard?: boolean) {
  }
}
