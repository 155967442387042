import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ShipmentOrder} from '@core/models/job-file/shipment-order.model';


@Component({
  selector: 'hmt-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit, OnDestroy {

  @Input() orders: ShipmentOrder[] = [];

  displayedColumns: string[] = [
    'orderNo',
    'shipmentNumber',
    'itemName',
    'packagingType',
    'numberOfPackages',
    'dimensions',
    'requiredSpace',
    'totalCBM',
    'grossWeight',
    'totalWeight',
    'pickup',
    'actions'
  ];

  constructor() {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {}
}
