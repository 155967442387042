import {Injectable} from '@angular/core';
import {OrganizationLocation} from '../models/organization-location';
import {Observable} from 'rxjs';
import {API} from '@configs/api.config';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LocationDefinition} from '../models/location-definition';

@Injectable({
  providedIn: 'root'
})
export class OrganizationLocationsService {

  constructor(private httpClient: HttpClient) {
  }

  saveOrganizationLocation(organizationLocation: OrganizationLocation): Observable<OrganizationLocation> {
    const url = API.locations.saveOrganizationLocation;
    return this.httpClient.post<OrganizationLocation>(url, organizationLocation);
  }

  updateOrganizationLocation(organizationLocation: OrganizationLocation): Observable<string> {
    const url = API.locations.saveOrganizationLocation;
    return this.httpClient.put<string>(url, organizationLocation);
  }

  getOrganizationLocationsByOrg(orgId: string): Observable<OrganizationLocation[]> {
    const filter = {
      where: {
        and: [{orgId}, {withinLocationId: ''}],
        locationType: {neq: 'EXTERNAL'}
      }
    };
    return this.httpClient.get<OrganizationLocation[]>(API.locations.getLocationsByFilter, {
      params: {
        filter: JSON.stringify(filter)
      }
    });
  }

  getOrganizationLocation(id: string): Observable<OrganizationLocation> {
    const url = API.locations.getOrganizationLocation.format([id]);
    return this.httpClient.get<OrganizationLocation>(url);
  }

  deleteOrganizationLocation(id: string): Observable<string> {
    const url = API.locations.deleteOrganizationLocation.format([id]);
    return this.httpClient.delete<string>(url);
  }

  getChildrenOrganizationLocationsOfLocation(id: string): Observable<OrganizationLocation[]> {
    const filter = {
      where: {
        withinLocationId: id,
        locationType: {neq: 'EXTERNAL'}
      },
    };
    const url = API.locations.getLocationsByFilter;
    return this.httpClient.get<OrganizationLocation[]>(url, {
      params: {
        filter: JSON.stringify(filter)
      }
    });
  }

  getLocationDefinitionByParentLocationType(type: string): Observable<LocationDefinition[]> {
    const url = API.locations.getLocationDefinitionByParentLocationType.format([type]);
    return this.httpClient.get<LocationDefinition[]>(url);
  }

  getLocationDefinitions(): Observable<LocationDefinition[]> {
    const url = API.locations.getLocationDefinitions;
    return this.httpClient.get<LocationDefinition[]>(url);
  }

  getLocationsByFilter(filter: string): Observable<OrganizationLocation[]> {
    const params = new HttpParams().set('filter', filter);
    return this.httpClient.get<OrganizationLocation[]>(API.locations.getLocationsByFilter, {params});
  }

  // TODO: REMOVE   one of the below APIs
  getLocationsByFilterForWizard(filter: string, orgTypes: string[], searchKey?: string): Observable<OrganizationLocation[]> {
    const url  = `${API.locations.getLocationsForWizard}`; 
    let params = new HttpParams().set('filter', filter);
    params = params.set('orgTypes', orgTypes.toString());
    params = params.set('searchKey', searchKey);
    params = params.set('pageSize', 25 + '');
    params = params.set('pageNumber', 0 + '');
    return this.httpClient.get<OrganizationLocation[]>(url, {params});
  }

  getLocationsByOrgTypesAndOrgIdsAndSearchKey(orgTypes: string[], orgIds: string[], purposes: string[], operationId?: string, searchKey?: string): Observable<OrganizationLocation[]> {
    const url = `${API.locations.getLocationsForWizard}?pageSize=25&pageNumber=0`;
    return this.httpClient.post<OrganizationLocation[]>(url, {orgTypes, orgIds, purposes, operationIds: operationId ? [operationId] : [], searchKey});
  }

  checkReferenceIdAvailability(referenceId: string, orgId: string): Observable<{availability: boolean}> {
    const url = API.locations.getLocationReferenceIdAvailability;
    let params = new HttpParams().set('orgId', orgId);
    params = params.set('searchKey', referenceId);
    return this.httpClient.get<{availability: boolean}>(url, {params});
  }
}
