<div class="information-table-details-container">
  <div class="header-container">
    <div class="header">
<!--      {{header}}-->
    </div>
  </div>
  <mat-table class="table" [dataSource]="dataSource">

    <ng-container *ngFor="let column of columnNames; let i = index" [matColumnDef]="column">
      <mat-header-cell *matHeaderCellDef> {{tableHeaders[i]}} </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          {{row[column]}}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row class="table-header" *matHeaderRowDef="columnNames; sticky: true"></mat-header-row>
    <mat-row class="table-row" *matRowDef="let row; columns: columnNames;">
    </mat-row>
  </mat-table>
</div>
