import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LineString } from '@shared/models/line-string';
import { Polygon } from '@shared/models/polygon';
import { MapComponent } from '../map/map.component';
import { GeoFenceSpeedLimit } from 'app/modules/organization/models/geo-fence-speed-limit.model';
import { GeoFence } from '@shared/models/geo-fence.model';
import { FormControl } from '@angular/forms';
import { GeometryType } from '@shared/models/enums/geometry-type';

@Component({
  selector: 'hmt-map-geo-fence-speed',
  templateUrl: './map-geo-fence-speed.component.html',
  styleUrls: ['./map-geo-fence-speed.component.scss']
})
export class MapGeoFenceSpeedComponent implements OnInit, AfterViewInit {

  existingGeoFences: GeoFence[];
  currentGeoFence: Polygon | LineString | GeoFence;
  formControl = new FormControl();
  
  @ViewChild('addressText') addressText;
  @ViewChild('mapComponent', {static: false}) mapComponent: MapComponent;
  @ViewChild('mapControls', {static: false, read: ElementRef}) mapControls: ElementRef;
  @ViewChild('boundsButton', {static: false, read: ElementRef}) boundsButton: ElementRef;
  @Input() isEditableMap: boolean = false;
  @Input('existingGeoFences') set setExistingGeoFences(geoFences: GeoFence[]) {
    this.existingGeoFences = geoFences;
    this.initMapConfig();
  }
  @Output() drawnGeoFence = new EventEmitter<Polygon | LineString | GeoFence>();

  geoFenceSpeedEditableDrawingManager: google.maps.drawing.DrawingManager = new google.maps.drawing.DrawingManager({
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP,
      drawingModes: [
        google.maps.drawing.OverlayType.MARKER,
        google.maps.drawing.OverlayType.POLYGON, 
        google.maps.drawing.OverlayType.POLYLINE, 
        google.maps.drawing.OverlayType.RECTANGLE,
        google.maps.drawing.OverlayType.CIRCLE,
      ]
    }
  });

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initMapConfig();
    this.initializeLocationSearch();
  }

  saveDrawnGeoFence(shape: Polygon | LineString | GeoFence) {
    this.currentGeoFence = shape;
    this.drawnGeoFence.emit(shape);
  }

  clearGeoFence() {
    this.mapComponent.removeSpeedLimitGeoFences();
    this.existingGeoFences = null;
    this.currentGeoFence = null;
    this.addressText.nativeElement.value = '';
    this.drawnGeoFence.emit(null);
    this.initMapConfig();
  }

  initializeLocationSearch(): void {
    if (!this.addressText) {
      return;
    }
    this.mapComponent?.getPlaces(this.addressText.nativeElement);
  }

  initMapConfig(): void {
    if (!this.mapComponent) {
      return;
    }

    this.mapComponent.initMap({
      ...this.mapComponent.defaultOptions,
      styles: [],
      minZoom: 1,
      zoom: 16,
      maxZoom: 20,
      fullscreenControl: false,
    });
    if (this.isEditableMap) {
      this.mapComponent.initializeDrawingManager(this.geoFenceSpeedEditableDrawingManager);
      this.mapComponent.overlayCompleteListener();
    }

    let geoFenceShapes: Array<google.maps.Polygon | google.maps.Polyline | google.maps.Circle | google.maps.Rectangle | google.maps.Marker> = []; 
    let pointerLatLng: number[];

    if(this.existingGeoFences?.length<=0) {
      this.mapComponent.removeSpeedLimitGeoFences();
      return;
    }

    if (this.existingGeoFences?.every(geoFence => geoFence?.coordinates?.length > 0 && geoFence?.type)) {
      let typeWhenEdit: GeometryType;

      switch (this.existingGeoFences[0].type) {
        case 'Polygon':
          typeWhenEdit = GeometryType.POLYGON;
          break;
        case 'LineString':
          typeWhenEdit = GeometryType.LINESTRING;
          break;
        case 'Circle':
          typeWhenEdit = GeometryType.CIRCLE;
          break;
        case 'Rectangle':
          typeWhenEdit = GeometryType.RECTANGLE;
          break;
        case 'Marker':
          typeWhenEdit = GeometryType.MAKER;
          break;
      }

      this.existingGeoFences.forEach(geoFence => {
        const geoFenceShape = this.createGeoFenceFromDrawingManagerAndGeoFenceType(geoFence, geoFence.type);
        switch (geoFence.type) {
          case 'Polygon':
            pointerLatLng = geoFence.coordinates.flat()[0];
            break;
          case 'LineString':
            pointerLatLng = geoFence.coordinates[0][0];
            break;
          case 'Circle':
            pointerLatLng = geoFence.coordinates[0][0];
            break;
          case 'Rectangle':
            // [north, south, east, west]
            pointerLatLng = [geoFence.coordinates[0][0][3], geoFence.coordinates[0][0][1]];
            break;
          case 'Marker':
            pointerLatLng = geoFence.coordinates[0][0];
            break;
        }
        geoFenceShapes.push(geoFenceShape);
      });
      this.mapComponent.drawSpeedLimitGeoFences(geoFenceShapes, pointerLatLng, this.isEditableMap, typeWhenEdit);
    }
  }

  createGeoFenceFromDrawingManagerAndGeoFenceType(fence: GeoFence, type: string):
   google.maps.Polygon | google.maps.Polyline | google.maps.Circle | google.maps.Rectangle | google.maps.Marker {
    switch (type) {
      case 'Polygon':
        const polygonConfig: google.maps.PolygonOptions = {
          editable: this.isEditableMap,
          paths: fence.coordinates.flat()
            .map(res => ({lng: res[0], lat: res[1]}))
        };
        // [[[lat, lng], [lat, lng], [lat, lng]], [[lat, lng], [lat, lng], [lat, lng]]
        return new google.maps.Polygon(polygonConfig);
      case 'LineString':
        const polylineConfig: google.maps.PolylineOptions = {
          editable: this.isEditableMap,
          path: fence.coordinates[0].map(res => ({lng: res[0], lat: res[1]}))
        };
        // [[[lat, lng], [lat, lng], [lat, lng]]]
        return new google.maps.Polyline(polylineConfig);
      case 'Circle':
        const circleConfig: google.maps.CircleOptions = {
          editable: this.isEditableMap,
          center: new google.maps.LatLng(fence.coordinates[0][0][1], fence.coordinates[0][0][0]),
          radius: fence.radius
        };
        // center -> [[[lat, lng]]] (in coordinates), radius -> number
        return new google.maps.Circle(circleConfig);
      case 'Rectangle':
        const rectangleConfig: google.maps.RectangleOptions = {
          editable: this.isEditableMap,
          bounds: {
            north: fence.coordinates[0][0][0],
            south: fence.coordinates[0][0][1],
            east: fence.coordinates[0][0][2],
            west: fence.coordinates[0][0][3]
          }
        };
        // [[[north, south, east, west]]]
        return new google.maps.Rectangle(rectangleConfig);
      case 'Marker':
        const markerConfig: google.maps.MarkerOptions = {
          position: {lng: fence.coordinates[0][0][0], lat: fence.coordinates[0][0][1]},
          draggable: false
        };
        // [[[lat, lng]]]
        return new google.maps.Marker(markerConfig);
    }
   }
}
