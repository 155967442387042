<div class="flex full-height col right-panel">
  <div style="flex-grow: 1" class="col wrapper vehicle-details">
    <div class="title alerts flex ai-c">
      <h3 class="uppercase bold">Vehicle Details</h3>
    </div>
    <hr class="full-width">

    <div class="job-details">
      <h3 class="job-type">{{(jobFile$ | async)?.title}}</h3>
      <h3 class="container">{{containerJob?.containerId}}</h3>
    </div>

    <div class="mt-20 services">
      <div class="flex row service" *ngFor="let service of services$ | async">
        <div class="line-tree" [ngClass]="{'completed': service.serviceStatus === 'COMPLETED' || service.serviceStatus === 'INPROGRESS', 'idle': service.serviceStatus === 'IDLE' || service.serviceStatus === 'READY'}">
          <div class="circle" [ngClass]="{'circle-completed': service.serviceStatus === 'COMPLETED' || service.serviceStatus === 'INPROGRESS', 'circle-pending': service.serviceStatus === 'IDLE' || service.serviceStatus === 'READY'}"></div>
        </div>
        <div class="flex col service-wrapper">
          <div class="flex row ai-c">
            <h3 class="title">{{service?.header}}</h3>
            <div class="status-wrapper">
                  <span class="status ml-auto" [ngClass]="{'on-time': service.segmentStatus === 'On Time', 'delayed': service.segmentStatus === 'Delayed'}">
                    {{service.segmentStatus}}
                  </span>
            </div>
          </div>
          <div class="flex row ai-c">
            <h4 class="type">{{service?.type}}</h4>
          </div>
          <div class="row">
            <ng-container *ngFor="let serviceTime of service.serviceTimes">
                  <span *ngIf="serviceTime.type === 'DATE'; else duration" class="duration">
                    <b>{{serviceTime.key}}</b> : {{serviceTime.value | date: 'dd LLL y | hhmm'}}{{serviceTime.value ? 'h' : ' -'}}
                  </span>
              <ng-template #duration>
                <span class="duration"><b>{{serviceTime.key}}</b> : {{serviceTime.value}}</span>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    //TODO: enable when needed
    <!-- <div class="flex col job-alerts mt-10">
      <h3 class="uppercase bold title">Alerts</h3>
      <div class="alerts-list">
        <div class="flex row alert">
          <div class="alert-icon">
            <img src="../../../../assets/icons/unplanned-stop-warning.svg">
          </div>
          <div class="flex col alert-content">
            <h3 class="uppercase bold alert-title">Nike Batch - 1234 - RM - Export</h3>
            <h4 class="alert-subtitle">Abans Container Yard to ACE Container Yard</h4>
            <span class="duration">2hrs ago</span>
          </div>
        </div>
        <div class="flex row alert">
          <div class="alert-icon">
            <img src="../../../../assets/icons/unplanned-stop-warning.svg">
          </div>
          <div class="flex col alert-content">
            <h3 class="uppercase bold alert-title">Nike Batch - 1234 - RM - Export</h3>
            <h4 class="alert-subtitle">Abans Container Yard to ACE Container Yard</h4>
            <span class="duration">2hrs ago</span>
          </div>
        </div>
        <div class="mt-10 see-all-jobs">
          <button mat-stroked-button class="see-all">View all(20+)</button>
        </div>
      </div>
    </div> -->

    <div class="flex col key-dates">
      <div class="flex col key-date" *ngFor="let keyDate of (jobFile$ | async)?.dateList">
        <p class="description">{{keyDate.title}}</p>
        <h4 class="date">{{keyDate.date | date:'dd MMMM yyyy h:mm a'}}</h4>
      </div>
    </div>

    <div class="flex col  mt-20 driver-details">
      <div class="title">
        <h4 class="bold uppercase">Driver Details</h4>
      </div>
      <ng-container *ngIf="(currentDriver$ | async) as driver">
        <p class="name">
          <b>Name : </b> {{driver.fullName}}
        </p>
        <p class="name">
          <b>Mobile : </b> {{driver.contactNo}}
        </p>
      </ng-container>
    </div>

    <div class="flex col  mt-20 shipment-details">
      <div class="title">
        <h4 class="bold uppercase">Shipment Details</h4>
      </div>
      <p class="name">
        <b>Container No : </b> {{containerJob?.containerNumber ? containerJob.containerNumber : '-'}}
      </p>
      <p class="name">
        <b>Type & Size :</b> {{containerJob?.containerTypeDescription ? containerJob.containerTypeDescription : '-'}}
      </p>
      <p class="name">
        <b>Gross Weight :</b> {{containerJob?.payloadGrossWeight ? containerJob.payloadGrossWeight + 'Kg' : '-'}}
      </p>
      <p class="name">
        <b>Net Weight :</b> {{containerJob?.payloadNetWeight ? containerJob.payloadNetWeight + 'Kg' : '-'}}
      </p>
      <ng-container *ngIf="jobFile$ | async as jobFile">
        <p class="name" *ngIf="jobFile?.propertyMap?.shippingLine">
          <b>Shipping Line :</b> {{jobFile.propertyMap.shippingLine}}
        </p>
        <p class="name" *ngIf="jobFile?.propertyMap?.vessel">
          <b>Vessel Number :</b> {{jobFile.propertyMap.vessel}}
        </p>
        <p class="name" *ngIf="jobFile?.propertyMap?.statusOfShipment">
          <b>Shipment Status :</b> {{jobFile.propertyMap.statusOfShipment}}
        </p>
      </ng-container>
    </div>

    <div class="flex col mt-20 permits">
      <div class="title">
        <h4 class="bold uppercase">Permits</h4>
      </div>
      <div class="flex row permit">
        <div class="permit-name">Prime Mover Port Permit:</div>
        <div class="flex ai-c status">
          <span class="material-icons valid">check_circle</span>
          <span> Valid</span>
        </div>
      </div>
      <div class="flex row permit">
        <div class="permit-name">Trailer Port Permit:</div>
        <div class="flex ai-c status">
          <span class="material-icons invalid">cancel</span>
          <span> Invalid</span>
        </div>
      </div>
      <div class="flex row permit">
        <div class="permit-name">Driver Permit:</div>
        <div class="flex ai-c status">
          <span class="material-icons valid">check_circle</span>
          <span> Valid</span>
        </div>
      </div>
    </div>
  </div>
  <!--    <div class="right-footer">-->
  <!--      <button class="go-to-jpm-btn" mat-button (click)="goToJpm()">GoTo JPM</button>-->
  <!--    </div>-->
</div>
