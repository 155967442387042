import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'hmt-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit {
  @Input()formGroup: FormGroup;
  @Output() addContact = new EventEmitter();
  @Output() removeContact = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit(): void {
  }

  deleteContact(val): void {
    this.removeContact.emit(val);
  }

  addNewContact() {
    this.addContact.emit();
  }
}
