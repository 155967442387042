<div class="vehicle-info">
  <div class="row resource-and-gps-details">
    <div class="col resource-details">
      <div class="row">
        <div class="col">
          <div class="row resource">
            <img *ngIf="liveVehicleInfo?.vehicleType === 'PRIME_MOVER'" src="./assets/icons/vehicle.svg" class="vehicle"
                 alt="vehicle"/>
            <img *ngIf="liveVehicleInfo?.vehicleType === 'TRUCK'" src="./assets/icons/truck.svg" class="truck"
                 alt="truck"/>
          </div>
          <div class="row resource" *ngIf="liveVehicleInfo?.vehicleType === 'PRIME_MOVER'">
            <img src="./assets/icons/trailer.svg" class="trailer" alt="trailer"/>
          </div>
        </div>
        <div class="col">
          <div class="row resource">
            <div class="vehicle">{{liveVehicleInfo?.vehicleNo ? liveVehicleInfo?.vehicleNo : '--'}}</div>
          </div>
          <div class="row resource" *ngIf="liveVehicleInfo?.vehicleType === 'PRIME_MOVER'">
            <div class="trailer">{{liveVehicleInfo?.trailer ? liveVehicleInfo.trailer : '--'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col resource-details">
      <div class="row">
        <div class="col">
          <div class="row resource"><img src="./assets/icons/driver.svg" class="driver" alt="driver"/></div>
          <div class="row resource"><img src="./assets/icons/user-assitant.svg" class="assistant" alt="assistant"/>
          </div>
        </div>
        <div class="col">
          <div class="row resource driver">{{liveVehicleInfo?.driver ? truncate(liveVehicleInfo.driver) : '--'}}</div>
          <div
            class="row resource assistant">{{liveVehicleInfo?.assistant ? truncate(liveVehicleInfo.assistant) : '--'}}</div>
        </div>
      </div>
    </div>
    <div class="col gps-details">
      <div class="row">
        <div class="col">
          <div class="row gps-status">{{liveVehicleInfo?.gpsConnected ? 'GPS CONNECTED' : 'GPS DISCONNECTED'}}</div>
          <div class="row gps-accuracy">Accurate to {{liveVehicleInfo?.gpsAccuracy}}</div>
          <div class="row gps-last-updated-time">Updated {{liveVehicleInfo?.lastUpdatedAt}} ago</div>
        </div>
      </div>
    </div>
    <div class="col gps">
      <mat-icon aria-hidden="false" class="gps-icon" [ngClass]="{'disconnected': !liveVehicleInfo?.gpsConnected}"
                aria-label="gps-icon">gps_fixed
      </mat-icon>
    </div>
  </div>
  <div class="row speed-details">
    <div class="col">
      <div class="row">
        <div class="speed current-speed">{{speeds?.currentSpeed ? number(speeds?.currentSpeed)?.toFixed(2) : '0'}}kmph
        </div>
      </div>
      <div class="row description">
        Current Speed
      </div>
    </div>
    <div class="col">
      <div class="row">
        <div class="speed max-speed">{{number(speeds?.maximumSpeed)?.toFixed(2)}}kmph</div>
      </div>
      <div class="row description">
        Max Speed
      </div>
    </div>
    <div class="col">
      <div class="row">
        <div
          class="speed average-speed">{{isCurrentLegAverageSpeed ? speeds?.averageSpeed ? number(speeds?.averageSpeed).toFixed(2) : '0'
          : speeds?.fullJpmAverageSpeed ? number(speeds.fullJpmAverageSpeed).toFixed(2) : '0'}} kmph
        </div>
      </div>
      <div class="row description">
        Average Speed
      </div>
      <div class="row">
        <mat-slide-toggle class="toggle" [checked]="isCurrentLegAverageSpeed" (change)="changeAverageSpeedType()">
          Current Leg
        </mat-slide-toggle>
      </div>
    </div>
    <div class="col">
      <div class="row">
        <div class="stops">{{liveVehicleInfo?.hardStops ? liveVehicleInfo?.hardStops : '0'}}</div>
      </div>
      <div class="row description">
        Hard Stops
      </div>
    </div>
  </div>
</div>
