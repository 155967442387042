<div class="resource">
  <div class="resource-profile">
    <div class="profile">
      <ng-container *ngIf="resourceType === 'VEHICLE'">
        <img class="image" src="./assets/icons/vehicle.svg" id="prime-mover-icon" alt="vehicle">
      </ng-container>
      <ng-container *ngIf="resourceType === 'TRAILER'">
        <img class="image" src="./assets/icons/trailer.svg" id="trailer-icon" alt="vehicle">
      </ng-container>
      <ng-container *ngIf="resourceType === 'HUMAN'">
        <div class="profile-font">{{getProfileDp(selectedResource?.name)}}</div>
      </ng-container>
    </div>
    <div class="profile-name">{{selectedResource.name}}</div>
  </div>
  <div class="resource-distance">
    <div class="col">
      <div class="header-distance" *ngIf="resourceType === 'HUMAN'">Total Distance</div>
      <div class="header-distance" *ngIf="resourceType !== 'HUMAN'">Odometer Reading</div>
      <div class="value-distance">
        {{selectedResource?.odometerDistance | number : '1.2-2'}} Km
      </div>
      <div class="header-distance" *ngIf="resourceType === 'HUMAN'">Odometer</div>
      <div class="header-distance" *ngIf="resourceType !== 'HUMAN'">Start</div>
    </div>
    <div class="col">
      <div class="value-distance margin-top">{{selectedResource?.odometerContributionPercentage}}%</div>
      <div class="header-distance">Contribution</div>
    </div>
    <div class="col">
      <div class="value-distance margin-top">
        {{selectedResource?.gpsDistance | number : '1.2-2'}} Km
      </div>
      <div class="header-distance" *ngIf="resourceType === 'HUMAN'">GPS</div>
      <div class="header-distance" *ngIf="resourceType !== 'HUMAN'">End</div>
    </div>
    <div class="col">
      <div class="value-distance margin-top">{{selectedResource?.distanceContributionPercentage}}%</div>
      <div class="header-distance">Contribution</div>
    </div>
    <div class="col">
      <div class="header-distance">Duration</div>
      <div class="value-distance">{{selectedResource?.duration | minutesToHoursMinutes}}</div>
      <div class="header-distance">Total</div>
    </div>
    <div class="col">
      <div class="value-distance margin-top">{{selectedResource?.durationContributionPercentage}}%</div>
      <div class="header-distance">Contribution</div>
    </div>
  </div>
</div>
