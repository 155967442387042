import {Component, EventEmitter, Inject, OnInit, Output, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InvoiceCurrency} from '../../models/invoice-currency.model';
import {Observable} from 'rxjs';
import {RateCard} from '@shared/models/rate-card.model';
import {Currency} from '../../../../shared/models/currency.model';
import {Invoice} from '@shared/models/invoice/invoice.model';
import {tap, map, startWith} from 'rxjs/operators';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'hmt-change-currency',
  templateUrl: './change-currency.component.html',
  styleUrls: ['./change-currency.component.scss']
})
export class ChangeCurrencyComponent implements OnInit {
  @Output() changeCurrency: EventEmitter<InvoiceCurrency> = new EventEmitter<InvoiceCurrency>();
  form: FormGroup;
  currencies: string[] = [];
  currenciesAll: string[] = [];
  filteredCurrencies: Observable<string[]>;

  constructor(
    public dialogRef: MatDialogRef<ChangeCurrencyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      invoice: Invoice,
      currencies: Observable<Currency[]>,
      currenciesAll: Observable<string[]>;
    },
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      currency: new FormControl(this.data.invoice.currency.currentCurrency, Validators.required),
      rate: new FormControl({value: this.data.invoice.currency.currencyRate, disabled: false}, [Validators.required, Validators.pattern(/^\d+(\.\d{1,10})?$/)])
    });
    this.currencies = [this.data.invoice.defaultOrgCurrencyCode, this.data.invoice.currencyCode];
    this.data.currenciesAll.pipe(
      tap((res) => {
        this.currencies = res || [];
        this.setupFilteredCurrencies();
      })
    ).subscribe();
  }

  setupFilteredCurrencies(): void {
    this.filteredCurrencies = this.form.get('currency').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.currencies.filter(currency => currency.toLowerCase().includes(filterValue));
  }

  close() {
    this.dialogRef.close();
  }

  onChangeCurrency(value: string) {
    if (value === this.data.invoice.defaultOrgCurrencyCode){
      this.form.controls.rate.setValue(1 / this.data.invoice.exchangeRate);
    }
    else {
      this.form.controls.rate.setValue(1);
    }
  }

  onApplyCurrency() {
    if (this.form.valid) {
      const currency = {
        defaultCurrency: this.data.invoice.currency.defaultCurrency,
        currentCurrency: this.form.get('currency').value,
        currencyRate: this.form.get('rate').value
      } as InvoiceCurrency ;
      this.changeCurrency.emit(currency);
      this.close();
    }
  }

  validateRate(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    if (value && !/^\d+(\.\d{0,10})?$/.test(value)) {
      input.value = value.slice(0, -1);
    }
  }
}
