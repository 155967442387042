<div class="invoice-progress-line col full-width">
  <hmt-progress-line [steps]="steps" [currentStep]="(invoice$ | async)?.progressStep"></hmt-progress-line>
  <ng-container *ngIf="(invoice$ | async) as invoice">
    <div class="middle-row row">
      <div class="left-bar"></div>
      <div class="invoice-progress-line-content full-width col">
        <div class="information-wrapper full-width col">
          <div class="company-details row">
            <div class="address col" *ngIf="(orgDetails$ | async)[invoice.serviceProvider] as serviceProvider">
              <img class="logo" src="{{serviceProvider.logo }}">
              <p class="font-large-bold">{{serviceProvider.orgName}}</p>
              <p class="font-medium">{{serviceProvider.address.addressLine}}</p>
              <p class="font-medium">{{serviceProvider.address.city}}, {{serviceProvider.address.country}}</p>
            </div>
            <div class="contract-title col" *ngIf="(orgDetails$ | async)[invoice.createdTo]as customer">
              <p class="right-align font-medium">Invoice Ref No : {{invoice.invoiceRefNo}}</p>
              <p class="font-large-bold">Invoice to</p>
              <p class="font-large-bold">{{customer.orgName}}</p>
              <p class="font-medium">{{customer.address.addressLine}}</p>
              <p class="font-medium">{{customer.address.city}}, {{customer.address.country}}</p>
            </div>
            <div class="bar-code">
              <img class="logo" src="assets/images/qr-code_01.png">
            </div>
          </div>

          <div class="job-details">
            <div class="jd-box">
              <p class="font-small-bold" *ngIf="invoice.jobTitles.length === 1">Job Title</p>
              <p class="font-small-bold" *ngIf="invoice.jobTitles.length > 1">Job Titles</p>
              <p class="font-medium" *ngFor="let jobTitle of invoice.jobTitles">{{jobTitle}}</p>
            </div>
            <div class="jd-box">
              <p class="font-small-bold" *ngIf="invoice.internalRefNos.length === 1">Internal Ref Number </p>
              <p class="font-small-bold" *ngIf="invoice.internalRefNos.length > 1">Internal Ref Numbers </p>
              <p class="font-medium" *ngFor="let refNo of invoice.internalRefNos">{{refNo}}</p>
            </div>
            <div class="jd-box">
              <p class="font-small-bold">Contract Reference Number</p>
              <p class="font-medium">{{invoice.contractRefNos[0]}}</p>
            </div>
            <div class="jd-box">
              <p class="font-small-bold">Created Date & Time</p>
              <p class="font-medium">{{invoice.createdAt | date}}</p>
            </div>
            <div class="jd-box">
              <p class="font-small-bold">Prepared By</p>
              <p class="font-medium">{{user.firstName}} {{user.lastName}}</p>
            </div>
            <div class="jd-box">
              <p class="font-small-bold">Customer Reference Number</p>
              <p class="font-medium">{{invoice.customerRefNo}}</p>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="contract-partners row">
            <div class="partner col" *ngIf="(orgDetails$ | async)[invoice.createdTo] as customer">
              <p class="title">Customer</p>
              <img class="logo" src="{{customer.logo}}">
              <p class="text">{{customer.orgName}}</p>
              <p class="text">Company Reg. No : {{customer.companyRegNum}}</p>
              <p class="text">{{customer.address.addressLine}}</p>
              <p class="font-medium">{{customer.address.city}}, {{customer.address.country}}</p>
              <p class="font-medium" *ngIf="suspendedVat">VAT No: {{customer.suspendedVat}}</p>
            </div>
            <div class="partner col" *ngIf="(orgDetails$ | async)[invoice.serviceProvider] as serviceProvider">
              <p class="title">Service Provider</p>
              <img class="logo" src="{{serviceProvider.logo}}">
              <p class="text">{{serviceProvider.orgName}}</p>
              <p class="text">Company Reg. No : {{serviceProvider.companyRegNum}}</p>
              <p class="text">{{serviceProvider.address.addressLine}}</p>
              <p class="text">{{serviceProvider.address.city}}, {{serviceProvider.address.country}}</p>
              <p class="font-medium" *ngIf="suspendedVat">VAT No: {{serviceProvider.suspendedVat}}</p>
            </div>
          </div>
          <ng-container *ngIf="toggled">
            <ng-container *ngFor="let group of groups">
              <p class="font-small-bold">{{group}}</p>
              <div class="loads col" *ngFor="let load of groupByJobTitle[group]">
                <div class="load col">
                  <div class="upper-row row">
                    <div class="load-management-card row">
                      <p
                        class="load-number">{{(load.loadNumber == null || load.loadNumber === "") ? load.loadId : load.loadNumber}}</p>
                      <p *ngIf="load.containerType !== ('FTL' || 'LTL')" class="load-type">{{load.containerType}}</p>
                    </div>
                    <p class="load-total">Total: {{applyCurrency(calculateLoadTotal(load))}}</p>
                    <mat-icon (click)="removeLoad(load._id, invoice._id)" *ngIf="checkPermission(invoice)" matTooltip="removeLoad">close
                    </mat-icon>
                  </div>
                  <div class="middle-row row">
                    <p class="description">Transportation Fee</p>
                    <p class="amount">{{applyCurrency(load.loadCost)}}</p>
                    <mat-icon></mat-icon>
                  </div>
                  <div class="middle-row row" *ngFor="let cost of load?.costs">
                    <p class="description">{{cost.description}}</p>
                    <p class="amount">{{applyCurrency(cost.value)}}</p>
                    <mat-icon class="close-icon" (click)="removeCost(cost.id, load.loadId)"
                              *ngIf="checkPermission(invoice)">close
                    </mat-icon>
                    <mat-icon class="edit-icon" (click)="addCost(load.loadId, cost)" *ngIf="checkPermission(invoice)">
                      edit
                    </mat-icon>
                  </div>
                  <div *ngIf="load?.addedAdditionalCost?.length > 0">
                    <div class="middle-row row" *ngFor="let cost of load.addedAdditionalCost">
                      <p class="description">{{cost.description}} ( {{ load.loadNumber || load.loadId }} )</p>
                      <p class="amount">{{applyCurrency(cost.value)}}</p>
                      <mat-icon class="close-icon" (click)="removeLoadAdditionalCost(cost.id, load.loadId)" *ngIf="checkPermission(invoice)">close</mat-icon>
                      <mat-icon class="edit-icon" (click)="addLoadAdditionalCost(load.loadId, cost)" *ngIf="checkPermission(invoice)">edit</mat-icon>
                    </div>
                  </div>

                  <div *ngFor="let commission of load?.commissions">
                    <div class="middle-row row" *ngIf="calculateCommisions(commission, load, commission.feeType[0]) > 0">
                      <p class="description" style="flex: 2;"><b>Commission and Fees ({{commission.description}})</b></p>
                      <p class="amount" style="width: 80px; text-align: right;">{{commission.value}}%</p>
                      <p class="amount" style="width: 120px; text-align: right;">
                        <mat-icon class="edit-icon" (click)="editCommission(commission, load.loadId)"  *ngIf="checkPermission(invoice)">
                          edit
                        </mat-icon>
                        <mat-icon class="close-icon" (click)="removeAllCommission(commission, load.loadId)" *ngIf="checkPermission(invoice)"  style="width: 24px;">
                          close
                        </mat-icon>
                      </p>
                    </div>
                    <div class="middle-row" *ngFor="let feetype of commission.feeType">
                     <div class="row inner-data" *ngIf="calculateCommisions(commission, load, feetype) > 0">
                      <p class="description" style="flex: 1;">{{feetype}}</p>
                      <p class="amount" style="width: 120px; text-align: right;">{{applyCurrency(calculateCommisions(commission, load, feetype))}}</p>
                      <mat-icon class="close-icon" (click)="removeCommission(commission, feetype, load.loadId)" *ngIf="checkPermission(invoice)" style="width: 24px;">
                        close
                      </mat-icon>
                     </div>
                      
                    </div>
                  </div>
                  
                  <div class="bottom-row row">
                    <button mat-stroked-button color="primary" (click)="addCost(load.loadId, null)"
                            [disabled]="!checkPermission(invoice)">
                      <mat-icon>add</mat-icon>
                      ADD FEE
                    </button>
                  </div>
                <br/>
                  <div class="upper-row row">
                    <div class="load-management-card row">
                      <p class="load-type">Sub Total</p>
                    </div>
                    <p class="load-total">{{applyCurrency(calculateLoadSubTotal(load))}}</p>
                    <mat-icon (click)="removeLoadSubTotal(load._id)" *ngIf="checkPermission(invoice)" matTooltip="removeLoad">close
                    </mat-icon>
                  </div>
                  <mat-divider></mat-divider>
                  <div class="middle-row" *ngIf="load?.addedOtherCost?.length > 0">
                    <div class="row">
                    <p class="description">Additional fee</p>
                    <p class="amount">{{applyCurrency(calculateAddedItemTotal(load))}}</p>
                    <mat-icon class="close-icon" (click)="removeAddedItemOfLoad(load.loadId)" *ngIf="checkPermission(invoice)">
                      close
                    </mat-icon>
                    </div>
                    <div class="row inner-data" *ngFor="let cost of load.addedOtherCost">
                      <p class="description flex-value">{{cost.description}}</p>
                      <p class="description" *ngIf="cost.paymentType === paymentType.PERCENTAGE">{{cost.value}} %</p>
                      <div class="li-amount row">
                        <p *ngIf="cost.paymentType === paymentType.AMOUNT || !cost.paymentType">{{applyCurrency(cost.value)}}</p>
                        <p *ngIf="cost.paymentType === paymentType.PERCENTAGE">{{applyCurrency(calculateLoadPercentage(load, cost.value))}}</p>
                        <mat-icon class="close-icon" (click)="removeLoadOtherCost(cost.id, load.loadId)" *ngIf="checkPermission(invoice)">
                          close
                        </mat-icon>
                        <mat-icon class="edit-icon" (click)="openLoadAddItem(load.loadId, cost)" *ngIf="checkPermission(invoice)">
                          edit
                        </mat-icon>
                      </div>
                    </div>
                  </div>

                  <mat-divider></mat-divider>
                  <div class="middle-row" *ngIf="load?.addedDiscount != null">
                    <div class="row">
                      <p class="description">Discount</p>
                      <p class="amount">{{applyCurrency(calculateAddedDiscountTotal(load))}}</p>
                      <mat-icon class="close-icon" (click)="removeAddedDiscountOfLoad(load.loadId)" *ngIf="checkPermission(invoice)">close</mat-icon>
                    </div>
                    <div class="row inner-data">
                      <p class="description flex-value" >{{load.addedDiscount.description}}</p>
                      <p class="description" *ngIf="load.addedDiscount.paymentType === paymentType.PERCENTAGE ">{{load.addedDiscount.value}} %</p>

                      <div class="li-amount row">
                        <p
                          *ngIf="load.addedDiscount.paymentType === paymentType.AMOUNT ||!load.addedDiscount.paymentType ">{{applyCurrency(load.addedDiscount.value)}}</p>
                        <p *ngIf="load.addedDiscount.paymentType === paymentType.PERCENTAGE">{{applyCurrency(calculateLoadPercentage(load , load.addedDiscount.value))}}</p>
                          <mat-icon class="close-icon" (click)="removeLoadDiscount(load.loadId)"
                          *ngIf="checkPermission(invoice)">close
                          </mat-icon>
                          <mat-icon class="edit-icon" (click)="openLoadAddDiscount(load.loadId, load.addedDiscount)" *ngIf="checkPermission(invoice)">
                            edit
                          </mat-icon>

                      </div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <br/>
                  <div class="bottom-row row">
                    <button mat-stroked-button color="primary" (click)="openLoadAddItem(load.loadId, null)" [disabled]="!checkPermission(invoice)">

                      % ADD ITEM
                    </button>
                    &nbsp;
                    <button mat-stroked-button color="primary" (click)="openLoadAddDiscount(load.loadId, null)"
                    [disabled]="!checkPermission(invoice)">
                      % ADD DISCOUNT
                    </button>
                  </div>
                  <div class="locations">
                    <ng-container *ngFor="let location of load.locations; let i = index ">
                      <div class="font-medium-bold">{{location}}</div> <div class="arrow" *ngIf="i < load.locations.length - 1"><mat-icon>keyboard_double_arrow_right</mat-icon> </div>
                    </ng-container>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!toggled">
            <mat-divider></mat-divider>
            <div class="loads col">
              <div class="load col">
                <div class="middle-row row">
                  <p class="description">Transportation Fee</p>
                  <p class="amount">{{applyCurrency(calculateTotalOfLoadsCost())}}</p>
                  <mat-icon></mat-icon>
                </div>
                <ng-container *ngFor="let load of invoice.loads">
                  <div class="middle-row row" *ngFor="let cost of load?.costs">
                    <p class="description">{{cost.description}}
                      ( {{ load.loadNumber || load.loadId }} )</p>
                    <p class="amount">{{applyCurrency(cost.value)}}</p>
                    <mat-icon class="close-icon" (click)="removeCost(cost.id, load.loadId)"
                              *ngIf="checkPermission(invoice)">close
                    </mat-icon>
                    <mat-icon class="edit-icon" (click)="addCost(load.loadId, cost)" *ngIf="checkPermission(invoice)">
                      edit
                    </mat-icon>
                  </div>
                  <div *ngIf="load?.addedAdditionalCost?.length > 0">
                    <div class="middle-row row" *ngFor="let cost of load.addedAdditionalCost">
                      <p class="description">{{cost.description}} ( {{ load.loadNumber || load.loadId }} )</p>
                      <p class="amount">{{applyCurrency(cost.value)}}</p>
                      <mat-icon class="close-icon" (click)="removeLoadAdditionalCost(cost.id, load.loadId)" *ngIf="checkPermission(invoice)">close</mat-icon>
                      <mat-icon class="edit-icon" (click)="addLoadAdditionalCost(load.loadId, cost)" *ngIf="checkPermission(invoice)">edit</mat-icon>
                    </div>
                  </div>

                  <div *ngFor="let commission of load?.commissions">
                    <div class="middle-row row" *ngIf="calculateTotalCommisions(commission, load) > 0">
                      <p class="description" style="flex: 2;"><b>Commission and Fees ({{commission.description}})</b></p>
                      <p class="amount" style="width: 80px; text-align: right;">{{commission.value}}%</p>
                      <p class="amount" style="width: 120px; text-align: right;">
                        {{applyCurrency(calculateTotalCommisions(commission, load))}}
                        <mat-icon class="edit-icon" (click)="editCommission(commission, load.loadId)"  *ngIf="checkPermission(invoice)">
                          edit
                        </mat-icon>
                        <mat-icon class="close-icon" (click)="removeAllCommission(commission, load.loadId)" *ngIf="checkPermission(invoice)"  style="width: 24px;">
                          close
                        </mat-icon>
                      </p>
                    </div>
                  </div>


                  <div class="middle-row" *ngIf="load.addedOtherCost?.length > 0">
                    <p class="description">Additional fee
                      ( {{ load.loadNumber || load.loadId }} )</p>
                    <div class="row inner-data" *ngFor="let cost of load.addedOtherCost">
                      <p class="description">{{cost.description}}</p>
                      <p class="description" *ngIf="cost.paymentType === paymentType.PERCENTAGE ">{{cost.value}} %</p>
                      <div class="li-amount row">
                        <p
                          *ngIf="cost.paymentType === paymentType.AMOUNT ||!cost.paymentType ">{{applyCurrency(cost.value)}}</p>
                        <p *ngIf="cost.paymentType === paymentType.PERCENTAGE">{{applyCurrency(calculateLoadPercentage(load , cost.value))}}</p>
                        <mat-icon class="close-icon" (click)="removeLoadOtherCost(cost.id, load.loadId)"
                        *ngIf="checkPermission(invoice)">close
                        </mat-icon>
                        <mat-icon class="edit-icon" (click)="openLoadAddItem(load.loadId, cost)" *ngIf="checkPermission(invoice)">
                          edit
                        </mat-icon>
                      </div>
                    </div>
                  </div>
                  <div class="middle-row" *ngIf="load.addedDiscount != null">
                    <p class="description">Discount
                      ( {{ load.loadNumber || load.loadId }} )</p>
                    <div class="row inner-data">
                      <p class="description">{{load.addedDiscount.description}}</p>
                      <p class="description" *ngIf="load.addedDiscount.paymentType === paymentType.PERCENTAGE ">{{load.addedDiscount.value}} %</p>

                      <div class="li-amount row">
                        <p
                          *ngIf="load.addedDiscount.paymentType === paymentType.AMOUNT ||!load.addedDiscount.paymentType ">{{applyCurrency(load.addedDiscount.value)}}</p>
                        <p *ngIf="load.addedDiscount.paymentType === paymentType.PERCENTAGE">{{applyCurrency(calculateLoadPercentage(load, load.addedDiscount.value))}}</p>
                        <mat-icon class="close-icon" (click)="removeLoadDiscount(load.loadId)"
                        *ngIf="checkPermission(invoice)">close
                        </mat-icon>
                        <mat-icon class="edit-icon" (click)="openLoadAddDiscount(load.loadId, load.addedDiscount)" *ngIf="checkPermission(invoice)">
                          edit
                        </mat-icon>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="middle-row row" *ngFor="let cost of invoice.loadsCosts">
                  <p class="description">{{cost.description}}</p>
                  <p class="amount">{{applyCurrency(cost.value)}}</p>
                  <mat-icon class="close-icon" (click)="removeLoadsCost(cost.id)" *ngIf="checkPermission(invoice)">
                    close
                  </mat-icon>
                  <mat-icon class="edit-icon" (click)="addLoadsCost(cost)" *ngIf="checkPermission(invoice)">edit
                  </mat-icon>
                </div>
                <div class="middle-row row">
                  <p class="description text-large">Total</p>
                  <p class="amount text-large">{{applyCurrency(calculateTotalOfAllLoads())}}</p>
                  <mat-icon class="close-icon" (click)="removeAllLoads(invoice._id)" *ngIf="checkPermission(invoice)">
                    close
                  </mat-icon>
                </div>
              </div>
              <mat-divider></mat-divider>
            </div>
          </ng-container>

          <div class="additional-cost col">
            <div class="ac-list row" *ngFor="let cost of invoice.additionalCosts">
              <p class="description">{{cost.description}}</p>
              <p class="amount">{{applyCurrency(cost.value)}}</p>
              <mat-icon (click)="removeAdditionalCost(cost.id)" *ngIf="checkPermission(invoice)">close</mat-icon>
            </div>
            <div class="ac-button row">
              <button mat-flat-button color="primary" (click)="addAdditionalCost()"
                      [disabled]="!checkPermission(invoice)">
                <mat-icon>add</mat-icon>
                ADD FEE
              </button>
            </div>
          </div>
          <div class="total-calculation col">
            <div class="sub-total row">
              <p class="st-title">Service Fee</p>
              <p class="st-amount">{{applyCurrency(calculateServiceFee())}}</p>
            </div>
            <mat-divider></mat-divider>
            <div class="line-items row" *ngFor="let lineItem of invoice.otherCosts">
              <p class="li-title">{{lineItem.description}}</p>
              <span class="li-percentage">
                <p *ngIf="lineItem.paymentType === paymentType.PERCENTAGE ">{{lineItem.value}} %</p>
              </span>
              <div class="li-amount row">
                <p
                  *ngIf="lineItem.paymentType === paymentType.AMOUNT ||!lineItem.paymentType ">{{applyCurrency(lineItem.value)}}</p>
                <p *ngIf="lineItem.paymentType === paymentType.PERCENTAGE">
                  {{applyCurrency(calculateLineItemValue(lineItem.value))}}</p>
                  <mat-icon class="close" (click)="removeLineItem(lineItem)"
                  *ngIf="checkPermission(invoice)">close
                  </mat-icon>
              </div>
            </div>
            <div class="line-items row" *ngIf="invoice.discount !== null">
              <p class="li-title">{{invoice.discount.description}}</p>
              <span class="li-percentage">
              <p *ngIf="invoice.discount.paymentType === paymentType.PERCENTAGE">{{invoice.discount.value}} %</p>
            </span>
              <div class="li-amount row">
                <p
                  *ngIf="invoice.discount.paymentType === paymentType.AMOUNT">{{applyCurrency(invoice.discount.value)}}</p>
                <p *ngIf="invoice.discount.paymentType === paymentType.PERCENTAGE">
                  {{applyCurrency(calculateDiscount())}}</p>
                <mat-icon class="edit" (click)="editDiscount(invoice.discount)" *ngIf="checkPermission(invoice)">edit
                </mat-icon>
                <mat-icon class="close" (click)="removeDiscount()" *ngIf="checkPermission(invoice)">close</mat-icon>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="btn-list">
              <p>Others</p>
              <button mat-stroked-button color="primary" (click)="openAddItem()" [disabled]="!checkPermission(invoice)">
                Add Fee
              </button>
              <button mat-stroked-button color="primary" (click)="openAddDiscount()"
                      [disabled]="!checkPermission(invoice)">
                Add Discount
              </button>
            </div>
            <div class="sub-total row">
              <p class="st-title">Sub Total</p>
              <p class="st-amount">{{applyCurrency(calculateSubTotal())}}</p>
            </div>
            <p>Tax</p>
            <mat-checkbox class="s-vat-c-box"
                          *ngIf="(orgDetails$ | async)[invoice.createdTo]?.suspendedVat != null && (orgDetails$ | async)[invoice.serviceProvider]?.suspendedVat != null"
                          (change)="onChangeSVat($event)"
                          [checked]="invoice.suspendedVat">Mark SVAT
            </mat-checkbox>
            <ng-container *ngFor="let tax of taxes; let i = index">
              <div class="line-items row" *ngIf="tax.description !== 'Vat'">
                <p class="li-title">{{tax.description}}</p>
                <span class="li-percentage">
                <p *ngIf="tax.paymentType === paymentType.PERCENTAGE">{{tax.value}} %</p>
              </span>
                <div class="li-amount row">
                  <p>{{applyCurrency(taxAmounts[i])}}</p>
                </div>
                <div class="li-amount row">
                  <p>{{applyCurrency(totalsAfterTax[i])}}</p>
                  <mat-icon class="edit" (click)="openEditTax(tax)" *ngIf="checkPermission(invoice)">edit</mat-icon>
                  <mat-icon class="close" (click)="removeTax(tax.id)" *ngIf="checkPermission(invoice)">close</mat-icon>
                </div>
              </div>
              <div class="line-items row" *ngIf="tax.description === 'Vat' && !suspendedVat">
                <p class="li-title">{{tax.description}}</p>
                <span class="li-percentage">
                <p *ngIf="tax.paymentType === paymentType.PERCENTAGE">{{tax.value}} %</p>
              </span>
                <div class="li-amount row">
                  <p>{{applyCurrency(taxAmounts[i])}}</p>
                </div>
                <div class="li-amount row">
                  <p>{{applyCurrency(totalsAfterTax[i])}}</p>
                  <mat-icon class="edit" (click)="openEditTax(tax)" *ngIf="checkPermission(invoice)">edit</mat-icon>
                  <mat-icon class="close" (click)="removeTax(tax.id)" *ngIf="checkPermission(invoice)">close</mat-icon>
                </div>
              </div>
            </ng-container>
            <div class="btn-list">
              <button mat-stroked-button color="primary" (click)="openAddTax()" [disabled]="!checkPermission(invoice)">
                <mat-icon>percent</mat-icon>
                Add Tax
              </button>
            </div>
            <div class="total row">
              <p class="t-title">Total</p>
              <p class="t-amount">{{applyCurrency(getTotal())}}</p>
            </div>
            <p class="text-total">{{applyCurrencyWithoutCurrencyCode(getTotal()) | numberToText : invoice.currency.currentCurrency}}</p>
          </div>
          <div *ngIf="suspendedVat" class="s-vat col">
            <p class="s-vat-amount">SVAT: {{applyCurrency(calculateSVat())}}</p>
          </div>
          <div class="currency row" *ngIf="invoice.defaultOrgCurrencyCode !== invoice.currencyCode">
            <p [ngClass]="invoice.exchangeRate == 0 ? 'font-small-bold-red': 'font-small-bold'"> Exchange
              Rate: 1 {{invoice.currencyCode}} =</p>
            <form [formGroup]="rateForm">
              <span class="spam-input"><input type="number" formControlName="rate"></span>
            </form>
            <p [ngClass]="invoice.exchangeRate == 0 ? 'font-small-bold-red': 'font-small-bold'">{{invoice.defaultOrgCurrencyCode}} </p>
          </div>
          <ng-container *ngIf="!toggled">
            <div class="containers-list col" *ngFor="let group of groups">
              <div class="cl-content col">
                <p class="font-large-bold">{{group}}</p>
                <div class="col">
                  <ng-container *ngFor="let containerType of getContainerTypes(groupByJobTitle[group])">
                    <p *ngIf="containerType !== ('FTL' || 'LTL')" class="font-size-16-purple">Container
                      Size {{containerType}}
                      (Qty: {{getGroupByContainerType(groupByJobTitle[group])[containerType].length}}) : </p>
                    <p *ngIf="containerType === ('FTL' || 'LTL')" class="font-size-16-purple">Load
                      (Qty: {{getGroupByContainerType(groupByJobTitle[group])[containerType].length}}) : </p>

                    <div>
                      <ng-container *ngFor="let load of getGroupByContainerType(groupByJobTitle[group])[containerType] let i = index">
                        <p *ngIf="i > 0" class="load-number">,</p><p class="font-medium">{{load.loadNumber ? (load.loadNumber) : (load.loadId)}}</p>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="payment-terms-str col">
          <p class="font-x-large-bold">Payment Terms</p>
          <p class="font-medium">{{invoice.invoicePaymentTermStr}}</p>
        </div>
        <div class="terms-and-condition">
          <p class="font-x-large-bold">Terms and Conditions</p><br>
          <div class="tac-e-panel">
            <div class="load" *ngFor="let load of invoice.loads">
              <mat-divider></mat-divider><br>
              <div class="content col">
                <div class="container-ids" >
                  <p class="title">Load ID:</p>
                  <p>{{load.loadId}}</p>
                </div>
                <ng-container *ngFor="let category of load.vehicleCategories">
                  <ng-container *ngIf="(rateCardsMap$ | async)[load.rateCard] as rateCard">
                    <p class="font-large-bold">Rate: {{invoiceExtraService.getVehicleCategoryName(rateCard, category)}}</p>
                    <div class="rate-sheet" *ngFor="let rateRow of invoiceExtraService.generateRateRows(rateCard, invoice.currency, category)">
                      <div class="col">
                        <div *ngFor="let rate of rateRow">
                          <p>{{rate?.name}}: {{rate?.value}}</p>
                        </div>
                      </div>
                    </div>
                    <p class="font-large-bold">Demurrage Slabs</p>
                    <div class="rate-sheet">
                      <div class="col">
                        <div *ngFor="let slab of invoiceExtraService.getDemurrageSlabs(rateCard, category); let i = index">
                          <p *ngIf="i === 0"> First {{slab.hours}} Hrs:  {{slab.rate}} {{invoice.currency.currentCurrency}} (Per Hr)</p>
                          <p *ngIf="i !== 0"> Next {{slab.hours}} Hrs: {{slab.rate}} {{invoice.currency.currentCurrency}} (Per Hr)</p>
                        </div>
                      </div>
                    </div>
                    <p>Until Clearance: {{rateCard.demurragePerHrTillClearance}} {{invoice.currency.currentCurrency}} (Per hr)</p><br>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="payment-terms">
          <mat-expansion-panel class="pt-e-panel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <p class="font-x-large-bold"
                   [ngClass]="invoice.invoicePaymentTerm == null ? 'font-large-bold-red' : 'font-x-large-bold'">Payment
                  Terms</p>
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="content">
              <hmt-payment-terms
                (paymentTerm)="addPaymentTerm($event)"
                [selectedPaymentTerm]="(invoice$ | async).invoicePaymentTerm"
                [totalCost]="getTotal() / (invoice$ | async).currency.currencyRate">
              </hmt-payment-terms>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
      <div class="right-bar">
        <mat-slide-toggle [labelPosition]="'before'" (change)="toggleLoads()">
          {{toggled ? 'Collapse' : 'Expand'}}
        </mat-slide-toggle>
      </div>
    </div>
  </ng-container>
  <div class="progress-line-footer row">
    <div class="left row">
      <button mat-stroked-button color="primary" (click)="backToInvoicePage()">Back</button>
    </div>
    <div class="right row" *ngIf="invoice != null">
      <button mat-stroked-button color="primary"
              *ngIf="invoice.progressStep === progressLineStep.DRAFT || invoice.progressStep === progressLineStep.SENT_FOR_INTERNAL_APPROVAL || invoice.progressStep === progressLineStep.INTERNAL_APPROVED"
              (click)="onSaveInvoice()">SAVE
      </button>
      <button mat-flat-button color="primary" [disabled]="checkSavedOrNot()"
              *ngIf="invoice.progressStep === progressLineStep.DRAFT
                && invoice.approvalState !== approvalStep.SEND_FOR_INTERNAL_APPROVAL
                && invoice.invoicePaymentTerm != null
                && invoice.exchangeRate > 0"
              (click)="onSubmitForApproval()">SUBMIT FOR APPROVAL
      </button>
      <button mat-flat-button color="warn" [disabled]="checkSavedOrNot()"
              *ngIf="invoice.approvalState === approvalStep.SEND_FOR_INTERNAL_APPROVAL"
              (click)="onInternalReject()">REJECT
      </button>
      <button mat-flat-button color="primary" [disabled]="checkSavedOrNot()"
              *ngIf="invoice.approvalState === approvalStep.SEND_FOR_INTERNAL_APPROVAL"
              (click)="onInternalApprove()">APPROVE
      </button>
      <button mat-stroked-button color="primary" [disabled]="checkSavedOrNot()"
              *ngIf="invoice.progressStep === progressLineStep.INTERNAL_APPROVED
                || invoice.progressStep === progressLineStep.SENT
                || invoice.progressStep === progressLineStep.PAID" (click)="downloadPdf()">
        DOWNLOAD
      </button>
      <button mat-flat-button color="primary" [disabled]="checkSavedOrNot()" *ngIf="invoice.progressStep === progressLineStep.INTERNAL_APPROVED"
              (click)="sentToCustomer()">MARK AS SENT
      </button>
      <button mat-flat-button color="primary"
              *ngIf="invoice.progressStep === progressLineStep.SENT && invoice.approvalState === approvalStep.CUSTOMER_APPROVED && invoice.overdueState === overdueStep.DEFAULT"
              (click)="markAsOverdue()">MARK AS OVERDUE
      </button>
      <button mat-flat-button color="primary"
              *ngIf="invoice.approvalState === approvalStep.CUSTOMER_APPROVED && (invoice.overdueState === overdueStep.OVERDUE || invoice.overdueState === overdueStep.DEFAULT)"
              (click)="markAsPaid()">MARK AS PAID
      </button>
      <button mat-flat-button color="warn"
              *ngIf="invoice.progressStep === progressLineStep.SENT && !(invoice.approvalState === approvalStep.CUSTOMER_APPROVED || invoice.approvalState === approvalStep.CUSTOMER_REJECTED)"
              (click)="onCustomerReject()">CUSTOMER REJECTED
      </button>
      <button mat-flat-button color="primary"
              *ngIf="invoice.progressStep === progressLineStep.SENT && !(invoice.approvalState === approvalStep.CUSTOMER_APPROVED || invoice.approvalState === approvalStep.CUSTOMER_REJECTED)"
              (click)="onCustomerApprove()">CUSTOMER APPROVED
      </button>
      <button mat-flat-button color="warn" *ngIf="invoice.overdueState === overdueStep.OVERDUE"
              (click)="maskAsLost()">MARK AS LOSS
      </button>
      <button mat-flat-button color="warn" *ngIf="invoice.overdueState === overdueStep.OVERDUE"
              (click)="markAsSubtended()">MARK AS SUBSTANDARD
      </button>
      <button mat-flat-button color="warn" *ngIf="invoice.overdueState === overdueStep.OVERDUE"
              (click)="markAsDoubtful()">MARK AS DOUBTFUL
      </button>
      <button mat-flat-button color="primary"
              (click)="changeCurrency()">CONVERT
      </button>
    </div>
  </div>
</div>

