<div class="container">
  <div class="dialog-title">
    <mat-icon *ngIf="type == 'ERROR'">error_outline</mat-icon>
    <mat-icon *ngIf="type == 'WARNING'">warning</mat-icon>
    <mat-icon *ngIf="type == 'SUCCESS'">thumb_up_alt</mat-icon>
    <mat-icon *ngIf="type == 'CONFIRM'">check_circle_outline</mat-icon>
    <h1 id="dialog-text" [ngClass]="type" mat-dialog-title>
      {{title | titlecase}}
    </h1>
  </div>

  <div class="dialog-body" mat-dialog-content>
    <p id="dialog-massage">{{message}}</p>
  </div>

  <div *ngIf="!alertOnly" mat-dialog-actions class="dialog-footer">
    <button mat-button (click)="onDismiss()">No</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
  </div>

  <div *ngIf="alertOnly" mat-dialog-actions class="dialog-footer">
    <button mat-button (click)="onOk()">Ok</button>
  </div>

</div>
