import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API} from '@configs/api.config';
import {ConfigType} from '@core/models/enums/config-type.enum';
import {ViewComponentConfig} from '@shared/models/view-component-config.model';
import {JobSummary} from '../../modules/wizard/models/job-summary.model';
import {UtilService} from '@core/services/util.service';
import {JobSummaryConfig} from '@core/models/job-summary-config';

@Injectable({
  providedIn: 'root'
})
export class JobSummaryConfigService {

  constructor(private httpClient: HttpClient, private utilService: UtilService) {
  }

  getConfig(workflowId: string, configType: ConfigType): Observable<JobSummaryConfig> {
    const url = API.jobFile.gatConfig.format([workflowId, configType]);
    return this.httpClient.get<JobSummaryConfig>(url);
  }

  setConfigData(jobSummary: JobSummary, viewComponentConfigs: ViewComponentConfig[]) {
    const configWithData = viewComponentConfigs
      .map((config: ViewComponentConfig) => {
        const data = config.dataMappingPaths.length === 1
          // if the dataMappingPaths length is 1, return type could be a string as well
          ? this.utilService.getObject(jobSummary, config.dataMappingPaths[0].path)
          : config.dataMappingPaths
            .reduce((prev, curr) => {
              const obj = this.utilService.getObject(jobSummary, curr.path, curr.defaultValue);
              if (curr.mapTo) {
                prev[curr.mapTo] = obj;
              } else {
                if (this.utilService.isString(obj) || this.utilService.isIndex(obj)) {
                  throw Error('Cannot map string values without a matTo value with multiple data mapping paths');
                }
                // if the mapping not exist, merge to same object,
                // could replace the existing values if same property exist
                prev = {...prev, ...obj as object};
              }
              return prev;
            }, {});
        return {
          ...config,
          data
        };
      });
    return configWithData;
  }
}
