import { Pipe, PipeTransform } from '@angular/core';

/**
 * Custom pipe that transforms an array of strings into a formatted tooltip list.
 * Each string in the array is prefixed with a bullet point (•) and joined with a newline character (\n).
 *
 * @param value - The array of strings to be transformed.
 * @returns The formatted tooltip list as a single string.
 */
@Pipe({name: 'tooltipList'})
export class TooltipListPipe implements PipeTransform {
  transform(value: string[]): string {
    return value.map(name => '• ' + name).join('\n');
  }
}