import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API} from '@configs/api.config';
import {PreSignedUrl} from '@control-tower/models/jpm/pre-signed-url';
import {map} from 'rxjs/operators';
import {RateCard} from '@shared/models/rate-card.model';
import { Pageable } from '@shared/models/pageable.model';
import {PaymentTerm} from '@shared/models/payment-term.model';
import {InvoiceCurrency} from '../models/invoice-currency.model';
import {PaymentTermType} from '@shared/models/enums/payment-term-type.enum';
import {OrgDetails} from '@shared/models/org-details.model';
import {ContractType} from '@shared/models/enums/rate-sheet-contract-type.enum';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private httpClient: HttpClient) { }

  getPdfPreSIgnedUrl(fileId: string) {
    const url = API.fileHandelar.getPreSignedUrl.format([fileId]);
    return this.httpClient.get<PreSignedUrl>(url);
  }

  getPDFFileData(url: string) {
    return this.httpClient.get(url, {responseType: 'blob'})
      .pipe(
        map((res) => new Blob([res], {type: 'application/pdf'}))
      );
  }

  getOrgDetails(orgIds: string[]): Observable<OrgDetails[]> {
    const url = API.organization.getOrgDetails;
    return this.httpClient.post<OrgDetails[]>(url, orgIds);
  }

  getRateCards(contractType: ContractType, orgIds?: string[], includeNonOrgRates = false, pageIndex?: number, pageSize?: number): Observable<Pageable<RateCard>> {
    const url = API.rateCard.getRateCards;
    let params = new HttpParams();

    if (orgIds) {
      params = params.set('orgIds', orgIds.join(','));
    }
    params = params.set('includeNonOrgRates', '' + includeNonOrgRates);

    if (pageIndex != null && pageSize != null) {
      params = params.set('pageNo', '' + pageIndex);
      params = params.set('pageSize', '' + pageSize);
    }

    params = params.set('isContractedRate', 'true');
    params = params.set('status', 'VALID');
    params = params.set('type', contractType);

    return this.httpClient.get<Pageable<RateCard>>(url, { params });
  }

  getRateCardsByFilters(orgId: string, contractType: ContractType, organizationId?: string, vertical?: string, searchKey?: string): Observable<RateCard[]> {
    const url = API.rateCard.getRateCardsByFilter.format([orgId]);
    let params = new HttpParams();

    if (organizationId) {
      params = params.set('customerId', '' + organizationId);
    }
    if (vertical) {
      params = params.set('vertical', '' + vertical);
    }
    if (searchKey) {
      params = params.set('searchKey', '' + searchKey);
    }
    if (contractType) {
      params = params.set('type', contractType);
    }

    params = params.set('status', 'VALID');

    return this.httpClient.get<RateCard[]>(url, {params});
  }

  getOrganizationInvoiceLoadsLimit(orgId: string): Observable<number> {
    const url = API.organization.getOrganizationInvoiceLoadsLimit.format([orgId]);
    return this.httpClient.get<number>(url);
  }

  getRateCardsByIds(rateCardIds: string[]): Observable<RateCard[]> {
    const url = API.rateCard.getRateCardsByIds;
    return this.httpClient.post<RateCard[]>(url, rateCardIds);
  }

  findCommitmentContractOrganizations(orgId: string, contractType: ContractType, fromDate: Date, toDate: Date): Observable<string[]> {
    const url = API.rateCard.findCommitmentSuppliers.format([orgId, contractType.toString(),
      fromDate.toISOString(), toDate.toISOString()]);
    return this.httpClient.get<string[]>(url);
  }
}
