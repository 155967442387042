import {RateCard} from '@shared/models/rate-card.model';
import { RateTier } from '@shared/models/rate-tier.model';
import { Organization } from 'app/modules/organization/models/organization';

export const RATE_CARD_MANAGER_ACTION_PREFIX = '[Rate Card State] ';

export class FetchOrganizations {
  static readonly type = `${RATE_CARD_MANAGER_ACTION_PREFIX} Load organizations`;
  constructor(){}
}

export class FetchSupplierOrganizations {
  static readonly type = `${RATE_CARD_MANAGER_ACTION_PREFIX} Load supplier organizations`;
  constructor(public orgType: string, public verticals: string[], public searchKey: string){}
}

export class SaveRateCard {
  static readonly type = `${RATE_CARD_MANAGER_ACTION_PREFIX} Save rate card`;
  constructor(public rateCard: RateCard){}
}

export class FetchRateCards {
  static readonly type = `${RATE_CARD_MANAGER_ACTION_PREFIX} Fetch Rate Cards`;
  constructor(public selectedOrgId: string, public pageSize: number, public pageIndex: number){}
}

export class DeleteRateCard {
  static readonly type = `${RATE_CARD_MANAGER_ACTION_PREFIX} Delete Rate Card`;
  constructor(public rateCardId: string){}
}
export class GetAllTiersAction {
  static readonly type = `${RATE_CARD_MANAGER_ACTION_PREFIX} Get All Tiers`;
  constructor(public pageSize: number, public pageIndex: number) {
  }
}

export class SaveVehicleTierAction {
  static readonly type = `${RATE_CARD_MANAGER_ACTION_PREFIX} Save Vehicle Tier`;
  constructor(public vehicleTier: RateTier) {
  }
}

export class UpdateVehicleTierAction {
  static readonly type = `${RATE_CARD_MANAGER_ACTION_PREFIX} Update Vehicle Tier`;
  constructor(public vehicleTier: RateTier) {
  }
}

export class RemoveVehicleTierAction {
  static readonly type = `${RATE_CARD_MANAGER_ACTION_PREFIX} Remove Vehicle Tier`;
  constructor(public vehicleTierId: string) {
  }
}

export class FetchCountries {
  static readonly type = `${RATE_CARD_MANAGER_ACTION_PREFIX} Fetch Countries`;
  constructor(public searchKey?: string) {
  }
}

export class GetDistanceMatrixTemplateOperationListWise {
  static readonly type = `${RATE_CARD_MANAGER_ACTION_PREFIX} Get Distance Matrix Template Operation List Wise`;
  constructor(public searchKey: string, public operationIds: string[]) {
  }
}
