import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {API} from '@configs/api.config';
import {MulQuotation, Quotation, JobFileQuotation} from '../models/quotation-file.model';
import {InvoiceCostModel} from '../models/invoice-cost.model';
import {QuotationRequestModel} from '../models/quotation-request.model';
import {File, FileRequest} from '@control-tower/models/files';
import {FinalizeQuotationRequestModel} from '../models/finalize-quotation-request.model';
import {RateTier} from '@shared/models/rate-tier.model';
import {TerminateJobQutModel} from '@shared/models/terminate-job-qut-model';
import {Person} from '@shared/models/person.model';
import {ResourceType} from '@schedule/models/enums';
import {getPersons} from '../../fuel/test-data/fuel.test';
import {CreateWorkOrderRequest} from '../models/create-work-order.request';
import {InitQuotation} from '../models/init-quotation.model';
import {CustomerInfo} from '@control-tower/models/customer-info.model';
import {ConfirmationMode} from '../models/enums/confirmation-mode.enum';
import {map} from 'rxjs/operators';
import {PreSignedUrl} from '@control-tower/models/jpm/pre-signed-url';
import {FileInfo} from "@shared/models/file-info";
import {RateCard} from '@shared/models/rate-card.model';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  constructor(private httpClient: HttpClient) { }

  getQuotationByOrgIdAndJobRefId(orgId: string, jobRefId: string): Observable<JobFileQuotation[]> {
    const url = API.quotation.getQuotationByOrgIdAndJobRefId.format([orgId, jobRefId]);
    return this.httpClient.get<JobFileQuotation[]>(url);
  }

  /**
   * This method will return a JobFileQuotation for a given order. order is stating from zero.
   *
   * @param orgId organization Id
   * @param jobRefId Job Reference Id
   * @param order order of the quotation
   */
  getQuotationByOrgIdAndJobRefIdAndOrder(orgId: string, jobRefId: string, order: number): Observable<JobFileQuotation> {
    const url = API.quotation.getQuotationByOrgIdAndJobRefIdAndOrder.format([orgId, jobRefId, order.toString()]);
    return this.httpClient.get<JobFileQuotation>(url);
  }

  getTemporaryQuotationForContainer(orgId: string, jobRefId: string, rateCardId: string, quotationRequestMODEL: QuotationRequestModel)
    : Observable<Quotation[]> {
    const url = API.quotation.getTemporaryQuotationForContainer.format([orgId, jobRefId, rateCardId]);
    return this.httpClient.post<Quotation[]>(url, quotationRequestMODEL);
  }

  // TODO: remove this method
  getInvoiceCosts(): Observable<InvoiceCostModel[]> {
    const url =  API.assets.getInvoiceCosts;
    return this.httpClient.get<InvoiceCostModel[]>(url);
  }

  updateQuotation(orgId: string, jobRefId: string, rateCardId: string, quotationRequestModel: QuotationRequestModel)
    : Observable<MulQuotation> {
    const url = API.quotation.updateQuotation.format([orgId, jobRefId, rateCardId]);
    return this.httpClient.put<MulQuotation>(url, quotationRequestModel);
  }

  // TODO: this is a duplicate of a file service, fix this
  getDocuments(jobFileId: string): Observable<{Contract: Array<{key: string}>}> {
    const url = API.s3.getDocuments.format([jobFileId]);
    return this.httpClient.get<{Contract: Array<{key: string}>}>(url);
  }

  // TODO: this is a duplicate of a file service, fix this
  getDocumentLink(fileRequest: FileRequest): Observable<string> {
    const url = API.youAreHere.getPDF.getDocumentLink.format([]);
    return this.httpClient.post(url, fileRequest, {responseType: 'text'});
  }

  finalizeQuotation(orgId: string, jobRefId: string, order: number, finalizeQuotationRequestModel: FinalizeQuotationRequestModel)
    : Observable<MulQuotation> {
    const url = API.quotation.finalizeQuotation.format([orgId, jobRefId, order.toString()]);
    return this.httpClient.post<MulQuotation>(url, finalizeQuotationRequestModel);
  }

  /**
   * This method will return a temporary finalized quotation MulQuotation model.
   *
   * @param orgId organization Id
   * @param jobRefId Job Reference Id
   * @param order order of the quotation
   * @param finalizeQuotationRequestModel data model need to query the MulQuotation
   */
  getTemporaryFinalizedMulQuotation(
    orgId: string, jobRefId: string, order: number, finalizeQuotationRequestModel: FinalizeQuotationRequestModel)
    : Observable<MulQuotation> {
    const url = API.quotation.getTemporaryFinalizeQuotation.format([orgId, jobRefId, order.toString()]);
    return this.httpClient.post<MulQuotation>(url, finalizeQuotationRequestModel);
  }

  generateContractForQuotation(orgId: string, jobRefId: string, acceptedQuotationId: string, preparedBy: string)
    : Observable<ArrayBuffer> {
    const url = API.quotation.generateContractForQuotation.format([orgId, jobRefId, acceptedQuotationId, preparedBy]);
    const httpOptions = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.httpClient.get<ArrayBuffer>(url, httpOptions);
  }

  getQuotationPdf(orgId: string, jobRefId: string, order: number, isDefaultCurrency: boolean): Observable<FileInfo> {
    const url = API.quotation.generatePdfUrlV2.format([orgId, jobRefId, order + '']);
    let params = new HttpParams();
    params = params.set('isDefaultCurrency', String(isDefaultCurrency));
    return this.httpClient.get<FileInfo>(url, {params});
  }

  getQuotationPDFUrl(orgId: string, jobRefId: string, order: number): Observable<File> {
    const url = API.quotation.getQuotationPDFUrl.format([orgId, jobRefId, order.toString()]);
    return this.httpClient.get<File>(url);
  }

  getQuotationPDFUrlWithCurrency(orgId: string, jobRefId: string, order: number, isDefaultCurrency: boolean): Observable<File> {
    const url = API.quotation.getQuotationPDFUrlWithCurrency.format([orgId, jobRefId, order.toString(), String(isDefaultCurrency)]);
    return this.httpClient.get<File>(url);
  }

  terminateJob(orgId: string, jobRefId: string, terminateJob: TerminateJobQutModel): Observable<TerminateJobQutModel> {
    const url = API.jobFile.terminateJob.format([orgId, jobRefId]);
    return this.httpClient.post<TerminateJobQutModel>(url, terminateJob);
  }

  loadAllSupervisors(orgId: string, designationType: ResourceType): Observable<Person[]> {
    const url = API.fleetManager.getPersonsByType.format(['update:quotation']);
    return this.httpClient.get<Person[]>(url);
  }

  assignSupervisorToQuotation(orgId: string, jobRefId: string, jobFileQuotationId: string, workOrderViewModel: CreateWorkOrderRequest)
    : Observable<string> {
    const url = API.quotation.assignSupervisorToQuotation.format([orgId, jobRefId, jobFileQuotationId]);
    return this.httpClient.post<string>(url, workOrderViewModel, {
      responseType: 'text' as 'json'
    });
  }

  saveInitQuotationFromWizard(orgId: string, jobRefId: string): Observable<InitQuotation> {
    const url = API.quotation.saveInitQuotation.format([orgId, jobRefId]);
    const httpOptions = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.httpClient.post<InitQuotation>(url, httpOptions);
  }

  getCustomerInfoByCustomerId(customerId: string): Observable<CustomerInfo> {
    const url = API.fleetManager.getCustomerByCustomerId.format([customerId]);
    return this.httpClient.get<CustomerInfo>(url);
  }

  /**
   * This method will update the current mul-quotation to discard true state and copy of current mul-quotation with status GENERATED.
   *
   * @param orgId Id of the organization
   * @param jobRefId job reference Id
   * @param jobQuotationId Job File Quotation Id
   * @return newly created containerRecordId of mulQuotation
   */
  updateTheOffer(orgId: string, jobRefId: string, jobQuotationId: string): Observable<string> {
    const url = API.quotation.updateTheOffer.format([orgId, jobRefId, jobQuotationId]);
    return this.httpClient.get<string>(url, {
      responseType: 'text' as 'json'
    });
  }

  acceptOfferAndGenerateContract(orgId: string, jobRefId: string, jobQuotationId: string,
                                 acceptQuotation: {confirmationMode: ConfirmationMode, generatedBy: string}): Observable<string> {
    const url = API.quotation.acceptOffer.format([orgId, jobRefId, jobQuotationId]);
    return this.httpClient.post<string>(url, acceptQuotation, {  responseType: 'text' as 'json'});
  }

  saveTheOffer(orgId: string, jobRefId: string, jobQuotationId: string): Observable<string> {
    const url = API.quotation.saveOffer.format([orgId, jobRefId, jobQuotationId]);
    return this.httpClient.get<string>(url, {  responseType: 'text' as 'json'});
  }

  sendForApproval(orgId: string, jobRefId: string, order: number): Observable<boolean> {
    const url = API.jobFile.sendQuotationToFleetManager.format([orgId, jobRefId, order.toString()]);
    return this.httpClient.get<boolean>(url);
  }

  getPdfPreSignedUrl(fileId: string) {
    const url = API.fileHandelar.getPreSignedUrl.format([fileId]);
    return this.httpClient.get<PreSignedUrl>(url);
  }

  getPDFFileData(url: string) {
    return this.httpClient.get(url, {responseType: 'blob'})
      .pipe(
        map((res) => new Blob([res], {type: 'application/pdf'}))
      );
  }
}
