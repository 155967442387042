import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {AuthState} from '@core/store/auth/auth.state';
import {EntityMap} from '@shared/models/types';
import {Observable} from 'rxjs';
import {User} from '@core/models/user.model';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  currentUser: User;
  routingMap: EntityMap<string, string[]>;
  @Select(AuthState.getUser) user$: Observable<User>;

  constructor(private router: Router, public store: Store) {
    this.user$.pipe(
      tap( user => this.currentUser = user)
    ).subscribe();
  }

  // TODO: handle authorization part
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return true;
    const currentUserScopes: string[] = this.currentUser.scopes;
    this.routingMap = {
      wizard: ['fill:wizard', 'invite:wizard'],
      quotation: [
        'view:quotation',
        'create:quotation',
        'update:quotation',
        'approve:quotation',
        'send:quotation',
        'accept:quotation',
        'download:quotation'
      ],
      finalize_quotation: ['accept:quotation', 'download:quotation'],
      generate_quotation: [
        'view:quotation',
        'create:quotation',
        'update:quotation',
        'approve:quotation',
        'send:quotation'
      ],
      contract: ['view:contract', 'download:contract'],
      schedule: ['update:schedule', 'approve:schedule'],
      control_tower: [
        'update:control-tower',
        'view:jpm',
        'invite:jpm',
        'complete:work-order',
        'update:runtime-costs'
      ],
      fuel: [
        'create:fuel-request',
        'approve:fuel-request',
        'reject:fuel-request',
        'complete:fuel-request'
      ],
      aggregated_jpm: [
        'view-all:aggregated-jpm',
        'view-jobs-owned:aggregated-jpm',
        'view-vehicle-job:aggregated-jpm'
      ],
      organizations_manager: [
        'view:org',
        'edit:org',
        'create:org',
        'view:hr',
        'edit:hr',
        'create:hr',
        'view:vehicle',
        'edit:vehicle',
        'create:vehicle',
        'view:location',
        'edit:location',
        'create:location',
        'activate:org'
      ],
      reports: [
        // TODO: Fix this correctly when the reports related scopes are available
        'view:job-master'
      ]
    };

    if (currentUserScopes.length) {
      const ifExist = this.routingMap[route.data.routeId].some(roles => currentUserScopes.includes(roles));
      if (!ifExist) {
        this.router.navigate(['/status-page/access-denied']);
        return false;
      }
      return true;
    }

    this.router.navigate(['/status-page/access-denied']);
    return false;

  }

  // TODO: handle authorization part
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return true;
    const currentUserScopes: string[] = this.currentUser.scopes;
    this.routingMap = {
      container_management: ['fill:wizard'],
      job_summary: ['invite:wizard'],
      fuel_approved: ['approve:fuel-request'],
      fuel_pending: ['create:fuel-request', 'complete:fuel-request', 'reject:fuel-request', 'approve:fuel-request'],
      fuel_completed: ['complete:fuel-request'],
      fuel_rejected: ['reject:fuel-request'],
      fuel_requests: ['create:fuel-request'],
      fuel_archived: ['approve:fuel-request'],
    };

    if (currentUserScopes.length > 0 && this.checkLogin()) {
      const ifExist = this.routingMap[route.data.routeId].some(roles => currentUserScopes.includes(roles));
      if (!ifExist) {
        this.router.navigate(['/status-page/access-denied']);
        return false;
      }
      return true;
    }

    this.router.navigate(['/status-page/access-denied']);
    return false;
  }

  isAuthenticated(): boolean {
    return this.store.selectSnapshot(AuthState.isAuthenticated);
  }

  // TODO: handle authorization part when available
  checkLogin(): boolean {
    if (this.isAuthenticated()) {
      return true;
    }
    // this.router.navigate(['/login', ]);
    return true;
  }

  private getToken(): string {
    return this.store.selectSnapshot(AuthState.token);
  }


  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64));
    return JSON.parse(jsonPayload);
  }
}
