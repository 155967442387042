<div class="job-terminate-container">
  <div class="title">
    <button mat-button class="close-button" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
    <div class="title-job-terminate">
      <span>Terminate Job</span>
    </div>
  </div>
  <div class="form-class">
    <form [formGroup]="formGroup">
      <div class="select-type">
        <p>Reason</p>
        <div class="select-option">
          <mat-toolbar>
            <button mat-button [ngClass]="{'active': formGroup.value.reason === 'Customer Canceled'}"
                    (click)="setTerminateType('Customer Canceled')">Customer Canceled</button>
            <button mat-button [ngClass]="{'active': formGroup.value.reason === 'Internal Issue'}"
                    (click)="setTerminateType('Internal Issue')">Internal Issue</button>
            <button mat-button [ngClass]="{'active': formGroup.value.reason === 'Other'}"
                    (click)="setTerminateType('Other')">Other</button>
          </mat-toolbar>
        </div>
      </div>
      <div class="date-time">
        <p>When This Happened</p>
        <mat-form-field>
          <input matInput [ngxMatDatetimePicker]="startTimePicker"
                 formControlName="terminateDate">
          <mat-datepicker-toggle matSuffix [for]="startTimePicker">
            <img matDatepickerToggleIcon src="./assets/icons/calendar.svg">
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #startTimePicker color="accent"
                                   enableMeridian="true">
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
      <div class="invoice">
        <mat-checkbox formControlName="invoiceCreated">
          Invoice
        </mat-checkbox>
      </div>
      <div class="comments">
        <p>COMMENTS</p>
        <mat-form-field>
          <textarea formControlName="comments" matInput rows="8" cols="35"></textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="footer">
    <div class="mid-flex-grow"></div>
    <div>
      <button mat-flat-button color="warn" class="terminate" (click)="save()">Terminate</button>
    </div>
  </div>
</div>
