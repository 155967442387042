<router-outlet></router-outlet>

<div class="overlay" *ngIf="spinnerPros?.spinner">
  <div class="overlay__wrapper">
    <div class="overlay__spinner">
     <div class="custom-message">
        <div class="loader-wrapper">
          <img class="image" src="assets/gif/box_loader.gif">
        </div>
        <span *ngIf="spinnerPros?.spinnerMessage" class="message">{{spinnerPros?.spinnerMessage}}</span>
     </div>
    </div>
  </div>
</div>