import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LatLng} from '@shared/models/enums/lat-lng.model';
import {GatewayLocation} from '@shared/models/gateway-location.model';
import {JobSummary} from '../../models/job-summary.model';
import {InitContainerViewModel} from '../../models/init-container-view.model';
import {ServiceViewModel} from '../../models/service-view.model';
import {takeUntil, tap} from 'rxjs/operators';
import {LoadJobSummaryAction} from '../../store/wizard.actions';
import {BehaviorSubject, Subject} from 'rxjs';
import {Actions, ofActionSuccessful} from '@ngxs/store';
import {MapComponent} from '@shared/components/map/map.component';
import {GeoCoordinate} from '@shared/models/geo-coordinate';
import {BillingIndicator} from "@control-tower/models/enums/billing-indicator.enum";

@Component({
  selector: 'hmt-transport-plan',
  templateUrl: './transport-plan.component.html',
  styleUrls: ['./transport-plan.component.scss']
})
export class TransportPlanComponent implements OnInit, AfterViewInit {
  @ViewChild('mapComponent', {static: false}) mapComponent: MapComponent;
  @Input() data: JobSummary = null;
  mapInitialized: BehaviorSubject<boolean> = new BehaviorSubject(false);
  serviceViewModels: ServiceViewModel[] = [];
  selectedLocation: GatewayLocation;
  selectedContainer: InitContainerViewModel;
  locationLabels: Array<{ label: string, isActive: boolean }> = [];
  estimatedDuration: number;
  serviceMap: Array<{ locationId: string, service: ServiceViewModel }> = [];
  selectedService: ServiceViewModel;

  constructor(private actions: Actions) {
  }

  ngAfterViewInit(): void {
    this.loadInitialData();
  }

  ngOnInit(): void {
  }

  loadInitialData() {
    this.changeContainer(this.data.containers[0]);
  }

  changeContainer(container: InitContainerViewModel) {
    this.serviceViewModels = [];
    this.selectedContainer = container;
    this.setMapData();
  }

  setMapData() {
    this.selectedContainer.services.forEach(loc => {
      if (loc.locationDetails != null) {
        this.serviceViewModels.push(loc);
        this.serviceMap.push({locationId: loc.id, service: loc});
      }
    });
    this.selectedLocation = this.serviceViewModels[0].locationDetails;
    this.initializeMapData(this.serviceViewModels);
    this.onChangeLocation('S');
  }

  initializeMapData(markerLocations: ServiceViewModel[] = [], useExpressWay: Array<{ useTolls: boolean, legName: string }> = []) {
    this.serviceViewModels = markerLocations;
    this.drawPaths(useExpressWay);
    this.addMarkers();
    const coordinates: LatLng[] = this.serviceViewModels.map(service => ({lng: service.locationDetails.geoLocation.lng,
      lat: service.locationDetails.geoLocation.lat}));
    this.mapComponent.listenToMap(coordinates);
  }

  drawPaths(useExpressWay: Array<{ useTolls: boolean, legName: string }> = []) {
    this.mapComponent.drawPaths(this.generateRoutes(useExpressWay), this.setDirectionsRendererOptions()).subscribe();
  }

  addMarkers(): void {
    this.mapComponent.initMap(this.mapComponent.defaultOptions, 9);
    this.mapComponent.removeMarkers();
    this.mapComponent.addMarkers(this.generateMarkers());
  }

  generateMarkers(): google.maps.MarkerOptions[] {
    this.locationLabels = [];
    const icon = {
      url: `./assets/icons/circle.svg`, scaledSize: new google.maps.Size(30, 30),
      labelOrigin: new google.maps.Point(15, 15),
    };
    const label = {text: 'Start', color: 'white', fontWeight: 'bold', fontSize: '14px'};
    return this.serviceViewModels?.map((location: ServiceViewModel, index): google.maps.MarkerOptions => ({
      position: location.locationDetails.geoLocation,
      icon,
      origin: new google.maps.Point(0, 0),
      label: {...label, text: this.setLabelText(index, this.serviceViewModels.length)},
    } as google.maps.MarkerOptions));
  }

  setLabelText(index: number, length: number): string {
    let label = null;
    if (index === 0) {
      label = 'S';
    } else if (index === length - 1) {
      label = 'E';
    } else {
      label = '' + index + '';
    }
    const markerLabel = {
      label,
      isActive: false
    };
    this.locationLabels.push(markerLabel);
    return label;
  }

  generateRoutes(useExpressWay: Array<{ useTolls: boolean, legName: string }> = [])
    : Array<{ origin: LatLng | string, destination: LatLng, waypoints: google.maps.DirectionsWaypoint[], useTolls: boolean }> {
    const routes = [];
    for (let i = 0; i < this.serviceViewModels?.length - 1; i++) {
      routes.push({
        origin: this.serviceViewModels[i]?.locationDetails.geoLocation,
        destination: this.serviceViewModels[i + 1]?.locationDetails.geoLocation,
        waypoints: this.generateWayPoints(this.serviceViewModels[i + 1]?.wayPoints) ?? [],
        useTolls: false
      });
    }
    return routes;
  }

  generateWayPoints(waypoints: GeoCoordinate[]): google.maps.DirectionsWaypoint[] | null {

    if (!waypoints || !waypoints.length) {
      return [];
    }

    return waypoints?.map(waypoint => {
      return {
        stopover: false,
        location: new google.maps.LatLng(waypoint?.lat, waypoint?.lng)
      };
    });
  }

  setDirectionsRendererOptions(): google.maps.DirectionsRendererOptions {
    return {
      draggable: false,
      polylineOptions: {
        strokeWeight: 3,
        strokeColor: '#90B1FC',
      },
      suppressMarkers: true
    };
  }

  onChangeLocation(locationLabel: string) {
    this.locationLabels.forEach((item) => {
      item.isActive = false;
    });
    if (locationLabel === 'S') {
      this.selectedLocation = this.serviceViewModels[0].locationDetails;
      this.mapComponent.changeMarkerIcon(0);
      this.locationLabels[0].isActive = true;
      this.onChangeService(0);
    } else if (locationLabel === 'E') {
      this.selectedLocation = this.serviceViewModels[this.serviceViewModels.length - 1].locationDetails;
      this.mapComponent.changeMarkerIcon(this.serviceViewModels.length - 1);
      this.locationLabels[this.serviceViewModels.length - 1].isActive = true;
      this.onChangeService(this.serviceViewModels.length - 1);
    } else {
      this.selectedLocation = this.serviceViewModels[locationLabel].locationDetails;
      this.mapComponent.changeMarkerIcon(locationLabel);
      this.locationLabels[locationLabel].isActive = true;
      this.onChangeService(+locationLabel);
    }
  }

  onChangeService(index: number) {
    this.selectedService = this.serviceMap[index].service;
  }
}
