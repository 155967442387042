export enum ActivityType {
    CONTAINER_YARD = 'CONTAINER_YARD',
    PORT = 'PORT',
    LOADING = 'LOADING',
    WAREHOUSE = 'WAREHOUSE',
    UNLOADING = 'UNLOADING',
    CUSTOM_TASK = 'CUSTOM_TASK',
    IN_TRANSIT = 'IN_TRANSIT',
    EXTERNAL = 'EXTERNAL',
    TERMINAL = 'TERMINAL',
    YARD = 'YARD',
    CIA = 'CIA'
}
