<mat-toolbar color="secondary">
    <section>
        <div class="example-flex-container">
            <button mat-icon-button color="basic" id="menu-toggle" aria-label="Icon button with a menu icon" id="expanded"
                (click)="toggleDrawer()">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
    </section>
    <section>
        <div class="company-logo" *ngIf="(logo | async)">
            <img src="{{(logo | async)?.url}}" class="logo" alt="logo">
        </div>
    </section>
    <section class="info">
        <div class="info">
          <span>Operation </span>
          <mat-form-field appearance="outline" class="operations-selector">
            <mat-select [value]="selectedOperation$ | async" [compareWith]="compareOperations" (selectionChange)="handleChangeOperation($event)">
              <mat-option [value]="{id: '', name: 'all'}">All</mat-option>
              <mat-option *ngFor="let op of operations$ | async" [value]="op">{{op.name}}</mat-option>
            </mat-select>
          </mat-form-field>

            <button mat-icon-button color="basic" aria-label="icon button with a menu icon">
                <img src="./assets/icons/notifications.svg" (click)="toggleNotificationView()" class="icon" alt="dashboard">
            </button>
            <button mat-mini-fab color="basic" class="info-button notifications"
                aria-label="icon button with a plus one icon">
                <span class="count-button">{{ (firebaseNotifications$ | async)?.length }}</span>
            </button>
            <button mat-icon-button class="name shorten-text" disabled aria-label="Icon button with a open in new tab icon"
                    matTooltip="Info about the action">
                <img src="./assets/icons/person.svg" alt="person" class="user-icon">&nbsp;
                {{ (user$ | async)?.firstName }} {{ (user$ | async)?.lastName }}
            </button>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Icon button with a open in new tab icon">
                <img src="./assets/icons/down-arrow.svg" class="icon" alt="dashboard">
            </button>

          <div>
            <div *ngIf="showNotifications" class="notification-panel">
              <div *ngFor="let notification of firebaseNotifications$ | async; let i = index">
                <div class="notification-body" (click)="onClickNotification(notification)">
                  <div class="notification-content">
                    <img *ngIf="notification.updatedNotificationType === 'INCIDENT'" class="image" src="./assets/icons/incident.svg" alt="delay">&nbsp;
                    <img *ngIf="notification.updatedNotificationType !== 'INCIDENT'" class="image" src="./assets/icons/complete.svg" alt="delay">&nbsp;
                    <div class="notification-details">
                      <div class="heading">{{notification.message}}</div>
                      <div class="sub-heading">{{notification.title}}</div>
                      <div class="time-diff">{{notification.timeDiff}}</div>
                    </div>
                  </div>
                  <div class="line"></div>
                </div>
               </div>
              <div class="see-more">
                <button class="property" mat-stroked-button color="primary" (click)="seeMore()">See more</button>
              </div>
            </div>
          </div>

          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <mat-icon>settings</mat-icon>
              <span>Settings</span>
            </button>
            <button mat-menu-item disabled>
              <mat-icon>security</mat-icon>
              <span>Privacy</span>
            </button>
            <button mat-menu-item (click)="logout()">
              <mat-icon>settings_power</mat-icon>
              <span>Logout</span>
            </button>
          </mat-menu>
        </div>
    </section>
</mat-toolbar>

<!-- <mat-form-field appearance="outline" class="operations-selector">
  <mat-label>Favorite food</mat-label>
  <mat-select>
    <mat-option value="volvo">Volvo</mat-option>
    <mat-option value="saab">Saab</mat-option>
    <mat-option value="mercedes">Mercedes</mat-option>
    <mat-option value="audi">Audi</mat-option>
  </mat-select>
</mat-form-field> -->
