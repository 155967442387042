import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Step} from '@shared/models/step.model';

@Component({
  selector: 'hmt-progress-line',
  templateUrl: './progress-line.component.html',
  styleUrls: ['./progress-line.component.scss']
})
export class ProgressLineComponent implements OnInit {
  @Input() steps: Step[];
  @Input('currentStep') set getCurrentStep(currentStep: string){
    this.currentStep = currentStep;
    const index = this.steps.findIndex(step => step.step === currentStep);
    this.currentStepNo = index + 1;
  }
  currentStepNo: number;
  currentStep: string;
  constructor() { }

  ngOnInit(): void {

  }

}
