import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {AlertDialogType, SnackBarData} from '@shared/models';

@Component({
  selector: 'hmt-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
  matSnackBarTye = AlertDialogType;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData, private matSnackBar: MatSnackBar) { }

  public close(): void {
    this.matSnackBar.dismiss();
  }
}
