import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import {HttpLink} from 'apollo-angular/http';
import {DefaultOptions, InMemoryCache} from '@apollo/client/core';
import {catchError, map} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {GRAPHQL, GRAPHQL_COMMON, INVOICE_GRAPHQL, REPORT_GRAPHQL} from '@configs/api.config';

@Injectable({
  providedIn: 'root'
})
export class GraphqlClientService {
  constructor(
    private httpClient: HttpClient,
    private gqlClient: Apollo,
    private httpLink: HttpLink,
    private store: Store) {
  }

  createLinkWithGQLServer(): void {

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };

    const {token} = this.store.selectSnapshot(store => store.auth);

    const graphql: any = {uri: GRAPHQL};

    if (this.gqlClient) {
      this.gqlClient.removeClient('invoice-endpoint');
      this.gqlClient.removeClient('report-endpoint');
      this.gqlClient.removeClient('gql-common-endpoint');
      this.gqlClient.removeClient();
    }

    this.gqlClient.createDefault({
      headers: {
        Authorization: `Bearer ${token}`
      },
      link: this.httpLink.create(graphql),
      cache: new InMemoryCache({
        addTypename: false
      }),
      defaultOptions
    });

    const invoice: any = {uri: INVOICE_GRAPHQL};

    this.gqlClient.createNamed('invoice-endpoint', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      link: this.httpLink.create(invoice),
      cache: new InMemoryCache({
        addTypename: false
      }),
      defaultOptions
    });

    const report: any = {uri: REPORT_GRAPHQL};

    this.gqlClient.createNamed('report-endpoint', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      link: this.httpLink.create(report),
      cache: new InMemoryCache({
        addTypename: false
      }),
      defaultOptions
    });

    
    const common: any = {uri: GRAPHQL_COMMON};
    this.gqlClient.createNamed('gql-common-endpoint', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      link: this.httpLink.create(common),
      cache: new InMemoryCache({
        addTypename: false
      }),
      defaultOptions
    });
  }

  queryDataFromGQL<Type>(variables: object, query, queryName: string, endpoint?: string): Observable<Type> {
    if (endpoint === 'invoice-endpoint') {
      return this.gqlClient.use('invoice-endpoint')
        .watchQuery<Type>(
          {
            query,
            variables,
            errorPolicy: 'all'
          }
        ).valueChanges
        .pipe(
          map(result => result.data[queryName]),
          catchError(err => {
            console.log(`gql error: ${err}`);
            return err;
          }));
    } else if (endpoint === 'report-endpoint') {
      return this.gqlClient.use('report-endpoint')
        .watchQuery<Type>(
          {
            query,
            variables,
            errorPolicy: 'all'
          }
        ).valueChanges
        .pipe(
          map(result => result.data[queryName]),
          catchError(err => {
            console.log(`gql error: ${err}`);
            return err;
          }));
    } else if (endpoint === 'gql-common-endpoint') {
      return this.gqlClient.use('gql-common-endpoint')
        .watchQuery<Type>(
          {
            query,
            variables,
            errorPolicy: 'all'
          }
        ).valueChanges
        .pipe(
          map(result => {
            if (result.errors && result.errors.length > 0) {
              console.log(result.errors[0].message);
            }
            return result.data[queryName];
          }),
          catchError(err => {
            console.log(`gql error: ${err}`);
            return err;
          }));
    } else {
      return this.gqlClient
        .watchQuery<Type>(
          {
            query,
            variables,
            errorPolicy: 'all'
          }
        ).valueChanges
        .pipe(
          map(result => result.data[queryName]),
          catchError(err => {
            console.log(`gql error: ${err}`);
            return err;
          })
        );
    }
  }

  mutateDataFromGQL<Type>(variables: object, query, queryName: string, endpoint?: string): Observable<Type> {

    if (endpoint === 'invoice-endpoint') {
      return this.gqlClient.use(endpoint)
        .mutate(
          {
            mutation: query,
            variables,
            errorPolicy: 'all'
          }
        )
        .pipe(
          map(result => result.data[queryName]),
          catchError(err => {
            console.log(`gql error: ${err}`);
            return err;
          })
        );
    } else if (endpoint === 'report-endpoint') {
      return this.gqlClient.use('report-endpoint')
        .mutate(
          {
            mutation: query,
            variables,
            errorPolicy: 'all'
          }
        )
        .pipe(
          map(result => result.data[queryName]),
          catchError(err => {
            console.log(`gql error: ${err}`);
            return err;
          })
        );
    } else {
      return this.gqlClient
        .mutate(
          {
            mutation: query,
            variables,
            errorPolicy: 'all'
          }
        )
        .pipe(
          map(result => result.data[queryName]),
          catchError(err => {
            console.log(`gql error: ${err}`);
            return err;
          })
        );
    }
  }
}
