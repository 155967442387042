export const FILE_ACTION_PREFIX = '[File State] ';

export class LoadDocumentsAction {
    static readonly type = `${FILE_ACTION_PREFIX} Load Documents`;
    constructor(public jobRefId: string) { }
}

export class LoadDocumentsOfJpm {
  static readonly type = `${FILE_ACTION_PREFIX} Load Documents for the JPM`;
  constructor(public jpmId: string) {}
}

export class LoadFileURLAction {
    static readonly type = `${FILE_ACTION_PREFIX} Load File Url`;
    constructor(public key: string) {}
}

export class LoadJPMDocumentsAction {
  static readonly type = `${FILE_ACTION_PREFIX} Load JPM Documents`;
  constructor(public orgId: string, public jobRefId: string) { }
}
