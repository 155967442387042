import { EntityMap } from './../../../../shared/models/types/entity-map.type';
import {AddContainer} from '../../models/add-container.model';
import {WizardLocation} from '../../models/locations.model';
import {WIZARD_ACTION_PREFIX} from '../wizard.actions';
import {ServiceViewModel} from '../../models/service-view.model';
import {GatewayLocation} from '@shared/models/gateway-location.model';
import {WorkOrderDefinition} from '@control-tower/models/work-order/work-order-definition.model';
import {ActivityRelationType} from '../../models/enums/activity-relation-type.enum';
import {ContainerTypeAndSize} from '@control-tower/models/jpm/jpm-container-type-and-size.model';
import {GeoCoordinate} from '@shared/models/geo-coordinate';
import {BillingInfo} from "@core/models/job-file/billing-info.model";
import { MoveShipmentOrderData } from '../../models/move-shipment-order.data';
import { BillingIndicator } from '../../models/enums/billing-indicator-type.enum';
import { InitLeg } from '../../models/init-leg.model';
import { AbstractControl, FormControl } from '@angular/forms';
import { VehicleDetails } from '@shared/models/vehicle-details.model';
import { WaypointData } from '../../models/waypoint-data.model';

export const CONTAINER_MANAGEMENT_ACTION_PREFIX = '[Wizard State]';

export class GetContainerWithContainerId {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Duplicate Container`;
  constructor(public containerId: string) {}
}

export class DuplicateContainerFromWizard {
  static readonly type = `${WIZARD_ACTION_PREFIX} Duplicate Container From Wizard`;
  constructor(public containerId: string) {
  }
}

export class AddContainerBulk {
  static readonly type = `${WIZARD_ACTION_PREFIX} Add container Bulk`;
  constructor(public containers: {containerNumber: string, typeAndSize: ContainerTypeAndSize, order: number}[]) {
  }
}

export class AddNewContainerFromWizard {
  static readonly type = `${WIZARD_ACTION_PREFIX} Add New Container From Wizard`;
  constructor(public vehicleData: VehicleDetails) {}
}

export class RemoveContainerFromWizard {
  static readonly type = `${WIZARD_ACTION_PREFIX} Remove Container From Wizard`;
  constructor(public containerId: string) {}
}

export class LoadContainerTypeAndSizes {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Load Container Type and Sizes`;
}

export class UpdateContainer {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Update Container`;
  constructor(public  container: AddContainer) {
  }
}

export class RemoveSpecOrder {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Remove spec order`;
  constructor(public  container: AddContainer) {
  }
}

export class AddSpecOrder {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Add spec order`;
  constructor(public  container: AddContainer) {
  }
}

export class UpdateShipmentOrdersData {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Update Orders Data`;
  constructor(public moveShipmentOrderData: MoveShipmentOrderData) {}
}

export class UpdateContainerByExpressWaysOption {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Update Container with ExpressWay option`;
  constructor(public containerId: string, public useExpressWays: boolean) {}
}

export class UpdateMapDistanceByExpressWaysOption {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Update Map Distance with ExpressWay option`;
  constructor(public containerId: string, public useExpressWays: boolean, public legs: InitLeg[]) {}
}

export class OptimizeOrderMovedContainers {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Optimize order removed containers`;
  constructor(public orgId: string, public jobRefId: string, public fromContainerId: string, public toContainerId: string) {
  }
}

export class PartiallySaveContainerData {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Save Container Data`;
  constructor(public  container: AddContainer) {
  }
}

export class UpdateContainerWaypoints {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} update waypoints`;
  constructor(public container: AddContainer) {
  }
}

export class UndoContainerRoute {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Undo container route`;
  constructor(public containerId: string) {
  }
}

export class LoadHSCodes {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Load HS Codes`;
}


export class SaveContainers {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Save Containers`;
}

export class GenerateLegsFromWizardLocations {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Generate Locations from WizardLocations`;
  constructor(public  locations: WizardLocation[]) {
  }
}

export class SaveInitQuotation {
  static readonly type = `${WIZARD_ACTION_PREFIX} Set Init Container`;
}

export class GetContainerByOrder {
  static readonly type = `${WIZARD_ACTION_PREFIX} Get Container by Order`;
  constructor(public  order: number) {
  }
}

export class SaveContainer {
  static readonly type = `${WIZARD_ACTION_PREFIX} Save Container`;
}

export class LoadFumigators {
  static readonly type = `${WIZARD_ACTION_PREFIX} Load Fumigators`;
}

export class SendForApproval {
  static readonly type = `${WIZARD_ACTION_PREFIX} Send for Approval`;
}

export class AddServiceAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Add Service`;
  constructor(public service: ServiceViewModel, public containerId: string, public index: number) {}
}

export class LoadAlreadySavedRoutes {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Load already saved routes`;
  constructor(public keyword: string) {
  }
}

export class SaveServiceSequenceTemplate {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Save service sequence template`;
  constructor(public containerId: string, public name: string) {
  }
}

export class SaveServiceAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Save Activity`;
  constructor(public containerId: string, public serviceViewModel: ServiceViewModel) { }
}

export class DeleteServiceAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Delete Service`;
  constructor(public containerId: string, public serviceId: string) { }
}

export class ApplyServiceSequenceTemplate{
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Apply service sequence template`;
  constructor(public containerId: string, public templateName: string) {
  }
}

export class RemoveServiceSequenceTemplate{
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Remove service sequence template`;
  constructor(public templateId: string) {
  }
}

export class UpdateServiceSequenceTemplate{
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Update service sequence template`;
  constructor(public containerId: string, public templateId: string, public templateName: string) {
  }
}

export class CalculateRouteDetailsAction {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Calculate route details`;
  constructor(public location: GatewayLocation, public containerId: string, public serviceId: string) { }
}


export class EditServiceAction {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Edit service action`;
  constructor(public containerId: string, public serviceViewModel: ServiceViewModel) {
  }
}

export class ChangeActivityOnEditServiceAction {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Change activity on edit service action`;
  constructor(public activity: WorkOrderDefinition, public newService: boolean, public activityRelationshipType: ActivityRelationType,
              public billingActivated?: boolean, public billingIndicatorType?: BillingIndicator, public searchValue?: string, public containerId?: string, public serviceId?: string){}
}

export class UpdateCustomsChannelAndAddDryServiceAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Update Channel And Add Dry Service`;
  constructor(public activityId: string, public containerId: string, public index: number, public customsChannel: string) {}
}

export class CleanSelectedServiceAction {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Clean selected service action`;
}

export class UpdateServiceTimeAction {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Update service's time action`;
  constructor(public containerId: string , public serviceId: string, public type: string, public duration: number) {
  }
}
export class UseExpressWayAction {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Use Express way action`;
  constructor(public containerId: string, public useTolls: boolean , public legIndex: number) {
  }
}

export class RemoveAllContainers {
  static readonly type = `${CONTAINER_MANAGEMENT_ACTION_PREFIX} Remove All Containers`;
}

export class UpdateCustomsChannelAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Update Customs Channel`;
  constructor( public containerId: string, public customsChannel: string) {}
}

export class UpdateChannelAndDeleteServiceAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Update Channel And Delete Service`;
  constructor(public containerId: string, public serviceId: string, public customsChannel: string) { }
}

export class SaveWaypoints {
  static readonly type = `${WIZARD_ACTION_PREFIX} Save waypoints in state`;
  constructor(public containerId: string, public undoStack: WaypointData[], public redoStack: WaypointData[]) {}
}

export class ResetContainerManagementState {
  static readonly type = `${WIZARD_ACTION_PREFIX} Reset container management state`;
}

export class SetBillingAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Set billing start and end`;
  constructor(public containerId: string, public serviceId: string, public billingInfo: BillingInfo) { }
}

export class SetFilledDataOnDialogClose {
  static readonly type = `${WIZARD_ACTION_PREFIX} Save filled data on dialog close`;
  constructor(public container: AddContainer) { }
}

export class AddWaypoint {
  static readonly type = `${WIZARD_ACTION_PREFIX} Add waypoint`;
  constructor(public containerId: string, public waypoint: WaypointData) {}
}

export class UndoWaypoint {
  static readonly type = `${WIZARD_ACTION_PREFIX} Undo waypoint`;
  constructor(public containerId: string) {}
}

export class RedoWaypoint {
  static readonly type = `${WIZARD_ACTION_PREFIX} Redo waypoint`;
  constructor(public containerId: string) {}
}
