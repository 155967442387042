export enum LocationType {
  WAREHOUSE = 'WAREHOUSE',
  PORT = 'PORT',
  CUSTOMS = 'CUSTOMS',
  BOI = 'BOI',
  CIA = 'CIA',
  CONTAINER_YARD = 'CONTAINER_YARD',
  EXTERNAL = 'EXTERNAL',
  THIRD_PARTY = 'THIRD_PARTY',
  GATE = 'GATE',
  FUMIGATION_CENTER = 'FUMIGATION_CENTER',
  FACILITY = 'FACILITY',
  BUILDING = 'BUILDING',
  OFFICE = 'OFFICE',
  GARAGE = 'GARAGE',
  CARGO_VERIFICATION_CENTER = 'CARGO_VERIFICATION_CENTER',
  CUSTOMS_VERIFICATION_CENTER = 'CUSTOMS_VERIFICATION_CENTER',
  REST_AREA = 'REST_AREA',
  TERMINAL = 'TERMINAL',
  YARD = 'YARD',
  VEHICLE_PARK = 'VEHICLE_PARK',
  FEEDER_BIRTH = 'FEEDER_BIRTH',
  CONTAINER_BIRTH = 'CONTAINER_BIRTH',
  BAY = 'BAY',
  PUMPING_STATION = 'PUMPING_STATION',
  FUMIGATION_LOCATION = 'FUMIGATION_LOCATION',
  EXPORT_PROCESSING_ZONE = 'EXPORT_PROCESSING_ZONE'
}
