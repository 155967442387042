import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from '@configs/api.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { File, FileRequest } from '../models/files';
import {EntityMap} from '@shared/models/types';
import {VersionedFile} from '@control-tower/models/files/versionedFile.model';
import {FileMetadata} from '@control-tower/models/files/file-metadata.model';
import {AttachmentModel} from '../../fuel/models/attachment.model';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private readonly http: HttpClient) {}

  getDocuments(jobFileId: string): Observable<any> {
    const url = API.s3.getDocuments.format([jobFileId]);
    return this.http.get<any>(url);
  }

  getDocumentLink(fileRequest: FileRequest): Observable<any> {
    const url = API.youAreHere.getPDF.getDocumentLink.format([]);
    return this.http.post(url, fileRequest, {responseType: 'text'});
  }

  getJPMDocumentsMetaData(orgId: string, jobRefId: string): Observable<EntityMap<string, FileMetadata[]>> {
    const url = API.jpm.getJPMDocuments.format([orgId, jobRefId]);
    return this.http.get<EntityMap<string, FileMetadata[]>>(url);
  }

  fetchDocumentUrl(documentId: string): Observable<AttachmentModel> {
    const url = API.fileHandelar.imageUrl.format([documentId]);
    return this.http.get<AttachmentModel>(url);
  }
}
