import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'hmt-container-number',
  templateUrl: './container-number.component.html',
  styleUrls: ['./container-number.component.scss']
})
export class ContainerNumberComponent implements OnInit {

  form: FormGroup;
  retypeForm: FormGroup;
  onFormChange: Subscription;
  onRetypeFormChange: Subscription;
  formValuesList: string[];
  retypeFormValuesList: string[];
  @Output() validateContainerNumber: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('input11') lastInputRefOfMainForm: ElementRef;
  @ViewChild('reInput11') lastInputRefOfRetypeFormForm: ElementRef;

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.createRetypeForm();
    this.onRetypeFormChange = this.retypeForm.valueChanges
      .subscribe((value) => {
        if (this.retypeForm.valid) {
          if (this.matchingWithRetypeForm()) {
            const containerNumber = this.convertFormValueArrayToString(this.getValuesAsArray(this.form.value));
            this.validateContainerNumber.emit(containerNumber);
          }
        }
      });
  }

  createForm() {
    this.form = this.formBuilder.group({
      ownerCode: new FormGroup({
        ownerCodeCharOne: this.createFormControl('[A-Z]+'),
        ownerCodeCharTwo: this.createFormControl('[A-Z]+'),
        ownerCodeCharThree: this.createFormControl('[A-Z]+')
      }),
      category: this.createFormControl('[UJZ]+'),
      serialNumber: new FormGroup({
        digitOne: this.createFormControl('[0-9]'),
        digitTwo: this.createFormControl('[0-9]'),
        digitThree: this.createFormControl('[0-9]'),
        digitFour: this.createFormControl('[0-9]'),
        digitFive: this.createFormControl('[0-9]'),
        digitSix: this.createFormControl('[0-9]'),
      }),
      checkDigit: this.createFormControl('[0-9]')
    });
  }

  createRetypeForm() {
    this.retypeForm = this.formBuilder.group({
      ownerCode: new FormGroup({
        ownerCodeCharOne: this.createFormControl(),
        ownerCodeCharTwo: this.createFormControl(),
        ownerCodeCharThree: this.createFormControl(),
      }),
      category: this.createFormControl(),
      serialNumber: new FormGroup({
        digitOne: this.createFormControl(),
        digitTwo: this.createFormControl(),
        digitThree: this.createFormControl(),
        digitFour: this.createFormControl(),
        digitFive: this.createFormControl(),
        digitSix: this.createFormControl(),
      }),
      checkDigit: this.createFormControl()
    });
  }

  createFormControl(pattern?: string): FormControl {
    return pattern ? new FormControl('', [Validators.required, Validators.pattern(pattern)])
      : new FormControl('', [Validators.required]);
  }

  matchingWithRetypeForm(): boolean {
    if (this.form.valid) {
      const formValues = this.getValuesAsArray(this.form.value);
      const reTypeformValues = this.getValuesAsArray(this.retypeForm.value);
      const misMatch = formValues.find(((value, index) => value !== reTypeformValues[index]));
      return !misMatch;
    }
  }

  getValuesAsArray(values): string[] {
    const arrayOfFormValues = [];
    arrayOfFormValues.push(values.ownerCode.ownerCodeCharOne);
    arrayOfFormValues.push(values.ownerCode.ownerCodeCharTwo);
    arrayOfFormValues.push(values.ownerCode.ownerCodeCharThree);
    arrayOfFormValues.push(values.category);
    arrayOfFormValues.push(values.serialNumber.digitOne);
    arrayOfFormValues.push(values.serialNumber.digitTwo);
    arrayOfFormValues.push(values.serialNumber.digitThree);
    arrayOfFormValues.push(values.serialNumber.digitFour);
    arrayOfFormValues.push(values.serialNumber.digitFive);
    arrayOfFormValues.push(values.serialNumber.digitSix);
    arrayOfFormValues.push(values.checkDigit);
    return arrayOfFormValues;
  }

  convertFormValueArrayToString(values: string[]): string {
    let containerNumber = '';
    values.forEach((val) => {
      containerNumber = containerNumber + val;
    });
    return containerNumber;
  }

  isReTypeFormValid() {
    return this.retypeForm.valid;
  }

  isFormInValid() {
    if (this.form.dirty) {
      return this.form.invalid;
    }
  }

  onPasteFirstInputOfMainForm(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    if (pastedText.length !== 11){
      return;
    }
    this.form.get('ownerCode').get('ownerCodeCharOne').patchValue(pastedText[0]);
    this.form.get('ownerCode').get('ownerCodeCharTwo').patchValue(pastedText[1]);
    this.form.get('ownerCode').get('ownerCodeCharThree').patchValue(pastedText[2]);
    this.form.get('category').patchValue(pastedText[3]);
    this.form.get('serialNumber').get('digitOne').patchValue(pastedText[4]);
    this.form.get('serialNumber').get('digitTwo').patchValue(pastedText[5]);
    this.form.get('serialNumber').get('digitThree').patchValue(pastedText[6]);
    this.form.get('serialNumber').get('digitFour').patchValue(pastedText[7]);
    this.form.get('serialNumber').get('digitFive').patchValue(pastedText[8]);
    this.form.get('serialNumber').get('digitSix').patchValue(pastedText[9]);
    this.form.get('checkDigit').patchValue(pastedText[10]);
    this.lastInputRefOfMainForm.nativeElement.focus();
  }

  onPasteFirstInputOfRetypeForm(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    if (pastedText.length !== 11){
      return;
    }
    this.retypeForm.get('ownerCode').get('ownerCodeCharOne').patchValue(pastedText[0]);
    this.retypeForm.get('ownerCode').get('ownerCodeCharTwo').patchValue(pastedText[1]);
    this.retypeForm.get('ownerCode').get('ownerCodeCharThree').patchValue(pastedText[2]);
    this.retypeForm.get('category').patchValue(pastedText[3]);
    this.retypeForm.get('serialNumber').get('digitOne').patchValue(pastedText[4]);
    this.retypeForm.get('serialNumber').get('digitTwo').patchValue(pastedText[5]);
    this.retypeForm.get('serialNumber').get('digitThree').patchValue(pastedText[6]);
    this.retypeForm.get('serialNumber').get('digitFour').patchValue(pastedText[7]);
    this.retypeForm.get('serialNumber').get('digitFive').patchValue(pastedText[8]);
    this.retypeForm.get('serialNumber').get('digitSix').patchValue(pastedText[9]);
    this.retypeForm.get('checkDigit').patchValue(pastedText[10]);
    this.lastInputRefOfRetypeFormForm.nativeElement.focus();
  }
}


