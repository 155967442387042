export enum JobType {
  IMPORT= 'IMPORT',
  EXPORT= 'EXPORT',
  DOMESTIC = 'DOMESTIC',
  LOCAL_HAULAGE = 'LOCAL HAULAGE',
  Container_Haulage = 'Container Haulage',
  Container_Repositioning  = 'Container Repositioning ',
  Container_Removal = 'Container Removal',
  Container_Relocation = 'Container Relocation',
  Full_Truck_Load = 'Full Truck Load',
  FIELD_SERVICES = 'FIELD SERVICES',
}
