import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'hmt-contact-numbers',
  templateUrl: './contact-numbers.component.html',
  styleUrls: ['./contact-numbers.component.scss']
})
export class ContactNumbersComponent implements OnInit {
  formGroup: FormGroup;
  @Input('formGroup') set setFormGroup(formGroup: FormGroup) {
    this.formGroup = formGroup;
  }
  @Output() addContactNumber = new EventEmitter();
  @Output() addFaxNumber = new EventEmitter();
  @Output() removeContactNumber = new EventEmitter<string>();
  @Output() removeFaxNumber = new EventEmitter<string>();
  constructor(
  ) { }

  ngOnInit(): void {
  }

  public addContactNumberFormGroup() {
    this.addContactNumber.emit();
  }

  public addFaxNumberFormGroup() {
    this.addFaxNumber.emit();
  }

  public removeContactNumberInFormGroup(i) {
    this.removeContactNumber.emit(i);
  }

  public removeFaxNumberInFormGroup(i) {
    this.removeFaxNumber.emit(i);
  }
}
