export enum FileTypeExtension {
  APPLICATION_PDF = 'application/pdf',
  APPLICATION_OCTET_STREAM = 'application/octet-stream',
  APPLICATION_MSWORD = 'application/msword',
  IMAGE_PNG = 'image/png',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_JPG = 'image/jpg',
  APPLICATION_WORD = 'application/doc',
  APPLICATION_DOX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  URL = 'url'
}
