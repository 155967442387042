import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject, interval } from 'rxjs';
import { map, mergeMap, takeUntil, take } from 'rxjs/operators';
import { LatLng } from '@shared/models/enums/lat-lng.model';
import { GeoCoordinate } from '@shared/models/geo-coordinate';
import { Point } from '@shared/models/point';
import { Polygon } from '@shared/models/polygon';
import { LineString } from '@shared/models/line-string';
import { GeometryType } from '@shared/models/enums/geometry-type';
import { EntityMap } from '@shared/models/types';
import { GeoFence } from '@shared/models/geo-fence.model';
import { StdioNull } from 'child_process';

declare var google: any;

@Component({
  selector: 'hmt-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {


  fullScreen: boolean = false;
  @ViewChild('mapContainer', { static: false }) gMap: ElementRef;
  unsubscribe: Subject<void> = new Subject<void>();
  map: google.maps.Map;
  idleListener;
  // TODO: decide and change the center of the map
  center: google.maps.LatLng = new google.maps.LatLng(8.056617, 80.425532);
  // center: google.maps.LatLng = new google.maps.LatLng(6.9017532, 79.8612056 );
  defaultOptions: google.maps.MapOptions = {
    minZoom: 0,
    zoom: 2,
    maxZoom: 10,
    center: this.center,
    fullscreenControl: true,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {
      position: google.maps.ControlPosition.TOP_LEFT
    },

    fullscreenControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT
    },
    zoomControl: false,
    mapTypeControl: true,
    streetViewControl: false,
    // styles: [
    //   {
    //     elementType: 'geometry',
    //     stylers: [
    //       {
    //         color: '#f5f5f5'
    //       }
    //     ]
    //   },
    //   {
    //     elementType: 'labels.icon',
    //     stylers: [
    //       {
    //         visibility: 'off'
    //       }
    //     ]
    //   },
    //   {
    //     elementType: 'labels.text.fill',
    //     stylers: [
    //       {
    //         color: '#616161'
    //       }
    //     ]
    //   },
    //   {
    //     elementType: 'labels.text.stroke',
    //     stylers: [
    //       {
    //         color: '#f5f5f5'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'administrative.land_parcel',
    //     elementType: 'labels.text.fill',
    //     stylers: [
    //       {
    //         color: '#bdbdbd'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'landscape.man_made',
    //     elementType: 'geometry.fill',
    //     stylers: [
    //       {
    //         color: '#edeff1'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'landscape.natural.landcover',
    //     elementType: 'geometry.fill',
    //     stylers: [
    //       {
    //         color: '#fcfcfc'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'poi',
    //     elementType: 'geometry',
    //     stylers: [
    //       {
    //         color: '#eeeeee'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'poi',
    //     elementType: 'labels.text',
    //     stylers: [
    //       {
    //         visibility: 'off'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'poi',
    //     elementType: 'labels.text.fill',
    //     stylers: [
    //       {
    //         color: '#757575'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'poi.business',
    //     stylers: [
    //       {
    //         visibility: 'off'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'poi.park',
    //     elementType: 'geometry',
    //     stylers: [
    //       {
    //         color: '#e5e5e5'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'poi.park',
    //     elementType: 'labels.text.fill',
    //     stylers: [
    //       {
    //         color: '#9e9e9e'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'road',
    //     elementType: 'geometry',
    //     stylers: [
    //       {
    //         color: '#ffffff'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'road',
    //     elementType: 'geometry.fill',
    //     stylers: [
    //       {
    //         color: '#fcfcfc'
    //       },
    //       {
    //         weight: 2
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'road',
    //     elementType: 'labels.icon',
    //     stylers: [
    //       {
    //         visibility: 'off'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'road.arterial',
    //     elementType: 'labels.text.fill',
    //     stylers: [
    //       {
    //         color: '#757575'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'road.highway',
    //     elementType: 'geometry',
    //     stylers: [
    //       {
    //         color: '#dadada'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'road.highway',
    //     elementType: 'geometry.fill',
    //     stylers: [
    //       {
    //         color: '#ffffff'
    //       },
    //       {
    //         lightness: 45
    //       },
    //       {
    //         weight: 3
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'road.highway',
    //     elementType: 'labels.text.fill',
    //     stylers: [
    //       {
    //         color: '#616161'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'road.local',
    //     elementType: 'geometry.fill',
    //     stylers: [
    //       {
    //         color: '#fbfbfb'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'road.local',
    //     elementType: 'labels.text.fill',
    //     stylers: [
    //       {
    //         color: '#9e9e9e'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'transit',
    //     stylers: [
    //       {
    //         visibility: 'off'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'transit.line',
    //     elementType: 'geometry',
    //     stylers: [
    //       {
    //         color: '#e5e5e5'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'transit.station',
    //     elementType: 'geometry',
    //     stylers: [
    //       {
    //         color: '#eeeeee'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'water',
    //     elementType: 'geometry',
    //     stylers: [
    //       {
    //         color: '#c9c9c9'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'water',
    //     elementType: 'geometry.fill',
    //     stylers: [
    //       {
    //         color: '#c4cee0'
    //       }
    //     ]
    //   },
    //   {
    //     featureType: 'water',
    //     elementType: 'labels.text.fill',
    //     stylers: [
    //       {
    //         color: '#9e9e9e'
    //       }
    //     ]
    //   }
    // ]
  };
  dashLineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 4
  };
  markers: google.maps.Marker[] = [];
  unplannedStops: { id: string, marker: google.maps.Marker }[] = [];
    routes: Array<{ origin: LatLng | string, destination: LatLng, waypoints: google.maps.DirectionsWaypoint[], useTolls: boolean }> = [];
  drawingManager: google.maps.drawing.DrawingManager;

  drawnGeoFence: google.maps.Polygon | google.maps.Polyline;
  drawnGeoFencesJobMap: google.maps.Polygon[] = [];
  parentGeoFence: google.maps.Polygon | google.maps.Polyline;
  directionRenderers: EntityMap<number, google.maps.DirectionsRenderer> = {};
  private directionsObjects: { [key: number]: any } = {};
  isDragging = false;

  @Input() suggestedPath: any;
  @Input() isGeoFenceSpeedLimitMap: boolean = false;
  @Output() boundChangeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectLocation = new EventEmitter<string>();
  @Output() place = new EventEmitter<Point>();
  @Output() geoFence = new EventEmitter<Polygon | LineString | GeoFence>();
  @Output() placeDetails = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.initMap()
    this.listenToFullScreen();
  }

  initMap(mapOptions = this.defaultOptions, minZoom?: number, maxZoom?: number): void {
    if (minZoom != null) {
      mapOptions.minZoom = minZoom;
    }
    if (maxZoom != null) {
      mapOptions.maxZoom = maxZoom;
    }
    this.map = new google.maps.Map(this.gMap.nativeElement, mapOptions);
    this.map.addListener('bounds_changed', () => {
      this.boundChangeEvent.emit();
    });
  }

  setMarkerBounds(): void {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach(mkr => {
      bounds.extend(mkr.getPosition());
    });
    this.map.fitBounds(bounds);
    console.log('marker bounds updated');
  }

  setBounds(coordinates: LatLng[]) {
    const bounds = new google.maps.LatLngBounds();
    coordinates.forEach(mkr => {
      bounds.extend(mkr);
    });
    this.map.fitBounds(bounds);
  }

  listenToMap(coordinates: LatLng[]) {
    if (this.idleListener) {
      google.maps.event.removeListener(this.idleListener);
    }
    this.idleListener = this.map.addListener('idle', () => {
      google.maps.event.removeListener(this.idleListener);
      this.setBounds(coordinates);
      google.maps.event.removeListener(this.idleListener);
    });
  }

  removeControl(position: google.maps.ControlPosition): void {
    if (this.map && this.map.controls.length > 0) {
      this.map.controls[position]?.pop();
    }
  }

  addControl(position: google.maps.ControlPosition, element: Node): void {
    this.map.controls[position].push(element);
  }

  addMapClickListener(addListener = false) {
    if (addListener) {
      google.maps.event.addListener(this.map, 'click', event => {
        this.removeMarkers();
        const marker = new google.maps.Marker({
          position: event.latLng,
          map: this.map
        });
        this.markers.push(marker);
        this.markers.forEach(mrkr => {
          mrkr.setMap(this.map);
        });
        this.place.emit({
          type: 'Point',
          coordinates: [event.latLng.lng(), event.latLng.lat()]
        });
      });
    }
  }

  addMarker(markerOption: google.maps.MarkerOptions, addListener = true): google.maps.Marker {
    const marker = new google.maps.Marker(markerOption);
    marker.setMap(this.map);
    this.markers.push(marker);
    if (addListener) {
      marker.addListener('click', () => {
        this.onSelectLocation(marker.getLabel());
      });
    }
    return marker;
  }

  addStopMarker(markerData: { id: string, marker: google.maps.MarkerOptions }): google.maps.Marker {
    const infowindow = new google.maps.InfoWindow({
      content: markerData.marker.title,
    });
    const marker = new google.maps.Marker(markerData.marker);
    marker.setMap(this.map);
    infowindow.open(this.map, marker);
    this.unplannedStops.push({ id: markerData.id, marker });
    return marker;
  }

  onSelectLocation(label) {
    this.selectLocation.emit(label.text);
  }

  addMarkers(markerOptions: google.maps.MarkerOptions[], addListener = true): void {
    markerOptions?.forEach(m => this.addMarker(m, addListener));
  }

  addUnplannedStopMarkers(markers: { id: string, marker: google.maps.MarkerOptions }[]): void {
    markers?.forEach(m => this.addStopMarker(m));
  }

  addUnplannedStopMarker(markerOption: google.maps.MarkerOptions, id: string): void {
    const marker = new google.maps.Marker(markerOption);
    console.log('here');
    marker.setMap(this.map);
    marker.id = id;
    this.unplannedStops.push({
      id,
      marker
    });
  }

  removeMarker(index: number): void {
    const removedMarkers: google.maps.Marker[] = this.markers.splice(index, 1);
    removedMarkers.forEach(m => m.setMap(null));
  }

  removeMarkers(): void {
    this.markers.forEach(m => m.setMap(null));
    this.markers = [];
    this.map.data.forEach(feature => this.map.data.remove(feature));
  }

  setCenter(coordinate: google.maps.LatLng, zoom = 12): void {
    this.map.setZoom(zoom);
    this.map.setCenter(coordinate);
  }

  removeUnplannedStopMarkers(): void {
    this.unplannedStops.forEach(m => m.marker.setMap(null));
    this.unplannedStops = [];
  }

  removeUnplannedStopMarker(markerId: string) {
    const markerToRemove = this.unplannedStops.find(marker => marker.id === markerId);
    if (markerToRemove) {
      const markerIndex: number = this.unplannedStops.findIndex(ups => ups.id === markerId);
      if (markerIndex !== undefined) {
        this.unplannedStops[markerIndex].marker.setMap(null);
        this.unplannedStops.splice(markerIndex, 1);
      }
    }
  }

  drawPaths(routes: {
    origin: LatLng | string,
    destination: LatLng,
    waypoints: google.maps.DirectionsWaypoint[],
    useTolls: boolean,
    existingRoute?: string
  }[],
    directionRendererOptions?: google.maps.DirectionsRendererOptions
  ): Observable<{ encodedPath: string, routeIndex: number, waypoints: GeoCoordinate[] }> {
    this.routes = routes;
    return interval(300)
      .pipe(
        take(routes.length),
        mergeMap((routeIndex: number) => {
          const route = routes[routeIndex];
          return this.drawPath(route.origin, route.destination, route.waypoints, route.useTolls, routeIndex, directionRendererOptions, true, route?.existingRoute);
        }),
        map((response: {
          result: any,
          status: google.maps.DirectionsStatus,
          routeIndex: number
        }) => {
          return {
            encodedPath: response.result.routes[0].overview_polyline,
            routeIndex: response.routeIndex,
            waypoints: response.result?.request?.waypoints
              ?.map(waypoint => ({ lat: waypoint?.location?.lat(), lng: waypoint?.location?.lng() }))
          };
        }),
      );
  }

  // TODO: convert waypoint model to our own one here and parse it to a google DirectionsWaypoint
  drawPath(origin: LatLng | string, destination: LatLng, waypoints: google.maps.DirectionsWaypoint[],
    useTolls = false,
    locationIndex?: number, directionsRendererOptions?: google.maps.DirectionsRendererOptions, addEventListener = true, existingRoute?: string)
    : Observable<{
      result: google.maps.DirectionsResult,
      status: google.maps.DirectionsStatus,
      routeIndex: number
    }> {
    return new Observable(observer => {
      const directionsService = new google.maps.DirectionsService();
      let request: google.maps.DirectionsRequest;
      request = {
        origin,
        destination,
        travelMode: google.maps.TravelMode.DRIVING,
        optimizeWaypoints: false,
        waypoints,
        avoidTolls: !useTolls,
        avoidFerries: true,
      };
      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          this.directionRenderers[locationIndex] = new google.maps.DirectionsRenderer({
            suppressMarkers: directionsRendererOptions?.suppressMarkers ?? false,
            preserveViewport: true,
            draggable: directionsRendererOptions?.draggable ?? true,
            markerOptions: directionsRendererOptions?.markerOptions,
            map: this.map,
          });
          // add listner to listen to adding waypoints by dragging
          if (this.directionRenderers[locationIndex]) {
            this.directionRenderers[locationIndex].setDirections(result);
            const leg = result.routes[0].legs[0];
            new google.maps.Marker({
              position: leg.start_location,
              icon: {
                url: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1" height="1"></svg>'
              },
              map: this.map
            });
            new google.maps.Marker({
              position: leg.end_location,
              icon: {
                url: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1" height="1"></svg>'
              },
              map: this.map
            });
            if (addEventListener) {
              this.directionRenderers[locationIndex].addListener('directions_changed', () => {
                this.isDragging = true;
                this.directionRenderers[locationIndex].setOptions({
                  draggable: false,
                })
                this.directionsObjects[locationIndex] = this.directionRenderers[locationIndex].getDirections();
                if (this.directionsObjects[locationIndex]) {
                  // reset waypoints before adding because drag delivers all the added waypoints
                  this.routes[locationIndex].waypoints = [];
                  this.directionsObjects[locationIndex].request?.waypoints?.forEach(waypoint => {
                    const latLngPoint = {
                      lat: waypoint?.location?.lat(),
                      lng: waypoint?.location?.lng()
                    };
                    if (this.routes[locationIndex]) {
                      this.routes[locationIndex].waypoints?.push(new google.maps.LatLng(latLngPoint.lat, latLngPoint.lng));
                    }
                  });
                  observer.next({ result: this.directionsObjects[locationIndex], status: null, routeIndex: locationIndex });
                }
              });
            }
          }
        } else {
          observer.error({ result, status });
        }

        if (!addEventListener) {
          observer.complete();
        }
      });
    });
  }

  /**
   * @desc generate encoded path for given LatLng Object list
   * @return retur the generated encoded path string
   * @param path list of google LatLng objects
   */
  getEncodedPath(path: google.maps.LatLng[] = []): string {
    const poly = new google.maps.Polyline({
      map: this.map,
    });

    // const path: google.maps.LatLng[] = [];
    // this.routes.forEach(route => {
    //   path.push(new google.maps.LatLng(route.origin as LatLng));
    // });
    poly.setPath(path);
    // Update the text field to display the polyline encodings
    return google.maps.geometry.encoding.encodePath(path);
  }

  drawEncodedPath(encodedPath: string, strokeColor = '#2F80ED', strokeWeight = 3, strokeOpacity = 1, zIndex = 1) {
    const plannedPath = new google.maps.Polyline({
      path: google.maps.geometry.encoding.decodePath(encodedPath),
      geodesic: true,
      strokeColor,
      strokeOpacity,
      strokeWeight,
      zIndex,
      map: this.map
    });
    return plannedPath;
  }

  /**
   * @description Draw a polyline with the given set of coordinates
   * @param path list of LatLng
   * @param strokeColor color of the line
   * @param strokeWeight weight of the line
   * @param strokeOpacity opacity of the line
   */
  drawPolyline(path: google.maps.LatLng[] = [], strokeColor = '#2F80ED', strokeWeight = 3, strokeOpacity = 1) {
    const polyPath = new google.maps.Polyline({
      path,
      geodesic: true,
      strokeColor,
      strokeOpacity,
      strokeWeight,
      map: this.map,
      zIndex: 1,
    });
  }

  getRotationAngle(origin: google.maps.LatLng, destination: google.maps.LatLng): number {
    return google.maps.geometry.spherical.computeHeading(origin, destination);
  }

  mergeEncodedPolylinePaths(encodedPolyLines: string[]): string {
    const decodedCoordinates = encodedPolyLines.reduce((decodedLatLngArray: google.maps.LatLng[], path: string) => {
      return [...decodedLatLngArray, ...google.maps.geometry.encoding.decodePath(path)];
    }, []);

    return google.maps.geometry.encoding.encodePath(decodedCoordinates);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    google?.maps?.event?.clearListeners(this.map, 'bounds_changed');
    if (this.idleListener) {
      google?.maps?.event?.clearListeners(this.idleListener);
    }
  }

  changeMarkerIcon(index) {
    const icon = {
      url: `./assets/icons/circle.svg`,
      scaledSize: new google.maps.Size(30, 30),
      labelOrigin: new google.maps.Point(15, 15),
    };

    const selectedIcon = {
      url: `./assets/icons/rec.svg`,
      scaledSize: new google.maps.Size(40, 40),
      labelOrigin: new google.maps.Point(20, 20),
    };
    this.markers.forEach(marker => {
      marker.setIcon(icon);
    });
    this.markers[+index].setIcon(selectedIcon);
  }

  getPlaces(address: HTMLInputElement) {
    const marker = this.isGeoFenceSpeedLimitMap ? null : this.addMarker({});
    address.addEventListener('input', () => {
      const input = address.value.trim();
      const coordinatePattern = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;

      if (coordinatePattern.test(input)) {
        const [latitude, longitude] = input.split(',').map(parseFloat);

        const place: google.maps.places.PlaceResult = {
          name: 'Coordinate',
          geometry: {
            location: new google.maps.LatLng(latitude, longitude),
            viewport: new google.maps.LatLngBounds(
              new google.maps.LatLng(latitude, longitude),
              new google.maps.LatLng(latitude, longitude)
            ),
          },
          formatted_address: input,
          address_components: [],
        };
        if (!place?.geometry?.location) {
          return;
        }
        (place.geometry.viewport) ? this.map.fitBounds(place.geometry.viewport) : this.map.setCenter(place.geometry.location);
        if (!this.isGeoFenceSpeedLimitMap) {
          marker.setPosition(place.geometry.location);
        }

        this.place.emit({
          // @ts-ignore
          type: 'Point',
          // @ts-ignore
          coordinates: [place.geometry.location.lng(), place.geometry.location.lat()]
        });

        this.placeDetails.emit({
          address: place.formatted_address,
          addressComponents: place.address_components
        });
      }
    });
    const autocomplete = new google.maps.places.Autocomplete(address,
      {
        types: ['geocode', 'establishment'],
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place: google.maps.places.PlaceResult = autocomplete.getPlace();
      if (!place?.geometry?.location) {
        return;
      }
      const feature = new google.maps.Data.Feature({
        geometry: place.geometry.location
      });
      this.map.data.add(feature);
      (place.geometry.viewport) ? this.map.fitBounds(place.geometry.viewport) : this.map.setCenter(place.geometry.location);
      if (!this.isGeoFenceSpeedLimitMap) {
        marker.setPosition(place.geometry.location);
      }

      this.map.data.toGeoJson((res) => {
        this.place.emit({
          // @ts-ignore
          type: res?.features[0]?.geometry?.type,
          // @ts-ignore
          coordinates: res?.features[0]?.geometry?.coordinates
        });
      });

      this.placeDetails.emit({
        address: place.formatted_address,
        addressComponents: place.address_components,
        phone:place?.formatted_phone_number ?? "",
        name: place?.name ?? ""
      });
    });
    this.map.data.forEach(feature => this.map.data.remove(feature));
  }

  drawGeoFence(searchArea: Point) {
    const pointerLatLng: google.maps.LatLng = new google.maps.LatLng(searchArea.coordinates[1], searchArea.coordinates[0]);
    this.map.setCenter(pointerLatLng);
    const marker = this.addMarker({});
    marker.setPosition(pointerLatLng);
    this.overlayCompleteListener();

    this.map.data.forEach(feature => this.map.data.remove(feature));
  }

  overlayCompleteListener() {
    if (this.isGeoFenceSpeedLimitMap) {
      this.geoFenceSpeedLimitMapOverlayCompleteListener();
      return;
    }
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      const polygonConfig: google.maps.PolygonOptions = {
        editable: true,
        paths: event.overlay.getPath().getArray().flat().map(res => ({ lat: res.lat(), lng: res.lng() }))
      };
      event.overlay.setMap(null);
      const path = new google.maps.Polygon(polygonConfig);
      this.drawnGeoFence?.setMap(null);
      this.drawnGeoFence = path;
      this.drawnGeoFence?.setMap(this.map);
      let coordinates = event.overlay.getPath().getArray().map(res => ([res.lng(), res.lat()]));
      if (event.type === 'polygon') {
        coordinates.push(coordinates[0]);
        coordinates = [coordinates];
      }
      const type = (event.type === 'polyline') ? GeometryType.LINESTRING : GeometryType.POLYGON;
      this.geoFence.emit({
        type,
        coordinates
      });
      google.maps.event.addListener(path.getPath(), 'insert_at', (editEvent) => {
        const editedCoordinates = path.getPath().getArray().map(res => ([res.lng(), res.lat()]));
        this.geoFence.emit({
          type: GeometryType.POLYGON,
          coordinates: [editedCoordinates]
        });
      });

      google.maps.event.addListener(path.getPath(), 'set_at', (editEvent) => {
        const editedCoordinates = path.getPath().getArray().map(res => ([res.lng(), res.lat()]));
        this.geoFence.emit({
          type: GeometryType.POLYGON,
          coordinates: [editedCoordinates]
        });
      });
    });
  }

  geoFenceSpeedLimitMapOverlayCompleteListener() {
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      let type, coordinates, radius, geoFenceConfig, geoFence;
      switch (event.type) {
        case 'polygon':
          geoFenceConfig = {
            editable: true,
            paths: event.overlay.getPath().getArray().flat().map(res => ({ lat: res.lat(), lng: res.lng() }))
          };
          geoFence = new google.maps.Polygon(geoFenceConfig);
          type = GeometryType.POLYGON;
          coordinates = event.overlay.getPath().getArray().map(res => ([res.lng(), res.lat()]));
          coordinates.push(coordinates[0]);
          coordinates = [coordinates];
          break;
        case 'polyline':
          geoFenceConfig = {
            editable: true,
            path: event.overlay.getPath().getArray().flat().map(res => ({ lat: res.lat(), lng: res.lng() }))
          };
          geoFence = new google.maps.Polyline(geoFenceConfig);
          type = GeometryType.LINESTRING;
          coordinates = event.overlay.getPath().getArray().map(res => ([res.lng(), res.lat()]));
          coordinates = [coordinates];
          break;
        case 'circle':
          geoFenceConfig = {
            editable: true,
            center: event.overlay.getCenter(),
            radius: event.overlay.getRadius()
          };
          geoFence = new google.maps.Circle(geoFenceConfig);
          type = GeometryType.CIRCLE;
          //[[[lat, lng]]] -> center
          coordinates = [[[event.overlay.getCenter().lng(), event.overlay.getCenter().lat()]]];
          radius = event.overlay.getRadius();
          break;
        case 'rectangle':
          geoFenceConfig = {
            editable: true,
            bounds: event.overlay.getBounds()
          };
          geoFence = new google.maps.Rectangle(geoFenceConfig);
          type = GeometryType.RECTANGLE;
          coordinates = event.overlay.getBounds().toJSON();
          // [[[north, south, east, west]]]
          coordinates = [
            [
              [coordinates.north, coordinates.south, coordinates.east, coordinates.west],
            ]
          ];
          break;
        case 'marker':
          geoFenceConfig = {
            editable: true,
            position: event.overlay.getPosition()
          };
          geoFence = new google.maps.Marker(geoFenceConfig);
          type = GeometryType.MAKER;
          coordinates = [[[event.overlay.getPosition().lng(), event.overlay.getPosition().lat()]]];
          break;
      }

      this.geoFenceEditAndSetListener(geoFence, type);

      event.overlay.setMap(null);
      this.drawnGeoFence?.setMap(null);
      this.drawnGeoFence = geoFence;
      this.drawnGeoFence?.setMap(this.map);

      this.geoFence.emit({
        type,
        coordinates,
        radius
      });
    });
  }

  geoFenceEditAndSetListener(geoFence, type: GeometryType) {
    let editedCoordinates, editedRadius
    switch (type) {
      case GeometryType.POLYGON:
        google.maps.event.addListener(geoFence.getPath(), 'insert_at', (editEvent) => {
          editedCoordinates = geoFence.getPath().getArray().map(res => ([res.lng(), res.lat()]));
          this.geoFence.emit({
            type,
            coordinates: [editedCoordinates],
            radius: null
          });
        });

        google.maps.event.addListener(geoFence.getPath(), 'set_at', (editEvent) => {
          editedCoordinates = geoFence.getPath().getArray().map(res => ([res.lng(), res.lat()]));
          this.geoFence.emit({
            type,
            coordinates: [editedCoordinates],
            radius: null
          });
        });
        break;
      case GeometryType.LINESTRING:
        google.maps.event.addListener(geoFence.getPath(), 'insert_at', (editEvent) => {
          editedCoordinates = geoFence.getPath().getArray().map(res => ([res.lng(), res.lat()]));
          this.geoFence.emit({
            type,
            coordinates: [editedCoordinates],
            radius: null
          });
        });

        google.maps.event.addListener(geoFence.getPath(), 'set_at', (editEvent) => {
          editedCoordinates = geoFence.getPath().getArray().map(res => ([res.lng(), res.lat()]));
          this.geoFence.emit({
            type,
            coordinates: [editedCoordinates],
            radius: null
          });
        });
        break;
      case GeometryType.CIRCLE:
        google.maps.event.addListener(geoFence, 'radius_changed', (editEvent) => {
          editedRadius = geoFence.getRadius();
          this.geoFence.emit({
            type,
            coordinates: [[[geoFence.getCenter().lng(), geoFence.getCenter().lat()]]],
            radius: editedRadius
          });
        });
        google.maps.event.addListener(geoFence, 'center_changed', (editEvent) => {
          editedCoordinates = [[[geoFence.getCenter().lng(), geoFence.getCenter().lat()]]];
          this.geoFence.emit({
            type,
            coordinates: editedCoordinates,
            radius: geoFence.getRadius()
          });
        });
        break;
      case GeometryType.RECTANGLE:
        google.maps.event.addListener(geoFence, 'bounds_changed', (editEvent) => {
          editedCoordinates = [
            [
              [
                geoFence.getBounds().toJSON().north,
                geoFence.getBounds().toJSON().south,
                geoFence.getBounds().toJSON().east,
                geoFence.getBounds().toJSON().west],
            ]
          ];
          this.geoFence.emit({
            type,
            coordinates: editedCoordinates,
            radius: editedRadius
          });
        });
        break;
    }
  }

  drawParentGeoFence(shape: google.maps.Polyline | google.maps.Polygon) {
    this.parentGeoFence = shape;
    this.parentGeoFence.setMap(this.map);
  }

  drawSpeedLimitGeoFences(
    shapes: Array<google.maps.Polyline | google.maps.Polygon | google.maps.Circle | google.maps.Rectangle | google.maps.Marker>,
    pointersCoordinate: number[],
    editable = false,
    type: GeometryType
    ) {
    const pointerLatLng: google.maps.LatLng = new google.maps.LatLng(pointersCoordinate[1], pointersCoordinate[0]);
    this.map.setCenter(pointerLatLng);
    if (editable) {
      this.overlayCompleteListener();
      this.geoFenceEditAndSetListener(shapes[0], type);
    }
    shapes.forEach(shape => {
      shape.setMap(this.map);
    });

    this.map.data.forEach(feature => this.map.data.remove(feature));
  }

  drawSavedGeoFence(shape: google.maps.Polyline | google.maps.Polygon, searchedPoint: Point) {
    if(searchedPoint) {
      const pointerLatLng: google.maps.LatLng = new google.maps.LatLng(searchedPoint?.coordinates[1], searchedPoint?.coordinates[0]);
      this.map.setCenter(pointerLatLng);
      const marker = this.addMarker({});
      marker.setPosition(pointerLatLng);
    }
    this.drawnGeoFence = shape;
    this.drawnGeoFence?.setMap(this.map);
    this.overlayCompleteListener();

    google.maps.event.addListener(shape.getPath(), 'insert_at', (event) => {
      const coordinates = shape.getPath().getArray().map(res => ([res.lng(), res.lat()]));
      const firstCoordinate = coordinates[0];
      coordinates.push(firstCoordinate);
      this.geoFence.emit({
        type: GeometryType.POLYGON,
        coordinates: [coordinates]
      });
    });

    google.maps.event.addListener(shape.getPath(), 'set_at', () => {
      const coordinates = shape.getPath().getArray().map(res => ([res.lng(), res.lat()]));
      const firstCoordinate = coordinates[0];
      coordinates.push(firstCoordinate);
      this.geoFence.emit({
        type: GeometryType.POLYGON,
        coordinates: [coordinates]
      });
    });

  }

  removeSpeedLimitGeoFences() {
    this.map.data.forEach(res => this.map.data.remove(res));
    this.markers.forEach(marker => this.removeMarkers());
  }

  drawGeoFences(shape: google.maps.Polygon) {
    this.drawnGeoFencesJobMap.push(shape);
    for(let i = 0; i < this.drawnGeoFencesJobMap.length; i++) {
      this.drawnGeoFencesJobMap[i].setMap(this.map);
    }
  }

  removeGeoFences() {
    this.drawnGeoFencesJobMap.forEach(geoFence => {
      geoFence.setMap(null);
    });
    this.drawnGeoFencesJobMap = [];
  }

  removeGeoFence(overlayType: google.maps.drawing.OverlayType) {
    if (this.drawnGeoFence) {
      this.drawnGeoFence.setMap(null);
    }
    this.map.data.forEach(res => this.map.data.remove(res));
    this.drawingManager?.setDrawingMode(overlayType);
  }

  removeParentGeoFence() {
    if (this.parentGeoFence) {
      this.parentGeoFence.setMap(null);
    }
    this.map.data.forEach(res => this.map.data.remove(res));
    this.drawingManager?.setMap(null);
    this.markers.forEach(marker => this.removeMarkers());
  }

  removeDrawingManagerOptions(): void {
    this.drawingManager?.unbindAll();
    this.drawingManager?.setDrawingMode(null);
  }

  initializeDrawingManager(drawingManager: google.maps.drawing.DrawingManager) {
    if (drawingManager?.getDrawingMode()) {
      this.drawingManager = drawingManager;
      this.map.setOptions({
        maxZoom: 20,
        zoom: 15,
        zoomControl: true
      });
      this.drawingManager?.setMap(this.map);
    }
  }


  goFullScreen(): void {
    if (this.fullScreen) return;
    // get map to full screen using css selector click
    document.querySelector('.gm-fullscreen-control')?.dispatchEvent(new Event('click'));
    this.map.setZoom(this.map.getZoom() + 1);
  }

  save(): void {
    if (!this.fullScreen) return;
    document.querySelector('.gm-fullscreen-control')?.dispatchEvent(new Event('click'));
    this.map.setZoom(this.map.getZoom() - 1);
  }

  // listen to full screen event
  listenToFullScreen(): void {
    const mapElement = document.querySelector('.gm-style') as HTMLElement;
    const thisClass = this;
    document.addEventListener('fullscreenchange', function () {
      if (document.fullscreenElement !== mapElement) {
        thisClass.fullScreen = true;
      } else {
        thisClass.fullScreen = false;
      }
    });
  }
}
