<div class="resources-details">
  <div class="resource-header">Resources</div>
  <div *ngIf="validateResourceMap(drivers)" class="resource-sub-header">Drivers</div>
  <ng-container *ngIf="drivers">
    <hmt-resource-view *ngFor="let driver of drivers | keyvalue"
                       [selectedResource]="driver.value"
                       [resourceType]="'HUMAN'"
    >
    </hmt-resource-view>
  </ng-container>
  <div *ngIf="validateResourceMap(assistants)" class="resource-sub-header">Assistants</div>
  <ng-container *ngIf="assistants">
    <hmt-resource-view *ngFor="let assistant of assistants | keyvalue"
                       [selectedResource]="assistant.value"
                       [resourceType]="'HUMAN'"
    >
    </hmt-resource-view>
  </ng-container>
  <div *ngIf="validateResourceMap(vehicles)" class="resource-sub-header">Vehicles</div>
  <ng-container *ngIf="vehicles">
    <hmt-resource-view *ngFor="let vehicle of vehicles | keyvalue"
                       [selectedResource]="vehicle.value"
                       [resourceType]="'VEHICLE'"
    >
    </hmt-resource-view>
  </ng-container>
  <div *ngIf="validateResourceMap(trailers)" class="resource-sub-header">Trailers</div>
  <ng-container *ngIf="trailers">
    <hmt-resource-view *ngFor="let trailer of trailers | keyvalue"
                       [selectedResource]="trailer.value"
                       [resourceType]="'TRAILER'"
    >
    </hmt-resource-view>
  </ng-container>
</div>
