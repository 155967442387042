<div class="upload-img">
  <div *ngFor="let file of selectedFiles ;let i=index;">
    <img class="image" matTooltip="{{file.name}}" [src]="imageURL[i]">
    <div class="middle">
      <button class="close-button-click" mat-icon-button (click)="removeFile(file.name)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <div class="upload-item">
    <label for="file-input">
      <img src="./assets/icons/add-file.svg"/>
    </label>
    <input id="file-input" multiple type="file" (change)="onFilesSelect($event.target.files)">
  </div>
</div>

