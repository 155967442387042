<div class="date-time-filter-bar">
  <form [formGroup]="filterForm" class="form">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>From</mat-label>
      <label>
        <input matInput [ngxMatDatetimePicker]="fromDate" placeholder="dd/mm/yyyy" formControlName="fromDate">
      </label>
      <mat-datepicker-toggle matSuffix [for]="fromDate">
        <img matDatepickerToggleIcon src="./assets/icons/calendar.svg" alt="calendar">
      </mat-datepicker-toggle>
      <ngx-mat-datetime-picker #fromDate color="accent" enableMeridian="true">
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <mat-form-field class="form-field" appearance="outline">
      <mat-label>To</mat-label>
      <label>
        <input matInput [ngxMatDatetimePicker]="toDate" placeholder="dd/mm/yyyy" formControlName="toDate">
      </label>
      <mat-datepicker-toggle matSuffix [for]="toDate">
        <img matDatepickerToggleIcon src="./assets/icons/calendar.svg" alt="calendar">
      </mat-datepicker-toggle>
      <ngx-mat-datetime-picker #toDate color="accent" enableMeridian="true">
      </ngx-mat-datetime-picker>
    </mat-form-field>
  </form>
</div>
