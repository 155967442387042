import {User} from '@core/models/user.model';
import { Operation } from 'app/modules/organization/models/operation';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public  username: string, public password: string, public redirectUrl?: string) {}
}

export class RefreshToken {
  static readonly type = '[Auth] Refresh token';
  constructor(public operation?: Operation) {}
}


export class LoginSuccess {
  static readonly type = '[Auth] Login Success';
  constructor(public redirectUrl?: string) {}
}
export class LoginFailed {
  static readonly type = '[Auth] Login Failed';
  constructor(public errorMsg?: string) {}
}
export class AutoLoginAction {
  static readonly type = '[Auth] Auto Login';
}

export class AutoLoginFailed {
  static readonly type = '[Auth] Auto Login Failed';
}

export class AutoLoginSuccess {
  static readonly type = '[Auth] Auto Login Success';
}

export class Logout {
  static readonly type = '[Auth] Logout';
  constructor(public  redirect = true, public currentPage = null) {}
}

export class SetUser {
  static readonly type = '[Auth] Set User';
  constructor(public user: User) {}
}

export class SetToken {
  static readonly type = '[Auth] Set Token';
  constructor(public token: string) {}
}

export class SetLogo {
  static readonly type = '[Auth] Set Logo';
  constructor(public orgId: string) {}
}

export class SetCurrentUserOperations {
  static readonly type = '[Auth] Set Current User Operations';
}

export class ResetLoginMessage {
  static readonly type = '[Auth] Reset Login Message';
}

export class PasswordResetRequestAction {
  static readonly type = '[Auth] Password Reset Request';
  constructor(public userId: string) {}
}

export class PasswordResetAction {
  static readonly type = '[Auth] Password Reset';
  constructor(public newPassword: string, public reTypeNewPassword: string, public resetKey: string) {}
}