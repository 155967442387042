<div class="distance-matrix col full-width-height">
  <div class="dm-header row">
    <p class="title">Distance Matrix</p>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="dm-content col">
    <div class="row">
      <button mat-stroked-button class="exit-full-screen" color="primary" (click)="close()">
        <mat-icon matPrefix>fullscreen_exit</mat-icon>
        Exit Full Screen
      </button>
    </div>
    <table>
      <thead>
      <tr>
        <th class="cell-dark"></th>
        <th class="cell-dark" *ngFor="let loc of selectedLocations; let i = index">
          <div class="top-right" (dblclick)="toggleFormVisibility(i)">
            {{ loc?.locationName }}
            <img (click)="toggleFormVisibility(i)" src="assets/icons/edit.svg" alt="edit"/>
            <img class="icon-button" *ngIf="!data.isDisabledForm" (click)="removeLocation(i)" src="assets/icons/close-red.svg" alt="add"/>
          </div>
          <form *ngIf="isFormVisible[i]" [formGroup]="locationForm">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Location</mat-label>
              <input type="text"
                     placeholder="Pick one"
                     aria-label="Location"
                     matInput
                     formControlName="location"
                     [matAutocomplete]="auto">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayLocationName">
                <mat-option *ngFor="let option of data.locations | async" [value]="option">{{ option.locationName }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <button mat-flat-button color="primary" class="small-button" (click)="updateLocation(i)">Set</button>
          </form>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of distanceMatrix; let i = index" [formGroup]="getRowFormGroup(i)">
        <th class="cell-dark">
          <div class="top-right">
            {{ selectedLocations[i]?.locationName }}
            <img class="icon-button" *ngIf="!data.isDisabledForm" (click)="removeLocation(i)" src="assets/icons/close-red.svg" alt="add"/>
          </div>
        </th>
        <ng-container *ngFor="let cell of row; let j = index">
          <td *ngIf="i !== j">
            <mat-form-field appearance="outline">
              <input matInput type="number" min="1" [formControlName]="getControlName(i, j)" hmtRestrictInputDecimalPlaces decimalPlaces="3">
            </mat-form-field>
          </td>
          <td *ngIf="i === j" class="cell-dark"></td>
        </ng-container>
      </tr>
      </tbody>
      <mat-icon class="bottom-right" *ngIf="!data.isDisabledForm" (click)="addLocation()">add_circle</mat-icon>
    </table>

  </div>
  <mat-divider></mat-divider>
  <div class="dm-footer row">
    <button mat-flat-button color="primary" [disabled]="data.isDisabledForm" (click)="saveMatrix()">Save</button>
  </div>
</div>

