<div class="resource-document col">
  <div class="document col">
    <div class="document-list row full-width" >
      <div class="document"  *ngFor="let document of documents; let i=index;">
        <div class="document-name" (click)="loadDocument(document.id)">{{document?.originalFileName}}</div>
        <div>
          <mat-icon class="material-icons-outlined" (click)="removeFile(document.id)">close</mat-icon>
        </div>
      </div>
    </div>
    <div class="upload-files">
      <label for="document-input">
        Upload Files
      </label>
      <input id="document-input" multiple type="file" (change)="onFilesSelect($event.target.files)">
    </div>
  </div>
</div>
