<div class="add-tier">
  <div class="container-top">
    <div class="btn-arrow">
      <button mat-icon-button color="accent" id="arrow-button" aria-label="Example icon button with a menu icon" (click)="close()">
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
    <div class="vertical-separator"></div>
    <div class="title-div">
      <h2>{{data?.edit ? 'Edit Vehicle Tier' : 'Create Vehicle Tier'}}</h2>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="container-form-tier">
    <form [formGroup]="rateTierForm" style="width: 100%">

      <div class="column-items">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Fuel cost per Km</mat-label>
          <input matInput type="number" formControlName="fuelCost">
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Tyre cost per Km</mat-label>
          <input matInput type="number" formControlName="tyreCost">
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Parts cost per Km</mat-label>
          <input matInput type="number" formControlName="partCost">
        </mat-form-field>
        <br>
      </div>

      <div class="cost-title row ai-c mt-10" *ngIf="costItems?.controls.length > 0">
        <div class="left"></div>
        <div class="title">Additional Costs</div>
        <div class="right"></div>
      </div>

      <div formArrayName="additionalCost" *ngFor="let costsitem of costItems?.controls; let i=index">
        <div class="row-items-1" [formGroupName]="i">

          <div class="col-1">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput formControlName="costName">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <mat-label>Amount</mat-label>
              <input matInput type="number" formControlName="cost">
            </mat-form-field>
          </div>
          <div class="col-3">
            <button mat-stroked-button color="primary" class="remove-cost-btn" (click)="removeTireCost(i)">Remove
            </button>
          </div>
        </div>
      </div>
      <div>
        <button mat-stroked-button color="primary" class="add-cost-btn" (click)="addNewTireCost()">Add New Cost</button>
      </div>
      <br>

      <div class="row full-width total-cost-per-km">
        <div class="label">Total Cost per Km </div>
        <div class="price">Rs. {{getTotalCostPerKm() | number : '1.2-2'}}</div>
      </div>
      <div class="button-row">
        <button mat-raised-button color="primary" (click)="close()">Close</button>
        <button mat-raised-button color="primary" class="save-button" (click)="saveNewTier()">Save</button>
      </div>
    </form>
  </div>
</div>
