import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UPLOAD_FILE_MAXIMUM_SIZE } from '@configs/constants';
import { DocumentInfo } from '@shared/models/document-info.model';
import { FileInfo } from '@shared/models/file-info';

@Component({
  selector: 'hmt-document-attachment-wizard',
  templateUrl: './document-attachment-wizard.component.html',
  styleUrls: ['./document-attachment-wizard.component.scss']
})
export class DocumentAttachmentWizardComponent implements OnInit {
  fileNamesLargerThanMaxFileSize: string[] = [];
  @Input() documents: FileInfo[] | null;
  @Output() viewDocument = new EventEmitter<string>();
  @Output() removeDocument = new EventEmitter<string>();
  @Output() uploadDocuments = new EventEmitter<File[]>();
  constructor() { }

  ngOnInit(): void {
  }
  onFilesSelect(fileList: FileList) {
    const selectedFiles: File[] = [];
    if (fileList && fileList.length) {
      const files = Array.from((fileList));
      files.forEach((file: File) => {
        if (file.size > UPLOAD_FILE_MAXIMUM_SIZE) {
          this.fileNamesLargerThanMaxFileSize.push(file.name);
        } else {
          if (!this.checkDuplicateRecords(file)) {
            selectedFiles.push(file);
          }
        }
      });
      this.uploadFiles(selectedFiles);
    }
  }

  checkDuplicateRecords(file: File): boolean {
    if(!this.documents) return false;
    for (const filteredFile of this.documents) {
      if (filteredFile.originalFileName === file.name) {
        return true;
      }
    }
    return false;
  }

  removeFile(id: string) {
    this.removeDocument.emit(id);
  }

  uploadFiles(files: File[]) {
    this.uploadDocuments.emit(files);
  }

  loadDocument(id: string) {
    this.viewDocument.emit(id);
  }
}

