<div class="add-cost col full-width-height">
  <div class="ac-header row">
    <p class="title">Add Cost</p>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="ac-content col">
    <form [formGroup]="form" class="full-width form">
      <mat-form-field class="full-width">
        <mat-label>Description</mat-label>
        <input matInput formControlName="description">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Fee</mat-label>
        <input matInput formControlName="cost" type="number" hmtRestrictInputDecimalPlaces decimalPlaces="2">
      </mat-form-field>
    </form>
  </div>
  <mat-divider></mat-divider>
  <div class="ac-footer row">
    <button mat-stroked-button color="warn" (click)="close()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onAddCost()">Add Fee</button>
  </div>
</div>
