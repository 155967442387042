import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import {catchError, map, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {AuthState} from '@core/store/auth/auth.state';
import {LoginFailed, Logout} from '@core/store/auth/auth.actions';
import { API } from '@configs/api.config';
import { SetSpinner } from '@core/store/shared.actions';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private store: Store, public router: Router) {
  }

  /**
   * @description - return token from storage or cookie
   */
  private getToken(): string {
    return this.store.selectSnapshot(AuthState.token);
  }

  /**
   * @description - intercept the http request and put jwt token
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.getToken();

    let clonedRequest = req;
    if (!req.url.includes('storage.googleapis.com')) {
      clonedRequest = req.clone({setHeaders: {Authorization: 'Bearer ' + token}});
    }
    return next.handle(clonedRequest)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (req.url.includes(API.auth.getToken)) {
            this.store.dispatch(new LoginFailed(error.message));
            return throwError(error);
          }
          if (error.status === 401) {
            this.store.dispatch(new Logout(true, this.router.url));
          }
          this.store.dispatch(new SetSpinner(false));
          return throwError(error);
        })
      );
  }
}
