import {Pipe, PipeTransform} from '@angular/core';
import {PreSignedUrl} from '@control-tower/models/jpm/pre-signed-url';

@Pipe({
  name: 'document_ref_type',
})
export class DocumentRefTypePipe implements PipeTransform {
  transform(documents: PreSignedUrl[], refType: string): any {
    if (!documents || !refType) {
      return documents;
    }
    return documents.filter(item => item.refType === refType);
  }
}
