import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RateCard} from '@shared/models/rate-card.model';
import {API} from '@configs/api.config';
import {RateTier} from '@shared/models/rate-tier.model';
import {Pageable} from '@shared/models/pageable.model';
import {ContractType} from '@shared/models/enums/rate-sheet-contract-type.enum';
import {VehicleCategory} from '@shared/models/vehicle-category.model';
import {OrganizationLocation} from '../../modules/organization/models/organization-location';
import { MatrixDataTemplate } from 'app/modules/organization/models/matrix-data.model';

@Injectable({
  providedIn: 'root'
})
export class RateCardService {

  constructor(private httpClient: HttpClient) { }

  getRateCards(orgIds?: string[], includeNonOrgRates = false, pageIndex?: number, pageSize?: number): Observable<Pageable<RateCard>> {
    const url = API.rateCard.getRateCards;
    let params = new HttpParams();

    if (orgIds) {
      params = params.set('orgIds', orgIds.join(','));
    }
    params = params.set('includeNonOrgRates', '' + includeNonOrgRates);

    if (pageIndex != null && pageSize != null) {
      params = params.set('pageNo', '' + pageIndex);
      params = params.set('pageSize', '' + pageSize);
    }

    params = params.set('isContractedRate', 'true');
    params = params.set('type', 'RATE_CARD');

    return this.httpClient.get<Pageable<RateCard>>(url, { params });
  }

  getRateCardsForOperation(orgIds?: string[], includeNonOrgRates = false, pageIndex?: number, pageSize?: number,searchKey?: string): Observable<Pageable<RateCard>> {
    const url = API.rateCard.getRateCardsForOperation;
    let params = new HttpParams();

    if (orgIds) {
      params = params.set('orgIds', orgIds.join(','));
    }
    params = params.set('includeNonOrgRates', '' + includeNonOrgRates);

    if (pageIndex != null && pageSize != null) {
      params = params.set('pageNo', '' + pageIndex);
      params = params.set('pageSize', '' + pageSize);
    }

    if (searchKey) {
      params = params.set('searchKey', '' + searchKey);
    }

    params = params.set('isContractedRate', 'true');
    params = params.set('type', 'RATE_CARD');

    return this.httpClient.get<Pageable<RateCard>>(url, { params });

  }

  getActiveRateCards(orgIds?: string[], includeNonOrgRates = false, operationId?: string, pageIndex?: number, pageSize?: number): Observable<Pageable<RateCard>> {
    const url = API.rateCard.getRateCards;
    let params = new HttpParams();

    if (orgIds) {
      params = params.set('orgIds', orgIds.join(','));
    }
    params = params.set('includeNonOrgRates', '' + includeNonOrgRates);
    
    if (operationId) {
      params = params.set('operationId', '' + operationId);
    }

    if (pageIndex != null && pageSize != null) {
      params = params.set('pageNo', '' + pageIndex);
      params = params.set('pageSize', '' + pageSize);
    }

    params = params.set('isContractedRate', 'true');
    params = params.set('status', 'VALID');
    params = params.set('type', ContractType.CUSTOMER_CONTRACT);

    return this.httpClient.get<Pageable<RateCard>>(url, { params });
  }

  getRateCardsByFilters(orgId: string, contractType: ContractType, customerId?: string,  operationId?: string, vertical?: string, searchKey?: string): Observable<RateCard[]> {
    const url = API.rateCard.getRateCardsByFilter.format([orgId]);
    let params = new HttpParams()
    if (customerId) {
      params = params.set('customerId', '' + customerId);
    }
    if (vertical) {
      params = params.set('vertical', '' + vertical);
    }
    if (searchKey) {
      params = params.set('searchKey', '' + searchKey);
    }
    if (contractType) {
      params = params.set('type', contractType);
    }

    if (operationId && operationId !== '') {
      params = params.set('operationId', '' + operationId);
    }

    params = params.set('status', 'VALID');

    return this.httpClient.get<RateCard[]>(url, {params});
  }

  saveTier(newTier: RateTier): Observable<RateTier> {
    const url = API.rateCard.saveTier;
    return this.httpClient.post<RateTier>(url, newTier);
  }

  removeTier(tierId: string): Observable<RateTier> {
    const url = API.rateCard.removeTier.format([tierId]);
    return this.httpClient.delete<RateTier>(url);
  }

  updateTier(newTier: RateTier): Observable<RateTier> {
    const url = API.rateCard.updateTier;
    return this.httpClient.put<RateTier>(url, newTier);
  }

  getAllTiers(orgId: string): Observable<RateTier[]> {
    const url = API.rateCard.getAllTiers.format([orgId]);
    return this.httpClient.get<RateTier[]>(url);
  }

  getPaginateTiers(orgId: string, pageSize: number, pageIndex: number): Observable<Pageable<RateTier>> {
    const url = API.rateCard.getPaginateRateTiers.format([orgId]);
    let params = new HttpParams();
    params = params.append('pageNo', String(pageIndex));
    params = params.append('pageSize', String(pageSize));
    return this.httpClient.get<Pageable<RateTier>>(url, {params});
  }

  saveRateCard(newRateCard: RateCard): Observable<RateCard> {
    const url = API.rateCard.saveRateCard;
    return this.httpClient.post<RateCard>(url, newRateCard);
  }

  deleteRateCard(rateCardId: string): Observable<string> {
    const url = API.rateCard.deleteRateCard.format([rateCardId]);
    return this.httpClient.delete<string>(url);
  }

  setExchangeRate(rateCardId: string, exchangeRate: number): Observable<RateCard> {
    const url = API.rateCard.setExchangeRate.format([rateCardId, exchangeRate.toString()]);
    return this.httpClient.put<RateCard>(url, null);
  }

  getRateCardsByIds(rateCardIds: string[]): Observable<RateCard[]> {
    const url = API.rateCard.getRateCardsByIds;
    return this.httpClient.post<RateCard[]>(url, rateCardIds);
  }

  getVehicleCategories(): Observable<VehicleCategory[]> {
    const url = API.organization.getVehicleCategories;
    return this.httpClient.get<VehicleCategory[]>(url);
  }

  getVehicleCategoryIdByName(vehicleCategoryName: string): Observable<string> {
    const url = API.organization.getVehicleCategoryIdByName;
    return this.httpClient.get<string>(url, {params: {vehicleCategoryName}});
  }

  getOrgLocations(orgId: string, searchValue: string): Observable<OrganizationLocation[]> {

    const url  = `${API.locations.getLocationsForWizard}`;
    let params = new HttpParams();
    if (searchValue !== ''){
      params = params.set('searchKey', searchValue);
    }
    params = params.set('orgTypes', ['ORG_PARTNER', 'PUBLIC'].toString());
    params = params.set('pageSize', 50 + '');
    params = params.set('pageNumber', 0 + '');
    return this.httpClient.get<OrganizationLocation[]>(url, {params});
  }

  getOrgLocationsByIds(locationIds: string[]): Observable<OrganizationLocation[]> {
    const filter = {
      where: {
        id: {
          inq: locationIds
        }
      },
    };
    const url  = `${API.locations.getLocationsForWizard}`;
    let params = new HttpParams().set('filter', JSON.stringify(filter));
    params = params.set('orgTypes', ['ORG_PARTNER', 'PUBLIC'].toString());
    return this.httpClient.get<OrganizationLocation[]>(url, {params});
  }

  getDistanceMatrixTemplateOperationListWise( orgId?: string, searchKey?: string ,operationIdList?: string[]): Observable<MatrixDataTemplate[]> {
    const url = API.rateCard.getDistanceMatrixOperationList.format([orgId]);
    const body = {
      searchKey: searchKey,
      operationIdList: operationIdList
    };
    return this.httpClient.post<MatrixDataTemplate[]>(url, body);
  }
}
