import {ComponentFactoryResolver, ComponentRef, Directive, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ComponentRegistryService} from '../../modules/wizard/services/component-registry.service';

@Directive({
  selector: '[hmtDynamicComponent]'
})
export class DynamicComponentDirective implements OnInit{
  @Input() componentName: string;
  @Input() data: { [key: string]: string | number };
  @Output() output = new EventEmitter();
  component: ComponentRef<any>;

  constructor(
    private componentRegistryService: ComponentRegistryService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {
  }

  ngOnInit(): void {
    const component = this.componentRegistryService.getComponent(this.componentName);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.component = this.viewContainerRef.createComponent(componentFactory);
    this.component.instance.data = this.data;
    // this.component.instance.output.subscribe((t) => {
    //   this.output.emit(t);
    // });
  }

}
