<div class="flag-down-rate full-width-height">
  <mat-expansion-panel hideToggle="true" (click)="click()" [expanded]="true" class="ci-expansion-panel">
    <mat-expansion-panel-header class="mep-header">
      <mat-panel-title>
        <mat-icon>{{icon  ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
        <p class="font-size-16">{{vehicleCategory.categoryName}}</p>
      </mat-panel-title>
      <mat-panel-description>
        <mat-icon (click)="removeRate()">delete</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="ci-expansion-panel-content col">
      <form [formGroup]="flagDownRateForm">
        <div class="row input-row">
          <div class="col">
            <mat-checkbox formControlName="flagDownCostActivated">Flagdown Rate</mat-checkbox>
            <mat-form-field>
              <mat-label>Rate to Charge</mat-label>
              <input matInput type="number" formControlName="flagDownCost" hmtRestrictInputDecimalPlaces
                     decimalPlaces="2">
            </mat-form-field>
          </div>
          <div class="col">
            <mat-checkbox formControlName="flagDownFrequencyActivated">Flagdown Frequency
            </mat-checkbox>
            <mat-form-field>
              <mat-label>No of Hours</mat-label>
              <input matInput type="number" formControlName="flagDownFrequency" hmtRestrictInputDecimalPlaces>
            </mat-form-field>
          </div>
        </div>

        <div class="row input-row">
          <div class="col">
            <mat-checkbox formControlName="jobFreeHoursActivated">Free Hours per FlagDown</mat-checkbox>
            <mat-form-field>
              <mat-label>No of Hours</mat-label>
              <input matInput type="number" formControlName="jobFreeHours" hmtRestrictInputDecimalPlaces>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-checkbox class="example-margin" formControlName="sellingRatePerExtraKmActivated">Selling Rate per
              Extra Km
            </mat-checkbox>
            <mat-form-field>
              <mat-label>Rate of Charge</mat-label>
              <input matInput type="number" formControlName="sellingRatePerExtraKm" hmtRestrictInputDecimalPlaces
                     decimalPlaces="2">
            </mat-form-field>
          </div>
        </div>

        <div class="row input-row">
          <div class="col">
            <mat-checkbox class="example-margin" formControlName="freeKmsPerFlagDownActivated">Free Km's per Flagdown
            </mat-checkbox>
            <mat-form-field>
              <mat-label>Number of Km's</mat-label>
              <input matInput type="number" formControlName="freeKmsPerFlagDown" hmtRestrictInputDecimalPlaces
                     decimalPlaces="3">
            </mat-form-field>
          </div>
          <div class="col fuel-efficiency">
            <mat-form-field>
              <mat-label>Fuel Efficiency</mat-label>
              <input matInput type="number" formControlName="fuelEfficiency" hmtRestrictInputDecimalPlaces
                     decimalPlaces="3">
            </mat-form-field>
          </div>
        </div>
        <div class="row input-row">
          <div class="col demurrage">
            <mat-checkbox formControlName="demurragePerHrActivated">Demurrage</mat-checkbox>

            <mat-radio-group class="demurrage-radio-group" formControlName="demurrageCalculationType"
                             *ngIf="demurragePerHrActivated">
              <div class="col">
                <mat-radio-button class="radio-btn" value="FULL_TRIP">Calculate Demurrage For the Complete trip
                </mat-radio-button>
                <mat-radio-button class="radio-btn" value="LOCATION_BASED">Location Based Demurrage Calculation
                </mat-radio-button>
              </div>

              <div class="check-box-group" *ngIf="dct === 'LOCATION_BASED'">
                <ul>
                  <li>
                    <mat-checkbox formControlName="demurragePublicLocation"
                                  [ngStyle]="{'color': checkLocationTypeSelected() ? '#FF0000' : '#000000'}">
                      Calculate Demurrage for Public Location
                    </mat-checkbox>
                  </li>
                  <li>
                    <mat-checkbox formControlName="demurrageOtherLocation"
                                  [ngStyle]="{'color': checkLocationTypeSelected() ? '#FF0000' : '#000000'}">
                      Calculate Demurrage for Private Location
                    </mat-checkbox>
                  </li>
                </ul>
              </div>
            </mat-radio-group>

            <!-- form array -->
            <div formArrayName="demurrageSlabs" class="col demurrage-slabs">
              <ng-container *ngFor="let slab of getDemurrageSlabs()?.controls; let i=index">
                <div class="row demurrage-slab" [formGroupName]="i">
                  <div class="next col ha-c">{{ i == 0 ? 'First' : 'Next' }}</div>
                  <div class="hours">
                    <mat-form-field>
                      <input matInput type="number" formControlName="hours" placeholder="hours"
                             hmtRestrictInputDecimalPlaces>
                    </mat-form-field>
                  </div>
                  <div class="col ha-c hours-label">
                    hrs
                  </div>
                  <div class="rate">
                    <mat-form-field>
                      <input matInput type="number" formControlName="rate" placeholder="Rate(rate per hr)"
                             hmtRestrictInputDecimalPlaces decimalPlaces="2">
                    </mat-form-field>
                  </div>
                  <div class="col ha-c remove-btn">
                    <button mat-stroked-button color="primary" (click)="removeDemurrageSlab(i)"
                    >Remove
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="add-slab">
              <button mat-stroked-button color="primary" (click)="addDemurrageSlab()">
                Add Slab
              </button>
            </div>
            <div class="row demurrage-till-clearance">
              <div class="label col ha-c">
                Until Clearance Per Hour
              </div>
              <div class="form-input">
                <mat-form-field>
                  <input matInput type="number" placeholder="Rate(rate per hr)"
                         formControlName="demurragePerHrTillClearance" hmtRestrictInputDecimalPlaces decimalPlaces="2">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="input-row">
          <div class="row">
            <h3>Costs</h3>
          </div>
          <div class="row full-width tier-running-cost">

            <mat-checkbox class="right-margin" [checked]="isCheckBoxUseTier" formControlName="tierCostActivated"
                          (change)="toggleRadioButton(1)">Use Tier
            </mat-checkbox>

            <mat-form-field class="right-margin">
              <input type="text"
                     placeholder="Select Tier"
                     aria-label="Number"
                     matInput
                     formControlName="vehicleTier"
                     [matAutocomplete]="auto">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                [displayWith]="displayTierName.bind(this)">
                <ng-container *ngFor="let tier of rateTiers">
                  <mat-option [value]="tier.tierId" (click)="setVehicleTier(tier)">{{ tier.name }}</mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
            <button mat-stroked-button color="primary" class="right-margin" (click)="openCreateTier()">
              Create Tier
            </button>
          </div>

          <div class="row full-width tier-running-cost">

            <mat-checkbox class="right-margin" [checked]="isCheckBoxFixedPrice" formControlName="perKmFixedPriceActivated"
                          (change)="toggleRadioButton(2)">Fixed Cost
            </mat-checkbox>

            <mat-form-field class="right-margin">
              <mat-label>Fixed Running Cost per Km</mat-label>
              <input matInput type="number" formControlName="perKmFixedPrice" hmtRestrictInputDecimalPlaces
                     decimalPlaces="2">
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</div>
