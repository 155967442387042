import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatNativeDateModule} from '@angular/material/core';
import {MatLineModule} from '@angular/material/core';
import {GoogleMapsModule} from '@angular/google-maps';
import {MatCardModule} from '@angular/material/card';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDividerModule} from '@angular/material/divider';
import {MatTabsModule} from '@angular/material/tabs';
import {MAT_CHIPS_DEFAULT_OPTIONS, MatChipsModule} from '@angular/material/chips';
import { HttpClientModule } from '@angular/common/http';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AlertDialogComponent} from './components/alert-dialog/alert-dialog.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SnackBarComponent} from './components/snack-bar/snack-bar.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import { AttachmentPickerComponent } from './components/attachment-picker/attachment-picker.component';
import { TerminateJobSharedComponent } from './components/terminate-job-shared/terminate-job-shared.component';
import { AssignSupervisorComponent } from './components/assign-supervisor/assign-supervisor.component';
import {PaymentTermsComponent} from '@shared/components/payment-terms/payment-terms.component';
import {MatMenuModule} from '@angular/material/menu';
import { ContainerNumberComponent } from './components/container-number/container-number.component';
import { MultiSelectDropDownComponent } from './components/multi-select-drop-down/multi-select-drop-down.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MinutesToHoursMinutesPipe } from './pipes/minutes-to-hours-minutes.pipe';
import {MapComponent} from '@shared/components/map/map.component';
import { MapLocationSearchComponent } from './components/map-location-search/map-location-search.component';
import { MapDrawGeoFenceComponent } from './components/map-draw-geo-fence/map-draw-geo-fence.component';
import {ImageAttachmentWizardComponent} from '@shared/components/image-attachment-wizard/image-attachment-wizard.component';
import {MatTreeModule} from '@angular/material/tree';
import {DynamicComponentDirective} from '@shared/directives/dynamic-component.directive';
import { RegisteredAddressComponent } from './components/registered-address/registered-address.component';
import { ContactNumbersComponent } from './components/contact-numbers/contact-numbers.component';
import { PrincipleContactsComponent } from './components/principle-contacts/principle-contacts.component';
import { ContactDetailsComponent } from './components/contact-details/contact-details.component';
import { ContactViewComponent } from './components/contact-view/contact-view.component';
import {LiveMapLegendComponent} from './components/live-map-legend/live-map-legend.component';
import {LiveMapVehicleDetailsComponent} from '@shared/components/live-map-vehicle-details/live-map-vehicle-details.component';
import {AuthorizePipe} from '@shared/pipes/authorize.pipe';
import { AppAutoTabDirective } from './directives/app-auto-tab.directive';
import { MapJpmComponent } from './components/map-jpm/map-jpm.component';
import { AddContainerBulkComponent } from './components/add-container-bulk/add-container-bulk.component';
import {ClickOutsideDirective} from '@shared/directives/click-outside.directive';
import { ResourcesDetailsComponent } from './components/resources-details/resources-details.component';
import { ResourceViewComponent } from './components/resource-view/resource-view.component';
import {JobImformationComponent} from '@shared/components/job-imformation/job-imformation.component';
import { NotificationComponent } from './components/notification/notification.component';
import {DocumentRefTypePipe} from '@shared/pipes/document-ref-type.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { JobListComponent } from './components/job-list/job-list.component';
import { CreateJobFileComponent } from './components/create-job-file/create-job-file.component';
import { InvoiceProgressLineComponent } from '../modules/invoice/pages/invoice-progress-line/invoice-progress-line.component';
import { ProgressLineComponent } from './components/progress-line/progress-line.component';
import { ShipmentOrderComponent } from './components/shipment-order/shipment-order.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { MatTableModule } from '@angular/material/table';
import { NumberToTextPipe } from './pipes/number-to-text.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { ZeroPadPipe } from './pipes/zero-pad.pipe';
import { DocumentAttachmentWizardComponent } from './components/document-attachment-wizard/document-attachment-wizard.component';
import { TooltipListPipe } from './pipes/tool-tip-list.pipe';
import { RestrictDecimalPlacesDirective } from './directives/restrict-number-input-directive';
import { MapGeoFenceSpeedComponent } from './components/map-geo-fence-speed/map-geo-fence-speed.component';
import { DistanceMatrixComponent } from './components/distance-matrix/distance-matrix.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { FlagDownRateComponent } from './components/flag-down-rate/flag-down-rate.component';
import { DateTimeFilterBarComponent } from './components/date-time-filter-bar/date-time-filter-bar.component';

// do not import any service here, because it will create multiple service instances unless it is acceptable
// do not import this to app module
// do import this module in any other feature module

@NgModule({
  declarations: [
    AlertDialogComponent,
    SnackBarComponent,
    NotificationComponent,
    AttachmentPickerComponent,
    TerminateJobSharedComponent,
    PaymentTermsComponent,
    AssignSupervisorComponent,
    ContainerNumberComponent,
    MapComponent,
    LiveMapLegendComponent,
    LiveMapVehicleDetailsComponent,
    MultiSelectDropDownComponent,
    MinutesToHoursMinutesPipe,
    MinutesToHoursMinutesPipe,
    ContainerNumberComponent,
    MapComponent,
    ContainerNumberComponent,
    MapLocationSearchComponent,
    MapDrawGeoFenceComponent,
    ImageAttachmentWizardComponent,
    MapDrawGeoFenceComponent,
    DynamicComponentDirective,
    RegisteredAddressComponent,
    ContactNumbersComponent,
    PrincipleContactsComponent,
    ContactDetailsComponent,
    ContactViewComponent,
    AuthorizePipe,
    DocumentRefTypePipe,
    AppAutoTabDirective,
    MapJpmComponent,
    AddContainerBulkComponent,
    JobImformationComponent,
    ResourcesDetailsComponent,
    ResourceViewComponent,
    JobListComponent,
    CreateJobFileComponent,
    InvoiceProgressLineComponent,
    ProgressLineComponent,
    ShipmentOrderComponent,
    OrderListComponent,
    NumberToTextPipe,
    TimeFormatPipe,
    ZeroPadPipe,
    DocumentAttachmentWizardComponent,
    TooltipListPipe,
    RestrictDecimalPlacesDirective,
    MapGeoFenceSpeedComponent,
    DistanceMatrixComponent,
    ValidationMessageComponent,
    FlagDownRateComponent,
    DateTimeFilterBarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // HttpClientModule,
    MatToolbarModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatCardModule,
    MatRadioModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatNativeDateModule,
    MatLineModule,
    MatDatepickerModule,
    MatInputModule,
    GoogleMapsModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatMenuModule,
    MatChipsModule,
    MatTreeModule,
    MatPaginatorModule
  ],
    exports: [
        // export any modules, components, pipes and etc.
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // HttpClientModule,
        MatGridListModule,
        MatSlideToggleModule,
        MatCardModule,
        MatRadioModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatButtonModule,
        MatSelectModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatSidenavModule,
        MatListModule,
        GoogleMapsModule,
        MatNativeDateModule,
        MatLineModule,
        MatDatepickerModule,
        MatInputModule,
        MatIconModule,
        MatButtonToggleModule,
        MatDividerModule,
        MatTabsModule,
        MatChipsModule,
        MatDialogModule,
        MatTableModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatTreeModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxMatTimepickerModule,
        MatMenuModule,
        AttachmentPickerComponent,
        PaymentTermsComponent,
        ContainerNumberComponent,
        MultiSelectDropDownComponent,
        MapComponent,
        ImageAttachmentWizardComponent,
        DynamicComponentDirective,
        RegisteredAddressComponent,
        ContactNumbersComponent,
        PrincipleContactsComponent,
        ContactDetailsComponent,
        ContactViewComponent,
        MapLocationSearchComponent,
        MapDrawGeoFenceComponent,
        LiveMapLegendComponent,
        LiveMapVehicleDetailsComponent,
        AuthorizePipe,
        AppAutoTabDirective,
        MapJpmComponent,
        AddContainerBulkComponent,
        JobImformationComponent,
        ResourcesDetailsComponent,
        MinutesToHoursMinutesPipe,
        DocumentRefTypePipe,
        MatPaginatorModule,
        MatIconModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        JobListComponent,
        CreateJobFileComponent,
        MatPaginatorModule,
        ShipmentOrderComponent,
        OrderListComponent,
        ProgressLineComponent,
        DragDropModule,
        NumberToTextPipe,
        TimeFormatPipe,
        ZeroPadPipe,
        TooltipListPipe,
        DocumentAttachmentWizardComponent,
        RestrictDecimalPlacesDirective,
        MapGeoFenceSpeedComponent,
        FlagDownRateComponent,
        DateTimeFilterBarComponent
    ],
    providers: [
      {
        provide: MAT_CHIPS_DEFAULT_OPTIONS,
        useValue: {
          separatorKeyCodes: [ENTER, COMMA]
        }
      }
    ]
})
export class SharedModule {
}
