<div class="container-number">
  <mat-label>Container Number</mat-label>
  <form class="maineForm" [formGroup]="form">
    <div formGroupName="ownerCode">
      <mat-form-field appearance="outline">
        <input matInput class="input" (paste)="onPasteFirstInputOfMainForm($event)" autofocus type="text" formControlName="ownerCodeCharOne" [maxlength]="1" [minlength]="1"
              hmtAppAutoTab [hmtAppAutoTabInput]="input2">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput #input2 type="text" formControlName="ownerCodeCharTwo" [maxlength]="1" [minlength]="1"
              hmtAppAutoTab [hmtAppAutoTabInput]="input3">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput #input3 type="text" formControlName="ownerCodeCharThree" [maxlength]="1" [minlength]="1"
              hmtAppAutoTab [hmtAppAutoTabInput]="input4">
      </mat-form-field>
    </div>
    <mat-form-field class="container-number-input" appearance="outline">
      <input matInput #input4 type="text" formControlName="category" [maxlength]="1" [minlength]="1"
            hmtAppAutoTab [hmtAppAutoTabInput]="input5">
    </mat-form-field>
    <div formGroupName="serialNumber">
      <mat-form-field class="container-number-input" appearance="outline">
        <input matInput #input5 type="text" formControlName="digitOne" maxlength="1" [maxlength]="1" [minlength]="1"
              hmtAppAutoTab [hmtAppAutoTabInput]="input6">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput #input6 type="text" formControlName="digitTwo" [maxlength]="1" [minlength]="1"
              hmtAppAutoTab [hmtAppAutoTabInput]="input7">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput #input7 type="text" formControlName="digitThree" [maxlength]="1" [minlength]="1"
             hmtAppAutoTab [hmtAppAutoTabInput]="input8">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput #input8 type="text" formControlName="digitFour" [maxlength]="1" [minlength]="1"
              hmtAppAutoTab [hmtAppAutoTabInput]="input9">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput #input9 type="text" formControlName="digitFive" [maxlength]="1" [minlength]="1"
              hmtAppAutoTab [hmtAppAutoTabInput]="input10">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput #input10 type="text" formControlName="digitSix" [maxlength]="1" [minlength]="1"
              hmtAppAutoTab [hmtAppAutoTabInput]="input11">
      </mat-form-field>
    </div>
    <mat-form-field class="container-number-input" appearance="outline">
      <input matInput #input11 type="text" formControlName="checkDigit" [maxlength]="1" [minlength]="1"
            hmtAppAutoTab [hmtAppAutoTabInput]="reInput1">
    </mat-form-field>
  </form>
  <div class="misMatch" *ngIf="isFormInValid()">
    <p>Container Number Validation is Invalid</p>
  </div>

  <mat-label>Retype Container Number</mat-label>
  <form class="maineForm" [formGroup]="retypeForm">
    <div formGroupName="ownerCode">
      <mat-form-field appearance="outline">
        <input #reInput1 (paste)="onPasteFirstInputOfRetypeForm($event)" matInput type="text" formControlName="ownerCodeCharOne" [maxlength]="1" [minlength]="1"
             hmtAppAutoTab  [hmtAppAutoTabInput]="reInput2">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input #reInput2 matInput type="text" formControlName="ownerCodeCharTwo" [maxlength]="1" [minlength]="1"
              hmtAppAutoTab [hmtAppAutoTabInput]="reInput3">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input #reInput3 matInput type="text" formControlName="ownerCodeCharThree" [maxlength]="1" [minlength]="1"
               hmtAppAutoTab [hmtAppAutoTabInput]="reInput4">
      </mat-form-field>
    </div>
    <mat-form-field class="container-number-input" appearance="outline">
      <input #reInput4 matInput type="text" formControlName="category" [maxlength]="1" [minlength]="1"
             hmtAppAutoTab [hmtAppAutoTabInput]="reInput5">
    </mat-form-field>
    <div formGroupName="serialNumber">
      <mat-form-field class="container-number-input"  appearance="outline">
        <input #reInput5 matInput type="text" formControlName="digitOne" [maxlength]="1" [minlength]="1"
               hmtAppAutoTab [hmtAppAutoTabInput]="reInput6">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input #reInput6 matInput type="text" formControlName="digitTwo" [maxlength]="1" [minlength]="1"
               hmtAppAutoTab [hmtAppAutoTabInput]="reInput7">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input #reInput7 matInput type="text" formControlName="digitThree" [maxlength]="1" [minlength]="1"
               hmtAppAutoTab [hmtAppAutoTabInput]="reInput8">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input #reInput8 matInput type="text" formControlName="digitFour" [maxlength]="1" [minlength]="1"
               hmtAppAutoTab [hmtAppAutoTabInput]="reInput9">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input #reInput9 matInput type="text" formControlName="digitFive" [maxlength]="1" [minlength]="1"
               hmtAppAutoTab [hmtAppAutoTabInput]="reInput10">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input #reInput10 matInput type="text" formControlName="digitSix" [maxlength]="1" [minlength]="1"
               hmtAppAutoTab [hmtAppAutoTabInput]="reInput11">
      </mat-form-field>
    </div>
    <mat-form-field class="container-number-input" appearance="outline">
      <input #reInput11 matInput type="text" formControlName="checkDigit" [maxlength]="1" [minlength]="1">
    </mat-form-field>
  </form>
  <div class="misMatch" *ngIf="isReTypeFormValid() && !matchingWithRetypeForm()">
    <mat-icon class="material-icons">warning</mat-icon><p>Container Number Validation Failed</p>
  </div>
</div>
