<div class="location-search-container">
  <mat-form-field>
    <input type="text"
           disabled
           matInput
           [formControl]="formControl"
           placeholder="Enter Location"
           #addressText>
  </mat-form-field>
  <div class="map">
    <hmt-map #mapComponent
             (place)="emitSearchedLocation($event)"
             (placeDetails)="emitSearchedLocationDetails($event)">
    </hmt-map>
  </div>
</div>
