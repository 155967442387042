export enum InvoiceType {
  PAYABLE = 'PAYABLE',
  RECEIVABLE = 'RECEIVABLE',
  PAYABLE_COMMITMENT = 'PAYABLE_COMMITMENT',
  RECEIVABLE_COMMITMENT = 'RECEIVABLE_COMMITMENT',
  PAYABLE_FUEL_RATE = 'PAYABLE_FUEL_RATE',
  PAYABLE_FUEL_RATE_INVOICED = 'PAYABLE_FUEL_RATE_INVOICED', // for child invoice which is invoiced
  PAYABLE_FUEL_RATE_FINAL = 'PAYABLE_FUEL_RATE_FINAL',
  RECEIVABLE_FUEL_RATE = 'RECEIVABLE_FUEL_RATE',
  RECEIVABLE_FUEL_RATE_INVOICED = 'RECEIVABLE_FUEL_RATE_INVOICED', // for child invoice which is invoiced
  RECEIVABLE_FUEL_RATE_FINAL = 'RECEIVABLE_FUEL_RATE_FINAL',
}
