import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ContainerTypeAndSize} from '@control-tower/models/jpm/jpm-container-type-and-size.model';
import {JobFileServiceModel} from '@core/models/job-file/job-file-service.model';
import {SetNotificationAction} from '@core/store/notification/notification.actions';
import {SetSpinner} from '@core/store/shared.actions';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {AlertDialogType} from '@shared/models';
import {EntityMap} from '@shared/models/types';
import {WorkOrderService} from '@shared/services/work-order.service';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';

import {QuotationService} from '../../../quotation/services/quotation.service';
import {AddContainer} from '../../models/add-container.model';
import {InitLeg} from '../../models/init-leg.model';
import {OrgPartner} from '../../models/org-partner.model';
import {SavedRoute} from '../../models/saved-route.model';
import {ServiceSequenceTemplate} from '../../models/service-sequence-template.model';
import {ServiceViewModel} from '../../models/service-view.model';
import {ContainerManagementStateModel} from '../../models/state/container-management-state.model';
import {ContainerManagementService} from '../../services/container-management.service';
import {GetLocationById} from '../locations/locations.actions';
import {GeoCoordinate} from './../../../../shared/models/geo-coordinate';
import {
  AddContainerBulk,
  AddNewContainerFromWizard,
  AddServiceAction,
  ApplyServiceSequenceTemplate,
  CalculateRouteDetailsAction,
  ChangeActivityOnEditServiceAction,
  CleanSelectedServiceAction,
  DeleteServiceAction,
  DuplicateContainerFromWizard,
  EditServiceAction,
  GenerateLegsFromWizardLocations,
  GetContainerByOrder,
  GetContainerWithContainerId,
  LoadAlreadySavedRoutes,
  LoadContainerTypeAndSizes,
  LoadFumigators,
  LoadHSCodes,
  PartiallySaveContainerData,
  RemoveAllContainers,
  RemoveContainerFromWizard,
  ResetContainerManagementState,
  SaveContainer,
  SaveContainers,
  SaveInitQuotation,
  SaveServiceAction,
  SaveServiceSequenceTemplate,
  SaveWaypoints,
  SendForApproval,
  UndoContainerRoute,
  SetBillingAction,
  UpdateChannelAndDeleteServiceAction,
  UpdateContainer,
  UpdateContainerWaypoints,
  UpdateCustomsChannelAction,
  UpdateCustomsChannelAndAddDryServiceAction,
  UpdateServiceTimeAction,
  UseExpressWayAction,
  RemoveSpecOrder,
  AddSpecOrder,
  OptimizeOrderMovedContainers,
  UpdateShipmentOrdersData,
  SetFilledDataOnDialogClose,
  UpdateContainerByExpressWaysOption,
  UpdateMapDistanceByExpressWaysOption,
  AddWaypoint,
  UndoWaypoint,
  RedoWaypoint, RemoveServiceSequenceTemplate, UpdateServiceSequenceTemplate,
} from './container-management.actions';
import {LoadType} from '@shared/models/enums/load-type.enum';
import {BillingIndicator} from '../../models/enums/billing-indicator-type.enum';
import {WaypointData} from '../../models/waypoint-data.model';
import {U} from '@angular/cdk/keycodes';
import { DISTRIBUTION_WF } from '@configs/constants';

export const containerMgtStateKey = 'containerManagement';
export const getInitialContainerMgtState = (): ContainerManagementStateModel => ({
  containers: {},
  containerTypeAnsSizes: [],
  hsCodes: [],
  legs: [],
  order: null,
  fumigators: [],
  alreadySavedRoutes: [],
  selectedService: null,
  undoWaypoints: {},
  redoWaypoints: {}
  // lastWaypoints: null,
  // waypoints: null
});

@State<ContainerManagementStateModel>({
  name: containerMgtStateKey,
  defaults: getInitialContainerMgtState()
})
@Injectable()
export class ContainerManagementState {

  constructor(
    private store: Store,
    private containerManagementService: ContainerManagementService,
    private router: Router,
    private quotationService: QuotationService,
    private workOderService: WorkOrderService) {
  }

  @Selector()
  static getContainers(state: ContainerManagementStateModel): AddContainer[] {
    return Object.values(state.containers);
  }

  @Selector()
  static getContainerManagementValidationStatus(state: ContainerManagementStateModel): boolean {
    if (Object.values(state.containers).length === 0) {
      return false;
    }
    if (Object.values(state.containers).some(container => container?.loadType === LoadType.FTL)) {
      return !Object.values(state.containers).some(container => {
        return container?.services?.some((service, index) => {
          return index === 0
            ? service.locationId == null || service.plannedStartDateTime == null
            : service.locationId == null;
        });
      });
    }
    return Object.values(state.containers).some(container => container?.selectionMethod !== null && container.typeAndSize.label !== null)
      && !Object.values(state.containers).some(container => {
        return container?.services?.some((service, index) => {
          return index === 0
            ? service.locationId == null || service.plannedStartDateTime == null
            : service.locationId == null;
        });
      });
  }

  @Selector()
  static getOrder(state: ContainerManagementStateModel): number {
    return state.order;
  }

  @Selector()
  static getLegs(state: ContainerManagementStateModel): InitLeg[] {
    return state.legs;
  }

  @Selector()
  static getContainerTypeAnsSizes(state: ContainerManagementStateModel): ContainerTypeAndSize[] {
    return state.containerTypeAnsSizes;
  }

  @Selector()
  static getMatchigHsCodes(state: ContainerManagementStateModel): (searchValue: number) => number[] {
    return (searchValue: number) => {
      if (searchValue) {
        return state.hsCodes.filter(hsCode => String(hsCode).startsWith(searchValue.toString()));
      }
      return state.hsCodes.slice(0, 10);
    };
  }


  @Selector()
  static getAlreadySavedRoutes(state: ContainerManagementStateModel): ServiceSequenceTemplate[] {
    return state.alreadySavedRoutes;
  }

  @Selector()
  static getContainerByContainerId(state: ContainerManagementStateModel): (containerId: string) => AddContainer {
    return (containerId: string) => state.containers[containerId];
  }

  @Selector()
  static getSelectedService(state: ContainerManagementStateModel) {
    return state.selectedService;
  }

  @Selector()
  static getFumigators(state: ContainerManagementStateModel): OrgPartner[] {
    return state.fumigators;
  }

  @Selector()
  static getWayPointsData(state: ContainerManagementStateModel): (containerId: string) => {
    undoStack: WaypointData[],
    redoStack: WaypointData[]
  } {
    return (containerId: string) => {
      return {undoStack: state.undoWaypoints[containerId], redoStack: state.redoWaypoints[containerId]};
    };
  }

  // @Selector()
  // static lastWaypoints(state: ContainerManagementStateModel): EntityMap<number, GeoCoordinate[]> {
  //   return state?.lastWaypoints;
  // }

  // @Selector()
  // static getWaypoints(state: ContainerManagementStateModel): EntityMap<number, GeoCoordinate[]> {
  //   return state?.waypoints;
  // }

  @Action(GetContainerWithContainerId)
  duplicateContainer({getState, patchState, dispatch}: StateContext<ContainerManagementStateModel>,
                     {containerId}: GetContainerWithContainerId) {
    const {wizard: {jobFile}} = this.store.selectSnapshot(s => s);
    const state = getState();
    return this.containerManagementService.getContainer(containerId, jobFile.jobRefId, jobFile.orgId)
      .pipe(
        tap(newContainer => {
          patchState({
            containers: {...state.containers, [newContainer.containerId]: newContainer}
          });
        }),
      );
  }

  @Action(LoadContainerTypeAndSizes)
  loadContainerTypeAndSizes({patchState}: StateContext<ContainerManagementStateModel>) {
    return this.containerManagementService.getContainerTypeAndSize()
      .pipe(
        map(containerTypeAnsSizes => patchState({containerTypeAnsSizes}))
      );
  }

  @Action(LoadHSCodes)
  loadHSCodes({patchState}: StateContext<ContainerManagementStateModel>) {
    return this.containerManagementService.getHSCodes()
      .pipe(
        map(hsCodes => patchState({hsCodes}))
      );
  }

  @Action(UpdateContainer)
  updateContainer({patchState, getState, dispatch}: StateContext<ContainerManagementStateModel>,
                  {container}: UpdateContainer) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(s => s);
    // const waypoints = getState()?.waypoints;
    // if (waypoints) {
    //   container = {...container, legs: container.legs.map((leg, index) => ({...leg, wayPoints: waypoints[index]}))};
    // }
    return this.containerManagementService.updateContainer(orgId, jobFile.jobRefId, container)
      .pipe(
        map(() => {
          dispatch(new SetNotificationAction('Container updated successfully', AlertDialogType.SUCCESS));
        }),
        catchError(err => dispatch(new SetNotificationAction('Error updating container', AlertDialogType.ERROR)))
      );
  }

  @Action(UpdateShipmentOrdersData)
  moveOrdersData({dispatch, patchState}: StateContext<ContainerManagementStateModel>, {moveShipmentOrderData}: UpdateShipmentOrdersData) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(s => s);

    return this.containerManagementService.updateOrdersData(orgId, jobFile.jobRefId, moveShipmentOrderData)
      .pipe(
        map((jf) => {
          dispatch([new SetSpinner(false, null), new SetNotificationAction('Remove spec order successfully', AlertDialogType.SUCCESS)]);
        }),
        catchError(err => dispatch([new SetSpinner(false, null), new SetNotificationAction('Error removing spec order', AlertDialogType.ERROR)]))
      );
  }

  @Action(UpdateContainerByExpressWaysOption)
  updateContainerByExpressWays({dispatch, patchState, getState}: StateContext<ContainerManagementStateModel>, {
    containerId,
    useExpressWays
  }: UpdateContainerByExpressWaysOption) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(s => s);

    return this.containerManagementService.getUpdatedContainerByExpressWays(orgId, jobFile.jobRefId, containerId, useExpressWays)
      .pipe(
        tap(updatedContainer => {
          patchState({
            containers: {...getState().containers, [updatedContainer?.containerId]: updatedContainer}
          });
          this.containerManagementService.createFullRouteUsingLegsForContainer(updatedContainer);
          this.containerManagementService.updateLoadsSummary({...getState().containers, [updatedContainer?.containerId]: updatedContainer});
          dispatch(new SetSpinner(false));
        }),
        catchError(err => dispatch(new SetNotificationAction('Error updating container', AlertDialogType.ERROR)))
      );
  }

  @Action(UpdateMapDistanceByExpressWaysOption)
  updateMapDistanceByExpressWays({dispatch, patchState, getState}: StateContext<ContainerManagementStateModel>, {
    containerId,
    useExpressWays,
    legs
  }: UpdateMapDistanceByExpressWaysOption) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(s => s);
    return this.containerManagementService.getUpdatedMapDistanceByExpressWays(orgId, jobFile.jobRefId, containerId, useExpressWays, legs)
      .pipe(
        tap(updatedContainer => {
          patchState({
            containers: {...getState().containers, [updatedContainer?.containerId]: updatedContainer}
          });
          dispatch(new SetSpinner(false));
        }),
        catchError(err => dispatch(new SetNotificationAction('Error updating container', AlertDialogType.ERROR)))
      );
  }

  @Action(RemoveSpecOrder)
  removeSpecOrder({patchState, getState, dispatch}: StateContext<ContainerManagementStateModel>,
                  {container}: RemoveSpecOrder) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(s => s);
    // const waypoints = getState()?.waypoints;
    // if (waypoints) {
    //   container = {...container, legs: container.legs.map((leg, index) => ({...leg, wayPoints: waypoints[index]}))};
    // }
    return this.containerManagementService.updateContainer(orgId, jobFile.jobRefId, container)
      .pipe(
        map(() => {
          dispatch(new SetNotificationAction('Remove spec order successfully', AlertDialogType.SUCCESS));
        }),
        catchError(err => dispatch(new SetNotificationAction('Error removing spec order', AlertDialogType.ERROR)))
      );
  }

  @Action(AddSpecOrder)
  addSpecOrder({patchState, getState, dispatch}: StateContext<ContainerManagementStateModel>,
               {container}: AddSpecOrder) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(s => s);
    // const wayPoints = getState()?.waypoints;
    // if (wayPoints) {
    //   container = {...container, legs: container.legs.map((leg, index) => ({...leg, wayPoints: wayPoints[index]}))};
    // }
    return this.containerManagementService.updateContainer(orgId, jobFile.jobRefId, container)
      .pipe(
        map(() => {
          dispatch(new SetNotificationAction('Add spec order successfully', AlertDialogType.SUCCESS));
        }),
        catchError(err => dispatch(new SetNotificationAction('Error adding spec order', AlertDialogType.ERROR)))
      );
  }

  @Action(OptimizeOrderMovedContainers)
  optimizeOrderMovedContainers({getState, dispatch}: StateContext<ContainerManagementStateModel>,
                               {orgId, jobRefId, fromContainerId, toContainerId}: OptimizeOrderMovedContainers) {
    return this.containerManagementService.optimizeOrderMovedContainers(orgId, jobRefId, fromContainerId, toContainerId)
      .pipe(
        tap(() => {
          dispatch(new SetNotificationAction('Optimize containers successfully', AlertDialogType.SUCCESS));
        }),
        catchError(err => dispatch(new SetNotificationAction('Error optimizing containers', AlertDialogType.ERROR)))
      );
  }

  @Action(PartiallySaveContainerData)
  partiallySaveContainerData({dispatch}: StateContext<ContainerManagementStateModel>,
                             {container}: PartiallySaveContainerData) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(s => s);
    return this.containerManagementService.partiallySaveContainerData(orgId, jobFile.jobRefId, container)
      .pipe(
        map(() => {
          dispatch([
            new GetContainerWithContainerId(container.containerId),
            new SetNotificationAction('Container updated successfully', AlertDialogType.SUCCESS)
          ]);
        }),
        catchError(err => dispatch(new SetNotificationAction('Error updating container', AlertDialogType.ERROR)))
      );
  }

  @Action(UpdateContainerWaypoints)
  updateContainerWaypoints({patchState, getState, dispatch}: StateContext<ContainerManagementStateModel>,
                           {container}: UpdateContainerWaypoints) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(s => s);
    return this.containerManagementService.updateContainerWhenAddWayPoint(orgId, jobFile.jobRefId, container)
      .pipe(
        map(() => {
        }),
        catchError(err => dispatch(new SetNotificationAction('Error updating waypoints', AlertDialogType.ERROR)))
      );
  }

  // @Action(UndoContainerRoute)
  // undoContainerRoute({patchState, getState, dispatch}: StateContext<ContainerManagementStateModel>,
  //                    {containerId}: UndoContainerRoute) {
  //   const waypoints = getState()?.waypoints;
  //   let container = getState()?.containers[containerId];
  //   if (waypoints) {
  //     container = {...container, legs: container.legs.map((leg, index) => ({...leg, wayPoints: getState()?.lastWaypoints[index]}))};
  //   }
  //   return patchState({waypoints: getState()?.lastWaypoints, containers: {[containerId]: container}});
  // }

  @Action(SaveContainers)
  saveContainers({dispatch, getState}: StateContext<ContainerManagementStateModel>) {
    const {containers, order} = getState();

    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(s => s);
    return this.containerManagementService.createInitContainer(Object.values(containers), jobFile.jobRefId, orgId, order)
      .pipe(
        map((groupId: number) => {
          dispatch(new SetNotificationAction('Container saved successfully', AlertDialogType.SUCCESS));
          this.router.navigate([`quotation/generator/${jobFile.jobRefId}/${groupId}`]);
        }),
        catchError(err => dispatch(new SetNotificationAction('Error saving containers', AlertDialogType.ERROR)))
      );
  }

  @Action(GenerateLegsFromWizardLocations)
  generateLegsFromWizardLocations({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>,
                                  {locations}: GenerateLegsFromWizardLocations) {

    return this.containerManagementService.generateLegsFromWizardLocations(locations)
      .pipe(
        map(legs => patchState({legs})),
        mergeMap(() => dispatch(new SetNotificationAction('Generated Legs Successfully', AlertDialogType.SUCCESS))),
        catchError(err => dispatch(new SetNotificationAction('Legs generation was not successful', AlertDialogType.ERROR)))
      );
  }

  @Action(SaveInitQuotation)
  saveInitQuotation({dispatch}: StateContext<ContainerManagementStateModel>) {
    const {wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    return this.quotationService.saveInitQuotationFromWizard(jobFile.orgId, jobFile.jobRefId)
      .pipe(
        mergeMap(() => dispatch(new SetNotificationAction('Quotation saved successfully', AlertDialogType.SUCCESS))),
        catchError(err => dispatch(
          [new SetNotificationAction('Saving Quotation was unsuccessful', AlertDialogType.ERROR),
            new SetSpinner(false)]))
      );
  }

  @Action(LoadFumigators)
  loadFumigators({patchState, dispatch}: StateContext<ContainerManagementStateModel>) {
    const organizationFunction = ['Fumigator'];
    const {auth: {user: {orgId}}} = this.store.selectSnapshot(s => s);
    return this.containerManagementService.getFumigators(orgId, organizationFunction)
      .pipe(
        map(fumigators => patchState({fumigators})),
        catchError(err => dispatch(new SetNotificationAction('Error occurred while loading fumigators', AlertDialogType.ERROR)))
      );
  }

  @Action(DuplicateContainerFromWizard)
  duplicateContainerFromWizard({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>,
                               {containerId}: DuplicateContainerFromWizard) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    return this.containerManagementService.duplicateContainer(orgId, jobFile.jobRefId, containerId)
      .pipe(
        map(() => dispatch(new SetNotificationAction('Container duplicate successfully', AlertDialogType.SUCCESS))),
        catchError(err => dispatch(new SetNotificationAction('Duplicating Container was unsuccessful', AlertDialogType.ERROR)))
      );
  }

  @Action(GetContainerByOrder)
  getContainerByOrder({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>, {order}: GetContainerByOrder) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    return this.containerManagementService.getContainerByOrder(order, jobFile.jobRefId, orgId)
      .pipe(
        map(result => {
          return result.reduce((prev: EntityMap<string, AddContainer>, curr: AddContainer) => {
            prev[curr.containerId] = curr;
            return prev;
          }, {});
        }),
        map(containers => patchState({containers, order})),
        catchError(err => dispatch(new SetNotificationAction('Retrieving container failed', AlertDialogType.ERROR)))
      );
  }

  @Action(SaveContainer)
  saveContainer({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>) {
    const {containers, order} = getState();
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    return this.containerManagementService.saveInitContainer(Object.values(containers), jobFile.jobRefId, orgId, order)
      .pipe(
        map(returnedOrder => patchState({order: returnedOrder})),
        mergeMap(() => dispatch(new SetNotificationAction('Container Saved Successfully', AlertDialogType.SUCCESS))),
        catchError(err => dispatch(new SetNotificationAction('Container not Saved Successfully', AlertDialogType.ERROR)))
      );
  }

  @Action(SendForApproval)
  sendForApproval({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>) {
    const {containers, order} = getState();
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    return this.containerManagementService.sendForApproval(jobFile.jobRefId, orgId, order)
      .pipe(
        mergeMap(() => {
          return dispatch(new SetNotificationAction('Container Send for approval Successfully', AlertDialogType.SUCCESS));
        }),
        catchError(err => dispatch(new SetNotificationAction('Container not Send for approval Successfully', AlertDialogType.ERROR)))
      );
  }

  @Action(AddServiceAction)
  addService({dispatch}: StateContext<ContainerManagementStateModel>, {service, containerId, index}: AddServiceAction) {
    const {wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    return this.containerManagementService.addService(jobFile.orgId, jobFile.jobRefId, containerId, index,
      this.convertServiceViewModelToService(service))
      .pipe(
        map((newService) => {
          if (!newService) {
            return;
          }
          dispatch(new GetLocationById(newService.locationId));
          return dispatch(new SetNotificationAction('Service added successfully', AlertDialogType.SUCCESS));
        }),
        catchError(() => dispatch(new SetNotificationAction('Adding service is unsuccessful', AlertDialogType.ERROR)))
      );
  }

  @Action(LoadAlreadySavedRoutes)
  LoadAlreadySavedRoutes({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>,
                         {keyword}: LoadAlreadySavedRoutes) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    // return this.containerManagementService.getAlreadySavedRoutes(jobFile.jobRefId, orgId, keyword)
    return this.containerManagementService.getAlreadySavedRoutesByOperationId(jobFile.jobRefId, orgId, jobFile.operationId, keyword)
      .pipe(
        map((alreadySavedRoutes) => patchState({alreadySavedRoutes})),
        catchError((error) =>
          dispatch(new SetNotificationAction('Error occurred while loading already saved routes', AlertDialogType.ERROR)))
      );
  }

  @Action(SaveServiceSequenceTemplate)
  SaveContainerTemplate({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>,
                        {containerId, name}: SaveServiceSequenceTemplate) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    const alreadySavedRoutes = getState().alreadySavedRoutes;
    const template: ServiceSequenceTemplate = {
      id: null,
      templateId: null,
      jobRefId: jobFile.jobRefId,
      orgId,
      containerId,
      templateName: name,
      operationId: jobFile.operationId
    };
    return this.containerManagementService.saveServiceSequenceTemplate(template)
      .pipe(
        mergeMap((savedTemplate) => {
          patchState({alreadySavedRoutes: [...alreadySavedRoutes, savedTemplate]});
          return dispatch(new SetNotificationAction(`New Template Created : ${savedTemplate.templateName}`, AlertDialogType.SUCCESS));
        }),
        catchError((error) => {
          return dispatch(new SetNotificationAction('Error occurred while saving Container template', AlertDialogType.ERROR));
        })
      );
  }

  @Action(SaveServiceAction)
  saveService({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>,
              {containerId, serviceViewModel}: SaveServiceAction) {
    const {wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    return this.containerManagementService.updateService(jobFile.orgId, jobFile.jobRefId, containerId,
      this.convertServiceViewModelToService(serviceViewModel))
      .pipe(
        map((service) => {
          if (!service) {
            return;
          }
          dispatch(new GetLocationById(service.locationId));
          return dispatch(new SetNotificationAction('Service updated successfully', AlertDialogType.SUCCESS));
        }),
        catchError(() => dispatch(new SetNotificationAction('Updating service is unsuccessful', AlertDialogType.ERROR)))
      );
  }

  convertServiceViewModelToService(serviceView: ServiceViewModel): JobFileServiceModel {
    return {
      id: serviceView.id,
      serviceName: serviceView.serviceName,
      activityId: serviceView.activity.id,
      locationId: serviceView.locationDetails?.id,
      locationType: serviceView.locationDetails?.locationType,
      locationRelationshipType: serviceView.locationRelationshipType,
      loadingBay: '',
      canAddServiceAfter: serviceView.canAddServiceAfter, // TODO: Need to set this correctly
      estimatedDuration: serviceView.estimatedDuration,
      plannedStartDateTime: serviceView.requestedTimeOfArrival,
      plannedTimeToLeave: serviceView.requestedTimeOfLeave,
      positioningTime: serviceView.positioningTime,
      workOrders: serviceView.workOrders,
      driverRequired: serviceView.driverRequired,
      assistantRequired: serviceView.assistantRequired,
      vehicleRequired: serviceView.vehicleRequired,
      trailerRequired: serviceView.trailerRequired,
      deletable: serviceView.deletable,
      externalLocation: serviceView.externalLocation,
      billingActivated: serviceView.billingActivated,
      billingIndicatorType: serviceView.billingIndicatorType,
      otherRefNumbers: serviceView?.otherRefNumbers,
      orderNumbers: serviceView?.orderNumbers,
      orderNumbersMap: serviceView.orderNumbersMap,
      otherRefNumbersMap: serviceView.otherRefNumbersMap
    };
  }

  @Action(DeleteServiceAction)
  deleteServiceAction({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>,
                      {containerId, serviceId}: DeleteServiceAction) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(state => state);

    return this.containerManagementService.deleteService(orgId, jobFile.jobRefId, containerId, serviceId)
      .pipe(
        map((activities) => {
          dispatch(new SetNotificationAction('Successfully delete activity', AlertDialogType.SUCCESS));
        }),
        catchError(() => dispatch(new SetNotificationAction('Error save activity', AlertDialogType.ERROR)))
      );
  }

  @Action(ApplyServiceSequenceTemplate)
  applyServiceSequenceTemplate({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>,
                               {containerId, templateName}: ApplyServiceSequenceTemplate) {
    const {auth: {user: {orgId}}, wizard: {jobFile: {jobRefId}}} = this.store.selectSnapshot(state => state);
    return this.containerManagementService.applyServiceSequenceTemplate(orgId, jobRefId, containerId, templateName)
      .pipe(
        map((addContainer: AddContainer) =>
          dispatch(new SetNotificationAction('Successfully applied service sequence template', AlertDialogType.SUCCESS))),
        catchError(() => dispatch(new SetNotificationAction('Error applying service sequence template', AlertDialogType.ERROR)))
      );
  }

  @Action(RemoveServiceSequenceTemplate)
  removeServiceSequenceTemplate({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>,
                               {templateId}: RemoveServiceSequenceTemplate) {
    const {auth: {user: {orgId}}, wizard: {jobFile: {jobRefId}}} = this.store.selectSnapshot(state => state);
    return this.containerManagementService.removeServiceSequenceTemplate(templateId)
      .pipe(
        map((addContainer: AddContainer) =>{
          const template = getState().alreadySavedRoutes.find(route => route.id === templateId);
          patchState({alreadySavedRoutes: getState().alreadySavedRoutes.filter(route => route.templateId !== templateId)});
          dispatch(new SetNotificationAction(`Template removed successfully - ${template.templateName}`, AlertDialogType.SUCCESS))
        }),
        catchError(() => dispatch(new SetNotificationAction('Error removing service sequence template', AlertDialogType.ERROR)))
      );
  }

  @Action(UpdateServiceSequenceTemplate)
  UpdateContainerTemplate({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>,
                        {containerId, templateId, templateName}: UpdateServiceSequenceTemplate) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    const template: ServiceSequenceTemplate = {
      id: templateId,
      jobRefId: jobFile.jobRefId,
      orgId,
      containerId,
      templateName: templateName,
      operationId: jobFile.operationId,
      templateId
    };
    const alreadySavedRoutes = getState().alreadySavedRoutes;
    return this.containerManagementService.updateServiceSequenceTemplate(template)
      .pipe(
        map((res) => {
          dispatch(new SetNotificationAction(`Template Updated : ${res.templateName}`, AlertDialogType.SUCCESS))
          patchState({alreadySavedRoutes: [res]});
        }),
        catchError((error) => {
          return dispatch(new SetNotificationAction('Error occurred while updating Container template', AlertDialogType.ERROR));
        })
      );
  }

  @Action(AddNewContainerFromWizard)
  addNewContainerFromWizard({dispatch}: StateContext<ContainerManagementStateModel>,
    {vehicleData}: AddNewContainerFromWizard) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    return this.containerManagementService.addNewContainer(orgId, jobFile.jobRefId, vehicleData)
      .pipe(
        map((containers: AddContainer[]) => {
          dispatch(new SetNotificationAction('New Container adding successfully', AlertDialogType.SUCCESS))
        }),
        catchError(err => dispatch(new SetNotificationAction('Adding New Container was unsuccessful', AlertDialogType.ERROR)))
      );
  }

  @Action(CalculateRouteDetailsAction)
  calculateRouteDetailsAction({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>,
                              {location, containerId, serviceId}: CalculateRouteDetailsAction) {
    const selectedContainer: AddContainer = getState().containers[containerId];
    const serviceIndex: number = selectedContainer.services.findIndex(service => service.id === serviceId);
    if (serviceIndex <= 0) {
      return;
    }
    const {locationId} = selectedContainer.services[serviceIndex - 1];
    if (locationId === location.id) {
      return;
    }
    return this.containerManagementService.calculateRouteDetails(locationId, location.id)
      .pipe(
        map(result => {
          const updatedContainer = {
            ...selectedContainer, services: selectedContainer.services.map(service => {
              if (service.id === serviceId) {
                if (serviceIndex === 0) {
                  return;
                }
                const {plannedTimeToLeave} = selectedContainer.services[serviceIndex - 1];
                const {estimatedDuration} = selectedContainer.services[serviceIndex];
                const prevServiceTimeToLeave = new Date(plannedTimeToLeave);
                const plannedTimeToArrive = new Date(prevServiceTimeToLeave.getFullYear(), prevServiceTimeToLeave.getMonth(),
                  prevServiceTimeToLeave.getDate(), prevServiceTimeToLeave.getHours(), prevServiceTimeToLeave.getMinutes()
                  + result.duration / 60, prevServiceTimeToLeave.getSeconds());
                const newPlannedTimeToLeave = new Date(prevServiceTimeToLeave.getFullYear(), prevServiceTimeToLeave.getMonth(),
                  prevServiceTimeToLeave.getDate(), prevServiceTimeToLeave.getHours(), prevServiceTimeToLeave.getMinutes()
                  + result.duration / 60 + estimatedDuration, prevServiceTimeToLeave.getSeconds());
                return {
                  ...service,
                  travelTime: result.duration / 60,
                  plannedStartDateTime: plannedTimeToArrive,
                  plannedTimeToLeave: newPlannedTimeToLeave
                };
              }
              return service;
            })
          };
          patchState({...getState(), containers: {...getState().containers, [containerId]: updatedContainer}});
        }),
        catchError(() => dispatch(new SetNotificationAction('Error getting calculated route details', AlertDialogType.ERROR)))
      );
  }

  @Action(RemoveContainerFromWizard)
  removeContainerFromWizard({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>,
                            {containerId}: RemoveContainerFromWizard) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(state => state);

    return this.containerManagementService.removeContainer(orgId, jobFile.jobRefId, containerId)
      .pipe(
        map(() => {
          const notification = (jobFile.workflowId === DISTRIBUTION_WF) ? 'Load has been successfully removed' : 'Container Removing successfully';
          dispatch([new SetSpinner(false), new SetNotificationAction(notification, AlertDialogType.SUCCESS)])
          const { [containerId]: removedContainer, ...remainingContainers } = getState().containers;
          patchState({...getState(), containers: remainingContainers});
        }),
        catchError(err => dispatch(new SetNotificationAction('Removing Container was unsuccessful', AlertDialogType.ERROR)))
      );
  }

  @Action(EditServiceAction)
  editService({patchState, getState}: StateContext<ContainerManagementStateModel>,
              {containerId, serviceViewModel}: EditServiceAction) {
    const selectedService = {...getState().containers[containerId].services.find(service => service.id === serviceViewModel.id)};
    patchState({selectedService});
  }

  @Action(ChangeActivityOnEditServiceAction)
  changeActivityOnEditService({patchState, getState}: StateContext<ContainerManagementStateModel>,
                              {
                                activity,
                                newService,
                                billingActivated,
                                billingIndicatorType,
                                containerId,
                                serviceId
                              }: ChangeActivityOnEditServiceAction) {
    if (!activity?.id) {
      return;
    }

    const {wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    return this.containerManagementService.generateDryServiceFromActivityId(activity.id, billingActivated, billingIndicatorType, jobFile?.jobRefId, containerId, serviceId)
      .pipe(
        tap((service: JobFileServiceModel) => {
          if (!newService) {
            service.id = getState().selectedService.id;
          }
          patchState({selectedService: service});
        })
      );
  }

  @Action(UpdateCustomsChannelAndAddDryServiceAction)
  updateChannelAndAddDryService({dispatch}: StateContext<ContainerManagementStateModel>,
                                {activityId, containerId, index, customsChannel}: UpdateCustomsChannelAndAddDryServiceAction) {
    const {wizard: {jobFile}} = this.store.selectSnapshot(state => state);

    return this.containerManagementService
      .updateChannelAndAddDryService(jobFile?.orgId, jobFile?.jobRefId, containerId, index, activityId, customsChannel, jobFile?.operationId, jobFile?.workflowId)
      .pipe(
        map((newService) => {
          if (!newService) {
            return;
          }
          return dispatch(new SetNotificationAction('Service added successfully', AlertDialogType.SUCCESS));
        }),
        catchError(() => dispatch(new SetNotificationAction('Adding service is unsuccessful', AlertDialogType.ERROR)))
      );
  }

  @Action(CleanSelectedServiceAction)
  cleanSelectedService({patchState}: StateContext<ContainerManagementStateModel>) {
    return patchState({selectedService: null});
  }

  @Action(UpdateServiceTimeAction)
  updateServiceTime({dispatch, patchState, getState}: StateContext<ContainerManagementStateModel>,
                    {containerId, serviceId, type, duration}: UpdateServiceTimeAction) {
    const selectedContainer: AddContainer = getState().containers[containerId];
    const updatedContainer = {
      ...selectedContainer, services: selectedContainer.services.map(service => {
        if (service.id === serviceId) {
          const plannedTimeToLeave = new Date(service.plannedTimeToLeave);
          const newPlannedTimeToLeave = new Date(plannedTimeToLeave.getFullYear(), plannedTimeToLeave.getMonth(),
            plannedTimeToLeave.getDate(), plannedTimeToLeave.getHours(), plannedTimeToLeave.getMinutes()
            + duration, plannedTimeToLeave.getSeconds());
          return {...service, plannedTimeToLeave: newPlannedTimeToLeave, estimatedDuration: duration};
        }
        return service;
      })
    };
    patchState({...getState(), containers: {...getState().containers, [containerId]: updatedContainer}});
  }

  @Action(UseExpressWayAction)
  useExpressWay({dispatch, patchState, getState}: StateContext<ContainerManagementStateModel>,
                {containerId, useTolls, legIndex}: UseExpressWayAction) {
    const selectedContainer: AddContainer = getState().containers[containerId];
    const updatedContainer = {
      ...selectedContainer, legs: selectedContainer.legs.map((leg: InitLeg, index: number) => {
        if (index === legIndex) {
          return {...leg, useTolls};
        }
        return leg;
      })
    };
    patchState({...getState(), containers: {...getState().containers, [containerId]: updatedContainer}});
  }

  @Action(AddContainerBulk)
  addContainerBulk({dispatch, patchState, getState}: StateContext<ContainerManagementStateModel>,
                   {containers}: AddContainerBulk) {
    const {auth: {user: {orgId}}, wizard: {jobFile: {jobRefId}}} = this.store.selectSnapshot(state => state);
    const {order} = getState();
    return this.containerManagementService
      .saveContainerBulk(orgId, jobRefId, containers, order)
      .pipe(
        mergeMap(res => dispatch(new SetNotificationAction('Successfully added container bulk', AlertDialogType.SUCCESS))),
        catchError(res => dispatch(new SetNotificationAction('Error occurred while saving container bulk', AlertDialogType.ERROR)))
      );
  }

  @Action(RemoveAllContainers)
  removeAllContainers({dispatch, getState}: StateContext<ContainerManagementStateModel>) {
    const {auth: {user: {orgId}}, wizard: {jobFile: {jobRefId}}} = this.store.selectSnapshot(state => state);
    const {order} = getState();
    return this.containerManagementService
      .removeAllContainers(orgId, jobRefId, order)
      .pipe(
        mergeMap(res => dispatch(new SetNotificationAction('Successfully Removed All containers', AlertDialogType.SUCCESS))),
        catchError(res => dispatch(new SetNotificationAction('Error Occcured while removing containers', AlertDialogType.ERROR)))
      );
  }

  @Action(UpdateCustomsChannelAction)
  updateCustomsChannel({dispatch}: StateContext<ContainerManagementStateModel>, {containerId, customsChannel}: UpdateCustomsChannelAction) {
    const {wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    return this.containerManagementService.updateCustomsChannel(jobFile.orgId, jobFile.jobRefId, containerId, customsChannel)
      .pipe(
        mergeMap(res => dispatch(new SetNotificationAction('Customs channel updated successfully', AlertDialogType.SUCCESS))),
        catchError(() => dispatch(new SetNotificationAction('Updating customs channel is unsuccessful', AlertDialogType.ERROR)))
      );
  }

  @Action(UpdateChannelAndDeleteServiceAction)
  updateChannelAndDeleteServiceAction({dispatch, getState, patchState}: StateContext<ContainerManagementStateModel>,
                                      {containerId, serviceId, customsChannel}: UpdateChannelAndDeleteServiceAction) {
    const {auth: {user: {orgId}}, wizard: {jobFile}} = this.store.selectSnapshot(state => state);

    return this.containerManagementService.updateChannelAndRemoveService(orgId, jobFile.jobRefId, containerId, serviceId, customsChannel)
      .pipe(
        map((response) => {
          dispatch(new SetNotificationAction('Successfully update channel and delete service', AlertDialogType.SUCCESS));
        }),
        catchError(() => dispatch(new SetNotificationAction('Error delete activity', AlertDialogType.ERROR)))
      );
  }

  @Action(SaveWaypoints)
  saveWaypoints({patchState, getState}: StateContext<ContainerManagementStateModel>, {containerId, undoStack, redoStack}: SaveWaypoints) {
    patchState({
      undoWaypoints: {...getState().undoWaypoints, [containerId]: undoStack},
      redoWaypoints: {...getState().redoWaypoints, [containerId]: redoStack}
    });
  }

  // @Action(ResetWaypoints)
  // resetWaypoints({patchState, getState}: StateContext<ContainerManagementStateModel>) {
  //   return patchState({waypoints: null, lastWaypoints: null});
  // }

  // @Action(ResetLastWaypoints)
  // resetLastWaypoints({patchState}: StateContext<ContainerManagementStateModel>) {
  //   return patchState({lastWaypoints: null});
  // }

  @Action(ResetContainerManagementState)
  resetContainerManagementState({patchState}: StateContext<ContainerManagementStateModel>) {
    return patchState(getInitialContainerMgtState());
  }

  @Action(SetBillingAction)
  setBilling({dispatch, patchState}: StateContext<ContainerManagementStateModel>,
             {containerId, serviceId, billingInfo}: SetBillingAction) {
    const {wizard: {jobFile}} = this.store.selectSnapshot(state => state);
    const {wizard: {containerManagement: {containers}}} = this.store.selectSnapshot(state => state);
    const isActive = containers[containerId].services.some((service) => {
      return billingInfo.billingIndicator !== service.billingIndicatorType && service.billingActivated;
    });
    if (isActive) {
      return this.containerManagementService.setBilling(jobFile.orgId, jobFile.jobRefId, containerId, serviceId, billingInfo)
        .pipe(
          map((response) => {
            dispatch(new SetNotificationAction('Successfully set billing info', AlertDialogType.SUCCESS));
          }),
          catchError(() => dispatch(new SetNotificationAction('Error updating billing info', AlertDialogType.ERROR)))
        );
    } else {
      const billingDisplayMap = {
        BILLING_START: 'Billing Start',
        BILLING_END: 'Billing End'
      };
      const billingWarningDisplayMap = {
        BILLING_START: 'Billing End',
        BILLING_END: 'Billing Start'
      };

      dispatch(new SetNotificationAction('Please set ' + billingWarningDisplayMap[billingInfo.billingIndicator] + ' to change ' + billingDisplayMap[billingInfo.billingIndicator], AlertDialogType.WARNING));
    }
  }

  @Action(SetFilledDataOnDialogClose)
  saveFilledData({patchState, getState}: StateContext<ContainerManagementStateModel>,
                 {container}: SetFilledDataOnDialogClose) {
    const selectedContainer: AddContainer = {
      ...getState().containers[container.containerId],
      selectionMethod: container?.selectionMethod,
      typeAndSize: container?.typeAndSize
    };
    patchState({
      containers: {...getState().containers, [container.containerId]: selectedContainer}
    });
  }
}
