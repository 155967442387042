import {Component, Input, OnInit} from '@angular/core';
import {PrincipalContact} from '@shared/models/principalContact.model';

@Component({
  selector: 'hmt-contact-view',
  templateUrl: './contact-view.component.html',
  styleUrls: ['./contact-view.component.scss']
})
export class ContactViewComponent implements OnInit {
  @Input() contact: PrincipalContact;

  constructor() { }

  ngOnInit(): void {
  }
}
