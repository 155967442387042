import {Injectable} from '@angular/core';
import {SetNotificationAction} from '@core/store/notification/notification.actions';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {AlertDialogType} from '@shared/models';
import {RateTier} from '@shared/models/rate-tier.model';
import {RateCardService} from '@shared/services/rate-card.service';
import {Organization} from 'app/modules/organization/models/organization';
import {OrganizationService} from 'app/modules/organization/services/organization.service';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {RateCardManagerStateModel} from '../models/state/rate-card-manager-state.model';
import {
  DeleteRateCard,
  FetchCountries,
  FetchOrganizations,
  FetchRateCards,
  FetchSupplierOrganizations,
  GetAllTiersAction,
  GetDistanceMatrixTemplateOperationListWise,
  RemoveVehicleTierAction,
  SaveRateCard,
  SaveVehicleTierAction,
  UpdateVehicleTierAction
} from './rate-card-manager.actions';
import {RateCard} from '@shared/models/rate-card.model';
import {Pageable} from '@shared/models/pageable.model';
import { Country } from '@shared/models/country.model';
import { ContractManagementService } from 'app/modules/contract-management/services/contract-management.service';
import { MatrixDataTemplate } from 'app/modules/organization/models/matrix-data.model';

export const getRateCardManagerInitialState = (): RateCardManagerStateModel => ({
  organizations: [],
  rateCards: [],
  vehicleTiers: [],
  pageableRateCard: {
    pageNo: 0,
    pageSize: 10,
    totalElements: 0,
  },
  pageableRateTier: {
    pageNo: 0,
    pageSize: 10,
    totalElements: 0,
  },
  currencies: [],
  distanceMatrixTemplates: []
});

@State<RateCardManagerStateModel>({
    name: 'rateCards',
    defaults: getRateCardManagerInitialState()
})

@Injectable()
export class RateCardManagerState {

  constructor(private organizationService: OrganizationService,
              private rateCardService: RateCardService,
              private store: Store, private contractManagementService: ContractManagementService){}

  @Selector()
  static getTotalElementsOfRateCard(state: RateCardManagerStateModel): number {
    return +state.pageableRateCard.totalElements;
  }

  @Selector()
  static getPageNoOfRateCard(state: RateCardManagerStateModel): number {
    return +state.pageableRateCard.pageNo;
  }

  @Selector()
  static getPageSizeOfRateCard(state: RateCardManagerStateModel): number {
    return +state.pageableRateCard.pageSize;
  }

  @Selector()
  static getTotalElementsOfRateTier(state: RateCardManagerStateModel): number {
    return +state.pageableRateTier.totalElements;
  }

  @Selector()
  static getPageNoOfRateTier(state: RateCardManagerStateModel): number {
    return +state.pageableRateTier.pageNo;
  }

  @Selector()
  static getPageSizeOfRateTier(state: RateCardManagerStateModel): number {
    return +state.pageableRateTier.pageSize;
  }

  @Selector()
  static vehicleRateTiers(state: RateCardManagerStateModel): RateTier[] {
    return state.vehicleTiers;
  }

  @Selector()
  static getOrganizations(state: RateCardManagerStateModel): Organization[] {
    return state.organizations || [];
  }

  @Selector()
  static getCurrencies(state: RateCardManagerStateModel): string[] {
    return state.currencies || [];
  }

  @Selector()
  static getRateCards(state: RateCardManagerStateModel): RateCard[] {
    return state.rateCards || [];
  }

  @Selector()
  static getSpecificRateCard(state: RateCardManagerStateModel): (id: string) => RateCard {
    return (id) => {
      return state.rateCards.find(rateCard => rateCard.rateCardId === id);
    };
  }

  @Selector()
  static getDistanceMatrixTemplates(state: RateCardManagerStateModel): MatrixDataTemplate[] {
    return state.distanceMatrixTemplates || [];
  }

  @Action(FetchSupplierOrganizations)
  fetchSupplierOrganizations({patchState, dispatch}: StateContext<RateCardManagerStateModel>, { orgType, verticals, searchKey}: FetchSupplierOrganizations) {
    const {user: {orgId}} = this.store.selectSnapshot(state => state.auth);
    return this.contractManagementService.findOrgPartnersAndOnBoardedOrganizations(orgId, orgType, searchKey, verticals)
    .pipe(
      map((organizations: Organization[]) => patchState({organizations:organizations})),
      catchError(() => dispatch(new SetNotificationAction('Error loading organizations', AlertDialogType.ERROR)))
    );
  }

  @Action(FetchOrganizations)
  fetchOrganizations({patchState, dispatch}: StateContext<RateCardManagerStateModel>) {
    const {user: {orgId}} = this.store.selectSnapshot(state => state.auth);
    return this.organizationService.getPartnerOrgs()
      .pipe(
        map((organizations: Organization[]) => patchState({organizations})),
        catchError(err => dispatch(new SetNotificationAction('Error loading organizations', AlertDialogType.ERROR)))
      );
  }

  @Action(SaveRateCard)
  saveRateCard({getState, patchState, dispatch}: StateContext<RateCardManagerStateModel>, {rateCard}: SaveRateCard) {
    const rateTiers = getState().vehicleTiers;
    if (rateCard.vehicleTier) {
      const tier = rateTiers.find(t => t.tierId == rateCard.vehicleTier);
      rateCard.tierName = tier?.name || ''
    }

    return this.rateCardService.saveRateCard(rateCard).pipe(
      mergeMap(res => dispatch(new SetNotificationAction('RateCard Saved Successfully', AlertDialogType.SUCCESS))),
      catchError(error => dispatch(new SetNotificationAction('Saving RateCard was not Successful', AlertDialogType.ERROR)))
    );
  }

  @Action(FetchRateCards)
  fetchRateCards({dispatch, patchState}: StateContext<RateCardManagerStateModel>, {selectedOrgId, pageSize, pageIndex}: FetchRateCards) {
    const {user: {orgId}} = this.store.selectSnapshot(state => state.auth);
    return this.rateCardService.getRateCards(selectedOrgId ? [selectedOrgId] : null, false, pageIndex, pageSize)
      .pipe(
        tap((pageableRateCards: Pageable<RateCard>) => {
          patchState({
            rateCards: pageableRateCards.content,
            pageableRateCard: {
              pageNo: pageableRateCards.pageable.pageNumber,
              pageSize: pageableRateCards.pageable.pageSize,
              totalElements: pageableRateCards.totalElements
            }
          });
        }),
        catchError(err => dispatch(new SetNotificationAction('Error while loading rate cards', AlertDialogType.ERROR)))
      );
  }

  @Action(DeleteRateCard)
  deleteRateCard({dispatch, patchState}: StateContext<RateCardManagerStateModel>, {rateCardId}: DeleteRateCard) {
    return this.rateCardService.deleteRateCard(rateCardId)
      .pipe(
        tap(() => {
          dispatch(new SetNotificationAction('Successfully deleted the rate card', AlertDialogType.SUCCESS));
        }),
        catchError(err => dispatch(new SetNotificationAction('Deleting vehicle was not successful', AlertDialogType.ERROR)))
      );
  }

  @Action(GetAllTiersAction)
  loadVehicleTiers({getState, patchState, dispatch}: StateContext<RateCardManagerStateModel>, {pageSize, pageIndex}: GetAllTiersAction) {
    const {user: {userId, orgId}} = this.store.selectSnapshot(authState => authState.auth);
    return this.rateCardService.getPaginateTiers(orgId, pageSize, pageIndex).pipe(
      tap((pageableRateTier: Pageable<RateTier>) => {
        patchState({
          vehicleTiers: pageableRateTier.content,
          pageableRateTier: {
            pageNo: pageableRateTier.pageable.pageNumber,
            pageSize: pageableRateTier.pageable.pageNumber,
            totalElements: pageableRateTier.totalElements
          }
        });
      }),
      catchError(err => dispatch(new SetNotificationAction('Error while loading rate tiers', AlertDialogType.ERROR)))
    );
  }

  @Action(SaveVehicleTierAction)
  saveTire({patchState, dispatch, getState}: StateContext<RateCardManagerStateModel>, {vehicleTier}: SaveVehicleTierAction) {
    return this.rateCardService.saveTier(vehicleTier).pipe(
      tap((tier: RateTier) => {
        const state = getState();
        patchState({
          vehicleTiers: [...state.vehicleTiers, tier]
        });
      }),
      mergeMap(res => dispatch(new SetNotificationAction('Tier Saved Successfully', AlertDialogType.SUCCESS))),
      catchError(error => dispatch(new SetNotificationAction('Saving Tier was not Successful', AlertDialogType.ERROR)))
    );
  }

  @Action(UpdateVehicleTierAction)
  updateTier({patchState, dispatch, getState}: StateContext<RateCardManagerStateModel>, {vehicleTier}: UpdateVehicleTierAction) {
    return this.rateCardService.updateTier(vehicleTier).pipe(
      tap((tier: RateTier) => {
        const state = getState();
        patchState({
          vehicleTiers: state.vehicleTiers.map(t => {
            if (t.tierId === tier.tierId) {
              return tier;
            }
            return t;
          })
        });
      }),
      mergeMap(res => dispatch(new SetNotificationAction('Tier Updated Successfully', AlertDialogType.SUCCESS))),
      catchError(error => dispatch(new SetNotificationAction('Updating Tier was not Successful', AlertDialogType.ERROR)))
    );
  }

  @Action(RemoveVehicleTierAction)
  removeTier({patchState, dispatch, getState}: StateContext<RateCardManagerStateModel>, {vehicleTierId}: RemoveVehicleTierAction) {
    return this.rateCardService.removeTier(vehicleTierId).pipe(
      tap(() => {
        const state = getState();
        patchState({
          vehicleTiers: state.vehicleTiers.filter(t => {
            return t.tierId != vehicleTierId;
          })
        });
      }),
      mergeMap(res => dispatch(new SetNotificationAction('Vehicle Tier Remove Successfully', AlertDialogType.SUCCESS))),
      catchError(error => dispatch(new SetNotificationAction('Removing Tier was not Successful', AlertDialogType.ERROR)))
    );
  }

  @Action(FetchCountries)
  fetchCountries({dispatch, patchState}: StateContext<RateCardManagerStateModel>, {searchKey}: FetchCountries) {
    return this.organizationService.fetchCurrencies()
      .pipe(
        map(currencies => {
          return patchState({currencies})
        }),
        catchError(error => dispatch(new SetNotificationAction('Error Loading currencies', AlertDialogType.ERROR)))
      ).subscribe()
  }

  @Action(GetDistanceMatrixTemplateOperationListWise)
  getDistanceMatrixTemplateOperationListWise({patchState, dispatch, getState}: StateContext<RateCardManagerStateModel>, {searchKey, operationIds}: GetDistanceMatrixTemplateOperationListWise) {
    const {user: {orgId, operations}} = this.store.selectSnapshot(state => state.auth);
   let operationIdList = operations;
    if(operationIds.length > 0){
      operationIdList = operationIds;
    }
    return this.contractManagementService.getDistanceMatrixTemplateOperationListWise(orgId, searchKey, operationIdList).pipe(
      tap((templateList: MatrixDataTemplate[]) => {
        patchState({distanceMatrixTemplates: templateList});
      }),
      catchError(error => dispatch(new SetNotificationAction('Fetching distance matrix templates not successful', AlertDialogType.ERROR)))
    );
  }
}
