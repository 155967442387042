<div class="map-geo-fence-speed-container">
  <div *ngIf="isEditableMap" class="row-1">
    <mat-form-field appearance="outline" class="address-input">
      <mat-label>Enter Location</mat-label>
      <input type="text"
             matInput
             [formControl]="formControl"
             placeholder="Enter Location"
             #addressText>
    </mat-form-field>
    <button mat-button mat-raised-button class="clear-button" (click)="clearGeoFence()" color="accent">
      Clear Geo Fence
    </button>
  </div>
  <hmt-map class="map" #mapComponent [isGeoFenceSpeedLimitMap]="true" (geoFence)="saveDrawnGeoFence($event)"></hmt-map>
</div>