import {Component, Input, OnInit} from '@angular/core';
import {JobFile} from '@core/models/job-file';
import {JobSummary} from '../../../modules/wizard/models/job-summary.model';
import {JobType} from '../../../modules/wizard/models/enums/job-type.enum';
import {JobInformation} from '../../../modules/wizard/models/job-information.model';
import {FIELD_SERVICES} from '@configs/constants';

@Component({
  selector: 'hmt-job-imformation',
  templateUrl: './job-imformation.component.html',
  styleUrls: ['./job-imformation.component.scss']
})
export class JobImformationComponent implements OnInit {
  data: JobInformation;

  @Input('data') set setData(jobInformation: JobInformation) {
    if (jobInformation != null) {
      this.data = jobInformation;
      this.initializeData();
    }
  }

  transportationType: { name: string, url: string };
  jobType: { name: string, url: string };
  containerLoadType: { name: string, url: string };
  fieldServicesWorkflowId = FIELD_SERVICES;
  constructor() {
  }

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    this.getTransportationType();
    this.getJobType();
    this.getContainerLoadType();
  }

  getTransportationType(): void {
    if (this.data?.workflowId?.startsWith('OF')) {
      this.transportationType = {
        name: 'Ocean Freight',
        url: 'hm-icon-ship-3d'
      };
    } else if (this.data?.workflowId?.startsWith('AF')) {
      this.transportationType = {
        name: 'Air Freight',
        url: 'hm-font-plane'
      };
    } else if (this.data?.workflowId?.startsWith('LF')) {
      this.transportationType = {
        name: 'Local Haulage',
        url: 'local-haulage'
      };
    } else {
      this.transportationType = {
        name: '',
        url: ''
      };
    }
  }

  getJobType(): void {
    const jobType = this.data?.workflowId?.split('-');
    if (jobType && jobType.length > 0) {
      if ('EX' === jobType[1]) {
        this.jobType = {
          name: JobType.EXPORT,
          url: 'hm-icon-export'
        };
      } else if ('IM' === jobType[1]) {
        this.jobType = {
          name: JobType.IMPORT,
          url: 'hm-icon-import'
        };
      } else if ('LF' === jobType[0] && 'LH' === jobType[1]) {
        this.jobType = {
          name: JobType.Container_Haulage,
          url: 'lh-job-type'
        };
      } else if ('LF' === jobType[0] && 'RP' === jobType[1]) {
        this.jobType = {
          name: JobType.Container_Repositioning,
          url: 'lh-job-type'
        };
      } else if ('LF' === jobType[0] && 'RM' === jobType[1]) {
        this.jobType = {
          name: JobType.Container_Removal,
          url: 'lh-job-type'
        };
      }
      else if ('LF' === jobType[0] && 'DO' === jobType[1]) {
        this.jobType = {
          name: JobType.DOMESTIC,
          url: 'lh-job-type'
        };
      }
      else if ('LF' === jobType[0] && 'DI' === jobType[1]) {
        this.jobType = {
          name: 'Distribution',
          url: 'distribution'
        };
      }
      else if ('LF' === jobType[0] && 'RL' === jobType[1] && 'FTL' !== jobType[2]) {
        this.jobType = {
          name: JobType.Container_Relocation,
          url: 'lh-job-type'
        };
      } else if ('LF' === jobType[0] && 'FTL' === jobType[2]) {
        this.jobType = {
          name: JobType.Full_Truck_Load,
          url: 'lh-job-type'
        };
      } else {
        this.jobType = {
          name: '',
          url: ''
        };
      }
    }
  }

  getContainerLoadType(): void {
    const containerLoadType = this.data?.workflowId?.split('-');
    if (containerLoadType && containerLoadType.length > 0) {
      if ('FCL' === containerLoadType[2]) {
        this.containerLoadType = {
          name: 'Full Container Load (FCL)',
          url: 'hm-font-fcl'
        };
      } else if ('LCL' === containerLoadType[2]) {
        this.containerLoadType = {
          name: 'Less Than Container Load (LCL)',
          url: 'hm-font-lcl'
        };
      }
    }
  }
}
