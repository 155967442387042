import {Vehicle} from '@shared/models/vehicle';
import {VehicleType} from '@schedule/models/enums';
import { Organization } from '../../models/organization';
import { FileInfo } from '@shared/models/file-info';

export const VEHICLES_ACTION_PREFIX = '[Vehicles State]';

export class AddVehicle {
  static readonly type = `${VEHICLES_ACTION_PREFIX} Add a vehicle of organization`;

  constructor(public vehicle: Vehicle) {
  }
}

export class UploadVehiclePhoto {
  static readonly type = `${VEHICLES_ACTION_PREFIX} Upload Vehicle Photo`;
  constructor(public file: File, public selectedOrg: Organization) {
  }
}

export class UploadVehicleDocuments {
  static readonly type = `${VEHICLES_ACTION_PREFIX} Upload Vehicle Documents`;
  constructor(public files: File[], public selectedOrg: Organization) {
  }
}

export class RemoveVehicleDocument {
  static readonly type = `${VEHICLES_ACTION_PREFIX} Remove Vehicle Document`;
  constructor(public fileId: string) {
  }
}

export class FetchVehiclePhoto {
  static readonly type = `${VEHICLES_ACTION_PREFIX} Fetch Vehicle Photo`;
  constructor(public fileId: string) {
  }
}

export class FetchVehicleDocuments {
  static readonly type = `${VEHICLES_ACTION_PREFIX} Fetch Vehicle Documents`;
  constructor(public files: FileInfo[], public toView: boolean = false) {
  }
}

export class GetVehiclesOfOrganization {
  static readonly type = `${VEHICLES_ACTION_PREFIX} Get all vehicles of an organization`;

  constructor(public orgId: string) {
  }
}

export class DeleteVehicleOfOrganization {
  static readonly type = `${VEHICLES_ACTION_PREFIX} Delete a vehicle of an organization`;

  constructor(public id: string) {
  }
}

export class GetVehiclesByResourceSelectionTypeAndVehicleType {
  static readonly type = `${VEHICLES_ACTION_PREFIX} Get vehicles by resource selection type and vehicle type`;

  constructor(public vehicleTypes: VehicleType[]) {
  }
}

export class GetAllRateTiers {
  static readonly type = `${VEHICLES_ACTION_PREFIX} Get all rate tiers for vehicle`;

  constructor(public orgId: string) {
  }
}


export class GetVehicleCategories {
  static readonly type = `${VEHICLES_ACTION_PREFIX} fetch vehicle categories`;

  constructor(public isStandard?: boolean) {
  }

}

export class GetVehicleCategoryIdByName {
  static readonly type = `${VEHICLES_ACTION_PREFIX} fetch vehicle category id by name`;

  constructor(public vehicleCategoryName: string) {
  }
}