<div class="change-currency col full-width-height">
  <div class="cc-header row">
    <p class="title">Convert Currency</p>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="cc-content col">
    <form [formGroup]="form" class="full-width">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Currency</mat-label>
        <input type="text" matInput formControlName="currency" [matAutocomplete]="auto" [autofocus]="false">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let currency of filteredCurrencies | async" [value]="currency">
            {{currency}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Rate</mat-label>
        <input #rateInput matInput formControlName="rate" type="number" (input)="validateRate($event)" min="0" step="0.001" [autofocus]="false">
        <mat-error *ngIf="form.controls['rate'].hasError('pattern')">
          Rate must be a positive number with up to 10 decimal places.
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <mat-divider></mat-divider>
  <div class="cc-footer row">
    <button mat-stroked-button color="warn" (click)="close()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onApplyCurrency()">Convert</button>
  </div>
</div>
