import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NgxsModule} from '@ngxs/store';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';

import {environment} from '@env/environment';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {HttpInterceptorService} from '@core/services/http-interceptor.service';
import {AuthState} from '@core/store/auth/auth.state';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NotificationState} from '@core/store/notification/notification.state';
import {CookieService} from 'ngx-cookie-service';
import {JobFileService} from '@core/services/job-file.service';
import {SharedState} from '@core/store/shared.state';
import {MatMenuModule} from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SharedModule } from '@shared/shared.module';
// import { AppWrapperComponent } from '../app-wrapper.component';
// import { MatListModule } from '@angular/material/list';
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { DialogHandlerService } from './services/dialog-handler.service';

@NgModule({
  declarations: [],
    imports: [
        // here import the modules that we need to import once in the application
        HttpClientModule,
        BrowserAnimationsModule,
        NgxsModule.forRoot([AuthState, NotificationState, SharedState], {
            developmentMode: !environment.production
        } as any),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production
        }),
        // NgxsLoggerPluginModule.forRoot({
        //   disabled: environment.production
        // }),
        // TODO: check how to use this
        NgxsStoragePluginModule.forRoot({
            key: 'auth'
        }),
        // translator module
        SharedModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatMenuModule
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    CookieService,
    JobFileService,
  ],
  exports: []
})
export class CoreModule {}
