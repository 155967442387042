import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {Person} from '@shared/models/person.model';

@Component({
  selector: 'hmt-assign-supervisor',
  templateUrl: './assign-supervisor.component.html',
  styleUrls: ['./assign-supervisor.component.scss']
})
export class AssignSupervisorComponent implements OnInit {
  title = 'REQUEST QUOTE INTERNALLY';
  workType = 'tansport work';
  clickedSupervisor: Person;
  message: string;
  @Output() selectedSupervisor: EventEmitter<{ supervisor: Person, message: string }> =
    new EventEmitter<{ supervisor: Person, message: string }>();

  constructor(
    private dialogRef: MatDialogRef<AssignSupervisorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      supervisors: Observable<Person[]>
    }
  ) {
  }

  ngOnInit(): void {
  }

  selectSupervisor(supervisor: Person) {
    this.clickedSupervisor = supervisor;
  }

  assignSupervisor() {
    this.selectedSupervisor.emit({
      supervisor: this.clickedSupervisor,
      message: this.message
    });
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
