import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '@shared/components/snack-bar/snack-bar.component';
import {MatSnackBarRef} from '@angular/material/snack-bar/snack-bar-ref';
import {MatSnackBarConfig} from '@angular/material/snack-bar/snack-bar-config';
import {AlertDialogType} from '@shared/models';
import {SNACK_BAR_DURATION} from '@configs/constants';
import { NotificationComponent } from '@shared/components/notification/notification.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarHandlerService {

  readonly defaultSnackBarOptions: MatSnackBarConfig = {
    duration: SNACK_BAR_DURATION,
    horizontalPosition: 'right',
    verticalPosition: 'top',
  };

  constructor(public snackBar: MatSnackBar) { }

  public showNotification(notificationData: any, type: AlertDialogType, config = this.defaultSnackBarOptions) {
    return this.snackBar.openFromComponent(NotificationComponent, {
      ...this.defaultSnackBarOptions,
      ...config,
      data: notificationData,
      panelClass: this.getPanelClass(type)
    });
  }

  public openFromComponent(type: AlertDialogType, message: string, config = this.defaultSnackBarOptions)
    : MatSnackBarRef<SnackBarComponent> {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      ...this.defaultSnackBarOptions,
      ...config,
      data: { type, message },
      panelClass: this.getPanelClass(type)
    });
  }

  public dismissAll(): void {
    this.snackBar.dismiss();
  }

  private getPanelClass(type: AlertDialogType): string {
    switch (type) {
      case AlertDialogType.SUCCESS:
        return AlertDialogType[AlertDialogType.SUCCESS].toLowerCase();
      case AlertDialogType.ERROR:
        return AlertDialogType[AlertDialogType.ERROR].toLowerCase();
      case AlertDialogType.WARNING:
        return AlertDialogType[AlertDialogType.WARNING].toLowerCase();
      default:
        return '';
    }
  }
}
