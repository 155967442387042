import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {User} from '@core/models/user.model';
import { Subject, combineLatest } from 'rxjs';
import {map, mergeMap, take, takeUntil, tap, withLatestFrom} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  jobUpdatesCollection = 'JOB_UPDATES';


  constructor(private firestore: AngularFirestore) {
  }

  getNotifications(orgId: string, jobRefId: string) {
    const date = new Date();
    return this.firestore.collection(orgId)
    .doc('JOB_UPDATES')
    .collection(jobRefId,
      ref => ref.where('createdAt', '>=', date))
    .stateChanges();
    // .snapshotChanges();
  }

  getLiveLocationsOfDifferentOrgVehicles(vehiclesData: Array<{orgId: string, containerId: string}>, unsubscribe: Subject<void>) {
    return vehiclesData.map(v => {
      return this.firestore
        .collection(v.orgId)
        .doc('VEHICLES')
        .collection('LOCATION_CHANGES', ref => ref.where('containerId', '==', v.containerId)
        .limit(1))
        .valueChanges()
        .pipe(takeUntil(unsubscribe));
    });
  }

  getVehiclesLiveLocations(orgId: string, operationId?: string) {
    const d =  this.firestore
    .collection(orgId)
    .doc('VEHICLES');

    if (!operationId) {
      return d.collection('LOCATION_CHANGES')
        .snapshotChanges();
    }

    return d.collection('LOCATION_CHANGES', ref => ref.where('operationIds', 'array-contains', operationId))
      .snapshotChanges();
  }

  getVehiclesLiveLocationByIds(orgId: string, vehicleIds: string[]) {
    const chunkSize = 10;
    const vehicleIdChunks = [];
    for (let i = 0; i < vehicleIds.length; i += chunkSize) {
        vehicleIdChunks.push(vehicleIds.slice(i, i + chunkSize));
    }

    const queries = vehicleIdChunks.map(chunk =>
        this.firestore
            .collection(orgId)
            .doc('VEHICLES')
            .collection('LOCATION_CHANGES', ref => ref.where('id', 'in', chunk))
            .valueChanges()
    );

    return combineLatest(queries).pipe(
        map(results => [].concat(...results))
    );
}

  getVehicleLiveLocation(orgId: string, containerId: string) {
     return this.firestore
      .collection(orgId)
      .doc('VEHICLES')
      .collection('LOCATION_CHANGES', ref => ref.where('containerId', '==', containerId))
       .valueChanges();
  }

  getUnplannedStops(orgId: string, jobRefId: string, jpmId: string) {
    return this.firestore
      .collection(orgId)
      .doc(this.jobUpdatesCollection)
      .collection(jobRefId, ref => ref.where('jpmId', '==', jpmId).where('updatedNotificationType', '==', 'UNPLANNED_STOP'))
      .stateChanges();
  }

  fetchMoreNotifications(notificationLimit: number, user: User) {
    const first = this.firestore.collection(user.orgId).doc('PERSONAL_NOTIFICATIONS').collection(user.userId,
      ref => ref.orderBy('createdAt', 'desc').limit(notificationLimit));
    return first.get()
      .pipe(
        take(1),
        mergeMap((documentSnapshots) => {
          const lastOne = documentSnapshots.docs[documentSnapshots.docs.length - 1];
          const x = this.firestore.collection(user.orgId).doc('PERSONAL_NOTIFICATIONS').collection(user.userId,
            ref => ref.orderBy('createdAt', 'desc').startAfter(lastOne).limit(notificationLimit)).snapshotChanges();
          return x;
        }),
      );
  }

  getInitialNotifications(notificationLimit: number, user: User) {
    return this.firestore.collection(user.orgId).doc('PERSONAL_NOTIFICATIONS').collection(user.userId,
      ref => ref.orderBy('createdAt', 'desc').limit(notificationLimit)).stateChanges();
  }
}
