import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  getObject(obj: object, path: string, defaultValue?: any)
    : string | number | object {
    const defaulttValue: object = this.isDefined(defaultValue) ? defaultValue : undefined;
    if (!this.isObject(obj) || !this.isString(path)) {
      return defaulttValue;
    }

    const paths = this.splitPathToParts(path);
    if (paths.length === 0) {
      return;
    }

    for (const key of paths) {
      if (this.isArray(obj) && !this.isIndex(key)) {
        obj = this.isDefined(obj[key]) ? obj[key] : undefined ;
      } else {
        obj = obj[key];
      }

      if (obj == null) {
        break;
      }
    }

    return this.isDefined(obj) ? obj : defaulttValue;
  }

  splitPathToParts(path: string): string[] {
    if (typeof path !== 'string') {
      return [];
    }

    return path
      .split(/[.]|(?:\[(\d|\*)\])/)
      // filter out false values like empty string, undefine
      .filter(item => !!item);
  }

  isArray(a): boolean {
    return Array.isArray(a);
  }

  isDefined(o): boolean {
    return typeof o !== 'undefined';
  }

  isString(k): boolean {
    return typeof k === 'string';
  }

  isIndex(k): boolean {
    return /^\d+$/.test(k);
  }

  isObject(o): boolean {
    return typeof o === 'object';
  }
}
