<div class="contact-view-container">
  <div class="column-1">
    <div class="title">
      PRINCIPLE CONTACT
      <div class="divider"></div>
    </div>
    <div class="row-1">
      <div class="name property-title">
        Name: <span class="property-value name-value">{{contact?.firstName}} {{contact?.lastName}}</span>
      </div>
      <div class="mobile property-title">
        Mobile: <span class="property-value mobile-value">{{contact?.mobile}}</span>
      </div>
    </div>
  </div>
  <div class="column-2">
    <div class="title">
      CONTACT DETAILS
      <div class="divider"></div>
    </div>
    <div class="row-1">
      <div class="address property-title">
        Address:
        <div class="address-content property-value">{{contact?.officeAddress?.addressLine}} {{contact?.officeAddress?.city}}</div>
      </div>
      <div class="contact-details">
        <div class="phone property-title">
          Phone: <span class="property-value phone-value">{{contact?.officeContact?.contactNumber}}
        <span class="property-title">  EXT: {{contact?.officeContact?.extension}}</span>
        </span>
        </div>
        <div class="fax property-title">
          Fax: <span class="property-value fax-value">{{contact?.faxNumber}}</span>
        </div>
        <div class="email property-title">
          Email: <span class="property-value email-value">{{contact?.email}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
