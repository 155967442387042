<div class="jobs-wrapper col">
  <div class="header row va-c" [ngClass]="{'header-boarder': headerBoarder}">
    <div class="title bold"> {{title}} </div>
    <div class="search">
      <form [formGroup]="jobFileSearchForm">
        <mat-form-field appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input formControlName="searchText" matInput placeholder="Search">
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="job-list">
    <mat-list role="list" [ngStyle]="{'height.px': visibleJobsCount * 72}">
      <mat-list-item class="job" role="listitem"
        [ngClass]="{'selected': selectedJobIndex == i}"
        (click)="onSelectJob(job, i)"
        *ngFor="let job of jobs; let i = index;">
        <!-- <ng-container *ngIf="enableMultiSelect"> -->
          <mat-checkbox [hidden]="!enableMultiSelect" (change)="tickChange($event, job, i)" mat-list-icon (click)="$event.stopPropagation();"></mat-checkbox>
        <!-- </ng-container> -->
        <div mat-line class="job-title bold">{{job?.jobTitle}} {{job?.createdTimestamp | date}} | {{job?.jobType}}</div>
        <div mat-line class="job-id"> Job Ref Id: {{job?.jobRefId}} </div>
        <mat-chip selected class="customer">{{job?.owner?.name}}</mat-chip>
        <div class="logo row va-c ha-c" >
          <ng-container *ngIf="job.customer && job.customer.orgId && logosMap[job.customer.orgId]">
            <img [src]="logosMap[job.customer.orgId]">
          </ng-container>
        </div>
        <div class="options" *ngIf="enableOptions">
          <button class="option-btn row va-c ha-c" (click)="$event.stopPropagation()" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Job Operations">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="moveTo(job, i)" mat-menu-item>
              <span class="uppercase" disabled>Move Job File</span>
            </button>
            <button (click)="rename(job, i)" mat-menu-item>
              <span class="uppercase" disabled>Rename</span>
            </button>
            <button (click)="terminate(job, i)" mat-menu-item disabled>
              <span class="uppercase">Terminate</span>
            </button>
          </mat-menu>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>

<div class="full-width row va-c ha-c job-paginator">
  <mat-paginator #paginator (page)="onPaginate($event)"
      [length]="paginationData?.totalElements"
      [pageSize]="paginationData.pageSize"
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page">
  </mat-paginator>
</div>