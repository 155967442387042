import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroPad'
})
export class ZeroPadPipe implements PipeTransform {

  transform(value: number, length: number = 2): string {
    // Convert the number to a string
    let strValue = value.toString();

    // Pad the string with leading zeros if its length is less than the specified length
    while (strValue.length < length) {
      strValue = '0' + strValue;
    }

    return strValue;
  }
}
