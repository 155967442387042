<div class="flex row hmt-notification">
    <div class="flex col icon">
        <mat-icon class="error" *ngIf="data?.type == matSnackBarTye.ERROR">error_outline</mat-icon>
        <mat-icon class="warning" *ngIf="data?.type == matSnackBarTye.WARNING">warning</mat-icon>
        <mat-icon class="success" *ngIf="data?.type == matSnackBarTye.SUCCESS">check_circle</mat-icon>
    </div>
    <div class="flex col content">
        <div class="title">{{data?.title}}</div>
        <div class="message">{{data?.message}}</div>
    </div>
    <div class="close-btn">
        <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
    </div>
</div>