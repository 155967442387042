import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {API, formatter} from '@configs/api.config';
import {WorkflowComponent, WorkflowConfig, WorkflowStep} from '../models/workflow-config.model';
import {JobCompletionStep} from '../models/job-completion-step';
import {Field, MandatoryFieldMap} from '../models/mandatory-field-map.model';
import {WorkflowData} from '../models/workflow-data.model';
import {WorkOrderDefinition} from '@control-tower/models/work-order/work-order-definition.model';
import {AddContainer} from '../models/add-container.model';
import {StepZeroData} from '../models/step-data.model';
import {WorkFlowDefinition} from '../models/work-flow-definition';
import {EntityMap} from '@shared/models/types';
import {WorkFlowComponent} from '../models/workflow/work-flow-component';
import {CustomsChannel} from '../models/enums/customs-channel.enum';

@Injectable({
  providedIn: 'root'
})
export class WorkFlowConfigurationService {

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getValidationMessage(): Observable<any> {
    const url = API.assets.getErrorMessage;
    return this.httpClient.get(url);
  }

  getRelevantConfig(workFlow: string): Observable<any> {
    const url = API.assets.getConfigRelevantToWorkFlow.format([workFlow]);
    return this.httpClient.get(url);
  }

  // getWorkflowConfigByOrgIdAndWorkflowId(orgId: string, workflowId: string): Observable<WorkflowConfig[]> {
  //   const url = API.workflow.getWorkflowConfigByWorkflowId.format([orgId, workflowId]);
  //   return this.httpClient.get<WorkflowConfig[]>(url);
  // }

  // getWorkflowDataByOrgIdAndJobRefId(orgId: string, jobRefId: string): Observable<WorkflowData[]> {
  //   const url = API.workflow.getWorkflowData.format([orgId, jobRefId]);
  //   return this.httpClient.get<WorkflowData[]>(url);
  // }

  generateJobFileCompletionSteps(workflowConfig: WorkflowConfig,
                                 mandatoryFieldMap: MandatoryFieldMap, workflowData: StepZeroData): JobCompletionStep[] {
    return workflowConfig.steps
      .map(workflowStep => {
        return {
          title: workflowStep.titile,
          name: null,
          order: workflowStep.stepNum,
          filledMandatory: true,
          subSteps: (workflowStep.component || [])
            .filter(workflowComponent => workflowComponent.options != null)
            .map(workflowComponent => {
              let components = [];
              if (workflowComponent.options.multi) {
                components = (workflowComponent.options.components || [])
                  .filter((nestedWorkflowComponent: WorkflowComponent, index: number) => {
                    return !!workflowComponent.options.availability[index]
                      && nestedWorkflowComponent.options && nestedWorkflowComponent.options.percentage;
                  });
              }
              if (workflowComponent.options.percentage) {
                components.push(workflowComponent);
              }
              return components
                .map((nestedWorkflowComponent: WorkflowComponent) => {
                  return {
                    title: nestedWorkflowComponent.label,
                    name: nestedWorkflowComponent.name,
                    order: workflowStep.stepNum,
                    filledMandatory: this.isMandatoryFieldFilled(mandatoryFieldMap, workflowData,
                      workflowStep.stepNum, nestedWorkflowComponent.name)
                  };
                });
            }).flat()
        };
      });
  }

  isMandatoryFieldFilled(mandatoryFieldMap: MandatoryFieldMap, workflowData: StepZeroData, order: number, name: string): boolean {
    return (mandatoryFieldMap[order][name] && !workflowData[name]);
  }

  generateMandatoryFieldsMap(workflowConfig: WorkflowConfig): MandatoryFieldMap {
    return workflowConfig.steps
      .reduce((mandatoryFieldMap: MandatoryFieldMap, currentStep: WorkflowStep) => {
        const fields: Field = {};
        (currentStep.component || [])
          .filter(workflowComponent => workflowComponent.options != null)
          .forEach(workflowComponent => {
            // have not checked the mandatory field of nested component
            if (workflowComponent.options.multi) { // nested components
              (workflowComponent.options.components || [])
                .filter((nestedWorkflowComponent: WorkflowComponent, index: number) => {
                  return workflowComponent.options.validate ? !!workflowComponent.options.validate[index] : false;
                })
                .forEach((nestedWorkflowComponent: WorkflowComponent, index: number) => {
                  fields[nestedWorkflowComponent.name] = true;
                });
            } else if (workflowComponent.options.mandatory && !workflowComponent.options.displayText) { // first level components
              fields[workflowComponent.name] = true;
            }
          });
        mandatoryFieldMap[currentStep.stepNum] = fields;
        return mandatoryFieldMap;
      }, {});
  }

  generateMandatoryFieldMapAfterStepTwo(steps: EntityMap<number, WorkFlowComponent[]>): MandatoryFieldMap {
   return Object.entries(steps)
     .reduce((mandatoryFieldMap: MandatoryFieldMap, [key, components]) => {
       const fields: Field = {};
       components
         .filter(component => component.options != null)
         .forEach((res) => {
           if (!res.options.components || res.options.components.length === 0) {
             if (res.options.availability && res.options.validate) {
               fields[res.name] = res.options.validate;
             }
           } else {
             res.options.components.forEach(comp => {
               if (comp.options.availability && comp.options.validate) {
                 fields[comp.name] = comp.options.validate;
               }
             });
           }
         });
       mandatoryFieldMap[+key] = fields;
       return mandatoryFieldMap;
     }, {});
  }

  validateAddContainerFields(addContainer: AddContainer, mandatoryFieldMap: MandatoryFieldMap): boolean {
    return Object.keys(mandatoryFieldMap['3']).some(property => !!addContainer[property]);
  }

  getActivityList(): Observable<WorkOrderDefinition[]> {
    const url = API.jobFile.activityList;
    return this.httpClient.get<WorkOrderDefinition[]>(url);
  }

  saveActivity(): Observable<boolean> {
    return of(true);
  }

  getWorkFlowSteps(workFlowId: string): Observable<EntityMap<number, WorkFlowComponent[]>> {
    const url = API.workflow.getWorkFlowSteps.format([workFlowId]);
    return this.httpClient.get<EntityMap<number, WorkFlowComponent[]>>(url);
  }

  getCustomsChannels(workFlowId: string): Observable<CustomsChannel[]> {
    const url = API.workflow.getCustomsChannels.format([workFlowId]);
    return this.httpClient.get<CustomsChannel[]>(url);
  }

  // TODO: move this to jobFile service
  confirmJobSummary(jobRefId: string): Observable<void> {
    const url = API.workflow.getCustomsChannels.format([jobRefId]);
    return this.httpClient.get<void>(url);
  }

  getWorkflowDefinitionV2(workflowId: string): Observable<WorkFlowDefinition>{
    const url = API.jobFile.loadWorkflowDefinition.format([workflowId]);
    return this.httpClient.get<WorkFlowDefinition>(url);
  }
}
