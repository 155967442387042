export enum ResourceType {
  VEHICLE = 'VEHICLE',
  DRIVER = 'DRIVER',
  ASSISTANT = 'ASSISTANT',
  TRAILER = 'TRAILER',
  PRIME_MOVER = 'PRIME_MOVER',
  TRUCK = 'TRUCK',
  SUPERVISOR = 'SUPERVISOR',
  FLEETMANAGER = 'FLEETMANAGER',
  YARD_MANAGER = 'YARD_MANAGER',
  EXECUTIVE = 'EXECUTIVE',
  MECHANIC = 'MECHANIC',
  TRAINEE_EXECUTIVE = 'TRAINEE_EXECUTIVE',
  OFFICE_ASSISTANT = 'OFFICE_ASSISTANT',
  HAULMATIC_BA = 'HAULMATIC_BA'
}
