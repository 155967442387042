<div class="job-information-container">
  <div class="job-details">
    <div class="job-title job-details-title-text">
      Job Title
      <div class="job-details-title-sub-text job-title-text">{{data?.jobTitle}}</div>
    </div>
    <div class="job-reference job-details-title-text">
      Job Reference Number
      <div class="job-details-title-sub-text job-reference-text">{{data?.jobRefId}}</div>
    </div>
    <div class="job-type job-details-title-text">
      Job Type
      <div class="job-type-display job-details-title-sub-text">{{data?.jobType}}</div>
    </div>
    <div class="customer-reference job-details-title-text">
      Reference Number
      <div class="job-details-title-sub-text customer-reference-text">{{data?.customer?.customerReferenceNo}}</div>
    </div>
  </div>
  <div class="service-selection">
    service selection
  </div>
  <div class="job-type-details" *ngIf="data?.workflowId !== fieldServicesWorkflowId">
    <div class="job-type-details-item">
      <span class="job-details-icon hm-icon {{transportationType?.url}}"></span>
      <span class="job-type-method"> {{transportationType?.name | titlecase}} </span>
    </div>
    <mat-icon *ngIf="!this.data?.workflowId?.startsWith('LF')">5</mat-icon >
    <div class="job-type-details-item" *ngIf="!this.data?.workflowId?.startsWith('LF')">
      <span class="job-details-icon hm-icon {{containerLoadType?.url}}"></span>
      <span class="container-load-type">{{containerLoadType?.name}} </span>
    </div>
    <mat-icon >add</mat-icon>
    <div class="job-type-details-item">
      <span class="job-details-icon hm-icon {{jobType?.url}}"></span>
      <span class="job-method">{{jobType?.name | titlecase}} </span>
    </div>
  </div>
  <div class="job-type-details" *ngIf="data?.workflowId === fieldServicesWorkflowId">
    <div class="job-type-details-item">
      <span class="job-details-icon hm-icon local-haulage"></span>
      <span class="job-type-method"> Field Service </span>
    </div>
    <mat-icon>5</mat-icon >
    <div class="job-type-details-item" >
      <span class="container-load-type">Field Assignment</span>
    </div>
  </div>
</div>
<br>


