<div class="order-list">
  <table mat-table [dataSource]="orders" class="mat-elevation-z8" width="100%">
    <ng-container matColumnDef="orderNo">
      <th mat-header-cell *matHeaderCellDef> Order No </th>
      <td mat-cell *matCellDef="let element"> {{element.orderNo}} </td>
    </ng-container>

    <ng-container matColumnDef="shipmentNumber">
      <th mat-header-cell *matHeaderCellDef> Shipment Number </th>
      <td mat-cell *matCellDef="let element"> {{element?.itemRefId}} </td>
    </ng-container>

    <ng-container matColumnDef="itemName">
      <th mat-header-cell *matHeaderCellDef> Item Name </th>
      <td mat-cell *matCellDef="let element"> {{element.itemName}} </td>
    </ng-container>

    <ng-container matColumnDef="packagingType">
      <th mat-header-cell *matHeaderCellDef> Packaging Type </th>
      <td mat-cell *matCellDef="let element"> {{element.packagingType}} </td>
    </ng-container>

    <ng-container matColumnDef="numberOfPackages">
      <th mat-header-cell *matHeaderCellDef> Number Of Packages </th>
      <td mat-cell *matCellDef="let element"> {{element?.quantity}} </td>
    </ng-container>

    <ng-container matColumnDef="dimensions">
      <th mat-header-cell *matHeaderCellDef> Dimensions
        (cm) </th>
      <td mat-cell *matCellDef="let element"> {{element.dimensions}} </td>
    </ng-container>

    <ng-container matColumnDef="requiredSpace">
      <th mat-header-cell *matHeaderCellDef> Required Space
        (cm) </th>
      <td mat-cell *matCellDef="let element" matTooltip="{{element?.cbm}}"> {{element?.cbm | number: '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="totalCBM">
      <th mat-header-cell *matHeaderCellDef> Total CBM </th>
      <td mat-cell *matCellDef="let element" matTooltip="{{element.totalCBM}}"> {{element.totalCBM | number: '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="grossWeight">
      <th mat-header-cell *matHeaderCellDef> Gross Weight(Kg) </th>
      <td mat-cell *matCellDef="let element"> {{element.weight | number: '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="totalWeight">
      <th mat-header-cell *matHeaderCellDef> Total Weight(Kg) </th>
      <td mat-cell *matCellDef="let element"> {{element.weight * element.quantity | number: '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="pickup">
      <th mat-header-cell *matHeaderCellDef> Pickup </th>
      <td mat-cell *matCellDef="let element"> {{element.pickup}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let element">
        <div class="row va-c ha-c visibility-icon-wrapper">
          <mat-icon class="eye-icon">visibility</mat-icon>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <!-- <div class="row va-c ha-c view-all-wrapper">
    <div class="row view-all uppercase">
      <mat-icon>arrow_down</mat-icon>
      <label>view all</label>
    </div>
  </div> -->
</div>
