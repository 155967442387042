import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[hmtAppAutoTab]'
})
export class AppAutoTabDirective implements AfterViewInit{

  constructor(private el: ElementRef) { }

  @Input() hmtAppAutoTabInput;

  @HostListener('input', ['$event.target']) onInput(input) {
    const length = input.value.length;
    const maxLength = input.attributes.maxlength.value;
    if (length >= maxLength ) {
      this.hmtAppAutoTabInput.focus();
    }
  }

  ngAfterViewInit() {
    if (this.el.nativeElement.attributes[4].value === 'ownerCodeCharOne'){
      this.el.nativeElement.focus();
    }
  }

}
