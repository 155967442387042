import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {KeyValueMap} from '../../models/freight-detail.model';

@Component({
  selector: 'hmt-freight-details',
  templateUrl: './key-value.component.html',
  styleUrls: ['./key-value.component.scss']
})
export class KeyValueComponent implements OnInit {
  items: Array<{ key: string, value: string }> = [];
  highlight: boolean;
  header: string;
  @Input() data: { freightDetails: KeyValueMap[], title: string };
  index = 0;
  @Output() output = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    this.highlight = true;
  }

  clickEvent(click: string) {
    this.output.emit({title: 'freight details', value: 12345});
  }
}
