<div class="customer-details-container">
  <div class="header-container">
    <div class="header">
      <div class="customer-data">
        <div>
          <div>
            <h1>{{data.title}}</h1>
          </div>
          <div class="customer-name customer-name-title">
            {{data.orgInfo?.name}}
            <span class="customer-name-text">
             {{data.orgInfo?.tickerCode}}
          </span>
          </div>
        </div>
        <div class="mid-flex-grow"></div>
        <div>
          <div class="company-logo">
            <img src="{{data?.orgInfo?.logoUrl}}" class="logo" alt="logo" *ngIf="data?.orgInfo?.logoUrl">
            <img src="assets/logo/no-image.svg" class="logo" alt="logo" *ngIf="!data?.orgInfo?.logoUrl">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="detail-box">
    <div class="detail-box-content">
      <div class="detail-box-data">
        <div class="detail-box-header">
          <div>CONTACT DETAILS</div>
          <div class="detail-divider">
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="detail-box-body">
          <div class="content-column">
            <p class="address">Address</p>
            {{data?.orgInfo?.address}}, <br>
            <!--            {{customerInfo?.contactDetails?.address?.line2}}, <br>-->
            <!--            {{customerInfo?.contactDetails?.address?.line3}}.-->
          </div>
          <div class="content-column">
            <p class="phone">
              Phone: <span class="span-data" *ngIf="data.orgInfo?.contactNumbers != null && data.orgInfo?.contactNumbers.length > 0">
              {{data.orgInfo?.contactNumbers[0]}}
            </span>
            </p>
            <!--            <p class="fax">Fax: <span class="span-data"> {{customerInfo?.contactDetails?.fax}}</span></p>-->
            <!--            <p class="email">Email: <span class="span-data"> {{customerInfo?.contactDetails?.email}}</span></p>-->
          </div>
        </div>
      </div>
      <div class="detail-box-data">
        <div class="detail-box-header">
          <div>PRINCIPLE CONTACT</div>
          <div class="detail-divider">
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="detail-box-body">
          <div class="content-column">
            <p class="name">Principal Contact Name: <span class="span-data"> {{data.orgInfo?.principleContactName}}</span></p>
          </div>
          <div class="content-column">
            <p class="phone">Phone: <span class="span-data"> {{data.orgInfo?.principleContactMobile}}</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-property">
        <p>Company Reg: <span class="span-data"> #{{data?.orgInfo?.companyRegNum}}</span></p>
      </div>
      <div class="footer-property">
        <p>VAT: <span class="span-data"> #{{data?.orgInfo?.vatNum}}</span></p>
      </div>
      <div class="footer-property">
        <p>TIN: <span class="span-data"> #{{data?.orgInfo?.tinNum}}</span></p>
      </div>
    </div>
  </div>
<!--  <div class="customs-house-agent" *ngIf="data?.customsHouseAgent">-->
<!--    <div class="title">-->
<!--      <h2>CUSTOMS HOUSE AGENT</h2>-->
<!--    </div>-->
<!--    <div class="contact">-->
<!--      <div class="contact-property">-->
<!--        <p>Name: <span class="span-data"> #{{customerInfo?.customsHouseAgent?.name}}</span></p>-->
<!--      </div>-->
<!--      <div class="contact-property">-->
<!--        <p>Mobile: <span class="span-data"> #{{customerInfo?.customsHouseAgent?.mobile}}</span></p>-->
<!--      </div>-->
<!--      <div class="contact-property">-->
<!--        <p>Email: <span class="span-data"> #{{customerInfo?.customsHouseAgent?.email}}</span></p>-->
<!--      </div>-->
<!--      <div class="contact-property">-->
<!--        <p>CHA Number: <span class="span-data"> #{{customerInfo?.customsHouseAgent?.cha}}</span></p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>

<br><br>
