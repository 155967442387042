import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MapComponent} from '@shared/components/map/map.component';
import {Point} from '@shared/models/point';
import {Polygon} from '@shared/models/polygon';
import {OrganizationLocation} from '../../../modules/organization/models/organization-location';
import {LocationType} from '../../../modules/organization/models/enums/location-type';
import {Observable, Subject} from 'rxjs';
import {LineString} from '@shared/models/line-string';
import {takeUntil, tap} from 'rxjs/operators';
import {LatLng} from '@shared/models/enums/lat-lng.model';

@Component({
  selector: 'hmt-map-draw-geo-fence',
  templateUrl: './map-draw-geo-fence.component.html',
  styleUrls: ['./map-draw-geo-fence.component.scss']
})
export class MapDrawGeoFenceComponent implements OnInit, AfterViewInit, OnDestroy {
  currentGeoFence: Polygon | LineString;
  selectedDrawingManager: google.maps.drawing.DrawingManager;
  existingLocation: OrganizationLocation;
  currentLocationType: LocationType;
  searchedLocation;
  private unsubscribe = new Subject<void>();

  polygonDrawingManager: google.maps.drawing.DrawingManager = new google.maps.drawing.DrawingManager({
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [google.maps.drawing.OverlayType.POLYGON]
    }
  });

  polylineDrawingManager: google.maps.drawing.DrawingManager = new google.maps.drawing.DrawingManager({
    drawingMode: google.maps.drawing.OverlayType.POLYLINE,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [google.maps.drawing.OverlayType.POLYLINE]
    }
  });

  @ViewChild('mapComponent', {static: false}) mapComponent: MapComponent;

  @Input('searchedLocation') set setSearchedLocation(obj: { geoPoint: Point, location: LocationType }) {
    if (obj?.geoPoint && obj?.location && this.mapComponent) {
      this.searchedLocation = obj;
      this.mapComponent.setBounds([new LatLng(obj?.geoPoint?.coordinates[1], obj?.geoPoint?.coordinates[0])]);
      this.produceDrawingManagerBasedOnLocType(obj);
      this.mapComponent.drawGeoFence(obj.geoPoint);
    }
  }

  @Input('reEnteredLocation') set reEnteredLocation(obj: { geoPoint: Point, location: LocationType }) {
    if (obj?.geoPoint && obj?.location && this.mapComponent) {
      this.mapComponent.removeMarkers();
      this.mapComponent.removeDrawingManagerOptions();
      this.mapComponent.removeGeoFence(this.getDrawingManagerOptionsFromLocType(this.currentLocationType)?.getDrawingMode());
      this.mapComponent.addMarker({
        position: new google.maps.LatLng(obj?.geoPoint?.coordinates[1], obj?.geoPoint?.coordinates[0])
      }, false);
      this.mapComponent.setBounds([new LatLng(obj.geoPoint.coordinates[1], obj.geoPoint.coordinates[0])]);
      this.produceDrawingManagerBasedOnLocType(obj);
    }
  }

  @Input('existingGeoFence') set setExistingGeoFence(location: OrganizationLocation) {
    this.existingLocation = location;
    if (location?.geofence && location?.locationType) {
      this.currentGeoFence = (location?.geofence) ? location?.geofence : location.geoLine;
      const shape = this.createShapeFromLocationTypeAndDrawingManager(location);
      this.mapComponent.drawSavedGeoFence(shape, location.geolocation);
    }
  }

  @Input('parentGeoFence') set setParentGeoFence(location: OrganizationLocation) {
    if (location?.geofence && location?.locationType) {
      const shape = this.createShapeFromLocationTypeAndDrawingManager(location);
      this.mapComponent.drawParentGeoFence(shape);
    }
  }

  @Input() clearMap: Observable<boolean>;

  @Input() navigateBack: Observable<boolean>;

  @Output() drawnGeoFence = new EventEmitter<Polygon | LineString>();

  constructor() {
  }

  ngOnInit(): void {
    this.listenToNavigateBack();
    this.listenToClearMap();
  }

  produceDrawingManagerBasedOnLocType(obj: { geoPoint: Point, location: LocationType }) {
    this.currentLocationType = obj.location;
    if (obj.location === LocationType.GATE) {
      this.selectedDrawingManager = this.polylineDrawingManager;
      this.selectedDrawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
      this.mapComponent.initializeDrawingManager(this.polylineDrawingManager);
    } else {
      this.selectedDrawingManager = this.polygonDrawingManager;
      this.selectedDrawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
      this.mapComponent.initializeDrawingManager(this.polygonDrawingManager);
    }
  }

  getDrawingManagerOptionsFromLocType(locationType: LocationType) {
    if (locationType === LocationType.GATE) {
      return this.polylineDrawingManager;
    }
    return this.polygonDrawingManager;
  }

  listenToClearMap(): void {
    this.clearMap
      .pipe(
        takeUntil(this.unsubscribe),
        tap(res => {
          if (res) {
            this.mapComponent.removeGeoFence(this.getDrawingManagerOptionsFromLocType(this.currentLocationType)
              .get('drawingControlOptions').drawingModes[0]);
          }
        })
      )
      .subscribe();
  }

  listenToNavigateBack() {
    this.navigateBack
      .pipe(
        takeUntil(this.unsubscribe),
        tap(res => {
          if (res) {
            this.mapComponent.initMap();
            this.mapComponent.removeGeoFence(this.getDrawingManagerOptionsFromLocType(this.currentLocationType)
              .get('drawingControlOptions').drawingModes[0]);
            this.mapComponent.removeParentGeoFence();
          }
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
  }

  clearGeoFence() {
    this.mapComponent.removeGeoFence(this.getDrawingManagerOptionsFromLocType(this.currentLocationType)
      .get('drawingControlOptions').drawingModes[0]);
    this.mapComponent.initMap();
    if (this.searchedLocation?.geoPoint) {
      const coordinate = new google.maps.LatLng(this.searchedLocation?.geoPoint?.coordinates[1],
        this.searchedLocation?.geoPoint?.coordinates[0]);
      const markerOptions: google.maps.MarkerOptions = {
        position: coordinate
      };
      this.mapComponent.addMarker(markerOptions);
      this.mapComponent.map.setCenter(coordinate);
      this.mapComponent.map.setZoom(16);
      this.produceDrawingManagerBasedOnLocType(this.searchedLocation);
    }
  }

  saveDrawnGeoFence(shape: Polygon | LineString) {
    this.currentGeoFence = shape;
    this.drawnGeoFence.emit(shape);
  }

  createShapeFromLocationTypeAndDrawingManager(location: OrganizationLocation): google.maps.Polygon | google.maps.Polyline {
    if (location.locationType === LocationType.GATE) {
      const polylineConfig: google.maps.PolylineOptions = {
        path: location.geoLine.coordinates.map(res => ({lng: res[0], lat: res[1]})),
        editable: true
      };
      this.selectedDrawingManager = this.polylineDrawingManager;
      this.mapComponent.initializeDrawingManager(this.polylineDrawingManager);
      return new google.maps.Polyline(polylineConfig);
    }
    const polygonConfig: google.maps.PolygonOptions = {
      editable: true,
      paths: location.geofence.coordinates.flat()
        .map(res => ({lng: res[0], lat: res[1]}))
    };
    this.selectedDrawingManager = this.polygonDrawingManager;
    this.mapComponent.initializeDrawingManager(this.polygonDrawingManager);
    return new google.maps.Polygon(polygonConfig);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
