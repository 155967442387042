import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {API, formatter} from '@configs/api.config';
import {WorkOrder} from '@control-tower/models/work-order/work-order';
import {WorkOrderDefinition} from '@control-tower/models/work-order/work-order-definition.model';
import {groupBy, map, mergeMap, reduce, toArray} from 'rxjs/operators';
import {EntityMap} from '@shared/models/types';
import {IncidentModel} from '@control-tower/models/incident.model';
import {IncidentActionType} from '@control-tower/models/enums/incident-action-type.enum';
import {ActivityDocumentsModel} from '@control-tower/models/work-order/activity-documents.model';
import {OverriddenDistanceInfo} from '@control-tower/models/jpm/overridden-distance.info';
import {DistanceType} from '@control-tower/models/enums/distance-type.enum';
import {EmptyResponse} from '@core/empty-response.model';
import {ActivityModifier} from '@control-tower/models/activity';
import {getWorkOderDefinitions} from '@control-tower/test-data/jpm.test';
import { BillingStatusModifier } from '@control-tower/models/billing-status-modifier.model';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderService {

  constructor(private httpClient: HttpClient) { }

  getWorkOrdersByJpmIdAndLegId(jpmId: string, legId: string): Observable<WorkOrder[]> {
    const url = API.jobFile.getWorkOrdersByJpmIdAndLegId.format([jpmId, legId]);
    return this.httpClient.get<WorkOrder[]>(url);
  }

  getWorkOrdersByJpmId(jpmId: string): Observable<WorkOrder[]> {
    const url = API.jobFile.getWorkOrdersByJpmId.format([jpmId]);
    return this.httpClient.get<WorkOrder[]>(url);
  }

  getWorkOrderByWorkOrderId(workOrderId: string): Observable<WorkOrder> {
    const url = API.jobFile.getWorkOrdersByWorkOrderId.format([workOrderId]);
    return this.httpClient.get<WorkOrder>(url);
  }

  deleteWorkOrderByWorkOrderId(workOrderId: string): Observable<any> {
    const url = API.jobFile.deleteWorkOrdersByWorkOrderId.format([workOrderId]);
    return this.httpClient.delete(url);
  }

  getWorkOrderDefinitionsByJpmIdAndLegId(jpmId: string, legId: string): Observable<WorkOrderDefinition[]> {
    const url = API.jobFile.getWorkOrdersDefinitionsByJpmIdAndLegId.format([jpmId, legId]);
    return this.httpClient.get<WorkOrderDefinition[]>(url);
  }

  getWorkOrderMonitoringActivitiesByOrgIdAndJobRefIdAndContainerId(orgId: string, jobRefId: string, containerId: string)
    : Observable<WorkOrder[]> {
    const url = API.jobFile.getWorkOrderMonitoringActivitiesByOrdIdAndJobRefIdAndContainerId.format([orgId, jobRefId, containerId]);
    return this.httpClient.get<WorkOrder[]>(url);
  }

  getActivityDocumentsForJpmId(jpmId: string): Observable<ActivityDocumentsModel[]> {
    const url = API.jobFile.getActivityDocumentsForJpm.format([jpmId]);
    return this.httpClient.get<ActivityDocumentsModel[]>(url);
  }

  markAsDoneTask(urlSuffix: string, data: any): Observable<{}> {
    const url = `${API.jpm.makeAsDoneTask}${urlSuffix}`;
    return this.httpClient.post<{}>(url, data);
  }

  markAsUndoneTask(workOrderId: string): Observable<any> {
    const url = API.jpm.markAsUnDone.format([workOrderId]);
    return this.httpClient.post<any>(url, null);
  }

  markAsCancel(workOrderId: string): Observable<any> {
    const url = API.jpm.markAsCancel.format([workOrderId]);
    return this.httpClient.post<any>(url, null);
  }

  markAsInvoke(workOrderId: string): Observable<any> {
    const url = API.jpm.markAsInvoke.format([workOrderId]);
    return this.httpClient.post<any>(url, null);
  }

  setWorkOrderGeoCoordinate(jpmId: string, workOrderId: string, vehicleId: string): Observable<any> {
    const url = API.jpm.setWorkOrderGeoCoordinate.format([jpmId, workOrderId, vehicleId]);
    return this.httpClient.post<any>(url, null);
  }

  getActivityList(): Observable<WorkOrderDefinition[]> {
    const url = API.jobFile.activityList;
    return this.httpClient.get<WorkOrderDefinition[]>(url);
  }

  getWorkOrderDefinitionByWorkOrderId(workOrderId: string): Observable<WorkOrderDefinition> {
    const url = API.jobFile.getWorkOrderDefinition.format([workOrderId]);
    return this.httpClient.get<WorkOrderDefinition>(url);
  }

  createActivity(activity: any): Observable<any> {
    const url = API.jobFile.createActivity;
    return this.httpClient.post<any>(url, activity);
  }

  moveActivity(activity: any): Observable<any> {
    const url = API.jobFile.moveActivity;
    return this.httpClient.post<any>(url, activity);
  }

  createUnPlannedStopActivity(activity: any): Observable<any> {
    const url = API.jobFile.createUnPlannedStopActivity;
    return this.httpClient.post<any>(url, activity);
  }

   removeActivity(activityId: string, by: string): Observable<any> {
    const url = API.jobFile.removeActivity.format([activityId, by]);
    return this.httpClient.delete<any>(url);
  }

  modifyActivity(activity: any): Observable<any> {
    const url = API.jobFile.modifyActivity;
    return this.httpClient.post<any>(url, activity);
  }

  groupAndSortBy(entities: any[], groupByProperty: string, sortBy: string): Observable<EntityMap<string, any[]>> {
    return from(entities)
      .pipe(
        groupBy(activity => activity[groupByProperty]),
        mergeMap(group => group.pipe(toArray())),
        map(group => group.sort((a, b) => {
          if (a[sortBy] === b[sortBy]) {
            return 0;
          }
          return a[sortBy] > b[sortBy] ? 1 : -1;
        })),
        reduce((activityMap, group) => ({...activityMap, [group[0][groupByProperty]]: group}), {}),
      );
  }

  createIncidentsMap(incidents: IncidentModel[], groupByActivityId: string): Observable<EntityMap<string, IncidentModel[]>> {
    return from(incidents)
      .pipe(
        groupBy(incident => incident[groupByActivityId]),
        mergeMap(group => group.pipe(toArray())),
        reduce((incidentMap, group) => ({...incidentMap, [group[0][groupByActivityId]]: group}), {}),
      );
  }

  createIncidentsMapForAllIncidents(incidents: IncidentModel[]): Observable<EntityMap<string, IncidentModel[]>> {
    return from(incidents)
      .pipe(
        groupBy(incident => incident.activityId),
        mergeMap(group => group.pipe(toArray())),
        reduce((incidentMap, group) => ({...incidentMap, [group[0].activityId]: group}), {}),
      );
  }

  addSpecialInstructionToWorkOrder(specialInstructions: string, workOrderPersistentId: string): Observable<WorkOrder> {
    const url = API.jobFile.saveSpecialInstructionOfActivity.format([workOrderPersistentId]);
    return this.httpClient.post<WorkOrder>(url, specialInstructions);
  }

  reportTheIncident(incidentModel: IncidentModel): Observable<IncidentModel> {
    const url = API.jpm.reportIncident;
    return this.httpClient.post<IncidentModel>(url, incidentModel);
  }

  getReportIncidents(jpmId: string, legId: string, activityId: string): Observable<IncidentModel[]> {
    const url = API.jpm.getReportIncidents.format([jpmId, legId, activityId]);
    return this.httpClient.get<IncidentModel[]>(url);
  }

  getAllIncidents(jpmId: string): Observable<IncidentModel[]> {
    const  url = API.jpm.getAllIncidents.format([jpmId]);
    return this.httpClient.get<IncidentModel[]>(url);
  }

  updateIncidentAction(incidentId: string, action: IncidentActionType, previousWorkOrderId: string): Observable<IncidentModel> {
    const url = API.jpm.updateIncidentAction.format([incidentId, action, previousWorkOrderId]);
    return this.httpClient.post<IncidentModel>(url, null);
  }

  modifyUrlReplacingParameters(url: string, jpmId: string, legId: string, workOrderPersistentId: string,
                               completionTime: Date, containerId: string, workOrderId?: string): string {
    const preDefineJpmId = /{jpmId}/gi;
    const preDefineLegId = /{legId}/gi;
    const preDefineWorkOrderPersistentId = /{workOrderPersistentId}/gi;
    const preDefineCompletionTime = /{completionTime}/gi;
    const preDefineContainerId = /{containerId}/gi;
    const preDefinedWorkOrderId = /{workOrderId}/gi;
    return url
      .replace(preDefineJpmId, jpmId)
      .replace(preDefineLegId, legId)
      .replace(preDefineWorkOrderPersistentId, workOrderPersistentId)
      .replace(preDefineContainerId, containerId)
      .replace(preDefineCompletionTime, completionTime.toISOString())
      .replace(preDefinedWorkOrderId, workOrderId);
  }

  getWorkOptionalOrderDefinitionsForActivity(activityId: string, previousWorkOrderId: string, orgId?: string, jobRefId?: string): Observable<WorkOrderDefinition[]> {
    const url = API.jobFile.getOptionalWorkOrdersDefinitions.format([activityId, previousWorkOrderId, orgId, jobRefId]);
    return this.httpClient.get<any>(url);
  }

  createAdditionalWorkOrders(previousWorkOrderId: string, workOrderDefinitions: string[]): Observable<WorkOrder[]> {
    const url = API.jobFile.addAdditionalWorkOrders.format([previousWorkOrderId]);
    return this.httpClient.post<WorkOrder[]>(url, workOrderDefinitions);
  }

  // overrideDistance(jpmLeg: string, jpmId: string,
  //                  readingType: string, isOverride: boolean, odometerReading: string): Observable<WorkOrder[]> {
  //   const url = API.jobFile.modifyLegDistance.format([jpmId, jpmLeg]);
  //   // return this.httpClient.post<WorkOrder[]>(url, workOrderDefinitions);
  //   return null;
  // }

  overrideDistance(jpmId: string,
                   readingType: string, isOverride: boolean, odometerReading: string): Observable<EmptyResponse> {
    const overriddenInfo: OverriddenDistanceInfo = {
      overridden: isOverride,
      overriddenDistance: odometerReading,
      distanceType: readingType === 'GPS Reading' ? DistanceType.GPS : DistanceType.MANUAL
    };
    const url = API.jpm.modifyJobDistance.format([jpmId]);
    return this.httpClient.post<EmptyResponse>(url, overriddenInfo);
  }

  deleteIncident(incidentId: string): Observable<void> {
    const url = API.jpm.deleteIncident.format([incidentId]);
    return this.httpClient.delete<void>(url);
  }

  getWorkOrderDefinitionList(workOrderIds: string[]): Observable<WorkOrderDefinition[]> {
    const url = API.jobFile.getWorkOrderDefinitionList;
    return this.httpClient.post<WorkOrderDefinition[]>(url, workOrderIds);
  }

  getWorkOrderDefinitionListWithChildren(workOrderIds: string[]): Observable<WorkOrderDefinition[]> {
    const url = API.jobFile.getWorkOrderDefinitionListWithChildren;
    return this.httpClient.post<WorkOrderDefinition[]>(url, workOrderIds);
  }

  getWorkOrderDefinitionsOfAdditionalActivitiesByWorkFlowId(workFlowId: string): Observable<WorkOrderDefinition[]> {
    const url = API.jobFile.getWorkOrderDefinitionsOfAdditionalActivitiesByWorkFlowId.format([workFlowId]);
    return this.httpClient.get<WorkOrderDefinition[]>(url);
  }

  modifyBillingStatus(activityId: string, billingStatusModifier: BillingStatusModifier) {
    const url = API.jobFile.modifyActivityBilling.format([activityId]);
    return this.httpClient.post(url, billingStatusModifier);
  }

  reTryDeferredActivity(activityId: string) {
    const url = API.jobFile.reTryDeferredActivity.format([activityId]);
    return this.httpClient.post(url, null);
  }

  deferActivity(incident: IncidentModel) {
    const url = API.jobFile.deferActivity;
    return this.httpClient.post(url, incident);
  }
}
