import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateTimeFilter } from 'app/modules/gps-manager/models/date-time-filter';


@Component({
  selector: 'hmt-date-time-filter-bar',
  templateUrl: './date-time-filter-bar.component.html',
  styleUrls: ['./date-time-filter-bar.component.scss']
})
export class DateTimeFilterBarComponent implements OnInit {
  @Output() dateTimeFilterChange = new EventEmitter<DateTimeFilter>();
  filterForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      fromDate: [new Date(Date.now() - 60 * 24 * 60 * 60 * 1000)], // 60 days ago
      toDate: [new Date()] // current date
    });
    // Emit default values on initialization
    this.dateTimeFilterChange.emit(this.filterForm.value);
    // Subscribe to form changes
    this.filterForm.valueChanges.subscribe((values: DateTimeFilter) => {
      this.dateTimeFilterChange.emit(values);
    });
  }
}
