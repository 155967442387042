import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';
import {User} from '@core/models/user.model';
import {GraphqlClientService} from '@core/services/graphql-client.service';
import {AuthState} from '@core/store/auth/auth.state';
import { LoadCountryById, LoadOrgById } from '@core/store/shared.actions';
import {SharedState} from '@core/store/shared.state';
import {environment} from '@env/environment';
import {Select, Store} from '@ngxs/store';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';
import { filter, mergeMap, take, tap } from 'rxjs/operators';
import { NavigationConfig } from './modules/organization/models/organization';

@Component({
  selector: 'hmt-app-wrapper',
  templateUrl: './app-wrapper.component.html',
  styleUrls: ['./app-wrapper.component.scss']
})
export class AppWrapperComponent implements OnInit {

  @Select(AuthState.isAuthenticated) isAuthenticated: Observable<boolean>;
  @Select(AuthState.getUser) user: Observable<User>;
  @Select(SharedState.getSpinnerStatus) isSpinnerEnabled: Observable<{spinner: boolean, spinnerMessage: string}>;
  @Select(SharedState.getNavigationConfig) navBarModuleConfigs$: Observable<NavigationConfig[]>;

  title = 'Haulmatic';
  @ViewChild('sidenav') sidenav: MatSidenav;
  expanded = false;
  isShowing = false;
  developer = environment.developer;
  version = environment.version;
  userOrganizationId: string;
  isLoading = false;

  constructor(private store: Store,
              private router: Router,
              private cookiesService: CookieService,
              private gqlService: GraphqlClientService,
  ) {
    this.userOrganizationId = this.cookiesService.get('orgId');
    this.gqlService.createLinkWithGQLServer();

    this.user
      .pipe(
        filter(u => u != null),
        take(1),
        mergeMap(user => {
          return this.store.dispatch(new LoadOrgById(user.orgId));
        }),
        mergeMap(_ => {
          return this.store.dispatch(new LoadCountryById());
        }),
      )
      .subscribe();
  }

  ngOnInit() {
  }

  mouseenter() {
    if (!this.expanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.expanded) {
      this.isShowing = false;
    }
  }

  onToggle() {
    this.expanded = !this.expanded;
  }

  collapseModule(module: NavigationConfig): void {
      this.navigateTo(module);
  }

  navigateTo(module: NavigationConfig): void {
    this.router.navigate([module.navigationUrl]);
  }
}



