import {Component, Input, OnInit} from '@angular/core';
import {CustomerInfo} from '@control-tower/models/customer-info.model';
import {OrgInfo} from '../../../contract/models/orgInfo.model';

@Component({
  selector: 'hmt-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {

  @Input() data: { orgInfo: OrgInfo, title: string; };

  constructor() {
  }

  ngOnInit(): void {
  }

}
