<div class="add-container-bulk">
  <button class="add-container" mat-raised-button color="accent"
          (click)="addContainerGroup()">
    Add {{utilService.getRelevantTitleBasedOnKeyOfLoadType(jobFile?.loadType)}} Group
  </button>
  <div class="containers-group-container" [formGroup]="formGroup">
    <div formArrayName="containerGroups"
         *ngFor="let containerGroup of formGroup['controls']?.containerGroups['controls']; let i = index">
      <div [formGroupName]="i">
        <div class="container-group">
          <div class="row-1">
            <div class="remove-container-group" (click)="removeContainerGroup(i)">
              <mat-icon>close</mat-icon>
            </div>
          </div>
          <div class="row-2">
            <mat-form-field class="type-and-size">
              <mat-select
                formControlName="containerType"
                placeholder="Select Container type and Size">
                <mat-option *ngFor="let typeAndSize of containerTypesAndSizes[i]"
                            [value]="typeAndSize"
                (click)="setNextTypeAndSizeArray(i)">
                  {{typeAndSize.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row-2">

          </div>
          <div class="row-3">
            <mat-form-field class="text-area" appearance="fill">
              <textarea
                (paste)="generateContainerNumbers(i)"
                (keyup.enter)="generateContainerNumbers(i)"
                matInput
                formControlName="containers">
              </textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="apply">
      <button mat-raised-button color="accent" (click)="addAllContainers()">Apply</button>
    </div>
  </div>
  <div class="container-viewer">
    <mat-chip-list class="chip-row" *ngFor="let container of containers | keyvalue">
      <div class=" title">{{container?.key}} Containers</div>
      <div class="chip-container">
        <mat-chip
          *ngFor="let item of container.value"
          [selectable]="true"
          [removable]="true"
          (removed)="removeContainerNumber(container.key, item?.containerNumber)"
          class="chip">
          {{item?.containerNumber}} | {{item?.typeAndSize?.label}}
          <mat-icon matChipRemove *ngIf="true" class="cancel">cancel</mat-icon>
        </mat-chip>
      </div>
    </mat-chip-list>
  </div>
  <button mat-raised-button color="accent" [disabled]="enableDisableGenerateContainers()" (click)="generateContainers()">Generate {{utilService.getRelevantTitleBasedOnKeyOfLoadType(jobFile?.loadType)}} Loads</button>
</div>

