import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: string | Date): string {
    if (!value) return '';

    // Parse the input value as a Date object
    const date = typeof value === 'string' ? new Date(value) : value;

    // Create a DatePipe instance to format the time
    const datePipe = new DatePipe('en-US');

    // Format the time using the 'shortTime' format ('h:mm a')
    const formattedTime = datePipe.transform(date, 'shortTime');

    // Remove leading '0' from the hour if present
    const parts = formattedTime.split(':');
    const hour = parseInt(parts[0], 10).toString();
    const minutes = parts[1];

    return `${hour}:${minutes}`;
  }
}
