<div class="select-terms-cointainer">
  <form [formGroup]="form">
    <mat-radio-group formControlName="selectedRadio">
      <div class="card" [ngStyle]="{'border-left': status == 1 ? '3px solid #6779CE' : '1px solid #BDBDBD' }">
        <mat-radio-button id="radioInAdvance" [checked]="isRadioInAdvance" [value]="1"
                          (click)="changeRadio('InAdvance')">
          Payment in Advance (100% payment in advance)
        </mat-radio-button>
      </div>

      <div class="card" [ngStyle]="{'border-left': status == 2 ? '3px solid #6779CE' : '1px solid #BDBDBD' }">
        <mat-radio-button id="radioImmediatelyCompletion" [checked]="isRadioImmediatelyCompletion" [value]="2"
                          (click)="changeRadio('ImmediatelyCompletion')">
          Payment immediately on completion
        </mat-radio-button>
      </div>

      <div class="card" [ngStyle]="{'border-left': status == 3 ? '3px solid #6779CE' : '1px solid #BDBDBD' }">
        <mat-radio-button id="radioDaysUponCompletion" [checked]="isRadioDaysUponCompletion" [value]="3"
                          (click)="changeRadio('DaysUponCompletion')">
          Payment in
        </mat-radio-button>
        <ng-container formGroupName="formDaysUponCompletion">
          <span class="spam-input"><input type="number" (input)="changeDaysUponCompletion()" placeholder="Credit days"
                                          [disabled]="isRadioDaysUponCompletion"
                                          formControlName="daysUponCompletionCreditDays"></span>days upon completion
        </ng-container>
        <div class="errors" *ngIf="form.get('formDaysUponCompletion.daysUponCompletionCreditDays').errors?.min">
          Credit days should grater than 0.
        </div>
        <div class="errors"
             *ngIf="form.get('formDaysUponCompletion.daysUponCompletionCreditDays').errors?.required">
          Credit days should be entered.
        </div>

      </div>

      <div class="card" [ngStyle]="{'border-left': status == 4 ? '3px solid #6779CE' : '1px solid #BDBDBD' }">
        <mat-radio-button id="radioAdvanceInValue" [checked]="isRadioAdvanceInValue" [value]="4"
                          (click)="changeRadio('AdvanceInValue')">
          Payment
        </mat-radio-button>
        <ng-container formGroupName="formAdvanceInValue">
          <span class="spam-input"><input type="number" (input)="changeAdvanceInValueAdvance()" placeholder="Advance"
                                          formControlName="advanceInValueAdvance"></span>
          in advance,<span class="spam-input"><input disabled formControlName="advanceInValueBalance" placeholder="Balance"></span> balance
          upon completion
        </ng-container>
        <div class="errors" *ngIf="form.get('formAdvanceInValue.advanceInValueAdvance').errors?.max">
          Advance should be lower than {{total}}.
        </div>
        <div class="errors" *ngIf="form.get('formAdvanceInValue.advanceInValueAdvance').errors?.min">
          Advance should be grater than 0.
        </div>
        <div class="errors" *ngIf="form.get('formAdvanceInValue.advanceInValueAdvance').errors?.required">
          Advance should be entered.
        </div>
      </div>

      <div class="card" [ngStyle]="{'border-left': status == 5 ? '3px solid #6779CE' : '1px solid #BDBDBD' }">
        <mat-radio-button [checked]="isRadioAdvanceInPercentage" [value]="5"
                          (click)="changeRadio('AdvanceInPercentage')">
          Payment
        </mat-radio-button>
        <ng-container formGroupName="formAdvanceInPercentage">
          <span class="spam-input"><input type="number" (input)="changeAdvanceInPercentageAdvance()" placeholder="Advance"
                                          formControlName="advanceInPercentageAdvance">%</span>
          in advance,<span class="spam-input"><input disabled formControlName="advanceInPercentageBalance" placeholder="Balance"
                                                     type="number">%</span>
          balance upon completion
        </ng-container>
        <div class="errors" *ngIf="form.get('formAdvanceInPercentage.advanceInPercentageAdvance').errors?.max">
          Advance should be lower than {{paymentInPercentage}} %.
        </div>
        <div class="errors" *ngIf="form.get('formAdvanceInPercentage.advanceInPercentageAdvance').errors?.min">
          Advance should be grater than 0 %.
        </div>
        <div class="errors"
             *ngIf="form.get('formAdvanceInPercentage.advanceInPercentageAdvance').errors?.required">
          Advance should be entered.
        </div>
      </div>

      <div class="card" [ngStyle]="{'border-left': status == 6 ? '3px solid #6779CE' : '1px solid #BDBDBD' }">
        <mat-radio-button [checked]="isRadioAdvanceInPercentageAndDays" [value]="6"
                          (click)="changeRadio('AdvanceInPercentageAndDays')">
          Payment
        </mat-radio-button>
        <ng-container formGroupName="formAdvanceInPercentageAndDays">
          <span class="spam-input"><input type="number" (input)="changeAdvanceInPercentageAndDays()" placeholder="Advance"
                                        formControlName="advanceInPercentageAndDaysAdvance">%</span>
          in advance,<span class="spam-input"><input type="number" disabled placeholder="Balance"
                                                     formControlName="advanceInPercentageAndDaysBalance">%</span>
          balance
          and
          <span class="spam-input"><input type="number" (input)="changeAdvanceInPercentageAndDays()" placeholder="Credit days"
                                          formControlName="advanceInPercentageAndDaysCreditDays"></span>number of days
          upon completion
        </ng-container>
        <div class="errors" *ngIf="form.get('formAdvanceInPercentageAndDays.advanceInPercentageAndDaysAdvance').errors?.max">
          Advance should be lower than {{paymentInPercentage}} %.
        </div>
        <div class="errors" *ngIf="form.get('formAdvanceInPercentageAndDays.advanceInPercentageAndDaysAdvance').errors?.min">
          Advance should be grater than 0 %.
        </div>
        <div class="errors" *ngIf="form.get('formAdvanceInPercentageAndDays.advanceInPercentageAndDaysAdvance').errors?.required">
          Advance should be entered.
        </div>
        <div class="errors" *ngIf="form.get('formAdvanceInPercentageAndDays.advanceInPercentageAndDaysCreditDays').errors?.required">
          Credit days should be entered.
        </div>
        <div class="errors" *ngIf="form.get('formAdvanceInPercentageAndDays.advanceInPercentageAndDaysCreditDays').errors?.min">
          Credit days should be grater than 0.
        </div>
      </div>

      <div class="card" [ngStyle]="{'border-left': status == 7 ? '3px solid #6779CE' : '1px solid #BDBDBD' }">
        <mat-radio-button [checked]="isRadioAdvanceInValueAndDays" [value]="7"
                          (click)="changeRadio('AdvanceInValueAndDays')">
          Payment
        </mat-radio-button>
        <ng-container formGroupName="formAdvanceInValueAndDays">
        <span class="spam-input"><input type="number" (input)="changeAdvanceInValueAndDays()" placeholder="Advance"
                                        formControlName="advanceInValueAndDaysAdvance"></span>
        in advance,<span class="spam-input"><input type="number" disabled placeholder="Balance"
                                                   formControlName="advanceInValueAndDaysBalance"></span> balance and
        <span class="spam-input"><input type="number" (input)="changeAdvanceInValueAndDays()" placeholder="Credit days"
                                        formControlName="advanceInValueAndDaysCreditDays"></span>number of days upon
        completion
        </ng-container>
        <div class="errors" *ngIf="form.get('formAdvanceInValueAndDays.advanceInValueAndDaysAdvance').errors?.max">
          Advance should be lower than {{total}}.
        </div>
        <div class="errors" *ngIf="form.get('formAdvanceInValueAndDays.advanceInValueAndDaysAdvance').errors?.min">
          Advance should be greter than 0.
        </div>
        <div class="errors" *ngIf="form.get('formAdvanceInValueAndDays.advanceInValueAndDaysAdvance').errors?.required">
          Advance should be entered.
        </div>
        <div class="errors" *ngIf="form.get('formAdvanceInValueAndDays.advanceInValueAndDaysCreditDays').errors?.min">
          Credit days should grater than 0.
        </div>
        <div class="errors" *ngIf="form.get('formAdvanceInValueAndDays.advanceInValueAndDaysCreditDays').errors?.required">
          Credit days should entered.
        </div>
      </div>
    </mat-radio-group>
  </form>
</div>
